export interface Entry {
    id: string,
    eventId: string,
    customerId: string
    isDeleted: boolean;
    editSource: EntryEditSource;
    number: number;
}

export interface EntryForCustomer extends Entry {
    fullName: string;
}

export enum EntryEditSource {
    Booking = "Booking",
    Entry = "Entry"
}

export const getDefaultEntry = (customerId: string, eventId: string): Entry => ({
    customerId: customerId,
    eventId: eventId,
    id: null,
    isDeleted: false
} as Entry);