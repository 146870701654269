import * as React from "react";
import { MenuItemProps } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { ConnectedEntriesList } from '../connected/connected.list';
import { ConnectedSearchSearchForm } from '../connected/connected.searchform';
import { ConnectedSelectionModal } from '../../customers/connected/connected.selectionmodal';
import { StickyBodyHeader } from "../../base/stickybodyheader";

export interface EntriesPageListProps {
    totalCount: number;
    totalPlayers: number;
    eventFullName: string;

}

export interface EntriesPageListCallbacks {
    onLoad: (eventId: string) => void;
    onCustomersModelOpen: () => void;
    onCustomersModalConfirm: () => void;
}

export class EntriesPageList extends React.Component<EntriesPageListProps & EntriesPageListCallbacks> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let eventId = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                eventId = (this.props as any).match.params.eventId;
            this.props.onLoad(eventId);
        }
    }

    render() {

        let eventId = null;
        if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
            eventId = (this.props as any).match.params.eventId;

        let menuItems = [
            {
                icon: "plus",
                label: "crea",
                isButton: true,
                isSmall: true,
                onClick: () => this.props.onCustomersModelOpen()
            },
            {
                icon: "ellipsis-h",
                label: "Evento",
                isButton: false,
                isSmall: true,
                path: `/events/${(this.props as any).match.params.eventId}/menu`
            } as MenuItemProps
        ] as Array<MenuItemProps>;

        return (
            <React.Fragment>
                <StickyBodyHeader>
                    <BodyHeader subTitle={this.props.eventFullName} title={`Entries (${this.props.totalCount}), Players (${this.props.totalPlayers})`} menuItems={menuItems} />
                    <ConnectedSearchSearchForm />
                </StickyBodyHeader>
                <ConnectedEntriesList />
                <ConnectedSelectionModal onConfirm={() => this.props.onCustomersModalConfirm()} />
            </React.Fragment>
        )
    }
}