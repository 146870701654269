import { ReservationStatus } from '../reservations/ReservationEntities';

export interface CommunicationListModel {
    eventId: string;
    locationId: string;
    communicationsCount: string;
}

export interface Communication {
    id: string;
    prevCheckIn: string;
    prevCheckOut: string;
    prevRoomType: string
    actCheckIn: string;
    actCheckOut: string;
    locationId: string;
    actRoomType: string;
    Status: ReservationStatus;
    communicationType: CommunicationType;
    createdDate: string;
    createdBy: string;
    sentDate: string;
    communicationSentsId: string;
    progressiveNumber: number;
    reservationStatus: ReservationStatus;
    eventId: string;
    reservationId: string;
    isStaffBooking: boolean;
}

export interface CommunicationSent {
    id: string;
    text: string;
    sentBy: string;
    sentDate: string;
    locationId: string;
    eventId: string;
    communicationList: Array<Communication>;
    locationName: string;
    profileFullName: string;
}

export enum CommunicationType {
    New = "New",
    Modified = "Modified",
    Canceled = "Canceled"
}