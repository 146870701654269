import * as React from "react";
import { PaymentMethod } from "../../../../store/payments/paymentmethods/PaymentMethodEntities";
import { BaseList } from '../../../base/baseList';
import GoToTop from "../../../base/gototop";


export interface PaymentMethodListProps {
    list: Array<PaymentMethod>;
}

export interface PaymentMethodListCallbacks {
    onRowClick?: (id: string) => void;
}

export class PaymentMethodList extends React.Component<PaymentMethodListProps & PaymentMethodListCallbacks>{

    constructor(props) {
        super(props);
    }

    private onRowClick(item: PaymentMethod) {

        this.props.onRowClick(item.id);
    }

    private getRows(list: Array<PaymentMethod>): JSX.Element[] {

        let elements = list.map(element => {
            
            let baseStyle = { cursor: "pointer" };

            if (element.isCustom) {
                return (
                    <tr key={element.id} style={baseStyle} onClick={() => this.onRowClick(element)}>
                        <td>
                            {element.method}
                        </td>
                    </tr>
                )
            } else {
                return (
                    <tr key={element.id}>
                        <td>
                            {element.method}
                        </td>
                    </tr>
                )
            }


        });

        return elements;

    }

    private getHead(): JSX.Element {

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>Metodo</th>
                </tr>
            </thead>
        );

    }

    render() {

        return (
            <React.Fragment>
                <BaseList hasNextPage={false} list={this.props.list} active={false} onEndOfList={() => { }} getElements={(items: Array<PaymentMethod>) => { return this.getRows(items) }} getHead={this.getHead} />
                <GoToTop />
            </React.Fragment>
        )
    }
}