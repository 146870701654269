import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from '../../../store/index';
import { actionMiddleware } from '../../../store/reservations/ReservationState';
import { ReservationEditPage, ReservationEditPageCallbacks, ReservationEditPageProps } from '../pages/editpage';


const mapStateToProsp = (state: ApplicationState): ReservationEditPageProps => {
    return {
        reservation: state.reservations.running !== undefined && state.reservations.running != null ? state.reservations.running.reservation : null,
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name.toUpperCase()}` : ''
    }
}

const mapDispatchToProps = () => (dispatch): ReservationEditPageCallbacks => {
    return {
        onDelete: () => dispatch(actionMiddleware.delete()),
        onLoad: (id?: string) => dispatch(actionMiddleware.setRunningState(id)),
        onSubmit: () => dispatch(actionMiddleware.onSubmit())
    }
}

export const ConnectedReservationEditPage = withRouter(connect<ReservationEditPageProps, ReservationEditPageCallbacks>(mapStateToProsp, mapDispatchToProps)(ReservationEditPage));