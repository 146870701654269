import { Action, Reducer } from 'redux';
import { ApplicationState } from '../';
import dotProp from "dot-prop";
import { uploadDocumentRequest, RequestModel, RequestMethods, ajaxRequest, checkRefreshToken, SaveRequest } from '../../utils/ajaxutilities';
import _ from "underscore";
import { Document } from './DocumentsEntities';
import { actionCreator as actionCreatorUI } from '../uiproperties/UIState';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export interface DocumentState {
    runnings: Array<RunningDocument>;
    editState: DocumentEditState;
    modalEditOpened: boolean;
}

export interface DocumentEditState {
    objectId: string;
    documentId: string;
    name: string;
}

export interface RunningDocument {
    list: Array<Document>;
    objectId: string;
    objectName: string;
    runningLoaded: boolean;
}

const getBaseState = (): DocumentState => ({
    runnings: [],
    editState: null,
    modalEditOpened: false
});

const getBaseRunning = (): RunningDocument => ({
    list: [],
    objectId: null,
    objectName: null,
    runningLoaded: false
});

enum TypeKeys {
    SET_OBJECT_RUNNING = "DOCUMENTS_SET_OBJECT_RUNNING",
    APPEND_DOCUMENT_TO_RUNNING = "DOCUMENTS_APPEND_DOCUMENT_TO_RUNNING",
    RUNNING_LOADED = "DOCUMENTS_RUNNING_LOADED",
    DELETE_DOCUMENT = "DOCUMENTS_DELETE_DOCUMENT",
    TOOGLE_EDIT_MODAL = "DOCUMENTS_TOOGLE_EDIT_MODAL",
    SET_MODAL_EDIT_STATE = "DOCUMENTS_SET_MODAL_EDIT_STATE",
    SET_EDIT_STATE = "DOCUMENTS_SET_EDIT_STATE",
    DELETE_OBJECT_RUNNING = "DOCUMENTS_DELETE_OBJECT_RUNNING"
}


interface DeleteObjectRunning extends Action {
    type: TypeKeys.DELETE_OBJECT_RUNNING
    objectId: string;
}

interface SetEditState extends Action {
    type: TypeKeys.SET_EDIT_STATE;
    editState: DocumentEditState;
}

interface ToogleEditModal extends Action {
    type: TypeKeys.TOOGLE_EDIT_MODAL;
    isOpened: boolean;
}

interface SetObjectRunning extends Action {
    type: TypeKeys.SET_OBJECT_RUNNING;
    model: RunningDocument;
    objectId: string;
}

interface AppendDocument extends Action {
    type: TypeKeys.APPEND_DOCUMENT_TO_RUNNING;
    model: Document;
    objectId: string;
}

interface SetRunningLoaded extends Action {
    type: TypeKeys.RUNNING_LOADED;
    loaded: boolean;
    objectId: string;
}

type DocumentAction = SetObjectRunning
    | AppendDocument
    | SetRunningLoaded
    | ToogleEditModal
    | SetEditState
    | DeleteObjectRunning;

export const actionsCreator = {
    setObjectRunning: (model: RunningDocument, objectId: string): SetObjectRunning => ({
        type: TypeKeys.SET_OBJECT_RUNNING,
        model: model,
        objectId: objectId
    }),
    setAppendDocument: (model: Document, objectId: string): AppendDocument => ({
        type: TypeKeys.APPEND_DOCUMENT_TO_RUNNING,
        model: model,
        objectId: objectId
    }),
    setRunningLoaded: (loaded: boolean, objectId: string): SetRunningLoaded => ({
        type: TypeKeys.RUNNING_LOADED,
        loaded: loaded,
        objectId: objectId
    }),
    toogleEditModal: (isOpened: boolean): ToogleEditModal => ({
        type: TypeKeys.TOOGLE_EDIT_MODAL,
        isOpened: isOpened
    }),
    setEditState: (editState: DocumentEditState): SetEditState => ({
        type: TypeKeys.SET_EDIT_STATE,
        editState: editState
    }),
    deleteObjectRunning: (objectId: string): DeleteObjectRunning => ({
        type: TypeKeys.DELETE_OBJECT_RUNNING,
        objectId: objectId
    })
}


export const actionMiddleware = {
    loadDocumentsForObject: (objectId: string, objectName: string) => (dispatch, getState: () => ApplicationState) => {
        let newRunning = getBaseRunning();
        newRunning.objectId = objectId;
        newRunning.objectName = objectName;
        newRunning.runningLoaded = false;
        dispatch(actionsCreator.setObjectRunning(newRunning, objectId));

        let requestList = new RequestModel(`/documents/listforobject/${objectId}`, RequestMethods.GET);

        ajaxRequest<Array<Document>>(requestList)(dispatch, getState).then(response => {

            if (response.success == false)
                return;

            newRunning.list = response.entity;
            newRunning.runningLoaded = true;

            dispatch(actionsCreator.setObjectRunning({ ...newRunning }, objectId));

        });
    },
    onUpload: (file: FormData, objectId: string, objectName: string) => (dispatch, getState: () => ApplicationState) => {

        uploadDocumentRequest(file, objectId, objectName)(dispatch, getState).then(response => {
            if (response.success == false)
                return;
            dispatch(actionsCreator.setAppendDocument(response.entity, objectId));
        });

    },
    onDelete: (id: string, objectId: string) => (dispatch, getState: () => ApplicationState) => {

        let runningDoc = _.find(getState().documents.runnings, (r) => r.objectId == objectId);

        if (runningDoc === undefined || runningDoc == null)
            return;

        let newRunningDoc = { ...runningDoc };

        let deleteRequest = new SaveRequest(`/documents/${id}`, RequestMethods.DELETE, null);

        ajaxRequest<any>(deleteRequest)(dispatch, getState).then(response => {

            if (response.success == false)
                return;

            let documentsForObject = [];

            newRunningDoc.list.forEach(x => {
                if (x.id != id)
                    documentsForObject.push(x);
            })

            newRunningDoc.list = documentsForObject;

            dispatch(actionsCreator.setObjectRunning({ ...newRunningDoc }, objectId));

        });
    },
    onOpenEditModal: (objectId: string, documentId: string, name: string) => (dispatch, getState: () => ApplicationState) => {
        let editState: DocumentEditState = {
            documentId: documentId,
            name: name,
            objectId: objectId
        };

        dispatch(actionsCreator.setEditState(editState));
        dispatch(actionsCreator.toogleEditModal(true));
    },
    onChangeName: (name: string) => (dispatch, getState: () => ApplicationState) => {
        let editState = { ...getState().documents.editState };
        editState.name = name;
        dispatch(actionsCreator.setEditState(editState));
    },
    onCloseModal: () => (dispatch, getState: () => ApplicationState) => {
        dispatch(actionsCreator.toogleEditModal(false));
        dispatch(actionsCreator.setEditState(null));
    },
    onEditSubmit: () => (dispatch, getState: () => ApplicationState) => {

        let runningDoc = getState().documents.editState;

        if (runningDoc === undefined || runningDoc == null)
            return;

        let updateRequest = new SaveRequest(`/documents/update/${runningDoc.documentId}/${runningDoc.name}`, RequestMethods.POST, null);

        ajaxRequest<Document>(updateRequest)(dispatch, getState).then(response => {

            if (response.success == false)
                return;

            let actualRunning = _.findIndex(getState().documents.runnings, (r) => r.objectId == runningDoc.objectId);

            if (actualRunning < 0)
                return;

            let newRunning = { ...getState().documents.runnings[actualRunning] };

            let documentIndex = _.findIndex(newRunning.list, d => d.id == runningDoc.documentId);

            if (documentIndex < 0)
                return;

            newRunning.list[documentIndex] = response.entity;

            dispatch(actionsCreator.deleteObjectRunning(runningDoc.objectId));

            dispatch(actionsCreator.toogleEditModal(false));
            dispatch(actionsCreator.setEditState(null));

            dispatch(actionsCreator.setObjectRunning({ ...newRunning }, runningDoc.objectId));

        });
    },
    downloadDocument: (id: string, fileName: string, extension: string) => (dispatch, getState: () => ApplicationState) => {


        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false)
                return;

            dispatch(showLoading());

            let token = getState().currentProfile.authData.token;

            fetch(`api/documents/download/${id}`, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": token
                })
            })
                .then(resp => resp.blob())
                .then(blob => {
                    dispatch(hideLoading());
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `${fileName}${extension}`;
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(() => {
                    dispatch(actionCreatorUI.setErrorMessages(["Si è verificato un errore"]));
                    dispatch(hideLoading());
                });

    });
    }
}


export const reducer: Reducer<DocumentState> = (state: DocumentState | undefined, incomingAction: DocumentAction): DocumentState => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.DELETE_OBJECT_RUNNING:
            let toDeleteIndex = _.findIndex(newState.runnings, (r) => r.objectId == incomingAction.objectId);
            if (toDeleteIndex > -1) {
                newState.runnings.splice(toDeleteIndex, 1);
                newState = dotProp.set(newState, `runnings`, [...newState.runnings]);
            }
            break;
        case TypeKeys.RUNNING_LOADED:
            let loadedIndex = _.findIndex(newState.runnings, (r) => r.objectId == incomingAction.objectId);
            if (loadedIndex > -1) {
                newState = dotProp.set(newState, `runnings.${loadedIndex}.runningLoaded`, incomingAction.loaded);
            }
            break;
        case TypeKeys.SET_OBJECT_RUNNING:

            let existObject = _.findIndex(newState.runnings, (r) => r.objectId == incomingAction.objectId);

            let runnings = [...newState.runnings];
            if (existObject > -1) {
                newState = dotProp.set(newState, `runnings.${existObject}`, { ...incomingAction.model });
            } else {
                runnings.push(incomingAction.model);
                newState = dotProp.set(newState, `runnings`, runnings);
            }

            break;
        case TypeKeys.APPEND_DOCUMENT_TO_RUNNING:
            let runningIndex = _.findIndex(newState.runnings, (r) => r.objectId == incomingAction.objectId);

            if (runningIndex > -1) {
                newState.runnings[runningIndex].list.push(incomingAction.model);
                newState = dotProp.set(newState, `runnings.${runningIndex}.list`, [...newState.runnings[runningIndex].list]);
            }
            break;
        case TypeKeys.TOOGLE_EDIT_MODAL:
            newState = dotProp.set(newState, `modalEditOpened`, incomingAction.isOpened);
            break;
        case TypeKeys.SET_EDIT_STATE:
            if (incomingAction.editState !== undefined && incomingAction.editState != null)
                newState = dotProp.set(newState, `editState`, { ...incomingAction.editState });
            else
                newState = dotProp.set(newState, `editState`, null);
            break;
    }

    return newState;
}