import * as React from "react";
import { ConfirmationModal } from '../../base/confirmationmodal';
import { MenuItemProps, MenuItem } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { ConnectedProfileEditForm } from '../connected/connected.profileeditform';
import { Profile } from '../../../store/profile/Profile';
import { ConnectedMainDocuments } from '../../documents/connected.maindocuments';
import { UnsavedChanges } from "../../base/unsavedchanges";

export interface ProfileEditPageProps {
    isNew: boolean;
    pageLoaded: boolean;
    onLoad?: (id: string) => void;
    onDelete?: () => void;
    profile: Profile;
    onSubmit?: () => void;
}


export class ProfileEditPage extends React.Component<ProfileEditPageProps>{
    private modalRef: React.RefObject<ConfirmationModal>;
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let id = (this.props as any).match.params.id;
            this.props.onLoad(id);
        }
    }

    private delete() {
        if (this.props.onDelete)
            this.props.onDelete();
    }

    render() {

        if (this.props.pageLoaded != true) {
            return (
                <React.Fragment>
                </React.Fragment>)
        }

        let menuItems = [];

        if (this.props.isNew == false) {
            menuItems = [
                {
                    icon: "trash",
                    label: "elimina",
                    isButton: true,
                    isDisabled: false,
                    onClick: () => { this.modalRef.current.toogleModal() }
                }
            ] as Array<MenuItemProps>
        }

        menuItems.push({
            icon: "list",
            label: "lista",
            isButton: false,
            isSmall: true,
            path: "/profiles"
        } as MenuItemProps);

        let documents = null;
        if (this.props.isNew == false)
            documents = (<ConnectedMainDocuments objectId={this.props.profile.id} objectName={`${this.props.profile.firstName !== undefined && this.props.profile.firstName != null ? this.props.profile.firstName : ""} ${this.props.profile.lastName} `} />);


        return (
            <React.Fragment>
                <UnsavedChanges confirmAction={this.props.onSubmit} />
                <BodyHeader title={this.props.isNew == true ? "Crea Utente" : "Modifica Utente"} menuItems={menuItems} />
                <ConfirmationModal text="Eliminare utente?" onConfirm={() => this.delete()} ref={this.modalRef} />
                <ConnectedProfileEditForm />
                {documents}
            </React.Fragment>
        )
    }
}