import * as React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Row, Col, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import _ from "underscore";
import { MenuItem } from '../../menu/menuitem';
import { MethodsDropDown } from '../methoddropdown';
import DecimalInput from "../../base/decimalinput";
import { TrasferModalState } from "../../../store/payments/payments/PaymentState";

export interface TraferModalProps {
    trasferData?: TrasferModalState;
}

export interface TrasferModalCallbacks {
    onPropertyChange: (propertyName: string, value: any) => void;
    onCloseModal: () => void;
    onSave: (eventId: string) => void;
}

export class TraferModal extends React.Component<TraferModalProps & TrasferModalCallbacks> {

    constructor(props) {
        super(props);
    }

    render() {

        if (!this.props.trasferData)
            return (<React.Fragment />);
        return (
            <Modal className="bg-dark modal-lg" isOpen={this.props.trasferData.modalOpen} toggle={() => this.props.onCloseModal()} backdrop="static">
                <ModalHeader className="text-eurorounders bg-dark" toggle={() => this.props.onCloseModal()}>Traferisci Denaro</ModalHeader>
                <ModalBody className="bg-dark text-eurorounders">
                    <Form className="text-eurorounders" >
                        <Row>
                            <Col lg="6" sm="6" xs="12">
                                <FormGroup>
                                    <Label>Da</Label>
                                    <MethodsDropDown onChange={(value: string) => this.props.onPropertyChange("sourceMethod", value)} selected={this.props.trasferData.data.sourceMethod} />
                                </FormGroup>
                            </Col>
                            <Col lg="6" sm="6" xs="12">
                                <FormGroup>
                                    <Label>A</Label>
                                    <MethodsDropDown onChange={(value: string) => this.props.onPropertyChange("destinationMethod", value)} selected={this.props.trasferData.data.destinationMethod} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" sm="6" xs="12">
                                <FormGroup>
                                    <Label>Importo</Label>
                                    <InputGroup>
                                        <DecimalInput onChane={(value: string) => { this.props.onPropertyChange("amount", value) }} value={this.props.trasferData.data.amount} />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col lg="6" sm="6" xs="12">
                                <FormGroup>
                                    <Label>Note</Label>
                                    <Input className="bg-dark text-eurorounders" type="text" value={this.props.trasferData.data.notes || ''} onChange={(e) => { this.props.onPropertyChange("notes", e.target.value) }} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <MenuItem isSmall={true} isButton={true} icon="save" label="Salva" onClick={() => this.props.onSave(this.props.trasferData.data.eventId)} />
                    <MenuItem isSmall={true} isButton={true} icon="times" label="Annulla" onClick={() => this.props.onCloseModal()} />
                </ModalFooter>
            </Modal>
        );
    }
}