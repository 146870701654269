import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware, actionForExternalModule } from '../../../store/pricings/PricingState';
import { PeriodPricing } from '../../../store/pricings/PricingEntities';
import { HotelsPriceForm, HotelsPriceFormProps, HotelsPriceFormCallbacks } from '../basecomponents/hotelspriceform';
import { actionCreator as actionCreatorUI } from '../../../store/uiproperties/UIState';

const mapStateToProsp = (state: ApplicationState): HotelsPriceFormProps => {

    return {
        periodPricing: state.pricings.running,
        roomTypes: state.locations.roomTypes,
        periodPricingBackup: state.pricings.runningBackup
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): HotelsPriceFormCallbacks => {
    return ({
        onChange: (propertyName: string, value: any, roomTypeId?: string) => {
            dispatch(actionsMiddleware.setRunningProp(propertyName, value, roomTypeId));
            dispatch(actionCreatorUI.setChangeInProgress(true));
        },
        onSubmit: () => dispatch(actionsMiddleware.save()),
        confirmSelection: () => dispatch(actionForExternalModule.confirmSelection()),
        loadExternalModule: () => dispatch(actionForExternalModule.loadLocationModule()),
        onSelect: actionForExternalModule.onRowClick,
        onCheckRoomType: (roomTypeId: string) => dispatch(actionsMiddleware.changeRoomPricing(roomTypeId)),
        openAvailabilityModal: (roomPricingId: string) => dispatch(actionsMiddleware.openAvailabilityModal(roomPricingId))
    });
}

export const ConnectedHotelsPriceEditForm = connect<HotelsPriceFormProps, HotelsPriceFormCallbacks>(mapStateToProsp, mapDispatchToProps)(HotelsPriceForm);