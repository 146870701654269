import * as React from "react";
import CollapsibleContent from '../base/collapsiblecontent';
import { BodyHeader } from '../base/bodyheader';

import './guide.css'

export default (): JSX.Element => {

    let origin = window.location.origin;
    return (<React.Fragment>
        <BodyHeader title="Guida" menuItems={[]} bottomSpace />
        <CollapsibleContent className="text-eurorounders" title="Installzione" titleBig>
            <ul>
                <li>
                    <CollapsibleContent title="Windows/Mac">
                        <ol>
                            <li className="guide-node">Scaricare ed installare Google Chrome</li>
                            <li className="guide-node">Navigare verso {origin}</li>
                            <li className="guide-node">
                                <div className="image-text">Un'icona a forma di "computer" apparirà a destra infondo la barra degli indirizzi cliccarla e confermare (icona nell0immagine non aggiornata)</div>
                                <div className="image-container">
                                    <img className="rounded mx-auto d-block img-fluid image" src="images/guide/addtohomescreen/win_mac/win_mac_install_prompt_edited.png" />
                                </div>

                            </li>
                            <li className="guide-node">
                                <div className="image-text">A questo punto l'applicazione sarà installata potrete in Windows fare "Aggiungi a taskbar" (windows) oppure su Mac "opzioni -{'>'} mantieni nel dock"</div>
                                <div className="image-container">
                                    <img className="rounded mx-auto d-block img-fluid image" src="images/guide/addtohomescreen/win_mac/pin_to_taskbar_edit.png" />
                                </div>
                            </li>
                        </ol>
                        <h6>Win/Mac disinstallazione:</h6>
                        <ol>
                            <li className="guide-node">
                                <div className="image-text">Aprire l'applicazione e cliccare sull'icona a "3 puntini" quindi cliccare "Disinstallare Euro Rounders Room Manager" e confermare</div>
                                <div className="image-container">
                                    <img className="rounded mx-auto d-block img-fluid image" src="images/guide/addtohomescreen/win_mac/win_mac_unistall_edited.png" />
                                </div>
                            </li>
                        </ol>
                    </CollapsibleContent>
                </li>
                <li>
                    <CollapsibleContent title="IOS">
                        <ol>
                            <li className="guide-node">Aprire Safari</li>
                            <li className="guide-node">Navigare verso {origin}</li>
                            <li className="guide-node">
                                <div className="image-text">Cliccare il bottone "Condividi"</div>
                                <div className="image-container-mobile">
                                    <img className="rounded mx-auto d-block img-fluid image img-thumbnail" src="images/guide/addtohomescreen/IOS/ios_step_1_edited.png" />
                                </div>
                            </li>
                            <li className="guide-node">
                                <div className="image-text">Trascinare in alto e cliccare "Aggiungi alla home +"</div>
                                <div className="image-container-mobile">
                                    <img className="rounded mx-auto d-block img-fluid image img-thumbnail" src="images/guide/addtohomescreen/IOS/ios_step_2_edited.png" />
                                </div>
                            </li>
                            <li className="guide-node">
                                <div className="image-text">Confermare</div>
                                <div className="image-container-mobile">
                                    <img className="rounded mx-auto d-block img-fluid image img-thumbnail" src="images/guide/addtohomescreen/IOS/ios_step_3_edited.png" />
                                </div>
                            </li>
                            <li className="guide-node">
                                <div className="image-text">Un'icona verrà aggiunta alla home del tuo iPhone</div>
                                <div className="image-container-mobile">
                                    <img className="rounded mx-auto d-block img-fluid image img-thumbnail" src="images/guide/addtohomescreen/IOS/ios_home_screen.png" />
                                </div>
                            </li>
                        </ol>
                        <h6>IOS disinstallazione:</h6>
                        <ol>
                            <li className="guide-node">Seguire la normale procedura di disinstallazione di un'app
                            </li>
                        </ol>
                    </CollapsibleContent>
                </li>
                <li>
                    <CollapsibleContent title="Android">
                        <ol>
                            <li className="guide-node">Aprire Chrome</li>
                            <li className="guide-node">Navigare verso {origin}</li>
                            <li className="guide-node">
                                <ul>
                                    <li className="guide-node">
                                        <div className="image-text">La prima volta che navigherete verso l'applicazione apparirà automaticamente un banner invitandovi ad installare l'applicazione cliccate per installare</div>
                                        <div className="image-container-mobile">
                                            <img className="rounded mx-auto d-block img-fluid image img-thumbnail" src="images/guide/addtohomescreen/Android/android1_edited.png" />
                                        </div>
                                    </li>
                                    <li className="guide-node">
                                        <div className="image-text">Se il banner non compare cliccare l'icona a "3 puntini" e successivamente "Installa app" (immagine non aggiornata)</div>
                                        <div className="image-container-mobile" style={{ marginBottom: "10px" }}>
                                            <img className="rounded mx-auto d-block img-fluid image img-thumbnail" src="images/guide/addtohomescreen/Android/android1_alternative_edit.png" />
                                        </div>
                                        <div className="image-container-mobile">
                                            <img className="rounded mx-auto d-block img-fluid image img-thumbnail" src="images/guide/addtohomescreen/Android/android1.1_edited.png" />
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="guide-node">
                                <div className="image-text">Confermare l'azione</div>
                                <div className="image-container-mobile">
                                    <img className="rounded mx-auto d-block img-fluid image img-thumbnail" src="images/guide/addtohomescreen/Android/android_install_prompt_edited.png" />
                                </div>
                                <div className="image-text">Alcuni smartphone android lasciano la scelta di piazzare l'icone dove si preferisce altrimenti cliccare "Aggiungi automaticamente"</div>
                                <div className="image-container-mobile">
                                    <img className="rounded mx-auto d-block img-fluid image img-thumbnail" src="images/guide/addtohomescreen/Android/android_install_prompt_confirm.png" />
                                </div>
                            </li>
                            <li className="guide-node">
                                <div className="image-text">A questo punto un'icona sarà stata aggiunta alla home</div>
                                <div className="image-container-mobile">
                                    <img className="rounded mx-auto d-block img-fluid image img-thumbnail" src="images/guide/addtohomescreen/Android/home.png" />
                                </div>
                            </li>
                        </ol>
                        <h6>Android disinstallazione:</h6>
                        <ol>
                            <li className="guide-node">Seguire la normale procedura di disinstallazione di un'app
                            </li>
                        </ol>
                    </CollapsibleContent>
                </li>
            </ul>
        </CollapsibleContent>
    </React.Fragment>)
}