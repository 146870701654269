import * as React from "react";
import { MenuRow } from '../../menu/menurow';
import { MenuItemProps } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { Event } from '../../../store/events/EventEntities';
import { MenuItem as MainMenuItemProps } from '../../../store/uiproperties/UIState';

import './eventmenu.css';

export interface EventAccountingMenuProps {
    event?: Event;
    menuVoices: Array<MainMenuItemProps>;
}

export interface EventAccountingMenuCallbacks {
    onLoad: () => void;
}

export class EventAccountingMenu extends React.Component<EventAccountingMenuProps & EventAccountingMenuCallbacks>{
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.onLoad();
    }

    render() {
        if (this.props.event === undefined || this.props.event == null) {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }

        let headerMenu = [];

        headerMenu.push({
            icon: "ellipsis-h",
            label: "Evento",
            isButton: false,
            isSmall: true,
            path: `/events/${this.props.event.id}/menu`
        } as MenuItemProps);

        return (
            <React.Fragment>
                <BodyHeader titleClassName="title" menuItems={headerMenu} subTitle={`${this.props.event.type !== undefined && this.props.event.type != null ? this.props.event.type : ''} ${this.props.event.name}`} title={`Conto`} bottomSpace={true} />
                <MenuRow menuVoices={this.props.menuVoices} />
            </React.Fragment>
        );
    }
}