import { Action, Reducer } from 'redux';
import { AppThunkAction, ApplicationState } from '../'
import { Profile } from './Profile'
import { Permission } from './Role';
import { push } from 'connected-react-router';
import moment from "moment";
import { ajaxRequest, loginRequest, RequestMethods, RequestModel, refreshTokenRequest, SaveRequest, HideRequest } from '../../utils/ajaxutilities';
import dotprop from "dot-prop";
import { actionCreator as actionCreatorUi } from '../uiproperties/UIState';
import $ from "jquery";

export interface CurrentProfileState {
    currentProfile: Profile;
    editingProfile: Profile;
    authData: AuthData;
    permissions: Array<Permission>;
}

export interface AuthData {
    token: string;
    refreshToken: string;
    signInName: string;
    expires: string;
    account: string;
}

type CurrentProfileActions =
    SetAuthData
    | SetPermissionsAndProfile
    | SetProfile
    | SetUiState
    | SetUiProfile;

enum TypeKeys {
    SET_AUTH_DATA = "CURRENT_PROFILE_SET_AUTH_DATA",
    SET_PERMISSION_FOR_CURRENT_PROFILE = "CURRENT_PROFILE_SET_PERMISSION_FOR_CURRENT_PROFILE",
    SET_PROFILE = "CURRENT_PROFILE_SET_PROFILE",
    SET_UI_STATE = "CURRENT_PROFILE_SET_UI_STATE",
    SET_UI_PROFILE = "CURRENT_PROFILE_SET_UI_PROFILE"
}

interface SetProfile extends Action {
    type: TypeKeys.SET_PROFILE;
    data: Profile;
}

export interface SetAuthData extends Action {
    type: TypeKeys.SET_AUTH_DATA;
    data: AuthData;
}

interface SetPermissionsAndProfile extends Action {
    type: TypeKeys.SET_PERMISSION_FOR_CURRENT_PROFILE;
    permissions: Array<Permission>;
    profile: Profile;
}

interface SetUiState extends Action {
    type: TypeKeys.SET_UI_STATE;
}

interface SetUiProfile extends Action {
    type: TypeKeys.SET_UI_PROFILE;
    profile: Profile;
}


export const actionCreators = {
    login: (signInName: string, password: string, rememberMe: boolean): AppThunkAction<any> => (dispatch, getState) => {

        loginRequest(signInName, password)(dispatch, getState).then(response => {

            if (response.success == false) {
                return;
            }
            dispatch(actionCreators.setAuthData(response.entity as AuthData));
            if (rememberMe) {
                localStorage.setItem("authData", JSON.stringify(response.entity));
            }
            dispatch(actionCreators.getProfileInfoAndPermission());
        }).catch(error => {
        });
    },
    refreshToken: () => (dispatch, getState: () => ApplicationState): JQueryPromise<boolean> => {

        let deferred = $.Deferred();

        let authData = getState().currentProfile.authData;

        let requestModel = new HideRequest("/profiles/refreshtoken", RequestMethods.POST, { accountId: authData.account, refreshToken: authData.refreshToken });

        refreshTokenRequest(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                deferred.resolve(false);
                dispatch(actionCreators.logout());
                return deferred.promise();
            }

            dispatch(actionCreators.setAuthData(response.entity));

            if (localStorage.getItem("authData") !== undefined) {
                localStorage.removeItem("authData");
                localStorage.setItem("authData", JSON.stringify(response.entity));
            }

            deferred.resolve(true);

        });

        return deferred.promise();
    },
    setAuthData: (authData: AuthData): SetAuthData => {
        return {
            type: TypeKeys.SET_AUTH_DATA,
            data: authData
        }
    },
    setProfileAndPermissions: (profile: Profile, permissions: Array<Permission>): SetPermissionsAndProfile => {

        return {
            type: TypeKeys.SET_PERMISSION_FOR_CURRENT_PROFILE,
            permissions: permissions,
            profile: profile
        }

    },
    updateProfile: (): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {
        dispatch(actionCreatorUi.setErrorMessages([]));
        //validation
        let uiProfileState: Profile = getState().currentProfile.editingProfile;

        //if (uiProfileState.account.password !== undefined && uiProfileState.account.password != null && uiProfileState.account.password != ""
        //    && uiProfileState.account.confirmPassword != uiProfileState.account.password) {
        //    dispatch(actionCreatorUi.setErrorMessages(["Password and Confirm password don't match"], "current-profile-error-messages"));
        //    return;
        //}

        if (uiProfileState.lastName == "" || uiProfileState.lastName == null || uiProfileState.lastName === undefined) {
            dispatch(actionCreatorUi.setErrorMessages(["Cognome obbligatorio"], "current-profile-error-messages"));
            return;
        }

        let request = new SaveRequest("/profiles/mydata", RequestMethods.POST, uiProfileState);

        ajaxRequest<Profile>(request)(dispatch, getState).then(response => {

            if (response.success == false) {
                return;
            }

            dispatch(actionCreators.updateProfileState(response.entity));
        });

    },
    updateProfileState: (profile: Profile): SetProfile => ({
        type: TypeKeys.SET_PROFILE,
        data: profile
    }),
    getProfileInfoAndPermission: (): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {

        let request = new RequestModel("/profiles/profileinfo", RequestMethods.GET, null, null, "home");


        ajaxRequest<any>(request)(dispatch, getState).then(response => {

            if (response.success == false) {
                return;
            }
            dispatch(actionCreators.setProfileAndPermissions(response.entity.profile as Profile, response.entity.permissions as Array<Permission>));
            dispatch(actionCreatorUi.setMenuVoicesToState(response.entity.permissions as Array<Permission>));
            dispatch(push("/"));
        });

    },
    logout: (): AppThunkAction<any> => dispatch => {
        if (localStorage.getItem("authData") !== undefined)
            localStorage.removeItem("authData");
        dispatch(actionCreators.setAuthData(null));
        dispatch(push('/login'));
        dispatch(actionCreators.setProfileAndPermissions(null, []));
        dispatch(actionCreatorUi.setMenuVoices([]));
    },
    setUiState: (): SetUiState => ({
        type: TypeKeys.SET_UI_STATE
    }),
    setUiProfileProp: (propertyName: string, value: any): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {

        dispatch(actionCreatorUi.setErrorMessages([]));

        let uiProfileState = (getState() as ApplicationState).currentProfile.editingProfile;

        switch (propertyName) {
            case "firstName":
                uiProfileState.firstName = value;
                break;
            case "lastName":
                uiProfileState.lastName = value;
                break;
            case "email":
                uiProfileState.email = value;
                break;
            case "phone":
                uiProfileState.phone = value;
                break;
            case "phonePrefix":
                uiProfileState.phonePrefix = value;
                break;
            case "city":
                uiProfileState.city = value;
                break;
            case "country":
                uiProfileState.country = value;
                break;
            case "zipCode":
                uiProfileState.zipCode = value;
                break;
            case "stateProvince":
                uiProfileState.stateProvince = value;
                break;
            case "address":
                uiProfileState.address = value;
                break;
            case "password":
                uiProfileState.account.password = value;
                break;
            case "confirmPassword":
                uiProfileState.account.confirmPassword = value;
                break;
            case "birthDate":
                uiProfileState.birthDate = value;
                break;
            case "taxCode":
                uiProfileState.taxCode = value;
                break;
            case "vatNumber":
                uiProfileState.vatNumber = value;
                break;
            case "privacy":
                uiProfileState.privacy = value;
                break;
        }

        dispatch(actionCreators.setUiProfile(uiProfileState));


    },
    setUiProfile: (uiProfile: Profile): SetUiProfile => ({
        type: TypeKeys.SET_UI_PROFILE,
        profile: uiProfile
    })
}

let baseState: CurrentProfileState = {
    currentProfile: {} as Profile,
    editingProfile: {} as Profile,
    authData: null,
    permissions: []
}

export const reducer: Reducer<CurrentProfileState> = (state: CurrentProfileState | undefined, incomingAction: CurrentProfileActions): CurrentProfileState => {

    if (state === undefined) {
        return baseState;
    }

    let newState = {} as CurrentProfileState;
    newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.SET_AUTH_DATA:
            newState = dotprop.set(newState, 'authData', incomingAction.data);
            break;
        case TypeKeys.SET_PERMISSION_FOR_CURRENT_PROFILE:
            newState = dotprop.set(newState, 'currentProfile', incomingAction.profile);
            newState = dotprop.set(newState, 'editingProfile', { ...incomingAction.profile });
            newState = dotprop.set(newState, 'permissions', incomingAction.permissions);
            break;
        case TypeKeys.SET_PROFILE:
            newState = dotprop.set(newState, 'currentProfile', incomingAction.data);
            newState = dotprop.set(newState, 'editingProfile', { ...incomingAction.data });
            break
        case TypeKeys.SET_UI_STATE:
            newState = dotprop.set(newState, 'editingProfile', { ...state.currentProfile });
            break;
        case TypeKeys.SET_UI_PROFILE:
            newState = dotprop.set(newState, 'editingProfile', incomingAction.profile);
            break
    }

    return newState;
};