import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { MenuRow, MenuRowProps } from './menurow';
import { MenuItemProps } from './menuitem';
import _ from "underscore";

export interface MenuRowOwnProps {
    pathToShow: string;
}

const mapStateToProps = (state: ApplicationState, ownProps: MenuRowOwnProps): MenuRowProps => {

    let menuVoices = _.filter(state.uiProperties.menuVoices, (item) => { return item.scope != null && item.scope == "home" });

    return {
        menuVoices: menuVoices
    };
}

export const ConnectedMenuRow = connect<MenuRowProps>(mapStateToProps)(MenuRow);