import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/customers/CustomerState';
import { CustomerForm, CustomerFormProps } from '../shared/customerform';
import { actionCreator as actionCreatorUI } from '../../../store/uiproperties/UIState';

export interface ConnectedCustomerFormProps {
    isSaveButtonEnabled: boolean;
}

const mapStateToProps = (state: ApplicationState, ownProps: ConnectedCustomerFormProps): CustomerFormProps => {

    return {
        customer: state.customers.runningCustomer,
        isSaveButtonVisible: ownProps.isSaveButtonEnabled
    }
}

const mapDispatchToProps = () => dispatch => {
    return {
        onChange: (propertyName: string, value: any) => {
            dispatch(actionsMiddleware.setRunningProfileProp(propertyName, value));
            dispatch(actionCreatorUI.setChangeInProgress(true));
        },
        onSubmit: () => dispatch(actionsMiddleware.save())
    }
}

export const ConnectedCustomerForm = connect<CustomerFormProps, {}, ConnectedCustomerFormProps>(mapStateToProps, mapDispatchToProps)(CustomerForm)