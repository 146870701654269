import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router"
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/pricings/PricingState';
import { HotelsPriceEditPage, HotelsPriceEditPageProps, HotelsPriceEditPageCallbacks } from '../pages/hotelspriceeditpage';

const mapStateToProsp = (state: ApplicationState): HotelsPriceEditPageProps => {

    return {
        pricing: state.pricings.running,
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name.toUpperCase()}` : ''

    }
}

const mapDispatchToProps = (dispatch) => (dispatch): HotelsPriceEditPageCallbacks => {
    return ({
        onDelete: () => dispatch(actionsMiddleware.delete()),
        onLoad: (id: string) => dispatch(actionsMiddleware.load(id)),
        onSubmit: () => dispatch(actionsMiddleware.save())
    });
}

export const ConnectedHotelsPricingEdit = withRouter(connect<HotelsPriceEditPageProps, HotelsPriceEditPageCallbacks>(mapStateToProsp, mapDispatchToProps)(HotelsPriceEditPage));