import * as React from 'react';
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { AnagraphicProps } from './anagraphic';
import { CheckBox } from '../base/checkbox';

export default class PrivacyRow extends React.Component<AnagraphicProps>{
    constructor(props) {
        super(props);
    }

    render() {

        let marketingCheckBox = null;

        let colWidth = "6";

        if (this.props.customerContext == true) {

            marketingCheckBox = (
                <Col lg={colWidth} sm={colWidth} xs="6">
                    <CheckBox isChecked={this.props.marketingPrivacy} label="Privacy Marketing" onCheck={(isChecked: boolean) => { this.props.onChange("marketingPrivacy", isChecked) }} />
                </Col>
            );
        }

        return (
            <Row>
                <Col lg={colWidth} sm={colWidth} xs="6">
                    <CheckBox isChecked={this.props.privacy} label="Privacy Dati" onCheck={(isChecked: boolean) => { this.props.onChange("privacy", isChecked) }} />
                </Col>
                {marketingCheckBox}
            </Row>
        )
    }

}