import * as React from "react";
import { CommunicationSent } from "../../../store/communications/CommunicationEntities";
import { Location } from '../../../store/locations/LocationEntities'
import { BaseList } from '../../base/baseList';
import _ from "underscore";
import moment from "moment";
import { formatToLocaleDateTime } from '../../../utils/commons';

export interface CommunicationsSentListProps {
    list: Array<CommunicationSent>;
}

export interface CommunicationsSentListCallbacks {
    onRowClick: (locationId: string) => void;
}

export class CommunicationsSentList extends React.Component<CommunicationsSentListProps & CommunicationsSentListCallbacks>{

    constructor(props) {
        super(props);
    }

    private getRows(list: Array<CommunicationSent>): JSX.Element[] {

        let renderList = list.map(model => {

            let sentDate = formatToLocaleDateTime(model.sentDate);


            return (
                <tr key={model.id} style={{ cursor: "pointer" }} onClick={() => this.props.onRowClick(model.id)}>
                    <td className="text-capitalize">
                        {model.locationName}
                    </td>
                    <td>
                        {model.profileFullName}
                    </td>
                    <td>
                        {sentDate}
                    </td>
                </tr>
            )
        });

        return renderList;

    }

    private getHead(): JSX.Element {

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>
                        Hotel
                    </th>
                    <th>
                        Inviato da
                    </th>
                    <th>
                        Data
                    </th>
                </tr>
            </thead>
        );

    }

    render() {

        return (
            <React.Fragment>
                <BaseList hasNextPage={false} list={this.props.list} active={false} onEndOfList={() => { }} getElements={(list: Array<CommunicationSent>) => { return this.getRows(list) }} getHead={() => { return this.getHead() }} />
            </React.Fragment>
        )
    }
}