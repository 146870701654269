import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/index';
import { actionsMiddleware } from '../../../../store/payments/paymentmethods/PaymentMethodState';
import { PaymentMethodListCallbacks, PaymentMethodListProps, PaymentMethodList } from '../base/list';

const mapStateToProsp = (state: ApplicationState): PaymentMethodListProps => {
    return {
        list: state.paymentMethods.paymentMethods
    }
}

const mapDispatchToProps = () => (dispatch): PaymentMethodListCallbacks => {
    return ({
        onRowClick: (id: string) => dispatch(actionsMiddleware.navigateToRunning(id))
    });
}

export const ConnectedPaymentMethodList = connect<PaymentMethodListProps, PaymentMethodListCallbacks>(mapStateToProsp, mapDispatchToProps)(PaymentMethodList);