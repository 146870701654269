import * as React from "react";
import { Form, FormGroup, Row, Col, Input, Label, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledPopover, PopoverHeader, PopoverBody, Button } from "reactstrap";
import { Reservation, ReservationStatus } from '../../../store/reservations/ReservationEntities';
import { Entry, EntryEditSource } from '../../../store/entries/EntriesEntities';
import { Location, RoomType } from '../../../store/locations/LocationEntities';
import { AgentRole } from "../../../store/agents/AgentEntities";
import { KeyDesc } from '../../../store/shared/KeyDesc';
import DecimalInput from '../../base/decimalinput';
import IntegerInput from '../../base/integerinput';
import { ERDatePicker as DatePicker } from '../../base/datepicker';
import BaseSelect from '../../base/baseselect';
import { CheckBox } from '../../base/checkbox';
import _ from "underscore";
import RankingStars from "../../base/rankingstars";
import { MenuItem } from '../../menu/menuitem';
import { ConnectedAgentsList } from '../../agents/connected/connected.agentslist';
import { ConnectedAgentSearchForm } from '../../agents/connected/connected.searchform';
import { ConnectedSelectionModal } from '../../customers/connected/connected.selectionmodal';
import { formatToLocaleDate, guid } from '../../../utils/commons';
import { PaymentMethod } from "../../../store/payments/paymentmethods/PaymentMethodEntities";
import { ReservationPaymentList } from './payment/paymentlist';
import { ConnectedReservationPaymentModal } from '../connected/connected.paymentmodal';

import './editform.css';

export interface ReservationEditFormProps {
    model: Reservation;
    entries: Array<Entry>;
    locations: Array<Location>;
    roomTypes: Array<RoomType>;
    reservationStates: Array<KeyDesc<string>>;
    reservationTypes: Array<KeyDesc<string>>;
    agentRoles: Array<AgentRole>;
    canControlAgent: boolean;
    selectedAgentRole: AgentRole;
    needConfirmSkipCapacity: boolean;
    needConfirmSkipCustomer: boolean;
    paymentMethods: Array<PaymentMethod>;
}

export interface ReservationEditFormCallbacks {
    onChange: (propertyName: string, value: any) => void;
    onRemoveCustomer: (id: string) => void;
    onRemoveAgent: (id: number) => void;
    onAgentModalToogle: (agentRole: AgentRole, open: boolean) => void;
    onAgentRowClick: (agentId: string) => void;
    onConfirmAgent: () => void;
    onCustomersModelOpen: () => void;
    confirmSkipValidation: (type: string) => void;
    onSubmit: () => void;
    onCustomersModalConfirm: () => void;
    setNeedConfirmSkip: (type: string) => void;
    onCheckEntry: (customerId: string, isChecked: boolean) => void;
    onDeletePayment: (index: number) => void;
    togglePaymentModal: () => void;
}

interface ReservationEditFormState {
    isAgentModalOpen: boolean;
}


export class ReservationEditForm extends React.Component<ReservationEditFormProps & ReservationEditFormCallbacks, ReservationEditFormState>{

    constructor(props) {
        super(props);

        this.state = {
            isAgentModalOpen: false
        }
    }

    private toogleAgentModal(agentRole: AgentRole) {

        this.props.onAgentModalToogle(agentRole, !this.state.isAgentModalOpen);
        this.setState({ isAgentModalOpen: !this.state.isAgentModalOpen });

    }

    private onConfirmAgent() {
        this.props.onConfirmAgent();
        this.toogleAgentModal(null);
    }

    private getCustomersList(): JSX.Element[] {

        let customers = this.props.model.customers.map(c => {
            let customerName = c.firstName + " " + c.lastName;

            let isChecked = false;
            let disableClass = "";
            this.props.entries.forEach(x => {
                if (c.id == x.customerId && x.isDeleted != true)
                    isChecked = true;

                if (c.id == x.customerId && x.editSource == EntryEditSource.Entry)
                    disableClass = "disable-events";
            });


            const rating = (<span className="reservation-customer-rating">
                <RankingStars ranking={c.rating} />
            </span>);

            let notes = (<React.Fragment />);

            if (c.notes) {
                notes = (
                    <React.Fragment>
                        <i id={`popover-customer-notes-${c.id}`} className={`icon far fa-sticky-note customer-notes-icon`} />
                        <UncontrolledPopover className="bg-dark text-eurorounders" trigger="legacy" placement="top" target={`popover-customer-notes-${c.id}`}>
                            <PopoverHeader className="bg-dark text-eurorounders">Note cliente</PopoverHeader>
                            <PopoverBody className="bg-dark text-eurorounders">
                                <p>Note:</p>
                                <p>{c.notes}</p>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </React.Fragment>
                )
            }

            return (
                <React.Fragment key={c.id}>
                    <Col lg="7" sm="7" xs="5">
                        <FormGroup>
                            <InputGroup>
                                <Input
                                    readOnly
                                    className="bg-dark text-eurorounders"

                                    type="text"
                                    value={customerName}
                                />
                                <InputGroupAddon addonType="append">
                                    <Button onClick={() => this.props.onRemoveCustomer(c.id)}>
                                        <i className={`icon fas fa-times fa-xs`} />
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col lg="5" sm="5" xs="7">
                        {notes}
                        <span className="reservation-customer-entries-checkbox">
                            <CheckBox className={disableClass} isChecked={isChecked} onCheck={(checked) => this.props.onCheckEntry(c.id, checked)} label="Player" />
                        </span>
                        {rating}
                    </Col>
                </React.Fragment>
            );
        })

        return customers;
    }

    private getAgentComponent(): JSX.Element[] {
        let size: number = 4;

        let toReturn = []

        if (this.props.agentRoles === undefined || this.props.agentRoles == null || this.props.agentRoles.length <= 0)
            return [];

        if (this.props.canControlAgent == false)
            return [];

        size = 12 / this.props.agentRoles.length;

        this.props.agentRoles.forEach(agentRole => {
            let agentName = '';
            let agentId = null;

            let agent = _.find(this.props.model.profiles, (ag) => ag.agentRole.id == agentRole.id);

            if (agent !== undefined && agent != null) {
                agentName = agent.firstName + " " + agent.lastName;
                agentId = agent.id;
            }

            toReturn.push(
                <React.Fragment key={agentRole.id}>
                    <Col lg={size} sm={size} xs="12">
                        <FormGroup>
                            <Label>{agentRole.roleDescription}</Label>
                            <InputGroup>
                                <Input
                                    readOnly
                                    className="bg-dark text-eurorounders"

                                    type="text"
                                    value={agentName}
                                    onClick={() => { this.toogleAgentModal(agentRole); }}
                                />
                                <InputGroupAddon addonType="append">
                                    <Button className={agentId != null ? '' : "disable-events"} onClick={() => this.props.onRemoveAgent(agentRole.id)}>
                                        <i className={`icon fas fa-times fa-xs`} />
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </React.Fragment>);
        });

        return toReturn;
    }

    private getAgentModal(): JSX.Element {
        if (this.props.selectedAgentRole === undefined || this.props.selectedAgentRole == null) {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }

        return (
            <Modal className="bg-dark modal-lg" isOpen={this.state.isAgentModalOpen} toggle={() => this.toogleAgentModal(null)} backdrop="static">
                <ModalHeader className="text-eurorounders bg-dark" toggle={() => this.toogleAgentModal(null)}>Seleziona {this.props.selectedAgentRole.roleDescription}</ModalHeader>
                <ModalBody className="bg-dark text-eurorounders">
                    <ConnectedAgentSearchForm />
                    <ConnectedAgentsList selectionEnabled={true} onRowClick={this.props.onAgentRowClick} />
                </ModalBody>
                <ModalFooter>
                    <MenuItem isSmall={true} isButton={true} icon="check" label="ok" onClick={() => { this.onConfirmAgent(); }} />
                    <MenuItem isSmall={true} isButton={true} icon="times" label="annulla" onClick={() => this.toogleAgentModal(null)} />
                </ModalFooter>
            </Modal>
        );
    }

    private getPopoverBalanceDetails(): JSX.Element {

        let details = this.props.model.reservationDays.map(rd => {

            let dayToShow = '-';

            let day = formatToLocaleDate(rd.day);

            if (day !== undefined && day != null)
                dayToShow = day;

            return (
                <div key={guid()} className="text-eurorounders">
                    <span>{day}: {rd.sellPrice} € - {rd.dailyTax} €</span>
                </div>
            );
        });

        return (
            <React.Fragment>
                {details}
            </React.Fragment>
        );

    }

    render() {

        let customersList = this.getCustomersList();

        let agentList = this.getAgentComponent();

        let totalEnabled = this.props.model.reservationDays.length > 0;

        let totalPrice = 0;
        let totalSumTax = 0;

        this.props.model.reservationDays.forEach(rd => {
            totalPrice = totalPrice + rd.sellPrice;
            totalSumTax = totalSumTax + rd.dailyTax;
        });


        let agentModal = this.getAgentModal();

        let popover = this.getPopoverBalanceDetails();

        let toPayDesc = "Da pagare";
        let toPay = this.props.model.toPay;
        if (toPay < 0) {
            toPayDesc = "Dare resto";
            toPay = toPay * -1;
        }

        return (
            <React.Fragment>
                <Form className="text-eurorounders" >
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>#Progressivo</Label>
                                <InputGroup>
                                    <IntegerInput
                                        onChane={(value?: number) => { }}
                                        value={this.props.model.progressiveNumber}
                                        readOnly={true}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">#</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Stato</Label>
                                <BaseSelect
                                    descField="desc"
                                    idField="key"
                                    options={this.props.reservationStates}
                                    value={this.props.model.status}
                                    defaultOptionDisabled={true}
                                    onChange={(value: any) => { this.props.onChange("status", value) }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Tipo</Label>
                                <BaseSelect
                                    descField="desc"
                                    idField="key"
                                    options={this.props.reservationTypes}
                                    value={this.props.model.type}
                                    defaultOptionDisabled={true}
                                    onChange={(value: any) => { this.props.onChange("type", value) }} />
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="3" xs="6" style={{ display: "flex", alignItems: "flex-end" }}>
                            <FormGroup>
                                <CheckBox label="Prenotazione web" isChecked={this.props.model.isWebReservation} onCheck={(checked) => this.props.onChange("isWebReservation", checked)} />
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="3" xs="6" style={{ display: "flex", alignItems: "flex-end" }}>
                            <FormGroup>
                                <CheckBox label="Check in" isChecked={this.props.model.checkInDone} onCheck={(checked) => this.props.onChange("checkInDone", checked)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Check In</Label>
                                <DatePicker selectedDate={this.props.model.checkIn} onChange={date => this.props.onChange("checkIn", date)} />
                            </FormGroup>
                        </Col>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Check Out</Label>
                                <DatePicker selectedDate={this.props.model.checkOut} onChange={date => this.props.onChange("checkOut", date)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Hotel</Label>
                                <BaseSelect
                                    descField="name"
                                    idField="id"
                                    options={this.props.locations}
                                    value={this.props.model.location !== undefined && this.props.model.location != null ? this.props.model.location.id : ''}
                                    onChange={(value: any) => { this.props.onChange("location", value) }} />
                            </FormGroup>
                        </Col>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Camera</Label>
                                <BaseSelect
                                    descField="type"
                                    idField="id"
                                    options={this.props.roomTypes}
                                    value={this.props.model.roomType !== undefined && this.props.model.roomType != null ? this.props.model.roomType.id : ''}
                                    onChange={(value: any) => { this.props.onChange("roomType", value) }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col lg="12" sm="12" xs="12">
                            <div className="reservation-customer-row">
                                <h4 className="customer-title" onClick={() => this.props.onCustomersModelOpen()}>Ospiti</h4>
                                <MenuItem isSmall={true} isButton={true} icon="user-check" label="Sel." onClick={() => this.props.onCustomersModelOpen()} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {customersList}
                    </Row>
                    <Row>
                        {agentList}
                    </Row>
                    <Row>
                        <Col lg="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Prezzo camera</Label>
                                <InputGroup>
                                    <DecimalInput readOnly className={totalEnabled ? "" : "disable-events"} onChane={(value: string) => { }} value={totalPrice} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Tasse</Label>
                                <InputGroup>
                                    <DecimalInput readOnly className={totalEnabled ? "" : "disable-events"} onChane={(value: string) => { }} value={this.props.model.totalTax} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Sconto</Label>
                                <InputGroup>
                                    <DecimalInput allowNegative={true} className={totalEnabled ? "" : "disable-events"} onChane={(value: string) => this.props.onChange("discount", value)} value={this.props.model.discount} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Saldo</Label>
                                <InputGroup>
                                    <DecimalInput allowNegative={true} className={totalEnabled ? "" : "disable-events"} onChane={(value: string) => this.props.onChange("totalPay", value)} value={this.props.model.totalPay} />
                                    <InputGroupAddon addonType="append">
                                        <Button className={totalEnabled ? "" : "disable-events"} id="popover-balance" type="button">€</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                            <UncontrolledPopover className="bg-dark text-eurorounders" trigger="legacy" placement="top" target="popover-balance">
                                <PopoverHeader className="bg-dark text-eurorounders">Dettagli</PopoverHeader>
                                <PopoverBody className="bg-dark text-eurorounders">
                                    {popover}
                                    <div>
                                        <div>
                                            Saldo:
                                        </div>
                                        <div>{totalPrice} + {totalSumTax} x {this.props.model.roomType !== undefined && this.props.model.roomType != null ? this.props.model.roomType.capacity : "0"} - {this.props.model.discount === undefined || this.props.model.discount == null ? "0" : this.props.model.discount} = {this.props.model.totalPay === undefined || this.props.model.totalPay == null ? "0" : this.props.model.totalPay}</div>
                                    </div>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="3" sm="6" xs="12" className="offset-lg-9 offset-sm-6 offset-xs-0">
                            <FormGroup>
                                <Label>{toPayDesc}</Label>
                                <InputGroup>
                                    <DecimalInput readOnly className="disable-events" onChane={(value: string) => { }} value={toPay} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" sm="12" xs="12">
                            <div className="reservation-customer-row">
                                <h4 className="customer-title" onClick={() => this.props.togglePaymentModal()}>Pagamenti</h4>
                                <MenuItem isSmall={true} isButton={true} icon="plus" label="Crea" onClick={() => this.props.togglePaymentModal()} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <ReservationPaymentList
                            payments={this.props.model.payments}
                            methods={this.props.paymentMethods}
                            onDelete={(index: number) => this.props.onDeletePayment(index)}
                        />
                    </Row>
                    <Row>
                        <Col lg="12" sm="12" xs="12">
                            <FormGroup>
                                <Label>Note</Label>
                                <Input className="bg-dark text-eurorounders" type="textarea" value={this.props.model.notes || ''} onChange={(e) => { this.props.onChange("notes", e.target.value) }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <MenuItem isSmall={true} isButton={true} icon="save" label="salva" onClick={() => this.props.onSubmit()} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                {agentModal}
                <ConnectedSelectionModal onConfirm={() => this.props.onCustomersModalConfirm()} />
                <Modal className="bg-dark" isOpen={this.props.needConfirmSkipCapacity} toggle={() => this.props.setNeedConfirmSkip("capacity")} backdrop="static">
                    <ModalHeader className="text-eurorounders bg-dark text-uppercase" toggle={() => this.props.setNeedConfirmSkip("capacity")}>Confirmation</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders modal-header-button-box">
                        <div>
                            <p>
                                Il numero di ospiti eccede la capacità della camera claccare 'Si' per continuare il salvataggio
                            </p>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon="save" label="si" onClick={() => this.props.confirmSkipValidation("capacity")} />
                        <MenuItem isSmall={true} isButton={true} icon="undo" label="No" onClick={() => this.props.setNeedConfirmSkip("capacity")} />
                    </ModalFooter>
                </Modal>
                <Modal className="bg-dark" isOpen={this.props.needConfirmSkipCustomer} toggle={() => this.props.setNeedConfirmSkip("customer")} backdrop="static">
                    <ModalHeader className="text-eurorounders bg-dark text-uppercase" toggle={() => this.props.setNeedConfirmSkip("customer")}>Confirmation</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders modal-header-button-box">
                        <div>
                            <p>
                                Almeno uno degli ospiti selezionati risulta avere già una prenotazione in queste date cliccare 'Si' per continuare il salvataggio
                            </p>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon="save" label="si" onClick={() => this.props.confirmSkipValidation("customer")} />
                        <MenuItem isSmall={true} isButton={true} icon="undo" label="No" onClick={() => this.props.setNeedConfirmSkip("customer")} />
                    </ModalFooter>
                </Modal>
                <ConnectedReservationPaymentModal />
            </React.Fragment>
        );
    }

}