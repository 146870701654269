import * as React from "react";
import { ConnectedPaymentItemList, PaymentItemsOwnProps } from '../connected/connected.list';
import { PaymentCategory } from '../../../../store/payments/paymentitems/PaymentItemEntities';
import { BodyHeader } from '../../../base/bodyheader';
import { MenuItemProps } from '../../../menu/menuitem';

export interface PaymentItemListPageProps {
    loaded: boolean;
    side: PaymentCategory;
}

export interface PaymentItemListPageCallbacks {
    onLoad?: () => void;
}

export class PaymentItemsPageList extends React.Component<PaymentItemListPageProps & PaymentItemListPageCallbacks> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad)
            this.props.onLoad();
    }

    render() {
        let menuItems = [
            {
                icon: "plus",
                label: "crea",
                path: this.props.side == PaymentCategory.Cost ? "/events/configurations/cost/create" : "/events/configurations/income/create"
            },
            {
                icon: "cogs",
                label: "config.",
                path: "/events/configurations"
            }
        ] as Array<MenuItemProps>

        let body = (<React.Fragment />);

        if (this.props.loaded)
            body = (<ConnectedPaymentItemList side={this.props.side} />)

        return (
            <React.Fragment>
                <BodyHeader title={this.props.side == PaymentCategory.Cost ? "Voci di Costo" : "Voci Incasso"} menuItems={menuItems} bottomSpace={true} />
                {body}
            </React.Fragment>
        )
    }
}