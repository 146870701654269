import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware, actionCreator } from '../../../store/customers/CustomerState';
import { CustomersPageList, CustomersPageListProps } from '../pages/customerslistpage';

const mapStateToProsp = (state: ApplicationState): CustomersPageListProps => {

    return {
        totalCount: state.customers.totalCount
    }
}

const mapDispatchToProps = () => dispatch => {
    return ({
        onLoad: () => {
            dispatch(actionsMiddleware.loadInitialState());
        },
        navigateToCustomer: actionsMiddleware.navigateToCustomer
    });
}

export const ConnectedCustomersPageList = connect<CustomersPageListProps>(mapStateToProsp, mapDispatchToProps)(CustomersPageList);