import { Action, Reducer } from 'redux';
import { ApplicationState } from '../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest, checkRefreshToken, SaveRequest, FetchListRequest } from '../../utils/ajaxutilities';
import dotProp from "dot-prop";
import { CoreResponse } from '../serverresponse/serverresponse';
import { PAGE_SIZE } from '../../utils/commons';
import { push } from 'connected-react-router';
import _ from "underscore";
import { Event } from './EventEntities';
import { KeyDesc } from '../shared/KeyDesc';
import $ from "jquery";
import { actionCreator as actionCreatorUi } from '../uiproperties/UIState';
import { actionsMiddleware as locationMiddleware, actionCreator as locationActionCreator } from '../locations/LocationState';
import { Location } from '../locations/LocationEntities';

const debouncedActions = (dispatch) => {
    dispatch(actionCreator.setList([]));
    dispatch(actionsMiddleware.loadSearchResult(false));
    dispatch(actionsMiddleware.getTotalCount());
}

const debounceSearch = _.debounce(debouncedActions, 500);

export interface EventState {
    list: Array<Event>;
    selectedEvents: Array<string>;
    selectionLimit?: number;
    totalCount: number;
    running?: Event;
    runningEventLocations: Array<Location>;
    moduleLoaded: boolean;
    searchCriteria: EventSearchCriteria;
}

export interface EventSearchCriteria {
    search: string;
    orderField: string;
    pageNumber: number;
    hasNextPage: boolean;
    dateFrom: string;
    dateTo: string;
    type: string;
}

let baseState: EventState = {
    list: [],
    moduleLoaded: false,
    searchCriteria: {
        dateFrom: null,
        dateTo: null,
        hasNextPage: false,
        orderField: null,
        pageNumber: 0,
        search: null,
        type: null
    },
    selectedEvents: [],
    selectionLimit: null,
    totalCount: 0,
    running: null,
    runningEventLocations: []
}


const getBaseSearch = (): EventSearchCriteria => ({
    dateFrom: null,
    dateTo: null,
    hasNextPage: false,
    orderField: null,
    pageNumber: 0,
    search: null,
    type: null
});

const getBaseState = (): EventState => ({
    list: [],
    moduleLoaded: false,
    searchCriteria: {
        dateFrom: null,
        dateTo: null,
        hasNextPage: false,
        orderField: null,
        pageNumber: 0,
        search: null,
        type: null
    },
    selectedEvents: [],
    selectionLimit: null,
    totalCount: 0,
    running: null,
    runningEventLocations: []
});

type EventAction =
    SetRunning
    | SetList
    | AppendList
    | SetSearchCriteria
    | SetModuleLoaded
    | ResetState
    | SetTotalCount
    | UnloadRunning
    | SetEventLocations
    | SetSelectedEvent
    | SetSelectionLimit;

enum TypeKeys {
    RESET_STATE = "EVENT_RESET_STATE",
    SET_RUNNING = "EVENT_SET_RUNNING",
    UNLOAD_RUNNING = "EVENT_UNLOAD_RUNNING",
    SET_LIST = "EVENT_SET_LIST",
    APPEND_LIST = "EVENT_APPEND_LIST",
    SET_SEARCH_CRITERIA = "EVENT_SET_SEARCH_CRITERIA",
    MODULE_LOADED = "EVENT_MODULE_LOADED",
    SET_TOTAL_COUNT = "EVENT_SET_TOTAL_COUNT",
    SET_EVENT_LOCATIONS = "EVENT_SET_EVENT_LOCATIONS",
    SET_SELECTED = "EVENT_SET_SELECTED",
    SET_SELECTION_LIMIT = "SET_SELECTION_LIMIT"
}

interface SetSelectionLimit extends Action {
    type: TypeKeys.SET_SELECTION_LIMIT,
    selectionLimit: number;
}

interface SetSelectedEvent extends Action {
    type: TypeKeys.SET_SELECTED;
    events: Array<string>;
}

interface SetEventLocations extends Action {
    type: TypeKeys.SET_EVENT_LOCATIONS;
    list: Array<Location>;
}

interface UnloadRunning extends Action {
    type: TypeKeys.UNLOAD_RUNNING
}

interface SetTotalCount extends Action {
    type: TypeKeys.SET_TOTAL_COUNT;
    count: number;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE;
}

interface SetRunning extends Action {
    type: TypeKeys.SET_RUNNING;
    model: Event;
}

interface SetList extends Action {
    type: TypeKeys.SET_LIST;
    list: Array<Event>;
}

interface AppendList extends Action {
    type: TypeKeys.APPEND_LIST;
    list: Array<Event>;
}

interface SetSearchCriteria extends Action {
    type: TypeKeys.SET_SEARCH_CRITERIA;
    searchCriteria: EventSearchCriteria;
}

interface SetModuleLoaded extends Action {
    type: TypeKeys.MODULE_LOADED;
    moduleLoaded: boolean;
}

export const actionCreator = {
    unloadRunning: (): UnloadRunning => ({
        type: TypeKeys.UNLOAD_RUNNING
    }),
    setTotalCount: (count: number): SetTotalCount => ({
        type: TypeKeys.SET_TOTAL_COUNT,
        count: count
    }),
    setList: (list: Array<Event>): SetList => ({
        type: TypeKeys.SET_LIST,
        list: list
    }),
    setRunning: (model: Event): SetRunning => ({
        type: TypeKeys.SET_RUNNING,
        model: model
    }),
    appendList: (models: Array<Event>): AppendList => ({
        type: TypeKeys.APPEND_LIST,
        list: models
    }),
    setSearchCriteria: (searchCriteria: EventSearchCriteria): SetSearchCriteria => ({
        type: TypeKeys.SET_SEARCH_CRITERIA,
        searchCriteria: searchCriteria
    }),
    setModuleLoaded: (loaded: boolean): SetModuleLoaded => ({
        type: TypeKeys.MODULE_LOADED,
        moduleLoaded: loaded
    }),
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    setEventLocations: (locations: Array<Location>): SetEventLocations => ({
        type: TypeKeys.SET_EVENT_LOCATIONS,
        list: locations
    }),
    setSelectedEvents: (events: Array<string>): SetSelectedEvent => ({
        type: TypeKeys.SET_SELECTED,
        events: events
    }),
    setSelectionLimit: (limit?: number): SetSelectionLimit => ({
        type: TypeKeys.SET_SELECTION_LIMIT,
        selectionLimit: limit
    })
}

export const actionForExternalModule = {
    loadLocationModule: () => (dispatch, getState: () => ApplicationState) => {
        abortAllRequest();
        let locationSeected = null;
        if (getState().events.running !== undefined && getState().events.running != null)
            locationSeected = getState().events.running.location;
        dispatch(locationMiddleware.loadInitialStateExternal(locationSeected));
    },
    confirmSelection: () => (dispatch, getState: () => ApplicationState) => {
        let selectedLocation = getState().locations.selectedLocation;
        dispatch(actionsMiddleware.setRunningProp("location", selectedLocation));
    },
    onRowClick: (model?: Location) => (dispatch, getState: () => ApplicationState) => {
        dispatch(locationActionCreator.setSelected(model));
    }
}

export const actionsMiddleware = {

    loadInitialState: () => (dispatch, getState) => {
        abortAllRequest();
        dispatch(actionCreator.setModuleLoaded(false));
        let appState = (getState() as ApplicationState);
        let preservePages: boolean = true;

        dispatch(actionCreator.resetState());

        if (appState.uiProperties.previousPath === undefined || appState.uiProperties.previousPath == null || !(appState.uiProperties.previousPath.startsWith("/events/:id/menu") || appState.uiProperties.previousPath.startsWith("/events/create"))) {
            dispatch(actionCreator.setSearchCriteria(getBaseSearch()));
            preservePages = false;
        }

        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false)
                return;

            $.when(dispatch(actionsMiddleware.loadSearchResult(preservePages)), dispatch(actionsMiddleware.getTotalCount())).then((resp, count, rts) => {
                let respCast = (resp as unknown as CoreResponse<any>);
                if (respCast.success == true)
                    dispatch(actionCreator.setModuleLoaded(true));
            });

        });
    },
    loadInitialStateExternal: (selectedEvents: Array<string> = [], eventContext: boolean = true, selectionLimit: number = null) => (dispatch, getState: () => ApplicationState) => {

        abortAllRequest();
        //calling eventContext true inside event destroy my current event state
        if (eventContext == false)
            dispatch(actionCreator.resetState());
        dispatch(actionCreator.setModuleLoaded(false));
        dispatch(actionCreator.setSelectedEvents(selectedEvents));
        dispatch(actionCreator.setSearchCriteria(getBaseSearch()));
        dispatch(actionCreator.setList([]));
        dispatch(actionCreator.setSelectionLimit(selectionLimit));
        $.when(dispatch(actionsMiddleware.loadSearchResult(false))).then((resp) => {
            let respCast = (resp as unknown as CoreResponse<any>);
            if (respCast.success == true)
                dispatch(actionCreator.setModuleLoaded(true));
        });
    },
    onSelect: (eventId?: string) => (dispatch, getState: () => ApplicationState) => {
        if (eventId === undefined || eventId == null) {
            return;
        }

        let selectedEvents = [...getState().events.selectedEvents];

        let selectedIndex = _.findIndex(selectedEvents, (id) => id == eventId);

        //want to delete
        if (selectedIndex > -1) {
            selectedEvents.splice(selectedIndex, 1);
            dispatch(actionCreator.setSelectedEvents(selectedEvents));
            return;
        }

        //want to add
        if (getState().events.selectionLimit != null && getState().events.selectionLimit <= selectedEvents.length) {
            //cannot add
            dispatch(actionCreatorUi.setErrorMessages([`Cannot select more than ${getState().events.selectionLimit} events`]));
            return;
        }

        selectedEvents.push(eventId);
        dispatch(actionCreator.setSelectedEvents(selectedEvents));
    },
    getNewEvent: () => (dispatch, getState: () => ApplicationState): Event => {
        return {} as Event;
    },
    getTotalCount: () => (dispatch, getState: () => ApplicationState): JQueryPromise<number> => {
        let promise = $.Deferred();
        let requestModel = new AbortableRequest("/events/count", RequestMethods.GET, { ...getState().events.searchCriteria });
        ajaxRequest<number>(requestModel)(dispatch, getState).then(response => {
            if (response.success == false) {
                promise.resolve(null);
                return;
            }
            dispatch(actionCreator.setTotalCount(response.entity));
            promise.resolve(response.entity);
        });

        return promise.promise();
    },
    genericSearch: (search: string, dateFrom: string, dateTo: string, type: string) => (dispatch, getState: () => ApplicationState) => {
        abortAllRequest();
        let searchCriteria = { ...getState().events.searchCriteria };
        searchCriteria.dateFrom = dateFrom;
        searchCriteria.dateTo = dateTo;
        searchCriteria.type = type;
        searchCriteria.search = search;

        //reset page number and alterate string search
        searchCriteria.pageNumber = 0;
        searchCriteria.hasNextPage = false;
        dispatch(actionCreator.setSearchCriteria(searchCriteria));
        debounceSearch(dispatch);

    },
    loadNextPage: () => (dispatch, getState: () => ApplicationState) => {
        let searchCriteria = getState().events.searchCriteria;
        searchCriteria.pageNumber += 1;
        dispatch(actionCreator.setSearchCriteria(searchCriteria));
        dispatch(actionsMiddleware.loadSearchResult(false));
    },
    loadSearchResult: (preservePages: boolean) => (dispatch, getState): JQueryPromise<CoreResponse<Array<Event>>> => {

        let promise = $.Deferred();

        let searchCriteria = (getState() as ApplicationState).events.searchCriteria;

        let requestModel = new FetchListRequest(`/events/search`, RequestMethods.GET, { ...searchCriteria, preservePages: preservePages });

        ajaxRequest<Array<Event>>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                return promise.resolve(response);
            }

            searchCriteria.hasNextPage = true;

            if (response.entity.length < PAGE_SIZE) {
                searchCriteria.hasNextPage = false;
            }

            if (preservePages && (response.entity.length < (searchCriteria.pageNumber + 1) * PAGE_SIZE)) {
                searchCriteria.hasNextPage = false;
            }

            dispatch(actionCreator.appendList(response.entity));

            dispatch(actionCreator.setSearchCriteria(searchCriteria));

            dispatch(actionCreatorUi.startFetchingPagedList(true));

            return promise.resolve(response);

        });

        return promise.promise();
    },
    load: (id?: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<Event> => {

        let deferred = $.Deferred();

        if (id === undefined || id == null || id == "") {
            let newModel = dispatch(actionsMiddleware.getNewEvent());
            dispatch(actionCreator.setRunning(newModel));
            dispatch(actionCreator.setModuleLoaded(true));
            deferred.resolve(newModel);
            return;
        }

        let requestModel = new RequestModel(`/events/${id}`, RequestMethods.GET, null, null);
        ajaxRequest<Event>(requestModel)(dispatch, getState).then(response => {
            if (response.success == false || response.entity == null || response.entity === undefined) {
                deferred.resolve(null);
                return;
            }
            dispatch(actionCreator.setRunning(response.entity));
            dispatch(actionCreator.setModuleLoaded(true));
        });

        return deferred.promise();
    },
    navigate: (id: string) => (dispatch, getState: () => ApplicationState) => {
        dispatch(actionCreator.setModuleLoaded(false));
        dispatch(actionCreator.unloadRunning());
        dispatch(push(`/events/${id}/menu`));
    },
    delete: () => (dispatch, getState: () => ApplicationState) => {

        let requestModel = new SaveRequest(`/events/${getState().events.running.id}`, RequestMethods.DELETE, null);

        return ajaxRequest<any>(requestModel)(dispatch, getState).then(response => {
            if (response.success == true) {
                dispatch(push("/events"));
            }
        });
    },
    save: () => (dispatch, getState: () => ApplicationState): JQueryPromise<Event> => {

        let promise = $.Deferred();

        dispatch(actionCreatorUi.setErrorMessages([]));
        //validation
        let model: Event = getState().events.running;

        let request = new SaveRequest(`/events`, RequestMethods.POST, model);

        ajaxRequest<Event>(request)(dispatch, getState).then(response => {
            if (response.success != true) {
                promise.resolve(null);
                return;
            }

            promise.resolve(response.entity);
            dispatch(actionCreator.setRunning(response.entity));
            if (model.id === undefined || model.id == null)
                dispatch(push(`/events/${response.entity.id}/menu`));
        });

        return promise.promise();
    },
    reOpenRunning: () => (dispatch, getState: () => ApplicationState): JQueryPromise<boolean> => {

        let promise = $.Deferred();

        dispatch(actionCreatorUi.setErrorMessages([]));
        //validation
        let model: Event = { ...getState().events.running };

        let request = new SaveRequest(`/events/reopen/${model.id}`, RequestMethods.POST, model);

        ajaxRequest<any>(request)(dispatch, getState).then(response => {
            if (response.success != true) {
                promise.resolve(null);
                return promise.promise();
            }
            promise.resolve(true);
            model.isFreezed = false;
            dispatch(actionCreator.setRunning(model));
        });

        return promise.promise();
    },
    reOpen: (id: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<boolean> => {

        let promise = $.Deferred();

        dispatch(actionCreatorUi.setErrorMessages([]));
        //validation
        let model: Event = { ...getState().events.running };

        let request = new SaveRequest(`/events/reopen/${id}`, RequestMethods.POST, model);

        ajaxRequest<any>(request)(dispatch, getState).then(response => {
            if (response.success != true) {
                promise.resolve(null);
                return promise.promise();
            }
            promise.resolve(true);
            model.isFreezed = false;
            dispatch(actionCreator.setRunning(model));
        });

        return promise.promise();
    },
    close: (id: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<boolean> => {

        let promise = $.Deferred();

        dispatch(actionCreatorUi.setErrorMessages([]));
        //validation
        let model: Event = { ...getState().events.running };

        let request = new SaveRequest(`/events/close/${id}`, RequestMethods.POST, model);

        ajaxRequest<any>(request)(dispatch, getState).then(response => {
            if (response.success != true) {
                promise.resolve(null);
                return promise.promise();
            }
            promise.resolve(true);
            model.isFreezed = true;
            dispatch(actionCreator.setRunning(model));
        });

        return promise.promise();
    },
    setRunningProp: (propertyName: string, value: any) => (dispatch, getState: () => ApplicationState) => {

        actionCreatorUi.setErrorMessages([]);

        let running = { ...getState().events.running };

        switch (propertyName) {
            case "name":
                running.name = value;
                break;
            case "dateFrom":
                running.dateFrom = value;
                break;
            case "dateTo":
                running.dateTo = value;
                break;
            case "launchDate":
                running.launchDate = value;
                break;
            case "feePerPartecipant":
                running.feePerPartecipant = value;
                break;
            case "type":
                running.type = value;
                break;
            case "location":
                running.location = value;
                break;
            case "buyIn":
                running.buyIn = value;
                break;
            case "guaranteedValue":
                running.guaranteedValue = value;
                break;
            case "toPrizePoolValue":
                running.toPrizePoolValue = value;
                break;
            case "earningFeePercentage":
                running.earningFeePercentage = value;
                break;
            case "finalEntries":
                running.finalEntries = value;
                break;
            case "finalPartecipants":
                running.finalPartecipants = value;
                break;
            case "finalPartecipantsNotBooking":
                running.finalPartecipantsNotBooking = value;
                break;

        }
        dispatch(actionCreator.setRunning(running));
    },
    setEventLocations: (eventId: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<Array<Location>> => {
        let promise = $.Deferred();


        let requestModel = new AbortableRequest(`/events/locations/${eventId}`, RequestMethods.GET);

        ajaxRequest<Array<Location>>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                return promise.resolve(null);
            }
            dispatch(actionCreator.setEventLocations(response.entity));
            return promise.resolve(response.entity);

        });

        return promise.promise();
    }
}

export const reducer: Reducer<EventState> = (state: EventState | undefined, incomingAction: EventAction): EventState => {

    if (state === undefined) {
        return baseState;
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.UNLOAD_RUNNING:
            newState = dotProp.set(newState, "running", null);
            break
        case TypeKeys.SET_TOTAL_COUNT:
            newState = dotProp.set(newState, "totalCount", incomingAction.count);
            break;
        case TypeKeys.RESET_STATE:
            let stateToUpdate = getBaseState();
            stateToUpdate.searchCriteria = newState.searchCriteria;
            newState = { ...stateToUpdate };
            break;
        case TypeKeys.SET_LIST:
            newState = dotProp.set(newState, "list", [...incomingAction.list]);
            break;
        case TypeKeys.APPEND_LIST:
            let list = newState.list.concat(incomingAction.list);
            newState = dotProp.set(newState, 'list', [...list]);
            break;
        case TypeKeys.SET_RUNNING:
            newState = dotProp.set(newState, "running", { ...incomingAction.model });
            break;
        case TypeKeys.SET_SEARCH_CRITERIA:
            newState = dotProp.set(newState, "searchCriteria", { ...incomingAction.searchCriteria });
            break;
        case TypeKeys.MODULE_LOADED:
            newState = dotProp.set(newState, "moduleLoaded", incomingAction.moduleLoaded);
            break;
        case TypeKeys.SET_EVENT_LOCATIONS:
            newState = dotProp.set(newState, "runningEventLocations", [...incomingAction.list]);
            break
        case TypeKeys.SET_SELECTED:
            newState = dotProp.set(newState, "selectedEvents", incomingAction.events);
            break;
        case TypeKeys.SET_SELECTION_LIMIT:
            newState = dotProp.set(newState, "selectionLimit", incomingAction.selectionLimit);
            break;
    }

    return newState;
}