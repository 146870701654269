import { Action, Reducer } from 'redux';
import { LocationGroup } from './LocationGroupEntities';
import { ApplicationState } from '../../index';
import { ajaxRequest, RequestMethods, RequestModel, checkRefreshToken, SaveRequest } from '../../../utils/ajaxutilities';
import dotprop from "dot-prop";
import { actionCreator as actionCreatorUi } from '../../uiproperties/UIState';
import { CoreResponse } from '../../serverresponse/serverresponse';
import $ from "jquery";
import _ from 'underscore';
import { push } from 'connected-react-router';


export interface LocationGroupsState {
    locationGroups: Array<LocationGroup>;
    runningLocationGroup: LocationGroup;
}

const locationGroupsApi = {
    getAll: () => (dispatch, getState): JQueryPromise<CoreResponse<Array<LocationGroup>>> => {

        let requestModel = new RequestModel("/locations/groups", RequestMethods.GET, null, null);

        return ajaxRequest<Array<LocationGroup>>(requestModel)(dispatch, getState);


    },
    get: (id: string) => (dispatch, getState): JQueryPromise<CoreResponse<LocationGroup>> => {

        let requestModel = new RequestModel(`/locations/group/${id}`, RequestMethods.GET);

        return ajaxRequest<LocationGroup>(requestModel)(dispatch, getState);

    },
    save: (locationGroup: LocationGroup) => (dispatch, getState): JQueryPromise<CoreResponse<LocationGroup>> => {

        let requestModel = new SaveRequest(`/locations/group`, RequestMethods.POST, locationGroup);

        return ajaxRequest<LocationGroup>(requestModel)(dispatch, getState);

    },
    delete: (id: string) => (dispatch, getState): JQueryPromise<CoreResponse<any>> => {

        let requestModel = new SaveRequest(`/locations/group/${id}`, RequestMethods.DELETE, null);

        return ajaxRequest<any>(requestModel)(dispatch, getState);

    }
};

export const actionCreator = {
    loadInitialState: () => (dispatch, getState) => {

        dispatch(actionCreator.resetState());

        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false) {
                return;
            }

            $.when(dispatch(actionCreator.loadList())).then((groups) => {

                if (groups.success == false)
                    return;
                dispatch(actionCreatorUi.setPageLoaded(true));
            });

        });

    },
    loadList: () => (dispatch, getState): JQueryPromise<CoreResponse<Array<LocationGroup>>> => {

        let deferred = $.Deferred();

        locationGroupsApi.getAll()(dispatch, getState).then(response => {

            if (!response.success) {
                deferred.resolve(response);
                return;
            }

            dispatch(actionCreator.setList(response.entity ?? []));

            return deferred.resolve(response);
        });

        return deferred.promise();
    },
    setProperty: (propetyName: string, value: any) => (dispatch, getState: () => ApplicationState) => {
        let newLocationGroup = { ...getState().locationGroups.runningLocationGroup };
        newLocationGroup.name = value;
        dispatch(actionCreator.setRunning(newLocationGroup));
    },
    findRunning: (groupId: string) => (dispatch, getState) => {

        if (groupId === undefined || groupId == null || groupId == "") {
            dispatch(actionCreator.setRunning(getRunning()));
            dispatch(actionCreatorUi.setPageLoaded(true));
            return;
        }

        locationGroupsApi.get(groupId)(dispatch, getState).then(response => {
            if (response.success == false)
                return;

            if (response.entity == null || response.entity === undefined) {
                dispatch(actionCreatorUi.setErrorMessages(["Gruppo non trovato"]));
                return;
            }

            dispatch(actionCreator.setRunning(response.entity));
            dispatch(actionCreatorUi.setPageLoaded(true));
        });
    },
    unloadRunning: (): UnloadRunning => ({
        type: TypeKeys.UNLOAD_RUNNING
    }),
    navigateToRunning: (groupId: string) => (dispatch, getState) => {
        dispatch(actionCreator.unloadRunning());
        dispatch(push(`/locations/group/edit/${groupId}`));
    },
    save: () => (dispatch, getState: () => ApplicationState) => {

        let model = getState().locationGroups.runningLocationGroup;

        locationGroupsApi.save(model)(dispatch, getState).then(response => {
            if (response.success == false)
                return;

            dispatch(actionCreator.setRunning(response.entity));
        });
    },
    delete: (groupId: string) => (dispatch, getState) => {
        locationGroupsApi.delete(groupId)(dispatch, getState).then(response => {
            if (response.success)
                dispatch(push('/locations/groups'));
        });
    },
    setList: (groups: Array<LocationGroup>): SetList => ({
        type: TypeKeys.SET_LIST,
        data: groups
    }),
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    setRunning: (group: LocationGroup): SetRunnig => ({
        type: TypeKeys.SET_RUNNING,
        data: group
    })
};

enum TypeKeys {
    SET_LIST = "LOCATIONGROUP_SET_LIST",
    RESET_STATE = "LOCATIONGROUP_RESET_STATE",
    SET_RUNNING = "LOCATIONGROUP_SET_RUNNING",
    UNLOAD_RUNNING = "LOCATIONGROUPUNLOAD_RUNNING"
}

type LocationGroupAction = SetList | ResetState | SetRunnig | UnloadRunning;

interface UnloadRunning extends Action {
    type: TypeKeys.UNLOAD_RUNNING
}

interface SetList extends Action {
    type: TypeKeys.SET_LIST;
    data: Array<LocationGroup>;
}

interface SetRunnig extends Action {
    type: TypeKeys.SET_RUNNING,
    data: LocationGroup;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE
}

const getRunning = (): LocationGroup => {
    return {
        id: null,
        name: null,
        locations: []
    } as LocationGroup;
}


let baseState: LocationGroupsState = {
    locationGroups: [],
    runningLocationGroup: null
}

export const reducer: Reducer<LocationGroupsState> = (state: LocationGroupsState | undefined, incomingAction: LocationGroupAction): LocationGroupsState => {

    if (state === undefined) {
        return baseState;
    }

    let newState: LocationGroupsState = { ...state };

    switch (incomingAction.type) {

        case TypeKeys.SET_LIST:
            newState = dotprop.set(newState, "locationGroups", incomingAction.data);
            break;
        case TypeKeys.RESET_STATE:
            newState = dotprop.set(newState, "locationGroups", []);
            newState = dotprop.set(newState, "runningLocationGroup", null);
            break;
        case TypeKeys.SET_RUNNING:
            newState = dotprop.set(newState, "runningLocationGroup", incomingAction.data);
            break;
        case TypeKeys.UNLOAD_RUNNING:
            newState = dotprop.set(newState, "runningLocationGroup", null);
            break;
    }


    return newState;
};