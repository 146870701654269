import * as React from "react";
import { Input, Row, Col, Form, FormGroup, Label } from "reactstrap";
import { MenuItemProps } from '../../menu/menuitem';

export interface LocationsSearchFormProps {
    searchString: string;
}

export interface LocationSearchFormCallbacks {
    onSearch: (searchString: string) => void;
}

export class LocationsSearchForm extends React.Component<LocationsSearchFormProps & LocationSearchFormCallbacks> {

    constructor(props) {
        super(props);
    }

    private onSearch(searchString: string) {

        if (this.props.onSearch)
            this.props.onSearch(searchString);

    }

    render() {

        return (
            <React.Fragment>
                <Form className="text-eurorounders search-form">
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Cerca:</Label>
                                <Input
                                    className="bg-dark text-eurorounders"
                                    
                                    type="search"
                                    value={this.props.searchString || ''}
                                    onChange={(e) => { this.onSearch(e.target.value) }}
                                    placeholder="Cerca per nome"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        )
    }
}