import * as React from 'react';
import { Form, Input, Button, Label } from 'reactstrap';
import { MenuItem } from '../menu/menuitem';
import { CheckBox } from '../base/checkbox';
import { ENVIRONMENT, EnvironmentType } from '../../utils/commons';
import './login.css';

export interface LoginProps {
    onSubmit?: (signInName: string, password: string, rememberMe: boolean) => void;
}

enum PropertyName {
    SignInName,
    Password,
    RememberMe
}

interface LoginState {
    signInName: string;
    password?: string;
    rememberMe: boolean;
}

export class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);
        this.state = {
            password: null,
            signInName: null,
            rememberMe: true
        }
    }

    private setStateValue(property: PropertyName, value: any) {

        let newState = {} as LoginState;

        switch (property) {
            case PropertyName.Password:
                newState.password = value;
                break;
            case PropertyName.SignInName:
                newState.signInName = value;
                break;
            case PropertyName.RememberMe:
                newState.rememberMe = value;
                break;
        }

        this.setState(newState);
    }

    private onSubmit() {
        this.props.onSubmit(this.state.signInName, this.state.password, this.state.rememberMe);
    }

    render() {

        let environment = null;

        if (ENVIRONMENT == EnvironmentType.Stage) {
            environment = (<div className="text-danger text-uppercase">{ENVIRONMENT}</div>);
        }

        return (
            <React.Fragment>
                <Form onSubmit={(e) => this.onSubmit()}  className="text-eurorounders">
                    <div className="login-form">
                        <div className="form-container">
                            <img width="300px" src="/Logo_eurorounders-03.png" />
                            {environment}
                            <div className="form-group">
                                <Label>Nome utente</Label>
                                <Input className="bg-dark text-eurorounders" autoComplete="new-username" type="text" name="sign-in-name" onChange={(e) => { this.setStateValue(PropertyName.SignInName, e.target.value) }} />
                            </div>
                            <div className="form-group">
                                <Label>Password</Label>
                                <Input className="bg-dark text-eurorounders" autoComplete="new-password" type="password" name="password" onChange={(e) => { this.setStateValue(PropertyName.Password, e.target.value) }} />
                            </div>
                            <div className="form-group">
                                <CheckBox label="Ricordami" isChecked={this.state.rememberMe} onCheck={(isChecked: boolean) => { this.setStateValue(PropertyName.RememberMe, isChecked) }} />
                            </div>
                            <div className="form-group">
                                <MenuItem isSmall={true} isButton={true} icon="sign-in-alt" label="login" onClick={() => { this.onSubmit() }} className={this.state.password == null || this.state.signInName == null || this.state.password == "" || this.state.signInName == "" ? "disable-events" : ""} />
                            </div>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}