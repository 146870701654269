import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/entries/EntryState';
import { actionCreator } from '../../../store/uiproperties/UIState';
import { EntriesList, EntriesListProps, EntriesListCallbacks } from '../base/list';


const mapStateToProsp = (state: ApplicationState): EntriesListProps => {

    return {
        hasNextPage: state.entries.searchCriteria.hasNextPage,
        list: state.entries.list,
        startLoading: state.uiProperties.startFetchingPagedList
    }
}

const mapDispatchToProps = (dispatch) => (dispatch) : EntriesListCallbacks => {
    return ({
        loadNewPage: () => {
            dispatch(actionsMiddleware.loadNextPage());
        },
        onDelete: (id: string) => dispatch(actionsMiddleware.delete(id)),
        onChangeNumber: (id, updateType) => dispatch(actionsMiddleware.updateNumber(id, updateType)),
        onUnload: () => dispatch(actionCreator.startFetchingPagedList(false))
    });
}

export const ConnectedEntriesList = connect<EntriesListProps, EntriesListCallbacks>(mapStateToProsp, mapDispatchToProps)(EntriesList);