import * as React from "react"
import { guid } from './../../../utils/commons';
import { Modal} from "reactstrap";

export interface OverlayProps {
    isOpened: boolean;
}

export class Overlay extends React.Component<OverlayProps>{
    constructor(props) {
        super(props);
    }

    render() {

        
        return (
            <React.Fragment>
                <Modal className="bg-dark" isOpen={this.props.isOpened} backdrop="static">
                </Modal>
            </React.Fragment>
        )
    }
}