import * as React from 'react';
import { Route, Redirect } from 'react-router';

export interface ProtectdRouteProps {
    isTokenSet: boolean;
    exact?: boolean;
    path: string;
    isLoginPage: boolean;
    isHome: boolean;
    onNavigate?: (actualPath: string, previousPath: string) => void;
}

export class ProtectedRoute extends React.Component<ProtectdRouteProps> {

    constructor(props) {
        super(props)
    }

    UNSAFE_componentWillReceiveProps(nextProps: ProtectdRouteProps) {
        if (nextProps.path !== this.props.path) {
            if (this.props.onNavigate)
                this.props.onNavigate(nextProps.path, this.props.path);
        }
    }

    render() {

        if (this.props.isHome) {
            return (
                <Route
                    exact
                    path={this.props.path}
                    render={({ location }) => this.props.isTokenSet
                        ? (this.props.children)
                        : (<Redirect to={{ pathname: "/login", state: { from: location } }} />)}
                />
            )
        }

        if (this.props.isLoginPage == true) {

            return (
                <Route
                    path={this.props.path}
                    render={({ location }) => !this.props.isTokenSet
                        ? (this.props.children)
                        : (<Redirect to={{ pathname: "/", state: { from: location } }} />)}
                />
            )

        }

        return (
            <Route
                path={this.props.path}
                render={({ location }) => this.props.isTokenSet
                    ? (this.props.children)
                    : (<Redirect to={{ pathname: "/login", state: { from: location } }} />)}
            />
        )

    }


}