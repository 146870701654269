import * as React from "react";
import { MenuItem, MenuItemProps } from "./menuitem";
import './menurow.css';
import { guid } from '../../utils/commons';


export interface MenuRowProps {
    menuVoices: Array<MenuItemProps>;
}


export class MenuRow extends React.Component<MenuRowProps>{
    constructor(props) {
        super(props)
    }


    private getMenuItems(): JSX.Element[] {
        return this.props.menuVoices.map(menuItem => {
            return (<MenuItem key={guid()} icon={menuItem.icon} label={menuItem.label} path={menuItem.path} />)
        })
    }

    render() {

        let menuItems = [];

        if (this.props.menuVoices.length > 0)
            menuItems = this.getMenuItems();

        return (
            <React.Fragment>
                <div className="menu-row-container">
                    {menuItems}
                </div>
            </React.Fragment>
        );
    }
}