import * as React from "react";
import { Input, Row, Col, Form, FormGroup, Label } from "reactstrap";
import { MenuItemProps } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { ConnectedCustomersList } from '../connected/connected.list';
import { ConnectedCustomerSearchForm } from '../connected/connected.searchform';
import { StickyBodyHeader } from "../../base/stickybodyheader";

export interface CustomersPageListProps {
    onLoad?: () => void;
    totalCount: number;
    navigateToCustomer?: (id: string) => void;
}

export class CustomersPageList extends React.Component<CustomersPageListProps> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad)
            this.props.onLoad();
    }

    render() {

        let menuItems = [
            {
                icon: "plus",
                label: "crea",
                path: "/customers/create"
            }
        ] as Array<MenuItemProps>

        return (
            <React.Fragment>
                <StickyBodyHeader>
                    <BodyHeader title={`Clienti (${this.props.totalCount})`} menuItems={menuItems} />
                    <ConnectedCustomerSearchForm />
                </StickyBodyHeader>
                <ConnectedCustomersList selectionEnabled={false} onRowClick={this.props.navigateToCustomer} />
            </React.Fragment>
        )
    }
}