import * as React from "react";
import { Form, FormGroup, Button, Input, CustomInput, Row, Col, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { PaymentCategory, PaymentItem } from '../../../../store/payments/paymentitems/PaymentItemEntities';
import _ from "underscore";
import { BodyHeader } from '../../../base/bodyheader';
import { MenuItemProps, MenuItem } from '../../../menu/menuitem';
import { ConfirmationModal } from '../../../base/confirmationmodal';
import { UnsavedChanges } from "../../../base/unsavedchanges";

export interface EditPaymentItemProps {
    paymentItem: PaymentItem;
    side: PaymentCategory;
}

export interface EditPaymentItemCallbacks {
    onLoad?: (roleId: string, side: PaymentCategory) => void;
    onSubmit?: () => void;
    onChange?: (desc: string) => void;
    onDelete?: (id: string, side: PaymentCategory) => void;
}



export class PaymentItemEditPage extends React.Component<EditPaymentItemProps & EditPaymentItemCallbacks>{
    private modalRef: React.RefObject<ConfirmationModal>;
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }

    componentDidMount() {
        let id = (this.props as any).match.params.id;
        this.props.onLoad(id, this.props.side);
    }

    private delete() {
        this.modalRef.current.toogleModal();
        if (this.props.onDelete)
            this.props.onDelete(this.props.paymentItem.id, this.props.side);
    }

    render() {

        if (!this.props.paymentItem) {
            return (
                <React.Fragment>
                </React.Fragment>
            )
        }


        let menuItems = [];

        if (this.props.paymentItem.id !== undefined && this.props.paymentItem.id != null && this.props.paymentItem.id != '') {
            menuItems = [
                {
                    icon: "trash",
                    label: "elimina",
                    isButton: true,
                    isDisabled: false,
                    onClick: () => { this.modalRef.current.toogleModal() }
                }
            ] as Array<MenuItemProps>
        }

        menuItems.push({
            icon: "list",
            label: "lista",
            isButton: false,
            isSmall: true,
            path: this.props.side == PaymentCategory.Cost ? "/events/configurations/costs" : "/events/configurations/incomings"
        } as MenuItemProps);

        let title = this.props.side == PaymentCategory.Cost ? "Voce di Costo" : "Voce Incasso"

        return (
            <React.Fragment>
                <UnsavedChanges confirmAction={this.props.onSubmit} />
                <BodyHeader title={this.props.paymentItem.id !== undefined && this.props.paymentItem.id != null && this.props.paymentItem.id != '' ? `Modifica ${title}` : `Crea ${title}`} menuItems={menuItems} />
                <Form className="text-eurorounders" >
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Descrizione</Label>
                                <Input className="bg-dark text-eurorounders" type="text" value={this.props.paymentItem.description || ''} onChange={(e) => { this.props.onChange(e.target.value) }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <MenuItem isSmall={true} isButton={true} icon="save" label="salva" onClick={() => this.props.onSubmit()} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <ConfirmationModal text={`Eliminare ${title}?`} onConfirm={() => this.delete()} ref={this.modalRef} />
            </React.Fragment>
        )
    }
}
