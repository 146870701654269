import * as React from "react";
import { Input, Row, Col, Form, FormGroup, Label } from "reactstrap";

export interface AgentsSearchFormProps {
    searchString: string;
}

export interface AgentsSearchFormCallbacks {
    onSearch: (searchString: string) => void;
}

export class AgentsSearchForm extends React.Component<AgentsSearchFormProps & AgentsSearchFormCallbacks> {

    constructor(props) {
        super(props);
    }

    private onSearch(searchString: string) {

        if (this.props.onSearch)
            this.props.onSearch(searchString);

    }

    render() {

        return (
            <React.Fragment>
                <Form className="text-eurorounders search-form">
                    <Row>
                        <Col lg={{ size: 6, order: 0 }} sm={{ size: 6, order: 0 }} xs={{ size: 12 }}>
                            <FormGroup>
                                <Label>Cerca</Label>
                                <Input
                                    className="bg-dark text-eurorounders"
                                    
                                    type="search"
                                    value={this.props.searchString || ''}
                                    onChange={(e) => { this.onSearch(e.target.value) }}
                                    placeholder="Cerca per nome, cognome, email, telefono"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        )
    }
}