import * as React from "react";
import { Location, LocactionTypeDesc } from "../../../store/locations/LocationEntities";
import { BaseList } from '../../base/baseList';
import { CheckBox } from '../../base/checkbox';
import _ from "underscore";
import GoToTop from "../../base/gototop";

export interface LocationsListProps {
    selectionEnabled: boolean;
    list: Array<Location>;
    locationTypesDesc: Array<LocactionTypeDesc>;
    hasNextPage: boolean;
    loadNewPage?: () => void;
    startLoading?: boolean;
    onRowClick?: (model: Location) => void;
    onUnload?: () => void;
}

export class LocationsList extends React.Component<LocationsListProps>{

    constructor(props) {
        super(props);
    }

    private getRows(list: Array<Location>): JSX.Element[] {

        let locationList = list.map(model => {

            let checkbox = (<React.Fragment></React.Fragment>);

            if (this.props.selectionEnabled == true) {
                checkbox = (
                    <td>
                        <CheckBox isChecked={model.isSelected} onCheck={(checked: boolean) => { }} />
                    </td>
                )
            }

            let type = _.find(this.props.locationTypesDesc, (td) => td.type == model.type);

            let typeDesc = '';

            if (type !== undefined && type != null)
                typeDesc = type.desc;

            return (
                <tr key={model.id} style={{ cursor: "pointer" }} onClick={() => this.props.onRowClick(model)}>
                    {checkbox}
                    <td>
                        {model.name}
                    </td>
                    <td className="d-none d-md-table-cell">
                        {model.email}
                    </td>
                    <td className="d-none d-md-table-cell">
                        {model.phone}
                    </td>
                    <td>
                        {model.city}
                    </td>
                    <td className="d-none d-md-table-cell">
                        {typeDesc}
                    </td>
                </tr >
            )
        });

        return locationList;

    }

    private getHead(): JSX.Element {

        let thSelection = (<React.Fragment></React.Fragment>);

        if (this.props.selectionEnabled == true) {
            thSelection = (<th>Sel.</th>);
        }

        return (
            <thead>
                <tr className="text-eurorounders">
                    {thSelection}
                    <th>Nome</th>
                    <th className="d-none d-md-table-cell">Email</th>
                    <th className="d-none d-md-table-cell">Tel.</th>
                    <th>Città</th>
                    <th className="d-none d-md-table-cell">Tipo</th>
                </tr>
            </thead>
        );

    }


    render() {

        return (
            <React.Fragment>
                <BaseList onUnload={this.props.onUnload} hasNextPage={this.props.hasNextPage} list={this.props.list} active={this.props.startLoading} onEndOfList={this.props.loadNewPage} getElements={(list: Array<Location>) => { return this.getRows(list) }} getHead={() => { return this.getHead() }} />
                <GoToTop />
            </React.Fragment>
        )
    }
}