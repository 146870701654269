import * as React from "react";
import { Input, Row, Col, Form, FormGroup, Label } from "reactstrap";
import { MenuItemProps } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { ConnectedLocationsList } from '../connected/connected.list';
import { ConnectedLocationSearchForm } from "../connected/connected.searchform";
import { StickyBodyHeader } from "../../base/stickybodyheader";

export interface LocationsPageListProps {
    onLoad?: () => void;
    totalCount: number;
    navigate?: (model: any) => void;
}

export class LocationsPageList extends React.Component<LocationsPageListProps> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad)
            this.props.onLoad();
    }

    render() {

        let menuItems = [
            {
                icon: "plus",
                label: "crea",
                path: "/locations/create"
            },
            {
                icon: "industry",
                label: "gruppi",
                path: "/locations/groups"
            }
        ] as Array<MenuItemProps>

        return (
            <React.Fragment>
                <StickyBodyHeader>
                    <BodyHeader title={`Hotels (${this.props.totalCount})`} menuItems={menuItems} />
                    <ConnectedLocationSearchForm />
                </StickyBodyHeader>
                <ConnectedLocationsList selectionEnabled={false} onRowClick={this.props.navigate} />
            </React.Fragment>
        )
    }
}