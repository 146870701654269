import * as React from 'react';
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { Contacts } from '../../store/shared/Anagraphic';
import PhoneNumberInput from '../base/phonenumber';
import BaseSelect from '../base/baseselect';

export interface ContactFormProps extends Contacts {
    emailLabel?: string;
    onChange?: (propertyName: string, value: any) => void;
}

interface PhonePrefix {
    name: string;
    dialCode: string;
    code: string;
}

const allPhonePrefixes: Array<PhonePrefix> = [
    {
        name: "+39 Italy",
        dialCode: "+39",
        code: "IT"
    },
    {
        name: "+355 Albania",
        dialCode: "+355",
        code: "AL"
    },
    {
        name: "+374 Armenia",
        dialCode: "+374",
        code: "AM"
    },
    {
        name: "+43 Austria",
        dialCode: "+43",
        code: "AT"
    },
    {
        name: "+375 Belarus",
        dialCode: "+375",
        code: "BY"
    },
    {
        name: "+32 Belgium",
        dialCode: "+32",
        code: "BE"
    },
    {
        name: "+387 Bosnia",
        dialCode: "+387",
        code: "BA"
    },
    {
        name: "+359 Bulgaria",
        dialCode: "+359",
        code: "BG"
    },
    {
        name: "+385 Croatia",
        dialCode: "+385",
        code: "HR"
    },
    {
        name: "+537 Cyprus",
        dialCode: "+537",
        code: "CY"
    },
    {
        name: "+420 Czech Republic",
        dialCode: "+420",
        code: "CZ"
    },
    {
        name: "+45 Denmark",
        dialCode: "+45",
        code: "DK"
    },
    {
        name: "+372 Estonia",
        dialCode: "+372",
        code: "EE"
    },
    {
        name: "+358 Finland",
        dialCode: "+358",
        code: "FI"
    },
    {
        name: "+33 France",
        dialCode: "+33",
        code: "FR"
    },
    {
        name: "+995 Georgia",
        dialCode: "+995",
        code: "GE"
    },
    {
        name: "+49 Germany",
        dialCode: "+49",
        code: "DE"
    },
    {
        name: "+30 Greece",
        dialCode: "+30",
        code: "GR"
    },
    {
        name: "+299 Greenland",
        dialCode: "+299",
        code: "GL"
    },
    {
        name: "+36 Hungary",
        dialCode: "+36",
        code: "HU"
    },
    {
        name: "+354 Iceland",
        dialCode: "+354",
        code: "IS"
    },
    {
        name: "+353 Ireland",
        dialCode: "+353",
        code: "IE"
    },
    {
        name: "+371 Latvia",
        dialCode: "+371",
        code: "LV"
    },
    {
        name: "+370 Lithuania",
        dialCode: "+370",
        code: "LT"
    },
    {
        name: "+352 Luxembourg",
        dialCode: "+352",
        code: "LU"
    },
    {
        name: "+356 Malta",
        dialCode: "+356",
        code: "MT"
    },
    {
        name: "+377 Monaco",
        dialCode: "+377",
        code: "MC"
    },
    {
        name: "+382 Montenegro",
        dialCode: "+382",
        code: "ME"
    },
    {
        name: "+31 Netherlands",
        dialCode: "+31",
        code: "NL"
    },
    {
        name: "+47 Norway",
        dialCode: "+47",
        code: "NO"
    },
    {
        name: "+48 Poland",
        dialCode: "+48",
        code: "PL"
    },
    {
        name: "+351 Portugal",
        dialCode: "+351",
        code: "PT"
    },
    {
        name: "+40 Romania",
        dialCode: "+40",
        code: "RO"
    },
    {
        name: "+378 San Marino",
        dialCode: "+378",
        code: "SM"
    },
    {
        name: "+381 Serbia",
        dialCode: "+381",
        code: "RS"
    },
    {
        name: "+421 Slovakia",
        dialCode: "+421",
        code: "SK"
    },
    {
        name: "+386 Slovenia",
        dialCode: "+386",
        code: "SI"
    },
    {
        name: "+34 Spain",
        dialCode: "+34",
        code: "ES"
    },
    {
        name: "+41 Switzerland",
        dialCode: "+41",
        code: "CH"
    },
    {
        name: "+90 Turkey",
        dialCode: "+90",
        code: "TR"
    },
    {
        name: "+380 Ukraine",
        dialCode: "+380",
        code: "UA"
    },
    {
        name: "+44 United Kingdom",
        dialCode: "+44",
        code: "GB"
    },
    {
        name: "+389 Macedonia",
        dialCode: "+389",
        code: "MK"
    },
    {
        name: "+373 Moldova",
        dialCode: "+373",
        code: "MD"
    },
    {
        name: "+7 Russia",
        dialCode: "+7",
        code: "RU"
    }
]

export default class ContactForm extends React.Component<ContactFormProps>{
    constructor(props) {
        super(props);
    }

    private setStateValue(propertyName: string, value: any) {
        if (this.props.onChange)
            this.props.onChange(propertyName, value);
    }

    render() {

        let emailLabel = "Email/Nome utente";

        if (this.props.emailLabel !== undefined && this.props.emailLabel != null)
            emailLabel = this.props.emailLabel;

        return (
            <React.Fragment>
                <Row>
                    <Col lg="6" sm="6" xs="12">
                        <FormGroup>
                            <Label>{emailLabel}</Label>
                            <Input className="bg-dark text-eurorounders"  type="email" value={this.props.email || ''} onChange={(e) => { this.setStateValue("email", e.target.value) }} />
                        </FormGroup>
                    </Col>
                    <Col lg="6" sm="6" xs="12">
                        <Row>
                            <Col lg="4" sm="4" xs="4">
                                <FormGroup>
                                    <Label>Pref.</Label>
                                    <BaseSelect
                                        descField="name"
                                        idField="dialCode"
                                        onChange={(value) => this.setStateValue("phonePrefix", value)}
                                        value={this.props.phonePrefix || ''}
                                        options={allPhonePrefixes}
                                    />

                                </FormGroup>
                            </Col>
                            <Col lg="8" sm="8" xs="8">
                                <FormGroup>
                                    <Label>Telefono</Label>
                                    <PhoneNumberInput alignLeft className="bg-dark text-eurorounders" value={this.props.phone || ''} onChane={(number) => { this.setStateValue("phone", number) }} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

}