import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router"
import { ApplicationState } from '../../../../store/index';
import { actionsMiddleware } from '../../../../store/payments/cashregister/CashRegisterState';
import { actionsMiddleware as paymentMiddleware } from '../../../../store/payments/payments/PaymentState';
import { CashRegisterPage, CashRegisterPageCallbacks, CashRegisterPageProps } from '../cashregister';

const mapStateToProsp = (state: ApplicationState): CashRegisterPageProps => {

    return {
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name}` : '',
        totals: state.cashRegister.totals
    } as CashRegisterPageProps
}

const mapDispatchToProps = (dispatch) => (dispatch): CashRegisterPageCallbacks => {
    return ({
        onLoad: (eventId: string) => dispatch(actionsMiddleware.loadInitialState(eventId)),
        openTrasferModal: (eventId: string) => dispatch(paymentMiddleware.openTrasfeModal(eventId)),
        openIncomeModal: (eventId: string) => dispatch(paymentMiddleware.openIncomeModal(eventId)),
        openOutcomeModal: (eventId: string) => dispatch(paymentMiddleware.openOutcomeModal(eventId)),
        openCashFundModal: (eventId: string) => dispatch(paymentMiddleware.openCashFundModal(eventId))
    });
}

export const ConnectedCashRegisterPage = withRouter(connect<CashRegisterPageProps, CashRegisterPageCallbacks>(mapStateToProsp, mapDispatchToProps)(CashRegisterPage));