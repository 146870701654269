import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/locations/LocationState';
import { actionCreator } from '../../../store/uiproperties/UIState';
import { LocationsList, LocationsListProps } from '../shared/list';
import { Location } from '../../../store/locations/LocationEntities';

export interface ConnectedLocationsListProps {
    onRowClick?: (model: Location) => void;
    selectionEnabled: boolean;
}

const mapStateToProsp = (state: ApplicationState, ownProps: ConnectedLocationsListProps): LocationsListProps => {
    return {
        hasNextPage: state.locations.searchCriteria.hasNextPage,
        list: state.locations.list,
        startLoading: state.uiProperties.startFetchingPagedList,
        selectionEnabled: ownProps.selectionEnabled,
        locationTypesDesc: state.locations.locactionTypeDesc
    }
}

const mapDispatchToProps = (dispatch, ownProps: ConnectedLocationsListProps) => dispatch => {
    return ({
        loadNewPage: () => {
            dispatch(actionsMiddleware.loadNextPage());
        },
        onRowClick: (model: Location) => {
            dispatch(ownProps.onRowClick(model));
        },
        onUnload: () => dispatch(actionCreator.startFetchingPagedList(false))
    });
}

export const ConnectedLocationsList = connect<LocationsListProps, {}, ConnectedLocationsListProps>(mapStateToProsp, mapDispatchToProps)(LocationsList);