import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import { actionMiddleware } from '../../store/documents/DocumentState';
import { EditDocument, EdiDocumentProps, EditDocumentCallbacks } from './editmodal';

const mapStateToProsp = (state: ApplicationState): EdiDocumentProps => {

    let props: EdiDocumentProps = {
        documentId: null,
        isOpened: state.documents.modalEditOpened,
        name: null,
        objectId: null
    }

    if (state.documents.editState !== undefined && state.documents.editState != null) {
        props = {
            documentId: state.documents.editState.documentId,
            name: state.documents.editState.name,
            objectId: state.documents.editState.objectId,
            isOpened: props.isOpened
        }
    }

    return props;
}

const mapDispatchToProps = (dispatch) => (dispatch): EditDocumentCallbacks => {
    return ({
        onCloseModal: () => dispatch(actionMiddleware.onCloseModal()),
        onChange: (value) => dispatch(actionMiddleware.onChangeName(value)),
        onSubmit: () => dispatch(actionMiddleware.onEditSubmit())
    });
}

export const ConnectedEditDocuments = connect<EdiDocumentProps, EditDocumentCallbacks>(mapStateToProsp, mapDispatchToProps)(EditDocument);