import * as React from "react";
import { ConnectedLocationGroupList } from '../connected/connected.grouplist';
import { BodyHeader } from '../../../base/bodyheader';
import { MenuItemProps } from '../../../menu/menuitem';

export interface LocationGroupPageListProps {
    onLoad?: () => void;
}

export class LocationGroupPageListPageList extends React.Component<LocationGroupPageListProps> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad)
            this.props.onLoad();
    }

    render() {
        let menuItems = [
            {
                icon: "plus",
                label: "crea",
                path: "/locations/group/create"
            },
            {
                icon: "hotel",
                label: "hotels",
                path: "/locations"
            }
        ] as Array<MenuItemProps>
        return (
            <React.Fragment>
                <BodyHeader title="Gruppi Hotels" menuItems={menuItems} bottomSpace={true} />
                <ConnectedLocationGroupList />
            </React.Fragment>
        )
    }
}