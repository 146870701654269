import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/reservations/ReservationQuoteState';
import { ReservatioQuoteSearchForm, ReservatioQuoteSearchFormCallbacks, ReservatioQuoteSearchFormProps } from '../basequote/quotesearchform';


const mapStateToProsp = (state: ApplicationState): ReservatioQuoteSearchFormProps => {

    return {
        roomTypes: state.locations.roomTypes,
        searchCriteria: state.reservationQuote.searchCriteria,
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): ReservatioQuoteSearchFormCallbacks => {
    return ({
        onPropertyChange: (propertyName: string, value: any) => dispatch(actionsMiddleware.propertyChange(propertyName, value)),
        onSearch: () => dispatch(actionsMiddleware.doSearch())
    });
}

export const ConnectedReservationQuoteSearchForm = connect<ReservatioQuoteSearchFormProps, ReservatioQuoteSearchFormCallbacks>(mapStateToProsp, mapDispatchToProps)(ReservatioQuoteSearchForm);