import * as React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Col, Row } from "reactstrap";
import { CommissionTemplate, CommissionTemplateMode, CommissionPaymentMode, PlayerCommissionSource, CommissionTemplateModeTraslation } from '../../../store/profile/Profile';
import { MenuItem } from '../../menu/menuitem';
import DecimalInput from '../../base/decimalinput';
import BaseSelect from "../../base/baseselect";

export interface EditCommmisionTemplateProps {
    commissionTemplate: CommissionTemplate;
    isOpen: boolean;
    saveOnConfirm?: boolean;
    onSubmit?: (saveOnConfirm?: boolean) => void;
    onChange?: (propertyName: string, value: string) => void;
    closeModal?: () => void;
    openModal?: () => void;
}

export default class EditCommmisionTemplate extends React.Component<EditCommmisionTemplateProps> {

    constructor(props) {
        super(props);
    }

    private getModeSelect(): JSX.Element {
        return (
            <Row>
                <Col lg="12" sm="12" xs="12">
                    <FormGroup>
                        <Label>Modalità</Label>
                        <BaseSelect
                            descField="desc"
                            idField="id"
                            onChange={(value) => this.props.onChange("mode", value)}
                            value={this.props.commissionTemplate.mode || ''}
                            options={[
                                {
                                    id: CommissionTemplateMode.Percentage,
                                    desc: CommissionTemplateModeTraslation[CommissionTemplateMode.Percentage]
                                },
                                {
                                    id: CommissionTemplateMode.Fixed,
                                    desc: CommissionTemplateModeTraslation[CommissionTemplateMode.Fixed]
                                },
                                {
                                    id: CommissionTemplateMode.Mixed,
                                    desc: CommissionTemplateModeTraslation[CommissionTemplateMode.Mixed]
                                }
                            ]}
                            defaultOptionDisabled={true}
                        />
                    </FormGroup>
                </Col>
            </Row>);
    }

    private getPlayerPaymentSource(): JSX.Element {
        return (
                <Col lg="12" sm="12" xs="12">
                    <FormGroup>
                        <Label>Provvigione entry su:</Label>
                        <BaseSelect
                            descField="desc"
                            idField="id"
                            onChange={(value) => this.props.onChange("playerCommissionSource", value)}
                            value={this.props.commissionTemplate.playerCommissionSource || ''}
                            options={[
                                {
                                    id: PlayerCommissionSource.BuyIn,
                                    desc: PlayerCommissionSource.BuyIn
                                },
                                {
                                    id: PlayerCommissionSource.Fee,
                                    desc: PlayerCommissionSource.Fee
                                }
                            ]}
                            defaultOptionDisabled={true}
                        />
                    </FormGroup>
                </Col>
            );
    }

    private getMixedDropDown(propValue: CommissionPaymentMode, propName: string): JSX.Element {
        return (
            <Col lg="6" sm="6" xs="12">
                <FormGroup>
                    <Label>Tipo provviggione</Label>
                    <BaseSelect
                        descField="desc"
                        idField="id"
                        onChange={(value) => this.props.onChange(propName, value)}
                        value={propValue || ''}
                        options={[
                            {
                                id: CommissionPaymentMode.Fixed,
                                desc: "Fissa"
                            },
                            {
                                id: CommissionPaymentMode.Percentage,
                                desc: "Percentuale"
                            }
                        ]}
                        defaultOptionDisabled={true}
                    />
                </FormGroup>
            </Col>
        );
    }

    render() {

        if (this.props.commissionTemplate === undefined || this.props.commissionTemplate == null) {
            return (
                <React.Fragment>
                </React.Fragment>
            )
        }

        let modeChoice = (<React.Fragment></React.Fragment>);

        let editTitle = `Modifica ${this.props.commissionTemplate.type == "Manual" ? "Manuale" : this.props.commissionTemplate.type}`;

        if (this.props.commissionTemplate.type != "Manual") {

            editTitle = `${editTitle} ${CommissionTemplateModeTraslation[this.props.commissionTemplate.mode]}`;
        }

        if (this.props.commissionTemplate.type == "Manual")
            modeChoice = this.getModeSelect();

        let provisioningPercentageSource = null;

        if (this.props.commissionTemplate.commissionForPlayerMode == CommissionPaymentMode.Percentage)
            provisioningPercentageSource = this.getPlayerPaymentSource();

        let colDimension = "12";

        
        let dropDownRoomMixed = null;
        let dropDownPlayerMixed = null;

        if (this.props.commissionTemplate.mode == CommissionTemplateMode.Mixed) {
            colDimension = "6";
            dropDownPlayerMixed = this.getMixedDropDown(this.props.commissionTemplate.commissionForPlayerMode, "commissionForPlayerMode");
            dropDownRoomMixed = this.getMixedDropDown(this.props.commissionTemplate.commissionForRoomMode, "commissionForRoomMode");

        }


        return (

            <React.Fragment>
                <Modal className="bg-dark modal-lg" isOpen={this.props.isOpen} toggle={() => this.props.closeModal()} backdrop="static">
                    <ModalHeader className="text-eurorounders bg-dark" toggle={() => this.props.closeModal()}>{editTitle}</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders modal-header-button-box">
                        <Form className="text-eurorounders" style={{ width: "100%" }}>
                            {modeChoice}
                            <Row>
                                {dropDownRoomMixed}
                                <Col lg={colDimension} sm={colDimension} xs="12">
                                    <FormGroup>
                                        <Label>Provvigione su booking</Label>
                                        <InputGroup>
                                            <DecimalInput key="commissionForRoom" onChane={(value: string) => this.props.onChange("commissionForRoom", value)} value={this.props.commissionTemplate.commissionForRoom} />
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText className="text-eurorounders bg-dark">{this.props.commissionTemplate.commissionForRoomMode == CommissionPaymentMode.Fixed ? "€" : "%"}</InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                {dropDownPlayerMixed}
                                <Col lg={colDimension} sm={colDimension} xs="12">
                                    <FormGroup>
                                        <Label>Provvigione su entry</Label>
                                        <InputGroup>
                                            <DecimalInput key="commissionForPlayer" onChane={(value: string) => this.props.onChange("commissionForPlayer", value)} value={this.props.commissionTemplate.commissionForPlayer} />
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText className="text-eurorounders bg-dark">{this.props.commissionTemplate.commissionForPlayerMode == CommissionPaymentMode.Fixed ? "€" : "%"}</InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                {provisioningPercentageSource}
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon="check" label="Confirm" onClick={() => this.props.onSubmit(this.props.saveOnConfirm)} />
                        <MenuItem isSmall={true} isButton={true} icon="times" label="Cancel" onClick={() => this.props.closeModal()} />
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}