import * as React from 'react';
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { Address } from '../../store/shared/Anagraphic';

export interface AddressFormProps extends Address {
    onChange?: (propertyName: string, value: any) => void;
}

export default class AddressForm extends React.Component<AddressFormProps>{
    constructor(props) {
        super(props);
    }

    private setStateValue(propertyName: string, value: any) {
        if (this.props.onChange)
            this.props.onChange(propertyName, value);
    }

    render() {

        return (
            <React.Fragment>
                <Row>
                    <Col lg="3" sm="6" xs="6">
                        <FormGroup>
                            <Label>Città</Label>
                            <Input className="bg-dark text-eurorounders"  type="text" value={this.props.city || ''} onChange={(e) => { this.setStateValue("city", e.target.value) }} />
                        </FormGroup>
                    </Col>
                    <Col lg="3" sm="6" xs="6">
                        <FormGroup>
                            <Label>C.A.P.</Label>
                            <Input className="bg-dark text-eurorounders"  type="text" value={this.props.zipCode || ''} onChange={(e) => { this.setStateValue("zipCode", e.target.value) }} />
                        </FormGroup>
                    </Col>
                    <Col lg="3" sm="6" xs="6">
                        <FormGroup>
                            <Label>Nazione</Label>
                            <Input className="bg-dark text-eurorounders"  type="text" value={this.props.country || ''} onChange={(e) => { this.setStateValue("country", e.target.value) }} />
                        </FormGroup>
                    </Col>
                    <Col lg="3" sm="6" xs="6">
                        <FormGroup>
                            <Label>Provincia</Label>
                            <Input className="bg-dark text-eurorounders"  type="text" value={this.props.stateProvince || ''} onChange={(e) => { this.setStateValue("stateProvince", e.target.value) }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" sm="12">
                        <FormGroup>
                            <Label>Indirizzo</Label>
                            <Input className="bg-dark text-eurorounders"  type="text" value={this.props.address || ''} onChange={(e) => { this.setStateValue("address", e.target.value) }} />
                        </FormGroup>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

}