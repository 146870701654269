import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware, actionCreator } from '../../../store/locations/LocationState';
import { LocationsPageList, LocationsPageListProps } from '../pages/listpage';

const mapStateToProsp = (state: ApplicationState): LocationsPageListProps => {

    return {
        totalCount: state.locations.totalCount
    }
}

const mapDispatchToProps = () => dispatch => {
    return ({
        onLoad: () => {
            dispatch(actionsMiddleware.loadInitialState());
        },
        navigate: actionsMiddleware.navigate
    });
}

export const ConnectedLocationsPageList = connect<LocationsPageListProps>(mapStateToProsp, mapDispatchToProps)(LocationsPageList);