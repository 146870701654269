import { Action, Reducer } from 'redux';
import { ApplicationState } from '../../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest, checkRefreshToken, SaveRequest } from '../../../utils/ajaxutilities';
import dotProp from "dot-prop";
import _ from "underscore";
import { PaymentMethod } from './PaymentMethodEntities'
import $ from "jquery";
import { push } from 'connected-react-router';
import { CoreResponse } from '../../serverresponse/serverresponse';


export interface PaymentMethodState {
    paymentMethods: Array<PaymentMethod>;
    runningPaymentMethod: PaymentMethod;
    loadCompleted: boolean;
}

const getBaseState = (): PaymentMethodState => {
    return {
        loadCompleted: false,
        paymentMethods: [],
        runningPaymentMethod: null
    } as PaymentMethodState;
}

const getDefault = (): PaymentMethod => {
    return {
        id: null,
        method: null,
        isCustom: true
    };
}

enum TypeKeys {
    SET_RUNNING = "PAYMENTMETHOD_SET_RUNNING",
    SET_LIST = "PAYMENTMETHOD_SET_LIST",
    MODULE_LOADED = "PAYMENTMETHOD_SET_MODULE_LOADED",
    RESET_STATE = "PAYMENTMETHOD_RESET_STATE"
}

interface SetList extends Action {
    type: TypeKeys.SET_LIST;
    methods: Array<PaymentMethod>;
}

interface SetRunning extends Action {
    type: TypeKeys.SET_RUNNING;
    method: PaymentMethod;
}

interface SetModuleLoaded extends Action {
    type: TypeKeys.MODULE_LOADED;
    loaded: boolean;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE;
}

type PaymentMethodActions =
    SetList
    | SetModuleLoaded
    | ResetState
    | SetRunning;


const actionsCreator = {
    setList: (items: Array<PaymentMethod>): SetList => ({
        methods: items,
        type: TypeKeys.SET_LIST
    }),
    setModuleLoaded: (loaded: boolean): SetModuleLoaded => ({
        type: TypeKeys.MODULE_LOADED,
        loaded: loaded
    }),
    setRunning: (method: PaymentMethod): SetRunning => ({
        method: method,
        type: TypeKeys.SET_RUNNING
    }),
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    })
}


export const sharedActions = {
    loadAll: () => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<Array<PaymentMethod>>> => {
        let promise = $.Deferred();

        let requestModel = new AbortableRequest(`/paymentmethods/list`, RequestMethods.GET);

        ajaxRequest<Array<PaymentMethod>>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                promise.resolve(response);
            }

            dispatch(actionsCreator.setList(response.entity));
            promise.resolve(response);
        });

        return promise.promise();
    },
    load: (id: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<PaymentMethod>> => {
        let promise = $.Deferred();

        let requestModel = new AbortableRequest(`/paymentmethods/${id}`, RequestMethods.GET);

        ajaxRequest<PaymentMethod>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                promise.resolve(response);
            }

            promise.resolve(response);
        });

        return promise.promise();
    }
}

export const actionsMiddleware = {
    loadInitialState: () => (dispatch, getState) => {
        dispatch(actionsCreator.resetState());
        $.when(dispatch(sharedActions.loadAll())).then((response: CoreResponse<Array<PaymentMethod>>) => {
            if (response.success)
                dispatch(actionsCreator.setModuleLoaded(true));
        });
    },
    navigateToRunning: (id: string) => (dispatch, getState) => {
        dispatch(push(`/events/configurations/paymentmethods/edit/${id}`));
    },
    setRunning: (id: string) => (dispatch, getState) => {

        if (!id) {
            dispatch(actionsCreator.setRunning(getDefault()));
            return;
        }

        $.when(dispatch(sharedActions.load(id))).then((response: CoreResponse<PaymentMethod>) => {
            if (!response.success)
                return;
            dispatch(actionsCreator.setRunning(response.entity));
        });
    },
    changeDescription: (desc: string) => (dispatch, getState: () => ApplicationState) => {
        let newState = { ...getState().paymentMethods.runningPaymentMethod };
        newState.method = desc;
        dispatch(actionsCreator.setRunning(newState));
    },
    delete: (id: string) => (dispatch, getState) => {
        let requestModel = new SaveRequest(`/paymentmethods/${id}`, RequestMethods.DELETE, null);

        ajaxRequest<any>(requestModel)(dispatch, getState).then(response => {
            if (response.success)
                dispatch(push('/events/configurations/paymentmethods'));
        });
    },
    save: () => (dispatch, getState: () => ApplicationState): JQueryPromise<any> => {
        let deferred = $.Deferred();

        let requestModel = new SaveRequest(`/paymentmethods`, RequestMethods.POST, getState().paymentMethods.runningPaymentMethod);

        ajaxRequest<any>(requestModel)(dispatch, getState).then((response: CoreResponse<PaymentMethod>) => {
            if (response.success)
                dispatch(actionsCreator.setRunning(response.entity));
            deferred.resolve();
        });

        return deferred.promise();
    }
}


export const reducer: Reducer<PaymentMethodState> = (state: PaymentMethodState | undefined, incomingAction: PaymentMethodActions): PaymentMethodState => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.RESET_STATE:
            let stateToUpdate = getBaseState();
            newState = { ...stateToUpdate };
            break;

        case TypeKeys.SET_LIST:
            newState = dotProp.set(newState, "paymentMethods", [...incomingAction.methods]);
            break;
        case TypeKeys.MODULE_LOADED:
            newState = dotProp.set(newState, "loadCompleted", incomingAction.loaded);
            break;
        case TypeKeys.SET_RUNNING:
            newState = dotProp.set(newState, "runningPaymentMethod", { ...incomingAction.method });
            break;

    }

    return newState;
}
