import { ApplicationState } from "../../../store/index";
import { connect } from "react-redux";
import { ErrorMessagesProps, ErrorMessages } from './errorMessages';
import { actionCreator } from '../../../store/uiproperties/UIState';
import _ from "underscore";

export interface ConnectedErrorMessagesProps {
    scope: string;
}

const mapStateToProps = (state: ApplicationState): ErrorMessagesProps => {

    let messagesToShow = [];

    state.uiProperties.errorMessages.forEach(message => {
        messagesToShow.push(message.message);
    })

    return {
        messages: messagesToShow
    }
};

const mapDispatchToProps = () => dispatch => {
    return ({
        close: () => {
            dispatch(actionCreator.setErrorMessages([]))
        }
    })
}

export const ConnectedErrorMessages = connect<ErrorMessagesProps>(mapStateToProps, mapDispatchToProps)(ErrorMessages);