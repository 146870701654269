import * as React from "react";
import { ConfirmationModal } from '../../base/confirmationmodal';
import { MenuItemProps, MenuItem } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { PeriodPricing } from '../../../store/pricings/PricingEntities';
import { ConnectedHotelsPriceEditForm } from '../connected/connected.editform';
import { ConnectedRoomAvailabilityModal } from '../connected/connected.roomavailabilitymodal';
import { UnsavedChanges } from "../../base/unsavedchanges";

export interface HotelsPriceEditPageProps {
    pricing?: PeriodPricing;
    eventFullName: string;

}

export interface HotelsPriceEditPageCallbacks {
    onLoad: (id: string) => void;
    onDelete: () => void;
    onSubmit: () => void;
}


export class HotelsPriceEditPage extends React.Component<HotelsPriceEditPageProps & HotelsPriceEditPageCallbacks>{
    private modalRef: React.RefObject<ConfirmationModal>;
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }

    componentDidMount() {

        if (this.props.onLoad) {
            let id = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                id = (this.props as any).match.params.id;
            this.props.onLoad(id);
        }
    }

    private delete() {
        if (this.props.onDelete)
            this.props.onDelete();
    }

    render() {

        if (this.props.pricing === undefined || this.props.pricing == null) {
            return (<React.Fragment></React.Fragment>);
        }

        let isNew = true;

        let menuItems = [];

        if (this.props.pricing.id !== undefined && this.props.pricing.id != null) {
            menuItems = [
                {
                    icon: "trash",
                    label: "elimina",
                    isButton: true,
                    isDisabled: false,
                    onClick: () => { this.modalRef.current.toogleModal() }
                }
            ] as Array<MenuItemProps>

            isNew = false;
        }

        menuItems.push({
            icon: "ellipsis-h",
            label: "Evento",
            isButton: false,
            isSmall: true,
            path: `/events/${this.props.pricing.event.id}/menu`
        } as MenuItemProps);

        menuItems.push({
            icon: "hotel",
            label: "hotels",
            isButton: false,
            isSmall: true,
            path: `/events/${this.props.pricing.event.id}/hotels`
        } as MenuItemProps);

        return (
            <React.Fragment>
                <UnsavedChanges confirmAction={this.props.onSubmit} />
                <BodyHeader subTitle={this.props.eventFullName} title={isNew == true ? "Crea configurazione hotel" : "Modifica configurazione hotel"} menuItems={menuItems} />
                <ConnectedHotelsPriceEditForm />
                <ConnectedRoomAvailabilityModal />
                <ConfirmationModal text="Eliminare configurazione hotel?" onConfirm={() => this.delete()} ref={this.modalRef} />
            </React.Fragment>
        )
    }
}