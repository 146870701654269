import { Action, Reducer } from 'redux';
import { ApplicationState } from '../../';
import { ajaxRequest, RequestMethods, AbortableRequest } from '../../../utils/ajaxutilities';
import dotProp from "dot-prop";
import _ from "underscore";
import { AccountingSummary } from './AccountingSummaryEntities';
import $ from "jquery";
import { CoreResponse } from '../../serverresponse/serverresponse';


export interface AccountingSummaryState {
    totals: AccountingSummary;
}

const getBaseState = (eventId?: string): AccountingSummaryState => {
    return {
        totals: getBaseSummary(eventId)
    } as AccountingSummaryState;
}

const getBaseSummary = (eventId?: string): AccountingSummary => ({
    summaries: [],
    total: 0,
    totalCost: 0,
    totalIncome: 0,
    eventId: eventId
} as AccountingSummary);

enum TypeKeys {
    SET_TOTALS = "ACCOUNTINGSUMMARY_SET_TOTALS",
}

interface SetTotals extends Action {
    type: TypeKeys.SET_TOTALS;
    state: AccountingSummary;
}


const actionsCreator = {
    setState: (state: AccountingSummary): SetTotals => ({
        state: state,
        type: TypeKeys.SET_TOTALS
    })
}


const sharedActions = {
    load: (eventId: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<AccountingSummary>> => {
        let promise = $.Deferred();

        let requestModel = new AbortableRequest(`/accountings/summary/${eventId}`, RequestMethods.GET);

        ajaxRequest<AccountingSummary>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                promise.resolve(response);
            }

            promise.resolve(response);
        });

        return promise.promise();
    }
}

export const actionsMiddleware = {
    loadInitialState: (eventId: string) => (dispatch, getState) => {
        dispatch(actionsCreator.setState(getBaseSummary(eventId)));
        $.when(dispatch(sharedActions.load(eventId))).then((response: CoreResponse<AccountingSummary>) => {
            if (response.success)
                dispatch(actionsCreator.setState(response.entity));
        });
    }
}


export const reducer: Reducer<AccountingSummaryState> = (state: AccountingSummaryState | undefined, incomingAction: SetTotals): AccountingSummaryState => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {

        case TypeKeys.SET_TOTALS:
            newState = dotProp.set(newState, "totals", { ...incomingAction.state });
            break;

    }

    return newState;
}
