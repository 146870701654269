import * as React from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { ChangePassword } from "./changepassword";
import { Account } from '../../../store/profile/Profile';
import { Role } from '../../../store/profile/Role';
import { ProfileType } from '../../../store/profile/Profile';
import { CheckBox } from '../../base/checkbox';

export interface AccountDataProps {
    account?: Account;
    profileType: ProfileType;
    allRoles?: Array<Role>;
    onChange?: (propertyName: string, value: any) => void;
}

export default class AccountDataForm extends React.Component<AccountDataProps>{
    constructor(props) {
        super(props);
    }

    private getRoleComponent(): JSX.Element {

        let rolesArray = this.props.allRoles.map(role => {
            return (<option key={role.id} value={role.id}>{role.name}</option>)
        });

        let defaultOption = [];

        defaultOption.push(<option key="role-key" value={undefined}>-</option>)

        let allOption = defaultOption.concat(rolesArray);

        let roleSelected = '';

        if (this.props.account.role !== undefined && this.props.account.role != null) {
            roleSelected = this.props.account.role.id;
        }

        return (
            <Col lg="6" sm="6" xs="6">
                <FormGroup>
                    <Label>Ruolo</Label>
                    <Input type="select" value={roleSelected} className="bg-dark text-eurorounders" onChange={(e) => this.props.onChange("role", e.target.value)}>
                        {allOption}
                    </Input>
                </FormGroup>
            </Col>

        )
    }

    render() {

        if (this.props.account === undefined || this.props.account == null)
            return (
                <React.Fragment>
                </React.Fragment>
            )

        let roleComponent = (<React.Fragment></React.Fragment>);

        if (this.props.allRoles !== undefined && this.props.allRoles != null)
            roleComponent = this.getRoleComponent();

        let isDisabled = false;
        if (this.props.account !== undefined && this.props.account != null && this.props.account.isDisabled)
            isDisabled = true;

        return (
            <React.Fragment>
                <Row style={{ alignItems: "center" }}>
                    {roleComponent}
                    <Col lg="6" sm="6" xs="6">
                        <CheckBox isChecked={isDisabled} label="Disattivato" onCheck={(isChecked: boolean) => { this.props.onChange("isDisabled", isChecked) }} />
                    </Col>
                </Row>
                <ChangePassword
                    password={this.props.account !== undefined && this.props.account != null ? this.props.account.password : undefined}
                    confirmPassword={this.props.account !== undefined && this.props.account != null ? this.props.account.confirmPassword : undefined}
                    onChange={(propertyName: string, value: any) => this.props.onChange(propertyName, value)}
                />
            </React.Fragment>
        )
    }
}