import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ApplicationState } from '../../../store/index';
import { actionCreator } from '../../../store/profile/ProfilesSate';
import { actionCreator as actionCreatorsUI } from '../../../store/uiproperties/UIState';
import { ProfileEdit, ProfileEditProps } from '../shared/editprofileform';

const mapStateToProps = (state: ApplicationState): ProfileEditProps => {
    return {
        allRoles: state.roles.rolesList,
        profile: state.profiles.runningProfile
    }
}

const mapDispatchToProps = () => dispatch => {
    return {
        onChange: (propertyName: string, value: any) => {
            dispatch(actionCreator.setRunningProfileProp(propertyName, value));
            dispatch(actionCreatorsUI.setChangeInProgress(true));
        },
        onSubmit: () => dispatch(actionCreator.saveProfile())
    }
}

export const ConnectedProfileEditForm = connect<ProfileEditProps>(mapStateToProps, mapDispatchToProps)(ProfileEdit)