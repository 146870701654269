import * as React from "react";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import { RoomType, Location } from "../../../store/locations/LocationEntities";
import BaseSelect from "../../base/baseselect";
import { BodyHeader } from '../../base/bodyheader';
import { MenuItem, MenuItemProps } from "../../menu/menuitem";


export interface BookingStatusPageProps {
    eventFullName: string;
    roomTypes: Array<RoomType>;
    locations: Array<Location>;
    selectedRoomTypeId: string;
    selectedLocationId: string;
}

export interface BookingStatusPageCallbacks {
    onLoad: () => void;
    onSearch: () => void;
    onPropertyChange: (propertyName: string, value: any) => void;
}


export class BookingStatusPage extends React.Component<BookingStatusPageProps & BookingStatusPageCallbacks>{
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let eventId = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                eventId = (this.props as any).match.params.eventId;
            this.props.onLoad();
        }
    }

    render() {

        let menuItems = [
            {
                icon: "ellipsis-h",
                label: "Evento",
                isButton: false,
                isSmall: true,
                path: `/events/${(this.props as any).match.params.eventId}/menu`
            } as MenuItemProps,
            {
                icon: "hotel",
                label: "hotels",
                isButton: false,
                isSmall: true,
                path: `/events/${(this.props as any).match.params.eventId}/hotels`
            } as MenuItemProps
        ];

        return (
            <React.Fragment>
                <BodyHeader subTitle={this.props.eventFullName} title="Stato prenotazioni" menuItems={menuItems} />
                <React.Fragment>
                    <Form className="text-eurorounders" >
                        <Row>
                            <Col lg="6" sm="6" xs="12">
                                <FormGroup>
                                    <Label>Hotel</Label>
                                    <BaseSelect
                                        descField="name"
                                        idField="id"
                                        options={this.props.locations}
                                        value={this.props.selectedLocationId}
                                        onChange={(value: any) => { this.props.onPropertyChange("selectedLocationId", value) }} />
                                </FormGroup>
                            </Col>
                            <Col lg="6" sm="6" xs="12">
                                <FormGroup>
                                    <Label>Camera</Label>
                                    <BaseSelect
                                        descField="type"
                                        idField="id"
                                        options={this.props.roomTypes}
                                        value={this.props.selectedRoomTypeId}
                                        onChange={(value: any) => { this.props.onPropertyChange("selectedRoomId", value) }} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <MenuItem isSmall={true} isButton={true} icon="search" label="Ok" onClick={() => this.props.onSearch()} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </React.Fragment>
            </React.Fragment>
        )
    }
}