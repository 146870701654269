import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/index';
import { HotelsSummary, HotelsSummaryProps } from './hotelssummary';


const mapStateToProsp = (state: ApplicationState): HotelsSummaryProps => {

    return {
        accounting: state.hotelsAccounting.running
    }
}



export const ConnectedHotelsSummary = connect<HotelsSummaryProps>(mapStateToProsp, {})(HotelsSummary);