import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from '../../store';
import { AppHeader, HeaderProps } from './appheader';
import { actionCreators } from '../../store/profile/CurrentProfile';

const mapStateToProps = (state: ApplicationState): HeaderProps => {
    return {
        name: state.currentProfile != null && state.currentProfile.currentProfile !== undefined && state.currentProfile.currentProfile != null ? state.currentProfile.currentProfile.firstName : null,
        surname: state.currentProfile != null && state.currentProfile.currentProfile !== undefined && state.currentProfile.currentProfile != null ? state.currentProfile.currentProfile.lastName : null,
        isProfileActive: state.currentProfile != null && state.currentProfile.currentProfile !== undefined && state.currentProfile.currentProfile != null && state.currentProfile.currentProfile.id !== undefined && state.currentProfile.currentProfile.id != null 
    }
}

const mapDispatchToProps = () => dispatch => {
    return ({
        logout: () => { dispatch(actionCreators.logout()); },
        onModalOpen: () => dispatch(actionCreators.setUiState())
    });
}

export const ConnectedHeader = withRouter(connect<HeaderProps>(mapStateToProps, mapDispatchToProps)(AppHeader));