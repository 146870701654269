import { Action, Reducer } from "redux";
import { ajaxRequest, loginRequest, RequestMethods, RequestModel } from '../../utils/ajaxutilities';
import { Permission } from '../profile/Role';
import { AppThunkAction } from '..';
import dotprop from "dot-prop";
import { MenuItemProps } from "../../components/menu/menuitem";

export interface UiPropertiesState {
    requestInProgress: boolean;
    errorMessages: Array<ErrorMessage>;
    menuVoices: Array<MenuItem>;
    pageInitialized: boolean;
    actualPath: string;
    previousPath: string;
    saveRequestInProcess: boolean;
    supportNativeDateInput: boolean;
    changeInProgress: boolean;
    isQrReaderOpen: boolean;
    startFetchingPagedList: boolean;
}

export interface ErrorMessage {
    scope: string;
    message: string;
}

let baseState: UiPropertiesState = {
    errorMessages: [],
    requestInProgress: false,
    menuVoices: [],
    actualPath: null,
    previousPath: null,
    pageInitialized: false,
    saveRequestInProcess: false,
    supportNativeDateInput: false,
    changeInProgress: false,
    isQrReaderOpen: false,
    startFetchingPagedList: false
}

export interface MenuItem {
    id: string;
    path: string;
    icon: string;
    label: string;
    scope: string;
    associatedToPermissions: Array<string>;
    order: number;
}

export const getEventMenu = (eventId: string, hasCashEnabled: boolean): Array<MenuItem> => {
    let menuItems = [
        {
            associatedToPermissions: ["Reservations"],
            id: "quote-reservation",
            icon: "calendar-check",
            label: "Cerca",
            order: 0,
            path: `/events/${eventId}/reservations/quote`,
            scope: "event"

        },
        {
            associatedToPermissions: ["Reservations"],
            id: "reservations",
            icon: "calendar-alt",
            label: "prenotazioni",
            order: 20,
            path: `/events/${eventId}/reservations`,
            scope: "event"
        },
        {
            associatedToPermissions: ["EventsEntries"],
            id: "entries",
            icon: "ticket-alt",
            label: "entries",
            order: 24,
            path: `/events/${eventId}/entries`,
            scope: "event"
        },
        {
            associatedToPermissions: ["EventsCommunications"],
            id: "comunications",
            icon: "envelope",
            label: "comunicazioni",
            order: 25,
            path: `/events/${eventId}/communications`,
            scope: "event"
        },
        {
            associatedToPermissions: ["EventsHotel"],
            id: "hotels",
            icon: "hotel",
            label: "hotels",
            order: 30,
            path: `/events/${eventId}/hotels`,
            scope: "event"
        },
        {
            associatedToPermissions: ["EventsKPI"],
            id: "kpi",
            icon: "chart-line",
            label: "kpis",
            order: 40,
            path: `/events/${eventId}/kpi`,
            scope: "event"
        }
    ]

    if (hasCashEnabled) {
        menuItems.push({
            associatedToPermissions: ["EventAccounting"],
            id: "cashDesk",
            icon: "hand-holding-usd",
            label: "conto",
            order: 50,
            path: `/events/${eventId}/v2/accounting`,
            scope: "event"
        });
    } else {
        menuItems.push({
            associatedToPermissions: ["EventAccounting"],
            id: "accounting",
            icon: "calculator",
            label: "rendiconto",
            order: 50,
            path: `/events/${eventId}/accounting`,
            scope: "event"
        });
    }


    return menuItems;
};

export const getEventAccountingMenu = (eventId: string): Array<MenuItem> => ([
    {
        associatedToPermissions: ["EventAccounting"],
        id: "cash-register",
        icon: "cash-register",
        label: "Cassa",
        order: 0,
        path: `/events/${eventId}/v2/accounting/cash-register`,
        scope: "accounting"
    },
    {
        associatedToPermissions: ["EventAccounting"],
        id: "movements",
        icon: "list",
        label: "Movimenti",
        order: 5,
        path: `/events/${eventId}/v2/accounting/movements`,
        scope: "accounting"
    },
    {
        id: "agents-account",
        path: `/events/${eventId}/v2/accounting/agents-account`,
        label: "agenti",
        icon: "user-tie",
        scope: "accounting",
        associatedToPermissions: ["EventAccounting"],
        order: 10
    },
    {
        associatedToPermissions: ["EventAccounting"],
        id: "hotels",
        icon: "hotel",
        label: "hotels",
        order: 20,
        path: `/events/${eventId}/v2/accounting/hotels-account`,
        scope: "accounting"
    },
    {
        associatedToPermissions: ["EventAccounting"],
        id: "accounting-summary",
        icon: "calculator",
        label: "riepilogo",
        order: 30,
        path: `/events/${eventId}/v2/accounting/summary`,
        scope: "accounting"
    }
]);


export const allMenuVoices: Array<MenuItem> = [
    {
        id: "events",
        path: "/events",
        label: "eventi",
        icon: "tasks",
        scope: "home",
        associatedToPermissions: ["Reservations", "Events"],
        order: 0
    },
    {
        id: "customers",
        path: "/customers",
        label: "clienti",
        icon: "users",
        scope: "home",
        associatedToPermissions: ["Customers"],
        order: 1
    },
    {
        id: "agents",
        path: "/agents",
        label: "agenti",
        icon: "user-tie",
        scope: "home",
        associatedToPermissions: ["Agents"],
        order: 1
    },
    {
        id: "profiles",
        path: "/profiles",
        label: "utenti",
        icon: "user-lock",
        scope: "home",
        associatedToPermissions: ["Users"],
        order: 1
    },
    {
        id: "locations",
        path: "/locations",
        label: "hotels",
        icon: "hotel",
        scope: "home",
        associatedToPermissions: ["Locations"],
        order: 0
    },
    {
        id: "guide",
        path: "/guide",
        label: "guida",
        icon: "question-circle",
        scope: "home",
        associatedToPermissions: ["*"],
        order: 0
    }
];

type UiActions = SetMenuVoices
    | SetErrorMessages
    | SetHistory
    | SetPageLoaded
    | SaveRequestInProcess
    | SupportNativeDateInput
    | SetChangeInProgress
    | ToogleQrReader
    | SetStartFetching;

enum TypeKeys {
    SET_MENU_VOICES = "UI_STATE_SET_MENU_VOICES",
    SET_ERROR_MESSAGES = "UI_STATE_SET_ERROR_MESSAGES",
    SET_HISTORY = "UI_STATE_SET_HISTORY",
    SET_PAGE_LOADED = "UI_STATE_SET_PAGE_LOADED",
    SAVE_REQUEST_IN_PROCESS = "UI_STATE_SAVE_REQUEST_IN_PROCESS",
    SET_SUPPORT_NATIVE_DATE_INPUT = "UI_STATE_SET_SUPPORT_NATIVE_DATE_INPUT",
    SET_CHANGE_IN_PROGRESS = "UI_STATE_SET_CHANGE_IN_PROGRESS",
    TOOGLE_QR_READER = "UI_STATE_TOOGLE_QR_READER",
    START_FETCHING_PAGED_LIST = "UI_STATE_START_FETCHING_PAGED_LIST"
}

interface SetStartFetching extends Action {
    type: TypeKeys.START_FETCHING_PAGED_LIST;
    start: boolean;
}

interface ToogleQrReader extends Action {
    type: TypeKeys.TOOGLE_QR_READER
}

interface SetChangeInProgress extends Action {
    type: TypeKeys.SET_CHANGE_IN_PROGRESS,
    inProgress: boolean;
}

interface SupportNativeDateInput extends Action {
    type: TypeKeys.SET_SUPPORT_NATIVE_DATE_INPUT;
    data: boolean;
}

export interface SetPageLoaded extends Action {
    type: TypeKeys.SET_PAGE_LOADED;
    data: boolean;
}

export interface SetMenuVoices extends Action {
    type: TypeKeys.SET_MENU_VOICES;
    data: Array<MenuItem>;
}

export interface SetErrorMessages extends Action {
    type: TypeKeys.SET_ERROR_MESSAGES;
    messages: Array<string>;
    scope: string;
}

interface SetHistory extends Action {
    type: TypeKeys.SET_HISTORY;
    actualPath: string;
    previousPath: string;
}

interface SaveRequestInProcess extends Action {
    type: TypeKeys.SAVE_REQUEST_IN_PROCESS,
    inProcess: boolean;
}


export const actionCreator = {
    setMenuVoicesToState: (permissions: Array<Permission>): AppThunkAction<any> => dispatch => {
        let menuVoicesToSet = [];

        allMenuVoices.forEach(m => {
            for (let p of permissions) {
                let toBreak = false;
                for (let x of m.associatedToPermissions) {
                    if (x == p.code) {
                        menuVoicesToSet.push(m);
                        toBreak = true;
                        break;
                    }
                }
                if (toBreak)
                    break;
            }

            if (m.associatedToPermissions !== undefined && m.associatedToPermissions != null && m.associatedToPermissions.length > 0 && m.associatedToPermissions[0] == "*")
                menuVoicesToSet.push(m);
        });

        dispatch(actionCreator.setMenuVoices(menuVoicesToSet));

    },
    supportNativeDateInput: () => (dispatch, getState) => {
        try {
            let input = document.createElement('input');
            input.setAttribute('type', 'date');

            var notADateValue = 'not-a-date';
            input.setAttribute('value', notADateValue);

            let support = input.value !== notADateValue;

            dispatch(actionCreator.setSupportNativeDateInput(support));

            document.removeChild(input);
        }
        catch { }
    },
    setMenuVoices: (menuVoices: Array<MenuItem>): SetMenuVoices => {
        return {
            type: TypeKeys.SET_MENU_VOICES,
            data: menuVoices
        }
    },
    setErrorMessages: (messages: Array<string>, scope: string = null): SetErrorMessages => ({
        type: TypeKeys.SET_ERROR_MESSAGES,
        messages: messages,
        scope: scope
    }),
    setPageLoaded: (loaded: boolean): SetPageLoaded => ({
        type: TypeKeys.SET_PAGE_LOADED,
        data: loaded
    }),
    setHistory: (actualPath: string, previousPath: string): SetHistory => ({
        type: TypeKeys.SET_HISTORY,
        actualPath: actualPath,
        previousPath: previousPath
    }),
    saveInProcess: (inProcess: boolean): SaveRequestInProcess => ({
        type: TypeKeys.SAVE_REQUEST_IN_PROCESS,
        inProcess: inProcess
    }),
    setSupportNativeDateInput: (support: boolean): SupportNativeDateInput => ({
        type: TypeKeys.SET_SUPPORT_NATIVE_DATE_INPUT,
        data: support
    }),
    setChangeInProgress: (inProgress: boolean): SetChangeInProgress => ({
        type: TypeKeys.SET_CHANGE_IN_PROGRESS,
        inProgress: inProgress
    }),
    toogleQrReader: (): ToogleQrReader => ({
        type: TypeKeys.TOOGLE_QR_READER
    }),
    startFetchingPagedList: (start: boolean): SetStartFetching => ({
        start: start,
        type: TypeKeys.START_FETCHING_PAGED_LIST
    })
}

export const reducer: Reducer<UiPropertiesState> = (state: UiPropertiesState | undefined, incomingAction: UiActions): UiPropertiesState => {
    if (state === undefined) {
        return baseState;
    }
    let newState = {} as UiPropertiesState;
    newState = { ...state };
    switch (incomingAction.type) {
        case TypeKeys.SET_MENU_VOICES:
            newState = dotprop.set(state, "menuVoices", incomingAction.data);
            break;
        case TypeKeys.SET_ERROR_MESSAGES:

            let messagesArray = incomingAction.messages.map(message => {
                return {
                    message: message,
                    scope: incomingAction.scope
                } as ErrorMessage
            })

            newState = dotprop.set(state, "errorMessages", messagesArray);
            break;
        case TypeKeys.SET_HISTORY:
            newState = dotprop.set(state, "actualPath", incomingAction.actualPath);
            newState = dotprop.set(state, "previousPath", incomingAction.previousPath);
            break;
        case TypeKeys.SET_PAGE_LOADED:
            newState = dotprop.set(state, "pageInitialized", incomingAction.data);
            break;
        case TypeKeys.SAVE_REQUEST_IN_PROCESS:
            newState = dotprop.set(state, "saveRequestInProcess", incomingAction.inProcess);
            break;
        case TypeKeys.SET_SUPPORT_NATIVE_DATE_INPUT:
            newState = dotprop.set(state, "supportNativeDateInput", incomingAction.data);
            break;
        case TypeKeys.SET_CHANGE_IN_PROGRESS:
            newState = dotprop.set(state, "changeInProgress", incomingAction.inProgress);
            break;
        case TypeKeys.TOOGLE_QR_READER:
            newState = dotprop.set(state, "isQrReaderOpen", !newState.isQrReaderOpen);
            break;
        case TypeKeys.START_FETCHING_PAGED_LIST:
            newState = dotprop.set(state, "startFetchingPagedList", incomingAction.start);
            break;
    }
    return newState;
};