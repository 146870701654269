import * as React from "react";
import { Input, Row, Col, Form, FormGroup, Label } from "reactstrap";
import { ERDatePicker as DatePicker } from '../../base/datepicker';

import moment from "moment";

export interface SearchFormProps {
    searchString: string;
    dateFrom?: string;
    dateTo?: string;
    type?: string;
}

export interface SearchFormCallbacks {
    onSearch: (searchString?: string, dateFrom?: string, dateTo?: string, type?: string) => void;
}

export class EventsSearchForm extends React.Component<SearchFormProps & SearchFormCallbacks> {

    constructor(props) {
        super(props);
    }


    render() {
        
        return (
            <React.Fragment>
                <Form className="text-eurorounders search-form">
                    <Row>
                        <Col lg="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Cerca per nome:</Label>
                                <Input
                                    className="bg-dark text-eurorounders"

                                    type="search"
                                    value={this.props.searchString || ''}
                                    onChange={(e) => { this.props.onSearch(e.target.value, this.props.dateFrom, this.props.dateTo, this.props.type) }}
                                    placeholder="Cerca per nome"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Cerca per tipo</Label>
                                <Input
                                    className="bg-dark text-eurorounders"

                                    type="search"
                                    value={this.props.type || ''}
                                    onChange={(e) => { this.props.onSearch(this.props.searchString, this.props.dateFrom, this.props.dateTo, e.target.value) }}
                                    placeholder="Cerca per tipo"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Data inizio</Label>
                                <DatePicker selectedDate={this.props.dateFrom} onChange={date => this.props.onSearch(this.props.searchString, date, this.props.dateTo, this.props.type)} />
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Data fine</Label>
                                <DatePicker selectedDate={this.props.dateTo} onChange={date => this.props.onSearch(this.props.searchString, this.props.dateFrom, date, this.props.type)} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        )
    }
}