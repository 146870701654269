import * as React from "react";
import { BodyHeader } from '../../base/bodyheader';
import moment from "moment";
import { ConnectedEventsList } from '../connected/connected.list';
import { ConnectedEventsSearchForm } from '../connected/connected.searchform';
import { StickyBodyHeader } from "../../base/stickybodyheader";


export interface EventsPageListProps {
    totalCount: number;
    canCreateEvents: boolean;
}

export interface EventsPageListCallbacks {
    onLoad: () => void;
    navigate: (id: string) => void;
}

export class EventsPageList extends React.Component<EventsPageListProps & EventsPageListCallbacks> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad)
            this.props.onLoad();
    }

    render() {

        let menuItems = []

        menuItems.push({
            icon: "cogs",
            label: "config",
            path: "/events/configurations"
        });

        if (this.props.canCreateEvents == true) {
            menuItems.push({
                icon: "plus",
                label: "crea",
                path: "/events/create"
            });
        }

        return (
            <React.Fragment>
                <StickyBodyHeader>
                    <BodyHeader title={`Eventi (${this.props.totalCount})`} menuItems={menuItems} />
                    <ConnectedEventsSearchForm />
                </StickyBodyHeader>
                <ConnectedEventsList selectionEnabled={false} onRowClick={this.props.navigate} />
            </React.Fragment>
        )
    }
}