import * as React from "react";
import { MenuItemProps } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { PaymentMethodDetails, TotalsWithMethodDetails } from "../../../store/payments/cashregister/CashRegisterEntities";
import { BaseList } from "../../base/baseList";
import { guid, round } from "../../../utils/commons";
import { ConnectedTrasferModal } from '../trasfer/connected.trasfermodal';
import { ConnectedIncomeModal } from '../income/connected.incomemodal';
import { ConnectedOutcomeModal } from '../outcome/connected.outcomemodal';
import { ConnectedCashFundModal } from '../cashfund/connected.cashfundmodal';


export interface CashRegisterPageProps {
    eventFullName: string;
    totals: TotalsWithMethodDetails;
}

export interface CashRegisterPageCallbacks {
    onLoad: (eventId: string) => void;
    openTrasferModal: (eventId: string) => void;
    openIncomeModal: (eventId: string) => void;
    openOutcomeModal: (eventId: string) => void;
    openCashFundModal: (eventId: string) => void;
}


export class CashRegisterPage extends React.Component<CashRegisterPageProps & CashRegisterPageCallbacks> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let eventId = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                eventId = (this.props as any).match.params.eventId;
            this.props.onLoad(eventId);
        }
    }


    private getRows(list: Array<PaymentMethodDetails>): JSX.Element[] {

        let payments = list.map(model => {

            let takingClass = "text-success";

            if (model.taking < 0)
                takingClass = "text-danger";

            return (
                <tr key={model.methodId}>
                    <td>
                        {model.methodDescription}
                    </td>
                    <td>
                        {round(model.totalInbound)}€
                    </td>
                    <td>
                        {round(model.totalOutbound)}€
                    </td>
                    <td className={`font-weight-bold ${takingClass}`}>
                        {round(model.taking)}€
                    </td>
                    <td>
                        {round(model.totalCashFund)}€
                    </td>
                    <td>
                        {round(model.takingCashFund)}€
                    </td>
                </tr>
            )
        });

        let takingClass = "text-success";

        if (this.props.totals.taking < 0)
            takingClass = "text-danger";

        payments.push((<tr key={guid()}>
            <td className="font-weight-bold">
                TOTALE
            </td>
            <td className="font-weight-bold">
                {round(this.props.totals.totalInbound)}€
            </td>
            <td className="font-weight-bold">
                {round(this.props.totals.totalOutbound)}€
            </td>
            <td className={`font-weight-bold ${takingClass}`}>
                {round(this.props.totals.taking)}€
            </td>
            <td className="font-weight-bold">
                {round(this.props.totals.totalCashFund)}€
            </td>
            <td className="font-weight-bold">
                {round(this.props.totals.takingCashFund)}€
            </td>
        </tr>))

        return payments;

    }

    private getHead(): JSX.Element {

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>Metodo</th>
                    <th>Entrate</th>
                    <th>Uscite</th>
                    <th className="font-weight-bold">Totale</th>
                    <th>Fondo cassa</th>
                    <th>Totale + fondo cassa</th>
                </tr>
            </thead>
        );

    }


    render() {

        let menuItems = [
            {
                icon: "arrow-up",
                label: "uscita",
                isButton: true,
                isSmall: true,
                onClick: () => this.props.openOutcomeModal((this.props as any).match.params.eventId)
            } as MenuItemProps,
            {
                icon: "arrow-down",
                label: "entrata",
                isButton: true,
                isSmall: true,
                onClick: () => this.props.openIncomeModal((this.props as any).match.params.eventId)
            } as MenuItemProps,
            {
                icon: "random",
                label: "trasfer.",
                isButton: true,
                isSmall: true,
                onClick: () => this.props.openTrasferModal((this.props as any).match.params.eventId)
            } as MenuItemProps,
            {
                icon: "battery-three-quarters",
                label: "f. cassa",
                isButton: true,
                isSmall: true,
                onClick: () => this.props.openCashFundModal((this.props as any).match.params.eventId)
            } as MenuItemProps,
            {
                icon: "hand-holding-usd",
                label: "conto",
                isButton: false,
                isSmall: true,
                path: `/events/${(this.props as any).match.params.eventId}/v2/accounting`
            } as MenuItemProps
        ] as Array<MenuItemProps>;

        let list = (<React.Fragment />);

        if (this.props.totals)
            list = (<BaseList hasNextPage={false} list={this.props.totals.paymentMethodDetails} active={false} getElements={(list: Array<PaymentMethodDetails>) => { return this.getRows(list) }} getHead={() => { return this.getHead() }} />);


        return (
            <React.Fragment>
                <BodyHeader bottomSpace subTitle={this.props.eventFullName} title="Cassa" menuItems={menuItems} />
                {list}
                <ConnectedTrasferModal />
                <ConnectedIncomeModal />
                <ConnectedOutcomeModal afterSave={(eventId: string) => this.props.onLoad(eventId)} />
                <ConnectedCashFundModal />
            </React.Fragment>
        )
    }
}