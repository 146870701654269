import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionMiddleware, actionCreator } from '../../../store/reservations/ReservationState';
import { actionCreator as agentActions } from '../../../store/agents/AgentsState';
import { ReservationEditForm, ReservationEditFormCallbacks, ReservationEditFormProps } from '../base/editform';
import { ProfileType } from "../../../store/profile/Profile";
import { actionCreator as actionCreatorsUI } from '../../../store/uiproperties/UIState';


const mapStateToProsp = (state: ApplicationState): ReservationEditFormProps => {

    return {
        agentRoles: state.reservations.agentRoles,
        canControlAgent: state.currentProfile.currentProfile.type != ProfileType.Agent,
        locations: state.events.runningEventLocations,
        model: state.reservations.running.reservation,
        reservationStates: state.reservations.reservationStatusDesc,
        roomTypes: state.locations.roomTypes,
        selectedAgentRole: state.reservations.running.runningAgentRole,
        needConfirmSkipCapacity: state.reservations.running.needConfirmSkipCapacity,
        needConfirmSkipCustomer: state.reservations.running.needConfirmSkipCustomer,
        entries: state.reservations.running.entries,
        reservationTypes: state.reservations.reservationTypeDesc,
        paymentMethods: state.paymentMethods.paymentMethods
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): ReservationEditFormCallbacks => {
    return ({
        onChange: (propertyName, value) => {
            dispatch(actionMiddleware.onChange(propertyName, value));
            dispatch(actionCreatorsUI.setChangeInProgress(true));
        },
        onCustomersModelOpen: () => dispatch(actionMiddleware.onCustomersModelOpen()),
        onRemoveAgent: (id) => {
            dispatch(actionMiddleware.onRemoveAgent(id));
            dispatch(actionCreatorsUI.setChangeInProgress(true));
        },
        onRemoveCustomer: (id) => {
            dispatch(actionMiddleware.onRemoveCustomer(id));
            dispatch(actionCreatorsUI.setChangeInProgress(true));
        },
        onSubmit: () => dispatch(actionMiddleware.onSubmit()),
        onAgentModalToogle: (agentRole, isOpen) => dispatch(actionMiddleware.onAgentModalOpen(agentRole, isOpen)),
        onConfirmAgent: () => {
            dispatch(actionMiddleware.onConfirmAgent());
            dispatch(actionCreatorsUI.setChangeInProgress(true));
        },
        onAgentRowClick: (id: string) => agentActions.setSelectedAgentMiddleware(id),
        onCustomersModalConfirm: () => actionMiddleware.onCustomersModalConfirm(),
        setNeedConfirmSkip: (type: string) => dispatch(actionMiddleware.setNeedConfirmSkip(type)),
        confirmSkipValidation: (type: string) => dispatch(actionMiddleware.confirmSkipValidation(type)),
        onCheckEntry: (customerId: string, isChecked: boolean) => {
            dispatch(actionMiddleware.onChangeCustomerEntry(customerId, isChecked));
            dispatch(actionCreatorsUI.setChangeInProgress(true));
        },
        onDeletePayment: (index: number) => dispatch(actionMiddleware.deletePayment(index)),
        togglePaymentModal: () => {
            dispatch(actionMiddleware.createNewPayment());
            dispatch(actionCreator.togglePaymentModal(true));
        }
    });
}

export const ConnectedReservationsEditForm = connect<ReservationEditFormProps, ReservationEditFormCallbacks>(mapStateToProsp, mapDispatchToProps)(ReservationEditForm);