import { Anagraphic } from '../shared/Anagraphic';
import { Role } from './Role';

export enum ProfileType {
    User = "User",
    Agent = "Agent",
    Master = "Master"
}

export interface Profile extends Anagraphic {
    id?: string;
    commisionTemplate?: CommissionTemplate;
    type: ProfileType;
    account?: Account;
    agentArea: string;
}

export interface Account {
    id?: string;
    signInName?: string;
    password?: string;
    confirmPassword?: string;
    isDisabled: boolean;
    role: Role;
}

export interface CommissionTemplate {
    id: string;
    type: string;
    commissionForPlayer: number;
    commissionForRoom: number;
    isDefault: boolean;
    isDeleted: boolean;
    visualOrder: number;
    mode: CommissionTemplateMode;
    playerCommissionSource?: PlayerCommissionSource;
    commissionForPlayerMode?: CommissionPaymentMode;
    commissionForRoomMode?: CommissionPaymentMode;
}


export enum CommissionPaymentMode {
    Fixed = "Fixed",
    Percentage = "Percentage"
}

export enum PlayerCommissionSource {
    BuyIn = "BuyIn",
    Fee = "Fee"
}

export enum CommissionTemplateMode {
    Fixed = "Fixed",
    Percentage = "Percentage",
    Mixed = "Mixed"
}

let commissionTemplateModeTraslation: any = {
    
}

commissionTemplateModeTraslation[CommissionTemplateMode.Fixed] = "Fisso";
commissionTemplateModeTraslation[CommissionTemplateMode.Percentage] = "Percntuale";
commissionTemplateModeTraslation[CommissionTemplateMode.Mixed] = "Misto";

export const CommissionTemplateModeTraslation = commissionTemplateModeTraslation;