import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionCreator } from '../../../store/agents/AgentsState';
import { AgentEdit, AgentEditProps } from '../shared/agenteditform';
import { actionCreator as actionCreatorUI } from '../../../store/uiproperties/UIState';

const mapStateToProps = (state: ApplicationState): AgentEditProps => {

    return {
        agent: state.agents.runningAgent,
        allCommisionTemplate: state.agents.commisionTemplateList
    }
}

const mapDispatchToProps = () => dispatch => {
    return {
        onChange: (propertyName: string, value: any) => {
            dispatch(actionCreator.setRunningAgentProp(propertyName, value));
            dispatch(actionCreatorUI.setChangeInProgress(true));
        },
        onSubmit: () => dispatch(actionCreator.saveAgent()),
        onAddAccount: () => dispatch(actionCreator.addAccount()),
        onRemoveAccount: () => dispatch(actionCreator.removeAccount()),
        openModal: () => {
            dispatch(actionCreator.setRunningAgentCommissionTemplate())
            dispatch(actionCreator.toogleCommissionTemplateModal(true))
        }
    }
}

export const ConnectedAgentEditForm = connect<AgentEditProps>(mapStateToProps, mapDispatchToProps)(AgentEdit)