import * as React from "react";
import { ConfirmationModal } from '../../base/confirmationmodal';
import { MenuRow } from '../../menu/menurow';
import { MenuItem, MenuItemProps } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { Event } from '../../../store/events/EventEntities';
import { MenuItem as MainMenuItemProps } from '../../../store/uiproperties/UIState';

import './eventmenu.css';

export interface EventMenuProps {
    event?: Event;
    menuVoices: Array<MainMenuItemProps>;
    canEditEvent: boolean;
}

export interface EventMenuCallbacks {
    onLoad: (id: string) => void;
}

export class EventMenu extends React.Component<EventMenuProps & EventMenuCallbacks>{
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let id = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                id = (this.props as any).match.params.id;
            this.props.onLoad(id);
        }
    }


    render() {
        if (this.props.event === undefined || this.props.event == null) {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }

        let headerMenu = [];

        if (this.props.canEditEvent == true) {
            headerMenu.push({
                icon: "pen",
                label: "Mod.",
                path: `/events/edit/${this.props.event.id}`
            } as MenuItemProps);
        }

        headerMenu.push({
            icon: "list",
            label: "lista",
            isButton: false,
            isSmall: true,
            path: "/events"
        } as MenuItemProps);

        return (
            <React.Fragment>
                <BodyHeader titleClassName="title" menuItems={headerMenu} title={`${this.props.event.type !== undefined && this.props.event.type != null ? this.props.event.type : ''} ${this.props.event.name}`} bottomSpace={true} />
                <MenuRow menuVoices={this.props.menuVoices} />
            </React.Fragment>
        );
    }
}