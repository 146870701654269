import * as React from "react";
import { ConfirmationModal } from '../../base/confirmationmodal';
import { MenuItemProps, MenuItem } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { Event } from '../../../store/events/EventEntities';
import { ConnectedEventEditForm } from '../connected/connected.editform';
import { ConnectedMainDocuments } from '../../documents/connected.maindocuments';
import { UnsavedChanges } from "../../base/unsavedchanges";

export interface EventEditPageProps {
    event?: Event;
}

export interface EventEditPageCallbacks {
    onLoad: (id: string) => void;
    onDelete: () => void;
    onSubmit: () => void;
}


export class EventEditPage extends React.Component<EventEditPageProps & EventEditPageCallbacks>{
    private modalRef: React.RefObject<ConfirmationModal>;
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let id = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                id = (this.props as any).match.params.id;
            this.props.onLoad(id);
        }
    }

    private delete() {
        if (this.props.onDelete)
            this.props.onDelete();
    }

    render() {

        if (this.props.event === undefined || this.props.event == null) {
            return (<React.Fragment></React.Fragment>);
        }

        let isNew = true;

        let menuItems = [];

        if (this.props.event.id !== undefined && this.props.event.id != null) {
            menuItems = [
                {
                    icon: "trash",
                    label: "elimina",
                    isButton: true,
                    isDisabled: false,
                    onClick: () => { this.modalRef.current.toogleModal() }
                }
            ] as Array<MenuItemProps>

            isNew = false;

            menuItems.push({
                icon: "ellipsis-h",
                label: "Evento",
                isButton: false,
                isSmall: true,
                path: `/events/${this.props.event.id}/menu`
            } as MenuItemProps);

        } else {
            menuItems.push({
                icon: "list",
                label: "lista",
                isButton: false,
                isSmall: true,
                path: "/events"
            } as MenuItemProps);
        }

        let documents = null;
        if (this.props.event !== undefined && this.props.event != null && this.props.event.id !== undefined && this.props.event.id != null)
            documents = (<ConnectedMainDocuments objectId={this.props.event.id} objectName={`${this.props.event.type}-${this.props.event.name} `} />);

        return (
            <React.Fragment>
                <UnsavedChanges confirmAction={this.props.onSubmit} />
                <BodyHeader title={isNew == true ? "Crea Evento" : "Modifica Evento"} menuItems={menuItems} />
                <ConnectedEventEditForm />
                <ConfirmationModal text="Eliminrae Evento?" onConfirm={() => this.delete()} ref={this.modalRef} />
                {documents}
            </React.Fragment>
        )
    }
}