import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from '../../../../store/index';
import { actionsMiddleware } from '../../../../store/payments/movements/MovementsState';
import { MovementsPageList, MovementsPageListCallbacks, MovementsPageListProps } from '../movements';


const mapStateToProsp = (state: ApplicationState): MovementsPageListProps => {
    return {
        totalCount: state.movements.count,
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name}` : '',
    };
}

const mapDispatchToProps = () => (dispatch): MovementsPageListCallbacks => {
    return ({
        onLoad: (eventId: string) => dispatch(actionsMiddleware.loadInitialState(eventId))
    });
}

export const ConnectedMovementsPage = withRouter(connect<MovementsPageListProps, MovementsPageListCallbacks>(mapStateToProsp, mapDispatchToProps)(MovementsPageList));