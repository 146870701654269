import * as React from "react";

import './stickybodyheader.css'

export class StickyBodyHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="bg-dark sticky-body-header">
                {this.props.children}
            </div>
        );
    }
}