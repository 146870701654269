import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router"
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/communications/CommunicationState';
import { CommunicationsPageList, CommunicationsPageListProps, CommunicationsPageListCallbacks } from '../pages/listpage';

const mapStateToProsp = (state: ApplicationState): CommunicationsPageListProps => {

    return {
        moduleLoaded: state.communications.moduleLoaded,
        eventFullName: state.events.running !== undefined && state.events.running != null ? state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name.toUpperCase()}` : '' : ''
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): CommunicationsPageListCallbacks => {
    return ({
        onLoad: (eventId: string) => dispatch(actionsMiddleware.loadInitialState(eventId))
    });
}

export const ConnectedCommunicationsListPage = withRouter(connect<CommunicationsPageListProps, CommunicationsPageListCallbacks>(mapStateToProsp, mapDispatchToProps)(CommunicationsPageList));