import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { RoomAvailabilityForm, RoomAvailabilityFormCallbacks, RoomAvailabilityFormProps } from '../basecomponents/roomavailabilityform';
import { actionsMiddleware } from "../../../store/pricings/PricingState";
import { actionCreator as actionCreatorUI } from '../../../store/uiproperties/UIState';

const mapStateToProsp = (state: ApplicationState): RoomAvailabilityFormProps => {

    return {
        roomPricing: state.pricings.modalAvailabilitiesEdit
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): RoomAvailabilityFormCallbacks => {
    return ({
        onChangeDay: (date: string, availability?: number) => {
            dispatch(actionsMiddleware.setRoomPricingAvailability(date, availability));
            dispatch(actionCreatorUI.setChangeInProgress(true));
        }
    });
}

export const ConnectedRoomAvailabilityForm = connect<RoomAvailabilityFormProps, RoomAvailabilityFormCallbacks>(mapStateToProsp, mapDispatchToProps)(RoomAvailabilityForm);