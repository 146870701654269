import * as React from "react";
import { Role } from "../../../../store/profile/Role";
import { BaseList } from '../../../base/baseList';
import GoToTop from "../../../base/gototop";


export interface RoleListProps {
    list: Array<Role>;
    loadNewPage?: () => void;
    onRowClick?: (roleId: string) => void;
}

export class RoleList extends React.Component<RoleListProps>{

    constructor(props) {
        super(props);
    }

    private onRowClick(role: Role) {
        if (role.isDefault == true)
            return;

        this.props.onRowClick(role.id);
    }

    private getRows(list: Array<Role>): JSX.Element[] {

        let elements = list.map(element => {
            let isEditable = element.isDefault == false ? "Si" : "No";

            let baseStyle = { cursor: "pointer" };

            if (element.isDefault == true)
                baseStyle = {} as any;

            return (
                <tr key={element.id} style={baseStyle} onClick={() => this.onRowClick(element)}>
                    <td>
                        {element.name}
                    </td>
                    <td>
                        {isEditable}
                    </td>
                </tr>
            )
        });

        return elements;

    }

    private getHead(): JSX.Element {

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>Ruole</th>
                    <th>Modifica</th>
                </tr>
            </thead>
        );

    }

    render() {

        return (
            <React.Fragment>
                <BaseList hasNextPage={false} list={this.props.list} active={false} onEndOfList={this.props.loadNewPage} getElements={(profileList: Array<Role>) => { return this.getRows(profileList) }} getHead={this.getHead} />
                <GoToTop />
            </React.Fragment>
        )
    }
}