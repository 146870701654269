import * as React from "react";
import { Form, FormGroup, Row, Col, Input, Label, InputGroup, InputGroupText, InputGroupAddon } from "reactstrap";
import { MenuItem } from '../../menu/menuitem';
import { SearchCriteria } from '../../../store/reservations/ReservationQuoteState';
import { RoomType } from '../../../store/locations/LocationEntities';
import BaseSelect from '../../base/baseselect';
import { ERDatePicker as DatePicker } from '../../base/datepicker';
import _ from "underscore";

export interface ReservatioQuoteSearchFormProps {
    searchCriteria: SearchCriteria;
    roomTypes: Array<RoomType>;
}

export interface ReservatioQuoteSearchFormCallbacks {
    onPropertyChange: (propertyName: string, value: any) => void;
    onSearch: () => void;
}


export class ReservatioQuoteSearchForm extends React.Component<ReservatioQuoteSearchFormProps & ReservatioQuoteSearchFormCallbacks>{

    constructor(props) {
        super(props)
    }

    render() {

        return (
            <React.Fragment>
                <Form className="text-eurorounders" >
                    <Row>
                        <Col lg="4" sm="4" xs="12">
                            <FormGroup>
                                <Label>Check In</Label>
                                <DatePicker selectedDate={this.props.searchCriteria.checkIn} onChange={date => this.props.onPropertyChange("checkIn", date)} />
                            </FormGroup>
                        </Col>
                        <Col lg="4" sm="4" xs="12">
                            <FormGroup>
                                <Label>Check Out</Label>
                                <DatePicker selectedDate={this.props.searchCriteria.checkOut} onChange={date => this.props.onPropertyChange("checkOut", date)} />
                            </FormGroup>
                        </Col>
                        <Col lg="4" sm="4" xs="12">
                            <FormGroup>
                                <Label>Camera</Label>
                                <BaseSelect
                                    descField="type"
                                    idField="id"
                                    options={this.props.roomTypes}
                                    value={this.props.searchCriteria.roomTypeId}
                                    onChange={(value: any) => { this.props.onPropertyChange("roomTypeId", value) }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <MenuItem isSmall={true} isButton={true} icon="search" label="cerca" onClick={() => this.props.onSearch()} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }

}