import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { PaymentMethod } from '../../store/payments/paymentmethods/PaymentMethodEntities';
import BaseSelect from "../base/baseselect";

interface MethodsDropDownComponentPros {
    methods: Array<PaymentMethod>;
    selectedValue?: string;
}

interface MethodsDropDownCallback {
    onChange: (id: string) => void;
}

class MethodsDropDownComponent extends React.Component<MethodsDropDownComponentPros & MethodsDropDownCallback>{
    constructor(props) {
        super(props);
    }

    render() {

        if (!this.props.methods)
            return (<React.Fragment />)

        return (<BaseSelect descField="method" idField="id" options={this.props.methods} onChange={(value: string) => this.props.onChange(value)} value={this.props.selectedValue} />)
    }
}

interface MethodsDropDownProps {
    onChange: (id: string) => void;
    selected?: string;
}

const mapStateToProsp = (state: ApplicationState, ownProps: MethodsDropDownProps): MethodsDropDownComponentPros => {
    return {
        methods: state.paymentMethods.paymentMethods,
        selectedValue: ownProps.selected
    }
}

const mapDispatchToProps = () => (dispatch, ownProps: MethodsDropDownProps): MethodsDropDownCallback => {
    return ({
        onChange: (value: string) => ownProps.onChange(value)
    });
}

export const MethodsDropDown = connect<MethodsDropDownComponentPros, MethodsDropDownCallback, MethodsDropDownProps>(mapStateToProsp, mapDispatchToProps)(MethodsDropDownComponent);