import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionCreator } from '../../../store/agents/AgentsState';
import { AgentsSearchForm, AgentsSearchFormCallbacks, AgentsSearchFormProps } from '../shared/searchform';

const mapStateToProsp = (state: ApplicationState): AgentsSearchFormProps => {

    return {
        searchString: state.agents.searchState.searchString
    }
}

const mapDispatchToProps = () => (dispatch): AgentsSearchFormCallbacks => {
    return ({
        onSearch: (searchString: string) => dispatch(actionCreator.genericSearch(searchString))
    });
}

export const ConnectedAgentSearchForm = connect<AgentsSearchFormProps, AgentsSearchFormCallbacks>(mapStateToProsp, mapDispatchToProps)(AgentsSearchForm);