import * as React from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";


export interface ChangePasswordProps {
    password: string;
    confirmPassword: string;
    onChange?: (propertyName: string, value: any) => void;
}

export class ChangePassword extends React.Component<ChangePasswordProps> {
    constructor(props) {
        super(props);
    }

    private setStateValue(propertyName: string, value: any) {
        if (this.props.onChange)
            this.props.onChange(propertyName, value);
    }



    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="6" sm="6" xs="6">
                        <FormGroup>
                            <Label>Password</Label>
                            <Input className="bg-dark text-eurorounders" autoComplete="new-password" type="password" value={this.props.password || ''} onChange={(e) => { this.setStateValue("password", e.target.value) }} />
                        </FormGroup>
                    </Col>
                    <Col lg="6" sm="6" xs="6">
                        <FormGroup>
                            <Label>Conferma password</Label>
                            <Input className="bg-dark text-eurorounders" autoComplete="new-password" type="password" value={this.props.confirmPassword || ''} onChange={(e) => { this.setStateValue("confirmPassword", e.target.value) }} />
                        </FormGroup>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}