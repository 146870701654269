import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/index';
import { actionCreator } from '../../../../store/profile/RolesState';
import { actionCreator as actionCreatorsUI } from '../../../../store/uiproperties/UIState';
import { RoleEdit, EditRoleProps } from '../pages/roleedit';
import { Permission } from '../../../../store/profile/Role';
import { withRouter } from 'react-router';

const mapStateToProsp = (state: ApplicationState): EditRoleProps => {
    return {
        allPermission: state.roles.permissions,
        role: state.roles.runningRole,
        pageLoaded: state.uiProperties.pageInitialized
    }
}

const mapDispatchToProps = () => dispatch => {
    return ({
        onLoad: (roleId: string) => {
            dispatch(actionCreator.findRunningRole(roleId));
        },
        onChange: (propertyName: string, value: any) => {
            dispatch(actionCreator.setProperty(propertyName, value));
            dispatch(actionCreatorsUI.setChangeInProgress(true));
        },
        onSubmit: () => dispatch(actionCreator.saveRole()),
        onSwitch: (permission: Permission, checked: boolean) => {
            dispatch(actionCreator.updateRunningPermissions(permission, checked));
            dispatch(actionCreatorsUI.setChangeInProgress(true));
        },
        onDelete: (id: string) => {
            dispatch(actionCreator.deleteRole(id));
        }
    });
}

export const ConnectedRoleEdit = withRouter(connect<EditRoleProps>(mapStateToProsp, mapDispatchToProps)(RoleEdit));