import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware, actionForExternalModule } from '../../../store/events/EventState';
import { Location } from '../../../store/locations/LocationEntities';
import { EventForm, EventFormCallbacks, EventFormProps } from '../shared/editform';
import { actionCreator as actionCreatorUI } from '../../../store/uiproperties/UIState';

const mapStateToProsp = (state: ApplicationState): EventFormProps => {

    return {
        event: state.events.running
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): EventFormCallbacks => {
    return ({
        onChange: (propertyName: string, value: any) => {
            dispatch(actionsMiddleware.setRunningProp(propertyName, value));
            dispatch(actionCreatorUI.setChangeInProgress(true));
        },
        onSubmit: () => dispatch(actionsMiddleware.save()),
        confirmSelection: () => dispatch(actionForExternalModule.confirmSelection()),
        loadExternalModule: () => dispatch(actionForExternalModule.loadLocationModule()),
        onSelect: actionForExternalModule.onRowClick,
        onSelectEvent: (id) => actionsMiddleware.onSelect(id)
    });
}

export const ConnectedEventEditForm = connect<EventFormProps, EventFormCallbacks>(mapStateToProsp, mapDispatchToProps)(EventForm);