import * as React from "react";
import { Location, LocationType, LocactionTypeDesc } from '../../../store/locations/LocationEntities';
import { LocationGroup } from '../../../store/locations/groups/LocationGroupEntities';
import ContactForm from '../../shared/contacts';
import AddressForm from '../../shared/address';
import { CheckBox } from '../../base/checkbox';
import { MenuItem } from '../../menu/menuitem';
import { Form, FormGroup, Row, Col, Input, Label, InputGroupAddon, InputGroup, InputGroupText } from "reactstrap";
import Collapsible from '../../base/collapsiblecontent';
import DecimalInput from '../../base/decimalinput';
import BaseSelect from "../../base/baseselect";


export interface LocationFormProps {
    location?: Location;
    locationGroups: Array<LocationGroup>;
    locationTypesDesc: Array<LocactionTypeDesc>;
}

export interface LocationFormCallbacks {
    onSubmit: () => void;
    onChange: (propertyName: string, value: any) => void;
    onRoomTypeInfoChange: (id: string, value: string) => void;
}

export class LocationForm extends React.Component<LocationFormProps & LocationFormCallbacks>{
    constructor(props) {
        super(props);
    }

    private getRoomTypesInfo(): Array<JSX.Element> {
        return this.props.location.roomTypeInformation.map(ri => {
            return (
                <Row key={ri.id}>
                    <Col lg="12" sm="12" xs="12">
                        <FormGroup>
                            <Label>Descrizione {ri.type}</Label>
                            <Input className="bg-dark text-eurorounders"  type="textarea" value={ri.information || ''} onChange={(e) => { this.props.onRoomTypeInfoChange(ri.id, e.target.value) }} />
                        </FormGroup>
                    </Col>
                </Row>
            );
        });
    }

    private getLocationTypeComponent(): JSX.Element {

        let ltOptions = this.props.locationTypesDesc.map(lt => {
            return (<option key={lt.type} value={lt.type}>{lt.desc}</option>)
        });

        let ltSelected = '';

        if (this.props.location.type !== undefined && this.props.location.type != null) {
            ltSelected = this.props.location.type;
        }

        return (
            <Col lg="6" sm="6" xs="12">
                <FormGroup>
                    <Label>Tipo</Label>
                    <Input type="select" value={ltSelected} className="bg-dark text-eurorounders" onChange={(e) => this.props.onChange("type", e.target.value)}>
                        {ltOptions}
                    </Input>
                </FormGroup>
            </Col>

        )
    }

    render() {

        if (this.props.location === undefined || this.props.location == null) {
            return (<React.Fragment></React.Fragment>)
        }

        let ltComp = this.getLocationTypeComponent();

        let roomsInfo = this.getRoomTypesInfo();

        return (
            <React.Fragment>
                <Form className="text-eurorounders" >
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input className="bg-dark text-eurorounders"  type="text" value={this.props.location.name || ''} onChange={(e) => { this.props.onChange("name", e.target.value) }} />
                            </FormGroup>
                        </Col>
                        {ltComp}
                    </Row>
                    <Row>
                        <Col lg="6" sm="6" xs="12" className="checkbox-in-form">
                            <FormGroup>
                                <CheckBox isChecked={this.props.location.guestsForCommunications || false} onCheck={(checked) => this.props.onChange("guestsForCommunications", checked)} label="Includi lista ospiti nelle comunicazioni" />
                            </FormGroup>
                        </Col>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Gruppo:</Label>
                                <BaseSelect
                                    descField="name"
                                    idField="id"
                                    options={this.props.locationGroups}
                                    value={this.props.location.locationGroupId}
                                    onChange={(value: any) => { this.props.onChange("locationGroupId", value) }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <ContactForm email={this.props.location.email} emailLabel="Email" phone={this.props.location.phone} onChange={(propName: string, value: any) => this.props.onChange(propName, value)} phonePrefix={this.props.location.phonePrefix} />
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>P. IVA</Label>
                                <InputGroup>
                                    <DecimalInput onChane={(value: string) => this.props.onChange("vat", value)} value={this.props.location.vat} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">%</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Collapsible title="Dettaglio indirizzo">
                        <AddressForm
                            address={this.props.location.address}
                            city={this.props.location.city}
                            country={this.props.location.country}
                            stateProvince={this.props.location.stateProvince}
                            zipCode={this.props.location.zipCode}
                            onChange={(propName: string, value: any) => this.props.onChange(propName, value)}
                        />
                    </Collapsible>
                    <Row>
                        <Col lg="12" sm="12" xs="12">
                            <FormGroup>
                                <Label>Descrizione location</Label>
                                <Input className="bg-dark text-eurorounders"  type="textarea" value={this.props.location.info || ''} onChange={(e) => { this.props.onChange("info", e.target.value) }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Collapsible title="Descrizioni camere">
                        {roomsInfo}
                    </Collapsible>
                    <Row>
                        <Col>
                            <FormGroup>
                                <MenuItem isSmall={true} isButton={true} icon="save" label="salva" onClick={() => this.props.onSubmit()} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        )
    }
}