import * as React from "react";
import { MenuItemProps } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { ConnectedTotalAccountingList } from '../connected/connected.totalaccounting';
import { ConnectedAgentsAccountingList } from '../connected/connected.agentsaccounting';
import { ConnectedHotelsAccountingList } from '../connected/connected.hotelsaccounting';


export interface AccountingDetailsPageProps {
    eventFullName: string;
}

export interface AccountingDetailsPageCallbacks {
    onLoad: (id: string) => void;
    downloadExcel: () => void;
}

enum TabActive {
    Total = "Total",
    Agents = "Agents",
    Hotels = "Hotels"
}

interface AccountingDetailsPageState {
    activeTab: TabActive;
}

export class AccountingDetailsPage extends React.Component<AccountingDetailsPageProps & AccountingDetailsPageCallbacks, AccountingDetailsPageState> {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: TabActive.Total
        }
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let id = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                id = (this.props as any).match.params.id;
            this.props.onLoad(id);
        }
    }

    private toogleTab(active: TabActive) {
        this.setState({ activeTab: active })
    }


    render() {


        let menuItems = [
            {
                icon: "file-excel",
                label: "Excel",
                isButton: true,
                isSmall: true,
                onClick: () => this.props.downloadExcel()
            } as MenuItemProps,
            {
                icon: "list",
                label: "Lista",
                isButton: false,
                isSmall: true,
                path: `/events/${(this.props as any).match.params.eventId}/accounting`
            } as MenuItemProps
        ] as Array<MenuItemProps>;

        return (
            <React.Fragment>
                <BodyHeader bottomSpace subTitle={this.props.eventFullName} title={`Dettaglio Rendicontazione`} menuItems={menuItems} />
                <Nav tabs className="text-eurorounders">
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab == TabActive.Total ? "active" : ""}
                            onClick={() => { this.toogleTab(TabActive.Total); }}
                        >
                            Totale
                            </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab == TabActive.Agents ? "active" : ""}
                            onClick={() => { this.toogleTab(TabActive.Agents); }}
                        >
                            Costo Agenti
                            </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab == TabActive.Hotels ? "active" : ""}
                            onClick={() => { this.toogleTab(TabActive.Hotels); }}
                        >
                            Costo Hotels
                            </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab.toString()}>
                    <TabPane tabId={TabActive.Total.toString()}>
                        <ConnectedTotalAccountingList />
                    </TabPane>
                    <TabPane tabId={TabActive.Agents.toString()}>
                        <ConnectedAgentsAccountingList />
                    </TabPane>
                    <TabPane tabId={TabActive.Hotels.toString()}>
                        <ConnectedHotelsAccountingList />
                    </TabPane>
                </TabContent>
            </React.Fragment>
        )
    }
}