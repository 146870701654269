import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionCreator } from '../../../store/profile/ProfilesSate';
import { actionCreator as actionCreatorsUI } from '../../../store/uiproperties/UIState';
import { ProfilePageList, ProfilePageListProps } from '../pages/profilepagelist';

const mapStateToProsp = (state: ApplicationState): ProfilePageListProps => {

    return {
        searchString: state.profiles.searchState.searchString
    }
}

const mapDispatchToProps = () => dispatch => {
    return ({
        onLoad: () => {
            dispatch(actionCreator.loadInitialState());
        },
        onSearch: (searchString: string) => dispatch(actionCreator.genericSearch(searchString))
    });
}

export const ConnectedProfilePageList = connect<ProfilePageListProps>(mapStateToProsp, mapDispatchToProps)(ProfilePageList);