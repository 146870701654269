import * as React from "react";
import { Card, CardBody, CardTitle, CardSubtitle, Table } from "reactstrap";
import { PeriodPricing, AlertAvailabilityType } from '../../../store/pricings/PricingEntities';
import { formatToLocaleDate } from '../../../utils/commons';
import { Link } from 'react-router-dom';
import { MenuItem } from '../../menu/menuitem';

import './hotelpricegrid.css';

interface PriceGridProps {
    periodPricing?: PeriodPricing
}

export default class PriceGrid extends React.Component<PriceGridProps>{
    constructor(props) {
        super(props);
    }

    private getTableRows(): JSX.Element[] {
        return this.props.periodPricing.roomPricings.map(roomPrice => {

            let icon = null;
            let alertClassName = "";
            if (roomPrice.alertAvailability == AlertAvailabilityType.Error) {
                icon = (<i className="fas fa-exclamation fa-xs" />);
                alertClassName = "text-danger"
            } else if (roomPrice.alertAvailability == AlertAvailabilityType.Warning) {
                icon = (<i className="fas fa-exclamation-triangle fa-xs" />);
                alertClassName = "text-warning"
            }

            return (
                <tr key={roomPrice.id}>
                    <td>
                        {roomPrice.roomType.type}
                    </td>
                    <td>
                        {roomPrice.sellPrice}€
                    </td>
                    <td>
                        {roomPrice.cost}€
                    </td>
                    <td>
                        {roomPrice.costNotSell}€
                    </td>
                    <td className={alertClassName} >
                        {roomPrice.availability} {icon}
                    </td>
                </tr>
            )
        });
    }

    render() {

        if (this.props.periodPricing === undefined || this.props.periodPricing == null) {
            return (<React.Fragment></React.Fragment>);
        }

        let checkIn = formatToLocaleDate(this.props.periodPricing.checkIn);
        let checkOut = formatToLocaleDate(this.props.periodPricing.checkOut);

        if (checkIn === undefined || checkIn == null)
            checkIn = "-";
        if (checkOut === undefined || checkOut == null)
            checkOut = "-";

        let rows = this.getTableRows();

        return (
            <Card className="bg-dark text-eurorounders card-border card-spacing">
                <CardBody className="bg-dark text-eurorounders card-body-header">
                    <CardTitle className="bg-dark text-eurorounders">Check in: <span className="font-weight-bold">{checkIn}</span> - Check out: <span className="font-weight-bold">{checkOut}</span></CardTitle>
                    <CardSubtitle className="bg-dark text-eurorounders">Tassa soggiorno: <span className="font-weight-bold">{this.props.periodPricing.dailyTax}€</span></CardSubtitle>
                    <MenuItem
                        icon="pen"
                        isSmall={true}
                        label="modifica"
                        path={`/events/${this.props.periodPricing.event.id}/hotels/edit/${this.props.periodPricing.id}`}
                        className="edit-pricing-link"
                    />
                </CardBody>
                <CardBody className="bg-dark text-eurorounders">
                    <Table responsive dark>
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                <th>Prezzo</th>
                                <th>Costo</th>
                                <th>Costo invend.</th>
                                <th>Disponibilità</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        );

    }
}