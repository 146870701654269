import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { AgentsAccounting, AgentsAccountingProps } from '../base/accountingdetails/agentsaccounting';


const mapStateToProsp = (state: ApplicationState): AgentsAccountingProps => {

    return {
        list: state.eventAccountings.running !== undefined && state.eventAccountings.running != null && state.eventAccountings.running.model !== undefined && state.eventAccountings.running.model != null ? state.eventAccountings.running.model.agentsAccounting : [] 
    }
}



export const ConnectedAgentsAccountingList = connect<AgentsAccountingProps>(mapStateToProsp, {})(AgentsAccounting);