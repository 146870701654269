import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import { actionMiddleware } from '../../store/documents/DocumentState';
import { MainDocument, MainDocumentsProps, MainDocumentsCallbacks } from './maindocuments';
import _ from "underscore";

export interface MainDocumentOwnProps {
    objectId: string;
    objectName: string;
}

const mapStateToProsp = (state: ApplicationState, ownProps: MainDocumentOwnProps): MainDocumentsProps => {

    let runningDocument = _.find(state.documents.runnings, (doc) => doc.objectId == ownProps.objectId);

    let props: MainDocumentsProps = {
        list: [],
        objectId: ownProps.objectId,
        objectName: ownProps.objectName
    }

    if (runningDocument !== undefined && runningDocument != null) {
        props = {
            list: runningDocument.list,
            objectId: ownProps.objectId,
            objectName: ownProps.objectName
        }
    } 

    return props;
}

const mapDispatchToProps = (dispatch) => (dispatch): MainDocumentsCallbacks => {
    return ({
        onLoad: (objectId, objectName) => dispatch(actionMiddleware.loadDocumentsForObject(objectId, objectName)),
        onUpload: (file, objectId, objectName) => dispatch(actionMiddleware.onUpload(file, objectId, objectName)),
        onDelete: (id, objectId) => dispatch(actionMiddleware.onDelete(id, objectId)),
        onOpenEditModal: (id, objectId, name) => dispatch(actionMiddleware.onOpenEditModal(objectId, id, name)),
        onDownloadDocument: (id, name, extension) => dispatch(actionMiddleware.downloadDocument(id, name, extension))
    });
}

export const ConnectedMainDocuments = connect<MainDocumentsProps, MainDocumentsCallbacks, MainDocumentOwnProps>(mapStateToProsp, mapDispatchToProps)(MainDocument);