import { ApplicationState } from "../../store/index";
import { connect } from "react-redux";
import { ProtectdRouteProps, ProtectedRoute } from "./protectedroute";
import { actionCreator } from '../../store/uiproperties/UIState';

export interface ConnectedProtectedRouteProps {
    exact: boolean;
    path: string;
    isLoginPage: boolean;
    isHome: boolean;
}

const mapStateToProps = (state: ApplicationState, ownProps: ConnectedProtectedRouteProps): ProtectdRouteProps => {

    let propsToReturn: ProtectdRouteProps = {
        isTokenSet: false,
        path: ownProps.path,
        exact: ownProps.exact,
        isLoginPage: ownProps.isLoginPage,
        isHome: ownProps.isHome
    };
    if (state !== undefined && state != null && state.currentProfile !== undefined && state.currentProfile != null && state.currentProfile.authData !== undefined && state.currentProfile.authData != null && state.currentProfile.authData.token !== undefined && state.currentProfile.authData.token != null)
        propsToReturn.isTokenSet = true;

    return propsToReturn;

}

const mapDispatchToProps = () => dispatch => {
    return ({
        onNavigate: (actualPath: string, previousPath: string) => {
            dispatch(actionCreator.setErrorMessages([]));
            dispatch(actionCreator.setHistory(actualPath, previousPath));
            dispatch(actionCreator.setPageLoaded(false));
            dispatch(actionCreator.setChangeInProgress(false));
        }
    })
}

export const ConnectedProtectedRoute = connect<ProtectdRouteProps>(mapStateToProps, mapDispatchToProps)(ProtectedRoute);