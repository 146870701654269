import { connect } from 'react-redux';
import { withRouter } from "react-router"
import { ApplicationState } from '../../../../store/index';
import { actionsMiddleware } from '../../../../store/payments/paymentitems/PaymentItemState';
import { actionCreator as actionCreatorsUI } from '../../../../store/uiproperties/UIState';
import { EditPaymentItemCallbacks, EditPaymentItemProps, PaymentItemEditPage } from '../pages/paymentitemeditpage';
import $ from "jquery";
import { PaymentCategory } from '../../../../store/payments/paymentitems/PaymentItemEntities';

export interface PaymentItemsEditOwnProps {
    side: PaymentCategory;
}

const mapStateToProsp = (state: ApplicationState, ownProps: PaymentItemsEditOwnProps): EditPaymentItemProps => {
    return {
        paymentItem: state.paymentItems.runningPaymentItem,
        side: ownProps.side
    }
}

const mapDispatchToProps = () => (dispatch): EditPaymentItemCallbacks => {
    return ({
        onChange: (desc: string) => {
            dispatch(actionsMiddleware.changeDescription(desc));
            dispatch(actionCreatorsUI.setChangeInProgress(true));
        },
        onDelete: (id: string, side: PaymentCategory) => dispatch(actionsMiddleware.delete(id, side)),
        onLoad: (id: string, side: PaymentCategory) => dispatch(actionsMiddleware.setRunning(id, side)),
        onSubmit: () => {
            $.when(dispatch(actionsMiddleware.save())).then(r => dispatch(actionCreatorsUI.setChangeInProgress(false)));
        }
    });
}

export const ConnectedPaymentItemEdit = withRouter(connect<EditPaymentItemProps, EditPaymentItemCallbacks, any>(mapStateToProsp, mapDispatchToProps)(PaymentItemEditPage));