import { Action, Reducer } from 'redux';
import { ApplicationState } from '../../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest, checkRefreshToken, SaveRequest } from '../../../utils/ajaxutilities';
import dotProp from "dot-prop";
import _ from "underscore";
import { TotalsWithMethodDetails } from './CashRegisterEntities'
import $ from "jquery";
import { CoreResponse } from '../../serverresponse/serverresponse';


export interface CashRegisterState {
    totals: TotalsWithMethodDetails;
}

const getBaseState = (): CashRegisterState => {
    return {
        totals: {
            paymentMethodDetails: []
        }
    } as CashRegisterState;
}

enum TypeKeys {
    SET_TOTALS = "CASHREGISTER_SET_TOTALS",
    RESET_STATE = "CASHREGISTER_RESET_STATE"
}

interface SetTotals extends Action {
    type: TypeKeys.SET_TOTALS;
    state: TotalsWithMethodDetails;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE;
}

type CashRegisterActions = SetTotals | ResetState;

const actionsCreator = {
    setState: (state: TotalsWithMethodDetails): SetTotals => ({
        state: state,
        type: TypeKeys.SET_TOTALS
    }),
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    })
}


const sharedActions = {
    load: (eventId: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<TotalsWithMethodDetails>> => {
        let promise = $.Deferred();

        let requestModel = new AbortableRequest(`/cashregistry/${eventId}`, RequestMethods.GET);

        ajaxRequest<TotalsWithMethodDetails>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                promise.resolve(response);
            }

            promise.resolve(response);
        });

        return promise.promise();
    }
}

export const actionsMiddleware = {
    loadInitialState: (eventId: string) => (dispatch, getState) => {
        dispatch(actionsCreator.resetState());
        $.when(dispatch(sharedActions.load(eventId))).then((response: CoreResponse<TotalsWithMethodDetails>) => {
            if (response.success)
                dispatch(actionsCreator.setState(response.entity));
        });
    }
}


export const reducer: Reducer<CashRegisterState> = (state: CashRegisterState | undefined, incomingAction: CashRegisterActions): CashRegisterState => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.RESET_STATE:
            let stateToUpdate = getBaseState();
            newState = { ...stateToUpdate };
            break;

        case TypeKeys.SET_TOTALS:
            newState = dotProp.set(newState, "totals", { ...incomingAction.state });
            break;

    }

    return newState;
}
