export enum ValidationLevel {
    Valid = "Valid",
    Fail = "Fail"
}

export enum ValidationCode {
    AlreadyExistsEntity = "AlreadyExistsEntity",
    WrongEmail = "WrongEmail",
    WrongPassword = "WrongPassword",
    WrongCapacity = "WrongCapacity",
    CustomersAlreadyHaveReservations = "CustomersAlreadyHaveReservations"
}

export interface CoreResponse<T> {
    success: boolean;
    messages: Array<string>;
    entity: T;
    validationLevel: ValidationLevel;
    validationCodes: Array<ValidationCode>;
}