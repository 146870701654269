import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/payments/payments/PaymentState';
import { actionCreator as agentActions } from '../../../store/agents/AgentsState';
import { OutcomeModal, OutcomeModalCallbacks, OutcomeModalProps } from './outcomemodal';

export interface OutcomeModalOwnProps {
    afterSave?: (eventId: string) => void;
}

const mapStateToProsp = (state: ApplicationState): OutcomeModalProps => {
    return {
        outcomeData: state.payment.outcomeModal,
        paymentItems: state.paymentItems.paymentItems
    }
}

const mapDispatchToProps = (dispatch, ownProps: OutcomeModalOwnProps) => (dispatch): OutcomeModalCallbacks => {
    return ({
        onCloseModal: () => dispatch(actionsMiddleware.closeOutcomeModal()),
        onPropertyChange: (name: string, value: any) => dispatch(actionsMiddleware.editOutcomeProperty(name, value)),
        onSave: (eventId: string) => {
            dispatch(actionsMiddleware.saveOutcome()).then((response: boolean) => {
                if (response && ownProps.afterSave)
                    ownProps.afterSave(eventId);
            })
        },
        onAgentRowClick: (id: string) => agentActions.setSelectedAgentMiddleware(id),
        onConfirmAgent: () => dispatch(actionsMiddleware.onConfirmAgent()),
        toggleAgentModal: () => dispatch(actionsMiddleware.outcomeToggleAgentModal())
    });
}

export const ConnectedOutcomeModal = connect<OutcomeModalProps, OutcomeModalCallbacks, OutcomeModalOwnProps>(mapStateToProsp, mapDispatchToProps)(OutcomeModal);