import * as React from "react";
import { Input, Row, Col, Form, FormGroup, Label, InputGroup, InputGroupAddon, Button } from "reactstrap";
import { GpModel } from "../../../utils/commons";
import { GPReader } from "../../base/qrreader";

export interface CustomerSearchFormProps {
    searchString: string;
}

export interface CustomerSearchFormCallbacks {
    onSearch: (searchString: string) => void;
    toogleQRReader: () => void;

}

export class CustomerSearchForm extends React.Component<CustomerSearchFormProps & CustomerSearchFormCallbacks> {

    constructor(props) {
        super(props);
    }

    private onSearch(searchString: string) {

        this.props.onSearch(searchString);

    }

    private onScan(data: GpModel) {
        if (!data)
            return;
        this.onSearch(`${data.firstName} ${data.lastName}`);
    }

    render() {

        return (
            <React.Fragment>
                <Form className="text-eurorounders search-form">
                    <GPReader onScan={(data: GpModel) => this.onScan(data)} />
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Cerca:</Label>
                                <InputGroup>
                                    <Input
                                        className="bg-dark text-eurorounders"

                                        type="search"
                                        value={this.props.searchString || ''}
                                        onChange={(e) => { this.onSearch(e.target.value) }}
                                        placeholder="Cerca per nome, cognome, email, telefono"
                                    />
                                    <InputGroupAddon addonType="append">
                                        <Button onClick={() => this.props.toogleQRReader()}>
                                            <i className={`icon fas fa-qrcode fa-xs`} />
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        )
    }
}