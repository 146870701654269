import { Action, Reducer } from 'redux';
import { AppThunkAction, ApplicationState } from '../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, checkRefreshToken, SaveRequest } from '../../utils/ajaxutilities';
import { OrderDirection } from '../../utils/commons';
import dotprop from "dot-prop";
import { actionCreator as actionCreatorUi } from '../uiproperties/UIState';
import { CoreResponse } from '../serverresponse/serverresponse';
import { PAGE_SIZE } from '../../utils/commons';
import { push } from 'connected-react-router';
import _ from "underscore";
import { Profile, Account, ProfileType, CommissionTemplate, CommissionTemplateMode, CommissionPaymentMode, PlayerCommissionSource } from '../../store/profile/Profile';
import { profilesFunction } from '../../store/profile/ProfilesSate';
import $ from "jquery";
import { AgentRole } from './AgentEntities';

const debouncedActions = (dispatch) => {
    dispatch(actionCreator.setAgentList([]));
    dispatch(actionCreator.loadData(false));
}

const debounceSearch = _.debounce(debouncedActions, 500);

export enum ListViewType {
    Standard,
    Provisioning
}

export interface AgentsSatate {
    agentsList: Array<Profile>;
    selectedAgent?: Profile;
    commisionTemplateList: Array<CommissionTemplate>;
    runningAgent: Profile;
    listViewType: ListViewType,
    editingCommisionTemplate: CommissionTemplate;
    isCommissionTemplateModalOpen: boolean;
    searchState: AgentsSearchState;
    isJustCommissionTemplateModalOpen: boolean;
    moduleLoaded: boolean;
}

interface AgentsSearchState {
    searchString: string;
    orderField: string;
    pageNumber: number;
    orderDirection: OrderDirection;
    hasNextPage: boolean;
}


enum TypeKeys {
    SET_AGENTS_FILTER = "AGENTS_STATE_SET_AGENTS_FILTER",
    SET_AGENTS_LIST = "AGENTS_STATE_SET_AGENTS_LIST",
    APPEND_AGENT_LIST = "AGENTS_STATE_APPEND_AGENT_LIST",
    RESET_STATE = "AGENTS_STATE_RESET_STATE",
    SET_RUNNING_AGENT = "AGENTS_STATE_SET_RUNNING_AGENT",
    SET_COMMISSION_TEMPLATE_LIST = "AGENTS_SET_COMMISSION_TEMPLATE_LIST",
    ADD_ACCOUNT = "AGENTS_ADD_ACCOUNT",
    REMOVE_ACCOUNT = "AGENTS_REMOVE_ACCOUNT",
    SET_EDITING_COMMISION_TEMPLATE = "AGENTS_SET_EDITING_COMMISION_TEMPLATE",
    REFRESH_SINGLE_AGENT_IN_LIST = "AGENTS_REFRESH_SINGLE_AGENT_IN_LIST",
    TOOGGLE_COMMISSION_TEMPLATE_MODAL = "AGENTS_TOOGGLE_COMMISSION_TEMPLATE_MODAL",
    TOOGGLE_JUST_COMMISSION_TEMPLATE_MODAL = "AGENTS_TOOGGLE_JUST_COMMISSION_TEMPLATE_MODAL",
    CONFIRM_COMMISSION_TEMPLATE_EDIT = "AGENTS_CONFIRM_COMMISSION_TEMPLATE_EDIT",
    SWITCH_LIST_VIEW_TYPE = "AGENTS_SWITCH_LIST_VIEW_TYPE",
    MODULE_LOADED = "AGENTS_MODULE_LOADED",
    UNLOAD_RUNNING = "AGENTS_UNLOAD_RUNNING",
    SET_SELECTED_AGENT = "AGENTS_SET_SELECTED_AGENT"
}

type ActionTypes =
    SetAgentsFilter
    | SetAgentList
    | AppendAgentList
    | ResetState
    | SetRunningAgent
    | SetCommisionTemplates
    | AddAccount
    | RemoveAccount
    | RefreshAgentInList
    | SetEditingCommisionTemplate
    | ToogleCommissionTemplateModal
    | ConfirmCommissionTemplateEdit
    | SwitchListViewType
    | ToogleJustCommissionTemplateModal
    | SetModuleLoaded
    | UnloadRunning
    | SetSelectedAgent;

interface SetSelectedAgent extends Action {
    type: TypeKeys.SET_SELECTED_AGENT;
    agent: Profile;
}

interface UnloadRunning extends Action {
    type: TypeKeys.UNLOAD_RUNNING;
}

interface SetModuleLoaded extends Action {
    type: TypeKeys.MODULE_LOADED,
    moduleLoaded: boolean;
}

interface SetAgentsFilter extends Action {
    type: TypeKeys.SET_AGENTS_FILTER;
    searchState: AgentsSearchState;
}

interface SetAgentList extends Action {
    type: TypeKeys.SET_AGENTS_LIST;
    agents: Array<Profile>;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE
}

interface AppendAgentList extends Action {
    type: TypeKeys.APPEND_AGENT_LIST;
    agents: Array<Profile>;
}

interface SetRunningAgent extends Action {
    type: TypeKeys.SET_RUNNING_AGENT;
    agent: Profile;
}

interface SetCommisionTemplates extends Action {
    type: TypeKeys.SET_COMMISSION_TEMPLATE_LIST;
    data: Array<CommissionTemplate>;
}

interface AddAccount extends Action {
    type: TypeKeys.ADD_ACCOUNT
}

interface RemoveAccount extends Action {
    type: TypeKeys.REMOVE_ACCOUNT
}

interface SetEditingCommisionTemplate extends Action {
    type: TypeKeys.SET_EDITING_COMMISION_TEMPLATE,
    commissionTemplate: CommissionTemplate
}

interface RefreshAgentInList extends Action {
    type: TypeKeys.REFRESH_SINGLE_AGENT_IN_LIST,
    agent: Profile
}

interface ToogleCommissionTemplateModal extends Action {
    type: TypeKeys.TOOGGLE_COMMISSION_TEMPLATE_MODAL;
    isOpen: boolean
}

interface ToogleJustCommissionTemplateModal extends Action {
    type: TypeKeys.TOOGGLE_JUST_COMMISSION_TEMPLATE_MODAL;
    isOpen: boolean
}

interface ConfirmCommissionTemplateEdit extends Action {
    type: TypeKeys.CONFIRM_COMMISSION_TEMPLATE_EDIT;
}

interface SwitchListViewType extends Action {
    type: TypeKeys.SWITCH_LIST_VIEW_TYPE;
    viewType: ListViewType;
}

const getNewAgent = (): Profile => {
    return {
        type: ProfileType.Agent,
    } as Profile
}

const getNewAccount = (): Account => ({
} as Account);

const getNewCommissionTemplate = (): CommissionTemplate => ({
    commissionForPlayer: 0,
    commissionForRoom: 0,
    id: null,
    isDefault: false,
    isDeleted: false,
    type: "Manual",
    visualOrder: null,
    mode: CommissionTemplateMode.Percentage,
    commissionForPlayerMode: CommissionPaymentMode.Percentage,
    commissionForRoomMode: CommissionPaymentMode.Percentage,
    playerCommissionSource: PlayerCommissionSource.BuyIn
});

let baseState: AgentsSatate = {
    agentsList: [],
    runningAgent: getNewAgent(),
    commisionTemplateList: [],
    listViewType: ListViewType.Standard,
    isCommissionTemplateModalOpen: false,
    editingCommisionTemplate: null,
    isJustCommissionTemplateModalOpen: false,
    searchState: {
        orderField: null,
        pageNumber: 0,
        searchString: null,
        orderDirection: OrderDirection.ASC,
        hasNextPage: false
    },
    moduleLoaded: false,
    selectedAgent: null
}

export const agentFunctions = {
    getDefaultProvisionings: () => (dispatch, getState): JQueryPromise<CoreResponse<Array<CommissionTemplate>>> => {
        let requestModel = new RequestModel(`/agents/defaultprovisionings`, RequestMethods.GET, null, null);
        return ajaxRequest<Array<CommissionTemplate>>(requestModel)(dispatch, getState);
    },
    updateProvisioningProfile: (provisioning: CommissionTemplate) => (dispatch, getState): JQueryPromise<CoreResponse<CommissionTemplate>> => {
        let requestModel = new SaveRequest(`/agents/updateprovisioning`, RequestMethods.POST, provisioning);
        return ajaxRequest<CommissionTemplate>(requestModel)(dispatch, getState);
    },
    agentRoles: () => (dispatch, getState): JQueryPromise<CoreResponse<Array<AgentRole>>> => {
        let requestModel = new RequestModel(`/agents/agentroles`, RequestMethods.GET);
        return ajaxRequest<Array<AgentRole>>(requestModel)(dispatch, getState);
    },
}

export const actionCreator = {
    addAccount: (): AddAccount => ({
        type: TypeKeys.ADD_ACCOUNT
    }),
    removeAccount: (): RemoveAccount => ({
        type: TypeKeys.REMOVE_ACCOUNT
    }),
    loadInitialState: (selectedAgent?: Profile) => (dispatch, getState) => {
        abortAllRequest();
        dispatch(actionCreator.setModuleLoaded(false));
        let appState = (getState() as ApplicationState);
        let preservePages: boolean = true;

        dispatch(actionCreator.resetState());

        if (appState.uiProperties.previousPath === undefined || appState.uiProperties.previousPath == null || !(appState.uiProperties.previousPath.startsWith("/agents/edit") || appState.uiProperties.previousPath.startsWith("/agents/create"))) {
            dispatch(actionCreator.setAgentsFilter(0, "", "", OrderDirection.ASC));
            preservePages = false;
        }

        if (selectedAgent !== undefined && selectedAgent != null) {
            dispatch(actionCreator.setSelectedAgent(selectedAgent));
        }

        if (appState.agents.commisionTemplateList === undefined || appState.agents.commisionTemplateList == null || appState.agents.commisionTemplateList.length == 0) {

            checkRefreshToken()(dispatch, getState).then(x => {

                if (x == false)
                    return;

                $.when(agentFunctions.getDefaultProvisionings()(dispatch, getState), dispatch(actionCreator.loadData(preservePages))).then((commission, initialData) => {

                    let initialStateResp = (initialData as unknown as CoreResponse<any>);

                    if (commission.success == false || initialStateResp.success == false) {
                        return;
                    }

                    dispatch(actionCreator.setCommissionTemplates(commission.entity));
                    dispatch(actionCreator.setModuleLoaded(true));

                });
            });
            return;
        }

        dispatch(actionCreator.loadData(preservePages)).then(resp => {
            let respCast = (resp as unknown as CoreResponse<any>);
            if (respCast.success == true)
                dispatch(actionCreator.setModuleLoaded(true));
        });
    },
    setCommissionTemplates: (commissions: Array<CommissionTemplate>): SetCommisionTemplates => ({
        type: TypeKeys.SET_COMMISSION_TEMPLATE_LIST,
        data: commissions
    }),
    genericSearch: (value: string): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {
        abortAllRequest();
        let agentFilter = { ...getState().agents.searchState };
        //reset page number and alterate string search
        agentFilter.pageNumber = 0;
        agentFilter.searchString = value;
        agentFilter.hasNextPage = false;
        dispatch(actionCreator.setAgentsFilterObject(agentFilter));
        debounceSearch(dispatch);

    },
    setAgentsFilterObject: (searchState: AgentsSearchState): SetAgentsFilter => ({
        type: TypeKeys.SET_AGENTS_FILTER,
        searchState: searchState
    }),
    setAgentsFilter: (pageNumber: number, orderField: string, searchString: string, orderDirection: OrderDirection, hasNextPage: boolean = true): SetAgentsFilter => ({
        type: TypeKeys.SET_AGENTS_FILTER,
        searchState: {
            orderField: orderField,
            pageNumber: pageNumber,
            searchString: searchString,
            orderDirection: orderDirection,
            hasNextPage: hasNextPage
        }
    }),
    loadNextPage: (): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {
        let searchState = getState().agents.searchState;
        searchState.pageNumber += 1;
        dispatch(actionCreator.setAgentsFilterObject(searchState));
        dispatch(actionCreator.loadData(false));
    },
    loadData: (preservePages: boolean) => (dispatch, getState): JQueryPromise<CoreResponse<any>> => {

        let promise = $.Deferred();

        let searchState = (getState() as ApplicationState).agents.searchState;

        profilesFunction.search("agents", searchState, preservePages)(dispatch, getState).then(response => {
            if (response.success == false) {
                dispatch(actionCreatorUi.setErrorMessages(response.messages));
                return;
            }

            searchState.hasNextPage = true;

            if (response.entity.length < PAGE_SIZE) {
                searchState.hasNextPage = false;
            }

            if (preservePages && (response.entity.length < (searchState.pageNumber + 1) * PAGE_SIZE)) {
                searchState.hasNextPage = false;
            }

            dispatch(actionCreator.appendAgentList(response.entity));

            dispatch(actionCreator.setAgentsFilterObject(searchState));

            dispatch(actionCreatorUi.startFetchingPagedList(true));

            return promise.resolve(response);

        });

        return promise.promise();
    },
    setAgentList: (agents: Array<Profile>): SetAgentList => ({
        type: TypeKeys.SET_AGENTS_LIST,
        agents: agents
    }),
    appendAgentList: (agents: Array<Profile>): AppendAgentList => ({
        type: TypeKeys.APPEND_AGENT_LIST,
        agents: agents
    }),
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    unloadRunning: (): UnloadRunning => ({
        type: TypeKeys.UNLOAD_RUNNING
    }),
    navigateToRunningAgent: (profileId: string): AppThunkAction<any> => (dispatch, getState) => {
        dispatch(actionCreator.setModuleLoaded(false));
        dispatch(actionCreator.unloadRunning());
        dispatch(push(`/agents/edit/${profileId}`));
    },
    setRunningAgentForProvisioning: (agentId: string): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {
        let agent = _.find(getState().agents.agentsList, (agent) => agent.id == agentId);

        if (agent !== undefined && agent != null)
            dispatch(actionCreator.setRunningAgent(agent));
    },
    loadRunningAgent: (id?: string): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {

        dispatch(actionCreator.setEditingCommissionTemplate(null));

        if (id === undefined || id == null || id == "") {
            dispatch(actionCreator.setRunningAgent(getNewAgent()));
            dispatch(actionCreator.setModuleLoaded(true));
            return;
        }
        profilesFunction.getProfileById(id)(dispatch, getState).then(profileResponse => {
            if (profileResponse.success == false || profileResponse.entity == null || profileResponse.entity === undefined)
                return;
            dispatch(actionCreator.setRunningAgent(profileResponse.entity));
            dispatch(actionCreator.refreshAgentInList(profileResponse.entity));
            dispatch(actionCreator.setModuleLoaded(true));
        });

    },
    findRunningCommissionTemplate: (id: string): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {
        let commissionToRun = _.find(getState().agents.commisionTemplateList, (ct) => ct.id == id)
        dispatch(actionCreator.setEditingCommissionTemplate(commissionToRun));
    },
    setRefreshAgentInList: (): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {
        let runningAgent = getState().agents.runningAgent;
        dispatch(actionCreator.refreshAgentInList(runningAgent));
    },
    refreshAgentInList: (agent: Profile): RefreshAgentInList => ({
        type: TypeKeys.REFRESH_SINGLE_AGENT_IN_LIST,
        agent: agent
    }),
    setRunningAgentCommissionTemplate: (): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {
        let runningCommissionTemplateForAgent = getState().agents.runningAgent.commisionTemplate;
        dispatch(actionCreator.setEditingCommissionTemplate(runningCommissionTemplateForAgent));
    },
    confirmCommissionTemplateForAgent: (): ConfirmCommissionTemplateEdit => ({
        type: TypeKeys.CONFIRM_COMMISSION_TEMPLATE_EDIT
    }),
    setEditingCommissionTemplate: (commissionTemplate: CommissionTemplate): SetEditingCommisionTemplate => ({
        type: TypeKeys.SET_EDITING_COMMISION_TEMPLATE,
        commissionTemplate: commissionTemplate
    }),
    toogleCommissionTemplateModal: (isOpen: boolean): ToogleCommissionTemplateModal => ({
        type: TypeKeys.TOOGGLE_COMMISSION_TEMPLATE_MODAL,
        isOpen: isOpen
    }),
    toogleJustCommissionTemplateModal: (isOpen: boolean): ToogleJustCommissionTemplateModal => ({
        type: TypeKeys.TOOGGLE_JUST_COMMISSION_TEMPLATE_MODAL,
        isOpen: isOpen
    }),
    setRunningCommissionTemplateProp: (propertyName: string, value: any): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {

        dispatch(actionCreatorUi.setErrorMessages([]));

        let runningCommission = { ...getState().agents.editingCommisionTemplate };

        switch (propertyName) {
            case "commissionForPlayer":
                runningCommission.commissionForPlayer = value;
                break;
            case "commissionForRoom":
                runningCommission.commissionForRoom = value;
                break;
            case "mode":
                runningCommission.mode = value;

                if (runningCommission.mode == CommissionTemplateMode.Fixed) {
                    runningCommission.commissionForPlayerMode = CommissionPaymentMode.Fixed;
                    runningCommission.commissionForRoomMode = CommissionPaymentMode.Fixed;
                }

                if (runningCommission.mode == CommissionTemplateMode.Percentage) {
                    runningCommission.commissionForPlayerMode = CommissionPaymentMode.Percentage;
                    runningCommission.commissionForRoomMode = CommissionPaymentMode.Percentage;
                }
                break;
            case "playerCommissionSource":
                runningCommission.playerCommissionSource = value;
                break;
            case "commissionForPlayerMode":
                runningCommission.commissionForPlayerMode = value;
                break;
            case "commissionForRoomMode":
                runningCommission.commissionForRoomMode = value;
                break;

        }

        dispatch(actionCreator.setEditingCommissionTemplate(runningCommission));

    },
    setRunningAgentProp: (propertyName: string, value: any): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {

        dispatch(actionCreatorUi.setErrorMessages([]));

        let runningAgent = { ...getState().agents.runningAgent };
        switch (propertyName) {
            case "firstName":
                runningAgent.firstName = value;
                break;
            case "lastName":
                runningAgent.lastName = value;
                break;
            case "email":
                runningAgent.email = value;
                break;
            case "phone":
                runningAgent.phone = value;
                break;
            case "phonePrefix":
                runningAgent.phonePrefix = value;
                break;
            case "city":
                runningAgent.city = value;
                break;
            case "country":
                runningAgent.country = value;
                break;
            case "zipCode":
                runningAgent.zipCode = value;
                break;
            case "stateProvince":
                runningAgent.stateProvince = value;
                break;
            case "address":
                runningAgent.address = value;
                break;
            case "birthDate":
                runningAgent.birthDate = value;
                break;
            case "taxCode":
                runningAgent.taxCode = value;
                break;
            case "vatNumber":
                runningAgent.vatNumber = value;
                break;
            case "password":
                runningAgent.account.password = value;
                break;
            case "confirmPassword":
                runningAgent.account.confirmPassword = value;
                break;
            case "isDisabled":
                runningAgent.account.isDisabled = value;
                break;
            case "agentArea":
                runningAgent.agentArea = value;
                break;
            case "commissionTemplate":

                let allCommision = getState().agents.commisionTemplateList;
                let commission = _.find(allCommision, (comm) => comm.id == value);

                if ((commission === undefined || commission == null) && value == "manual") {
                    commission = getNewCommissionTemplate();
                    dispatch(actionCreator.setEditingCommissionTemplate(commission));
                    dispatch(actionCreator.toogleCommissionTemplateModal(true));
                } else {
                    runningAgent.commisionTemplate = commission;
                }
                break;
            case "privacy":
                runningAgent.privacy = value;
                break;
        }

        dispatch(actionCreator.setRunningAgent(runningAgent));

    },
    setRunningAgent: (agent: Profile): SetRunningAgent => ({
        type: TypeKeys.SET_RUNNING_AGENT,
        agent: agent
    }),
    delete: (): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {


        profilesFunction.delete(getState().agents.runningAgent.id)(dispatch, getState).then(response => {
            if (response.success == true) {
                dispatch(push("/agents"));
            }
        });
    },
    saveAgent: () => (dispatch, getState: () => ApplicationState): JQueryPromise<Profile> => {

        let deferred = $.Deferred();

        dispatch(actionCreatorUi.setErrorMessages([]));
        //validation
        let agentToUpdate: Profile = getState().agents.runningAgent;

        if (agentToUpdate.lastName == "" || agentToUpdate.lastName == null || agentToUpdate.lastName === undefined) {
            dispatch(actionCreatorUi.setErrorMessages(["Cognome obbligatorio"]));
            return;
        }

        profilesFunction.save("agents", agentToUpdate)(dispatch, getState).then(response => {
            if (response.success == false) {
                return deferred.resolve(null);
            }

            dispatch(actionCreator.setRunningAgent(response.entity));
            return deferred.resolve(response.entity);
        });

        return deferred.promise();
    },
    switchView: (viewType: ListViewType): SwitchListViewType => ({
        type: TypeKeys.SWITCH_LIST_VIEW_TYPE,
        viewType: viewType
    }),
    updateCommissionTemplate: () => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<CommissionTemplate>> => {
        let deferred = $.Deferred();

        agentFunctions.updateProvisioningProfile(getState().agents.editingCommisionTemplate)(dispatch, getState).then(response => {
            if (response.success == false) {
                return deferred.resolve(null);
                return;
            }

            return deferred.resolve(response.entity);
        });

        return deferred.promise();
    },
    updateDefaultCommissionAndRefreshList: () => (dispatch, getState: () => ApplicationState) => {
        dispatch(actionCreator.updateCommissionTemplate()).then(response => {
            if (response == null)
                return;
            let newCommission: CommissionTemplate = response as CommissionTemplate;
            let commissionIndex = _.findIndex(getState().agents.commisionTemplateList, (comm) => comm.id == newCommission.id);

            if (commissionIndex < 0)
                return;

            let commissionList = getState().agents.commisionTemplateList;

            commissionList[commissionIndex] = newCommission;

            dispatch(actionCreator.setCommissionTemplates(commissionList));

            dispatch(actionCreator.toogleJustCommissionTemplateModal(false));

        })
    },
    setRunningManualCommissionForAgent: (agentId: string) => (dispatch, getState: () => ApplicationState): any => {
        let agent = _.find(getState().agents.agentsList, (agent) => agent.id == agentId);
        dispatch(actionCreator.setRunningAgent(agent));
        dispatch(actionCreator.setRunningAgentCommissionTemplate());
        dispatch(actionCreator.toogleCommissionTemplateModal(true));
    },
    setModuleLoaded: (loaded: boolean): SetModuleLoaded => ({
        type: TypeKeys.MODULE_LOADED,
        moduleLoaded: loaded
    }),
    setSelectedAgent: (agent: Profile): SetSelectedAgent => ({
        type: TypeKeys.SET_SELECTED_AGENT,
        agent: agent
    }),
    setSelectedAgentMiddleware: (id: string) => (dispatch, getState: () => ApplicationState) => {
        let findeAgent = _.find(getState().agents.agentsList, (agent) => agent.id == id);
        dispatch(actionCreator.setSelectedAgent(findeAgent));
    }
};

export const reducer: Reducer<AgentsSatate> = (state: AgentsSatate | undefined, incomingAction: ActionTypes): AgentsSatate => {

    if (state === undefined) {
        return baseState;
    }

    let newState: AgentsSatate = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.UNLOAD_RUNNING:
            newState = dotprop.set(newState, 'runningAgent', null);
            break;
        case TypeKeys.MODULE_LOADED:
            newState = dotprop.set(newState, 'moduleLoaded', incomingAction.moduleLoaded);
            break;
        case TypeKeys.RESET_STATE:
            newState = dotprop.set(newState, 'agentsList', []);
            newState = dotprop.set(newState, 'runningAgent', null);
            newState = dotprop.set(newState, 'editingCommisionTemplate', null);
            newState = dotprop.set(newState, 'selectedAgent', null);
            break;
        case TypeKeys.SET_AGENTS_FILTER:
            newState = dotprop.set(newState, 'searchState', { ...incomingAction.searchState });
            break;
        case TypeKeys.SET_AGENTS_LIST:
            newState = dotprop.set(newState, 'agentsList', incomingAction.agents);
            break;
        case TypeKeys.APPEND_AGENT_LIST:
            let agents = newState.agentsList.concat(incomingAction.agents)
            newState = dotprop.set(newState, 'agentsList', agents);
            break;
        case TypeKeys.SET_RUNNING_AGENT:
            newState = dotprop.set(newState, 'runningAgent', { ...incomingAction.agent });
            break;
        case TypeKeys.SET_COMMISSION_TEMPLATE_LIST:
            newState = dotprop.set(newState, 'commisionTemplateList', [...incomingAction.data]);
            break;
        case TypeKeys.ADD_ACCOUNT:
            let newRunningAgentAdd = newState.runningAgent;
            newRunningAgentAdd.account = getNewAccount();
            newState = dotprop.set(newState, 'runningAgent', { ...newRunningAgentAdd });
            break;
        case TypeKeys.REMOVE_ACCOUNT:
            let newRunningAgent = newState.runningAgent;
            newRunningAgent.account = null;
            newState = dotprop.set(newState, 'runningAgent', { ...newRunningAgent });
            break;
        case TypeKeys.REFRESH_SINGLE_AGENT_IN_LIST:
            let agentIndex = _.findIndex(newState.agentsList, (agent) => agent.id == incomingAction.agent.id);
            if (agentIndex > -1) {
                newState = dotprop.set(newState, `agentsList.${agentIndex}`, { ...incomingAction.agent });
                newState = dotprop.set(newState, "agentsList", [...newState.agentsList]);
            }
            break;
        case TypeKeys.SET_EDITING_COMMISION_TEMPLATE:
            newState = dotprop.set(newState, 'editingCommisionTemplate', { ...incomingAction.commissionTemplate });
            break;
        case TypeKeys.TOOGGLE_COMMISSION_TEMPLATE_MODAL:
            newState = dotprop.set(newState, 'isCommissionTemplateModalOpen', incomingAction.isOpen);
            break;
        case TypeKeys.CONFIRM_COMMISSION_TEMPLATE_EDIT:
            let agent = newState.runningAgent;
            agent.commisionTemplate = newState.editingCommisionTemplate;
            newState = dotprop.set(newState, 'runningAgent', { ...agent });
            break;
        case TypeKeys.SWITCH_LIST_VIEW_TYPE:
            newState = dotprop.set(newState, 'listViewType', incomingAction.viewType);
            break;
        case TypeKeys.TOOGGLE_JUST_COMMISSION_TEMPLATE_MODAL:
            newState = dotprop.set(newState, 'isJustCommissionTemplateModalOpen', incomingAction.isOpen);
            break;
        case TypeKeys.SET_SELECTED_AGENT:
            if (incomingAction.agent === undefined
                || incomingAction.agent == null
                || (newState.selectedAgent !== undefined && newState.selectedAgent != null && newState.selectedAgent.id == incomingAction.agent.id))
                newState = dotprop.set(newState, 'selectedAgent', null);
            else {
                newState = dotprop.set(newState, 'selectedAgent', { ...incomingAction.agent });
            }

            break;
    }

    return newState;
};
