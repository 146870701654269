import * as React from "react";
import { Card, CardBody, CardTitle, CardSubtitle, Table } from "reactstrap";
import { HotelAccounting } from '../../../../store/eventaccountings/EventAccountingEntities';
import { formatToLocaleDate, guid } from '../../../../utils/commons';

import './hotels-accounting.css';
import './agents-accounting.css';
import CollapsibleContent from "../../../base/collapsiblecontent";

interface HotelAccountingViewProps {
    hotelAccounting?: HotelAccounting
}

class HotelAccountingView extends React.Component<HotelAccountingViewProps>{
    constructor(props) {
        super(props);
    }

    private getTableRows(): JSX.Element[] {

        if (this.props.hotelAccounting.hotelNightsAccountings === undefined || this.props.hotelAccounting.hotelNightsAccountings == null)
            return [];

        return this.props.hotelAccounting.hotelNightsAccountings.map(details => {


            let checkIn = formatToLocaleDate(details.checkIn);
            let checkOut = formatToLocaleDate(details.checkOut);

            if (checkIn === undefined || checkIn == null)
                checkIn = "-";
            if (checkOut === undefined || checkOut == null)
                checkOut = "-";

            return (
                <React.Fragment key={guid()}>
                    <tr key={guid()}>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{checkIn}</div>
                                <div>{checkOut}</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.type}</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>Notti Standard/Packet</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.cost}€</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.totalSold}</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.totalSoldCost}€</div>
                            </div>
                        </td>
                    </tr>
                    <tr key={guid()}>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{checkIn}</div>
                                <div>{checkOut}</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.type}</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>Notti Invendute</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.costUnsold}€</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.totalUnsold}</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.totalUnsoldCost}€</div>
                            </div>
                        </td>
                    </tr>
                    <tr key={guid()}>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{checkIn}</div>
                                <div>{checkOut}</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.type}</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>Notti Staf Hotel</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.costStaff}€</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.totalStaffHotel}</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.totalStaffHotelCost}€</div>
                            </div>
                        </td>
                    </tr>
                    <tr key={guid()}>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{checkIn}</div>
                                <div>{checkOut}</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.type}</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>Notti Staff</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.costStaff}€</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.totalStaff}</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.totalStaffCost}€</div>
                            </div>
                        </td>
                    </tr>
                    <tr key={guid()}>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{checkIn}</div>
                                <div>{checkOut}</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.type}</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>Notti Guest</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.cost}€</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.totalGuest}</div>
                            </div>
                        </td>
                        <td>
                            <div className="agents-accounting-table-cell">
                                <div>{details.totalGuestCost}€</div>
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            )
        });
    }

    render() {

        if (this.props.hotelAccounting === undefined || this.props.hotelAccounting == null) {
            return (<React.Fragment></React.Fragment>);
        }

        let rows = this.getTableRows();

        return (
            <Card className="bg-dark text-eurorounders card-border card-spacing">
                <CardBody className="bg-dark text-eurorounders card-body-header">
                    <CardSubtitle className="bg-dark text-eurorounders">Totale costo notti: <span className="font-weight-bold">{this.props.hotelAccounting.totalRoomCost}€</span></CardSubtitle>
                    <CardSubtitle className="bg-dark text-eurorounders">Tasse standard/packet: <span className="font-weight-bold">{this.props.hotelAccounting.standardBookingTax}€</span></CardSubtitle>
                    <CardSubtitle className="bg-dark text-eurorounders">Tasse staff: <span className="font-weight-bold">{this.props.hotelAccounting.staffBookingTax}€</span></CardSubtitle>
                    <CardSubtitle className="bg-dark text-eurorounders">Tasse staff hotel: <span className="font-weight-bold">{this.props.hotelAccounting.staffHotelBookingTax}€</span></CardSubtitle>
                    <CardSubtitle className="bg-dark text-eurorounders">Tasse guest: <span className="font-weight-bold">{this.props.hotelAccounting.guestBookingTax}€</span></CardSubtitle>
                    <CardSubtitle className="bg-dark text-eurorounders">Totale: <span className="font-weight-bold">{this.props.hotelAccounting.totalCost}€</span></CardSubtitle>
                </CardBody>
                <CardBody className="bg-dark text-eurorounders">
                    <Table responsive dark>
                        <thead>
                            <tr>
                                <th>
                                    <div className="agents-accounting-table-cell">
                                        <div>Periodo</div>
                                    </div>
                                </th>
                                <th>
                                    <div className="agents-accounting-table-cell">
                                        <div>Camera</div>
                                    </div>
                                </th>
                                <th>
                                    <div className="agents-accounting-table-cell">
                                        <div>Tipo prenotaz.</div>
                                    </div>
                                </th>
                                <th>
                                    <div className="agents-accounting-table-cell">
                                        <div>Costo</div>
                                    </div>
                                </th>
                                <th>
                                    <div className="agents-accounting-table-cell">
                                        <div>#Notti</div>
                                    </div>
                                </th>
                                <th>
                                    <div className="agents-accounting-table-cell">
                                        <div>Totale</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        );

    }
}


export interface HotelAccountingListProps {
    list?: Array<HotelAccounting>
}

export class HotelAccountingList extends React.Component<HotelAccountingListProps>{
    constructor(props) {
        super(props);
    }

    private getHotelList(): JSX.Element[] {

        let elements = [];

        this.props.list.forEach(toRender => {

            elements.push(
                <CollapsibleContent titleBig={true} className="bg-dark text-eurorounders text-capitalize" title={toRender.name} key={toRender.id}>
                    <HotelAccountingView hotelAccounting={toRender} key={guid()} />
                </CollapsibleContent>
            )
        });

        return elements;
    }

    render() {

        if (this.props.list === undefined || this.props.list == null) {
            return (<React.Fragment></React.Fragment>);
        }

        let hotelList = this.getHotelList();

        return (
            <React.Fragment>
                {hotelList}
            </React.Fragment>
        );

    }
}