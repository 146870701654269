import * as React from "react";
import { Link } from 'react-router-dom';
import { guid } from '../../utils/commons';

import './menuitem.css';
import { Label, Input } from "reactstrap";

export interface MenuItemProps {
    path?: string;
    icon?: string;
    label?: string;
    isSmall?: boolean;
    className?: string;
    isButton?: boolean;
    isFileUploader?: boolean;
    onFileUpload?: (file: FormData) => void;
    onClick?: () => void;
    isDisabled?: boolean;
}

export class MenuItem extends React.Component<MenuItemProps>{
    constructor(props) {
        super(props)
    }

    private getIconElement(smallClass: string): JSX.Element {

        let iconSize = "3";

        if (smallClass == "small")
            iconSize = "2"


        if (this.props.icon !== undefined && this.props.icon != null)
            return (
                <div className={`icon ${smallClass}`}>
                    <i className={`fas fa-${this.props.icon} fa-${iconSize}x`}></i>
                </div>);

        return null;
    }

    private getTextElement(smallClass: string): JSX.Element {
        if (this.props.label !== undefined && this.props.label != null)
            return (
                <div className={`text-uppercase text ${smallClass}`}>
                    {this.props.label}
                </div>
            );
        return null;
    }

    private getLink(smallClass: string, className: string) {
        let icon = this.getIconElement(smallClass);
        let label = this.getTextElement(smallClass);

        let disabledClass = ""

        if (this.props.isDisabled == true) {
            disabledClass = "disable-events";
        }
        return (
            <div className={`menu-item-container ${smallClass} ${className} ${disabledClass}`}>
                <Link to={this.props.path} className="text-eurorounders">
                    <div className={`menu-item menu-item-border ${smallClass}`}>
                        {icon}
                        {label}
                    </div>
                </Link>
            </div>
        )
    }

    private getButton(smallClass: string, className: string) {
        let icon = this.getIconElement(smallClass);
        let label = this.getTextElement(smallClass);
        let disabledClass = ""

        if (this.props.isDisabled == true) {
            disabledClass = "disable-events";
        }
        return (
            <div style={{ cursor: "pointer" }} onClick={this.props.onClick} className={`menu-item-container text-eurorounders ${smallClass} ${className} ${disabledClass}`}>
                <div className={`menu-item menu-item-border ${smallClass}`}>
                    {icon}
                    {label}
                </div>
            </div>
        )
    }

    private uploadFile(files: FileList) {

        if (files === undefined || files == null || files.length == 0 || files[0] === undefined || files[0] == null)
            return

        let file = files[0];

        const data = new FormData()
        data.append('file', file);
        this.props.onFileUpload(data);
    }

    private getFileUploader(smallClass: string, className: string) {
        let icon = this.getIconElement(smallClass);
        let label = this.getTextElement(smallClass);
        let disabledClass = ""

        if (this.props.isDisabled == true) {
            disabledClass = "disable-events";
        }
        return (
            <div style={{ cursor: "pointer" }} className={`menu-item-container text-eurorounders ${smallClass} ${className} ${disabledClass}`}>
                <div className={`menu-item menu-item-border ${smallClass}`}>
                    <Label>
                        {icon}
                        {label}
                        <Input key={guid()} style={{ display: 'none' }} type="file" onChange={(e) => this.uploadFile(e.target.files)} />
                    </Label>
                </div>
            </div>
        )
    }

    render() {
        let smallClass = "";
        if (this.props.isSmall == true)
            smallClass = "small";

        let className = ''
        if (this.props.className)
            className = this.props.className;

        let elementToRender = null;

        if (this.props.isButton == true)
            elementToRender = this.getButton(smallClass, className);
        else if (this.props.isFileUploader)
            elementToRender = this.getFileUploader(smallClass, className);
        else
            elementToRender = this.getLink(smallClass, className);
        return (
            <React.Fragment>
                {elementToRender}
            </React.Fragment>
        )
    }
}