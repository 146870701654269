import { Action, Reducer } from 'redux';
import { ApplicationState } from '../../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest, checkRefreshToken, SaveRequest } from '../../../utils/ajaxutilities';
import dotProp from "dot-prop";
import _ from "underscore";
import { PaymentCategory, PaymentItem } from './PaymentItemEntities'
import $ from "jquery";
import { push } from 'connected-react-router';
import { CoreResponse } from '../../serverresponse/serverresponse';

export interface PaymentItemState {
    paymentItems: Array<PaymentItem>;
    runningPaymentItem: PaymentItem;
    loadCompleted: boolean;
}

const getBaseState = (): PaymentItemState => {
    return {
        loadCompleted: false,
        paymentItems: [],
        runningPaymentItem: null
    } as PaymentItemState;
}

const getDefaultIncome = (): PaymentItem => {
    return {
        category: PaymentCategory.Income,
        id: null,
        isCustom: true,
        description: null,
        isVisible: true,
        systemItem: null
    };
}

const getDefaultCost = (): PaymentItem => {
    return {
        category: PaymentCategory.Cost,
        id: null,
        isCustom: true,
        description: null,
        isVisible: true,
        systemItem: null
    };
}

enum TypeKeys {
    SET_RUNNING = "PAYMENTITEM_SET_RUNNING",
    SET_LIST = "PAYMENTITEM_SET_LIST",
    MODULE_LOADED = "PAYMENTITEM_SET_MODULE_LOADED",
    RESET_STATE = "PAYMENTITEM_RESET_STATE"
}

interface SetList extends Action {
    type: TypeKeys.SET_LIST;
    paymentItems: Array<PaymentItem>;
}

interface SetRunning extends Action {
    type: TypeKeys.SET_RUNNING;
    paymentItem: PaymentItem;
}

interface SetModuleLoaded extends Action {
    type: TypeKeys.MODULE_LOADED;
    loaded: boolean;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE;
}

type PaymentItemActions =
    SetList
    | SetModuleLoaded
    | ResetState
    | SetRunning;


const actionsCreator = {
    setList: (items: Array<PaymentItem>): SetList => ({
        paymentItems: items,
        type: TypeKeys.SET_LIST
    }),
    setModuleLoaded: (loaded: boolean): SetModuleLoaded => ({
        type: TypeKeys.MODULE_LOADED,
        loaded: loaded
    }),
    setRunning: (paymentItem: PaymentItem): SetRunning => ({
        paymentItem: paymentItem,
        type: TypeKeys.SET_RUNNING
    }),
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    })
}


export const sharedActions = {
    loadAll: () => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<Array<PaymentItem>>> => {
        let promise = $.Deferred();

        let requestModel = new AbortableRequest(`/paymentitems/list`, RequestMethods.GET);

        ajaxRequest<Array<PaymentItem>>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                promise.resolve(response);
            }

            dispatch(actionsCreator.setList(response.entity));
            promise.resolve(response);
        });

        return promise.promise();
    },
    load: (id: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<PaymentItem>> => {
        let promise = $.Deferred();

        let requestModel = new AbortableRequest(`/paymentitems/${id}`, RequestMethods.GET);

        ajaxRequest<PaymentItem>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                promise.resolve(response);
            }

            promise.resolve(response);
        });

        return promise.promise();
    }
}

export const actionsMiddleware = {
    loadInitialState: () => (dispatch, getState) => {
        dispatch(actionsCreator.resetState());
        $.when(dispatch(sharedActions.loadAll())).then((response: CoreResponse<Array<PaymentItem>>) => {
            if (response.success)
                dispatch(actionsCreator.setModuleLoaded(true));
        });
    },
    navigateToRunning: (id: string, side: PaymentCategory) => (dispatch, getState) => {
        if (side == PaymentCategory.Cost)
            dispatch(push(`/events/configurations/cost/edit/${id}`));
        else
            dispatch(push(`/events/configurations/income/edit/${id}`));
    },
    setRunning: (id: string, side: PaymentCategory) => (dispatch, getState) => {

        if (!id) {
            if (side == PaymentCategory.Cost)
                dispatch(actionsCreator.setRunning(getDefaultCost()));
            else
                dispatch(actionsCreator.setRunning(getDefaultIncome()));
            return;
        }

        $.when(dispatch(sharedActions.load(id))).then((response: CoreResponse<PaymentItem>) => {
            if (!response.success)
                return;
            dispatch(actionsCreator.setRunning(response.entity));
        });
    },
    changeDescription: (desc: string) => (dispatch, getState: () => ApplicationState) => {
        let newState = { ...getState().paymentItems.runningPaymentItem };
        newState.description = desc;
        dispatch(actionsCreator.setRunning(newState));
    },
    delete: (id: string, side: PaymentCategory) => (dispatch, getState) => {
        let requestModel = new SaveRequest(`/paymentitems/${id}`, RequestMethods.DELETE, null);

        ajaxRequest<any>(requestModel)(dispatch, getState).then(response => {
            if (response.success) {
                if (side == PaymentCategory.Cost)
                    dispatch(push('/events/configurations/costs'));
                else
                    dispatch(push('/events/configurations/incomings'));
            }
        });
    },
    save: () => (dispatch, getState: () => ApplicationState): JQueryPromise<any> => {
        let deferred = $.Deferred();

        let requestModel = new SaveRequest(`/paymentitems`, RequestMethods.POST, getState().paymentItems.runningPaymentItem);

        ajaxRequest<any>(requestModel)(dispatch, getState).then((response: CoreResponse<PaymentItem>) => {
            if (response.success)
                dispatch(actionsCreator.setRunning(response.entity));
            deferred.resolve();
        });

        return deferred.promise();
    }
}


export const reducer: Reducer<PaymentItemState> = (state: PaymentItemState | undefined, incomingAction: PaymentItemActions): PaymentItemState => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.RESET_STATE:
            let stateToUpdate = getBaseState();
            newState = { ...stateToUpdate };
            break;

        case TypeKeys.SET_LIST:
            newState = dotProp.set(newState, "paymentItems", [...incomingAction.paymentItems]);
            break;
        case TypeKeys.MODULE_LOADED:
            newState = dotProp.set(newState, "loadCompleted", incomingAction.loaded);
            break;
        case TypeKeys.SET_RUNNING:
            newState = dotProp.set(newState, "runningPaymentItem", { ...incomingAction.paymentItem });
            break;

    }

    return newState;
}
