import * as React from "react";
import { Collapse } from "reactstrap";

import './collapsible.css';

export interface CollapsibleContentButton {
    isActive: boolean;
    onClick: () => void;
    label: string;
    className?: string;
}

interface CollapsibleContentProps {
    title: string;
    className?: string;
    titleBig?: boolean;
    colorIcon?: string;
    titleIcon?: string;
    button?: CollapsibleContentButton;
}

interface CollapsibleContentState {
    isExpanded: boolean;
}

export default class CollapsibleContent extends React.Component<CollapsibleContentProps, CollapsibleContentState>{
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        }
    }

    private handleCollapse() {
        this.setState({ isExpanded: !this.state.isExpanded })
    }

    private getButton(): JSX.Element {
        if (!this.props.button)
            return (<React.Fragment />);

        let className = "";

        if (this.props.button.className)
            className = `${className} ${this.props.button.className}`;

        if (!this.props.button.isActive)
            className = `${className} disable-events`;

        return (<span className={`button ${className}`} onClick={() => this.props.button.onClick()}>{this.props.button.label}</span>)
    }

    render() {

        let className = "collapsible-content";

        if (this.props.className !== undefined && this.props.className != null)
            className = `${className} ${this.props.className}`;

        let iconClassName = "angle-down";

        if (this.state.isExpanded)
            iconClassName = "angle-up";

        let titleClass = "title";

        if (this.props.titleBig == true) {
            titleClass = "title big";
        }

        let iconTitle = null;


        if (this.props.titleIcon !== undefined && this.props.titleIcon != null) {
            iconTitle = (<i className={`icon fas fa-${this.props.titleIcon} fa-xs}`} />);
        }

        const button = this.getButton();

        return (
            <React.Fragment>
                <div className={className}>
                    <div onClick={() => this.handleCollapse()} className="title-container">
                        <span className={titleClass}>{this.props.title}</span>
                        <span className={this.props.colorIcon}>{iconTitle}</span>
                        <span><i className={`icon fas fa-${iconClassName} fa-xs`} /></span>
                    </div>
                    {button}
                    <Collapse isOpen={this.state.isExpanded}>
                        {this.props.children}
                    </Collapse>
                </div>
            </React.Fragment>
        )
    }
}