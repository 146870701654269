import * as React from "react";
import { EntryForCustomer } from "../../../store/entries/EntriesEntities";
import { ConfirmationModal } from '../../base/confirmationmodal';
import { BaseList } from '../../base/baseList';
import _ from "underscore";
import { EntryUpdateNumberActionType } from '../../../store/entries/EntryState';

import './list.css';
import GoToTop from "../../base/gototop";


export interface EntriesListProps {
    list: Array<EntryForCustomer>;
    hasNextPage: boolean;
    startLoading?: boolean;
}

export interface EntriesListCallbacks {
    loadNewPage: () => void;
    onDelete: (id: string) => void;
    onChangeNumber: (id: string, action: EntryUpdateNumberActionType) => void;
    onUnload: () => void;
}

interface EntriesListState {
    idPendingDelete?: string;
}

export class EntriesList extends React.Component<EntriesListProps & EntriesListCallbacks, EntriesListState>{
    private deleteConfirmation: React.RefObject<ConfirmationModal>;

    constructor(props) {
        super(props);
        this.state = {
            idPendingDelete: null
        };
        this.deleteConfirmation = React.createRef<ConfirmationModal>();
    }

    private onDelete() {
        this.props.onDelete(this.state.idPendingDelete);
        this.deleteConfirmation.current.toogleModal();
    }

    private openModal(id: string) {
        this.setState({ idPendingDelete: id });
        this.deleteConfirmation.current.toogleModal();
    }

    private onCloseModal() {
        this.setState({ idPendingDelete: null });
    }

    private getRows(list: Array<EntryForCustomer>): JSX.Element[] {

        let customerArray = list.map(entry => {

            let disableRemoveEntry = entry.number <= 1 ? "disable-events" : "";

            return (
                <tr key={entry.id}>
                    <td>
                        {entry.fullName}
                    </td>
                    <td>
                        <div className="update-number-cell">
                            <span className={disableRemoveEntry} onClick={() => this.props.onChangeNumber(entry.id, EntryUpdateNumberActionType.Decrease)} style={{ cursor: "pointer" }}><i style={{ marginRight: "18px" }} className="fas fa-minus fa-1x"></i></span>
                            <span>{entry.number}</span>
                            <span onClick={() => this.props.onChangeNumber(entry.id, EntryUpdateNumberActionType.Increase)} style={{ cursor: "pointer" }}><i style={{ marginLeft: "18px" }} className="fas fa-plus fa-1x"></i></span>
                        </div>
                    </td>
                    <td>
                        {entry.editSource}
                    </td>
                    <td>
                        <span onClick={() => this.openModal(entry.id)} style={{ height: "25px", width: "25px", display: "inline-block", position: "relative", cursor: 'pointer' }}>
                            <i style={{ position: "relative", left: "5px" }} className="fas fa-trash fa-1x"></i>
                        </span>
                    </td>
                </tr >
            )
        });

        return customerArray;

    }

    private getHead(): JSX.Element {

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>Nome</th>
                    <th>Numero</th>
                    <th>Sorgente</th>
                    <th>Elimina</th>
                </tr>
            </thead>
        );

    }


    render() {

        return (
            <React.Fragment>
                <BaseList onUnload={this.props.onUnload} hasNextPage={this.props.hasNextPage} list={this.props.list} active={this.props.startLoading} onEndOfList={this.props.loadNewPage} getElements={(list: Array<EntryForCustomer>) => { return this.getRows(list) }} getHead={() => { return this.getHead() }} />
                <ConfirmationModal ref={this.deleteConfirmation} onConfirm={() => this.onDelete()} text="Eliminare entry?" confirmIcon="save" onClosed={() => this.onCloseModal()} />
                <GoToTop />
            </React.Fragment>
        )
    }
}