import { Action, Reducer } from 'redux';
import { ApplicationState } from '../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest, checkRefreshToken, SaveRequest } from '../../utils/ajaxutilities';
import dotProp from "dot-prop";
import { CoreResponse } from '../serverresponse/serverresponse';
import { formatToLocaleDate, PAGE_SIZE, round } from '../../utils/commons';
import { push } from 'connected-react-router';
import _ from "underscore";
import { Event } from '../events/EventEntities';
import { ComparedKpi, KpiViewModel, PredictionStatus } from './KpiEntities';
import { KeyDesc } from '../shared/KeyDesc';
import $ from "jquery";
import { actionCreator as actionCreatorUi } from '../uiproperties/UIState';
import { actionsMiddleware as locationMiddleware, actionCreator as locationActionCreator } from '../locations/LocationState';
import { Location } from '../locations/LocationEntities';


export interface KpiState {
    kpi: KpiViewModel;
    moduleLoaded: boolean;
    eventId: string;
}

const getBaseState = (): KpiState => ({
    kpi: {
        actualAndAverageKpi: [],
        consumerPredictionIndex: [],
        eventsForAverageAndConsumerPrediction: [],
        eventsForBookingPrediction: [],
        predictionCreated: false,
        predictionStatus: null,
        selectedEvents: [],
        warningMessages: [],
        predictedConsumerFinalEntries: null,
        predictedConsumerLowerBoundFinalEntries: null,
        predictedConsumerUpperBoundFinalEntries: null,
        kpiDetails: null
    },
    eventId: null,
    moduleLoaded: false
});

type KpiAction =
    | SetModuleLoaded
    | ResetState
    | SetKpi
    | SetEventId;

enum TypeKeys {
    RESET_STATE = "KPI_RESET_STATE",
    SET_KPI = "KPI_SET_KPI",
    MODULE_LOADED = "KPI_MODULE_LOADED",
    SET_EVENT_ID = "KPI_SET_EVENT_ID"
}

interface SetKpi extends Action {
    type: TypeKeys.SET_KPI;
    kpi: KpiViewModel;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE;
}

interface SetModuleLoaded extends Action {
    type: TypeKeys.MODULE_LOADED;
    moduleLoaded: boolean;
}

interface SetEventId extends Action {
    type: TypeKeys.SET_EVENT_ID
    eventId: string
}

export const actionCreator = {
    setModuleLoaded: (loaded: boolean): SetModuleLoaded => ({
        type: TypeKeys.MODULE_LOADED,
        moduleLoaded: loaded
    }),
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    setKpi: (kpi: KpiViewModel): SetKpi => ({
        type: TypeKeys.SET_KPI,
        kpi: kpi
    }),
    setEventId: (eventId: string): SetEventId => ({
        type: TypeKeys.SET_EVENT_ID,
        eventId: eventId
    })
}

export const actionsMiddleware = {

    loadInitialState: (eventId: string) => (dispatch, getState) => {
        abortAllRequest();
        dispatch(actionCreator.setModuleLoaded(false));
        let appState = (getState() as ApplicationState);

        dispatch(actionCreator.resetState());
        dispatch(actionCreator.setEventId(eventId));

        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false)
                return;

            $.when(dispatch(actionsMiddleware.loadKpi(eventId))).then((resp, count) => {

                if (resp)
                    dispatch(actionCreator.setModuleLoaded(true));
            });

        });
    },
    loadKpi: (id: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<KpiViewModel> => {

        let deferred = $.Deferred();

        if (id === undefined || id == null) {
            deferred.resolve(true);
            return deferred.promise();
        }
        let requestModel = new RequestModel(`/predictions/${id}`, RequestMethods.GET, null, null);
        ajaxRequest<KpiViewModel>(requestModel)(dispatch, getState).then(response => {
            dispatch(actionCreator.setKpi(response.entity));
            deferred.resolve(response.entity);
        });

        return deferred.promise();

    },
    onEventsConfirmed: () => (dispatch, getState: () => ApplicationState): JQueryPromise<KpiViewModel> => {
        let deferred = $.Deferred();

        let selectedEvents = { ids: getState().events.selectedEvents };

        let requestModel = new SaveRequest(`/predictions/${getState().kpis.eventId}`, RequestMethods.POST, selectedEvents);
        ajaxRequest<KpiViewModel>(requestModel)(dispatch, getState).then(response => {
            dispatch(actionCreator.setKpi(response.entity));
            deferred.resolve(response.entity);
        });

        return deferred.promise();
    },
    onDelete: (eventId: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<any>> => {
        let deferred = $.Deferred();
        let requestModel = new SaveRequest(`/predictions/${eventId}`, RequestMethods.DELETE, null);
        ajaxRequest<KpiViewModel>(requestModel)(dispatch, getState).then(response => {
            dispatch(actionCreator.resetState());
            dispatch(actionCreator.setEventId(eventId));
            dispatch(actionCreator.setModuleLoaded(true));
            dispatch(actionsMiddleware.loadKpi(eventId));
        });

        return deferred.promise();
    }
}

export const reducer: Reducer<KpiState> = (state: KpiState | undefined, incomingAction: KpiAction): KpiState => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.RESET_STATE:
            newState = { ...getBaseState() };
            break;
        case TypeKeys.MODULE_LOADED:
            newState = dotProp.set(newState, "moduleLoaded", incomingAction.moduleLoaded);
            break;
        case TypeKeys.SET_KPI:
            incomingAction.kpi.actualAndAverageKpi.forEach(x => {
                x.date = formatToLocaleDate(x.date);
                x.value = round(x.value);
                x.comparedValue = round(x.comparedValue);
            });
            newState = dotProp.set(newState, "kpi", incomingAction.kpi);
            break;
        case TypeKeys.SET_EVENT_ID:
            newState = dotProp.set(newState, "eventId", incomingAction.eventId);
            break;
    }

    return newState;
}