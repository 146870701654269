import { Action, Reducer } from 'redux';
import { ApplicationState } from '../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest, checkRefreshToken, SaveRequest } from '../../utils/ajaxutilities';
import { OrderDirection } from '../../utils/commons';
import dotProp from "dot-prop";
import { CoreResponse } from '../serverresponse/serverresponse';
import { PAGE_SIZE } from '../../utils/commons';
import { push } from 'connected-react-router';
import _ from "underscore";
import { AgentAccounting, EventAccounting, EventAccountingStatus, HotelAccounting, NightsAccounting } from './EventAccountingEntities';
import $ from "jquery";
import { actionsMiddleware as eventActions } from '../events/EventState';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { actionCreator as actionCreatorUI } from '../uiproperties/UIState';


export interface EventAccountingState {
    list: Array<EventAccounting>;
    totalCount: number;
    eventId: string;
    moduleLoaded: boolean;
    running: EventAccounting;
}



const getBaseState = (eventId: string = null): EventAccountingState => ({
    eventId: eventId,
    list: [],
    totalCount: 0,
    moduleLoaded: false,
    running: null
});


enum TypeKeys {
    RESET_STATE = "ACCOUNTING_RESET_STATE",
    SET_LIST = "ACCOUNTING_SET_LIST",
    MODULE_LOADED = "ACCOUNTING_MODULE_LOADED",
    SET_TOTAL_COUNT = "ENTRY_SET_TOTAL_COUNT",
    SET_EVENT_ID = "ACCOUNTING_SET_EVENT_ID",
    SET_RUNNING = "ACCOUNTING_SET_RUNNING"
}

type EventAccountingAction =
    ResetState
    | SetList
    | ModuleLoaded
    | SetTotalCount
    | SetEventId
    | SetRunning;

interface SetEventId extends Action {
    type: TypeKeys.SET_EVENT_ID;
    eventId: string;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE;
}

interface SetList extends Action {
    type: TypeKeys.SET_LIST;
    list: Array<EventAccounting>;
}

interface ModuleLoaded extends Action {
    type: TypeKeys.MODULE_LOADED;
    loaded: boolean;
}

interface SetTotalCount extends Action {
    type: TypeKeys.SET_TOTAL_COUNT;
    totalCount: number;
}

interface SetRunning extends Action {
    type: TypeKeys.SET_RUNNING
    model: EventAccounting;
}

export const actionCreator = {
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    setList: (entries: Array<EventAccounting>): SetList => ({
        type: TypeKeys.SET_LIST,
        list: entries
    }),
    setModuleLoaded: (loaded: boolean): ModuleLoaded => ({
        type: TypeKeys.MODULE_LOADED,
        loaded: loaded
    }),
    setTotalCount: (total: number): SetTotalCount => ({
        type: TypeKeys.SET_TOTAL_COUNT,
        totalCount: total
    }),
    setEventId: (eventId: string): SetEventId => ({
        type: TypeKeys.SET_EVENT_ID,
        eventId: eventId
    }),
    setRunning: (model: EventAccounting): SetRunning => ({
        type: TypeKeys.SET_RUNNING,
        model: model
    })
}

export const actionsMiddleware = {
    loadInitialState: (eventId: string) => (dispatch, getState): JQueryPromise<any> => {
        let deferred = $.Deferred();
        abortAllRequest();
        dispatch(actionCreator.setModuleLoaded(false));

        dispatch(actionCreator.resetState());
        dispatch(actionCreator.setEventId(eventId));
        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false)
                return;
            $.when(dispatch(actionsMiddleware.loadList()), dispatch(eventActions.load(eventId))).then((resp, count) => {
                let respCast = (resp as unknown as CoreResponse<any>);
                if (respCast.success == true)
                    dispatch(actionCreator.setModuleLoaded(true));
                deferred.resolve();
            });

        });

        return deferred.promise();
    },
    loadList: () => (dispatch, getState: () => ApplicationState): JQueryPromise<Array<EventAccounting>> => {
        let deferred = $.Deferred();

        let request: AbortableRequest = new AbortableRequest(`/events/accountinglist/${getState().eventAccountings.eventId}`, RequestMethods.GET);

        ajaxRequest<Array<EventAccounting>>(request)(dispatch, getState).then(response => {

            if (response.success == false) {
                deferred.resolve(null);
                return deferred.promise();
            }

            dispatch(actionCreator.setList(response.entity));
            dispatch(actionCreator.setTotalCount(response.entity.length));
            deferred.resolve(response.entity);

        });

        return deferred.promise();
    },
    onNavigate: (id: string) => (dispatch, getState: () => ApplicationState) => {
        dispatch(push(`/events/${getState().eventAccountings.eventId}/accounting/${id}`));
    },
    load: (id: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<EventAccounting> => {
        let deferred = $.Deferred();

        let requestModel = new RequestModel(`/events/accounting/${id}`, RequestMethods.GET, null, null);

        ajaxRequest<EventAccounting>(requestModel)(dispatch, getState).then(response => {
            if (response.success == false || response.entity == null || response.entity === undefined) {
                deferred.resolve(null);
                return deferred.promise();
            }
            dispatch(actionCreator.setRunning(response.entity));
            deferred.resolve(response.entity);
        });

        return deferred.promise();
    },
    downloadExcel: () => (dispatch, getState: () => ApplicationState) => {
        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false)
                return;

            dispatch(showLoading());

            let token = getState().currentProfile.authData.token;

            fetch(`api/events/accounting/excel/${getState().eventAccountings.running.id}`, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": token
                })
            })
                .then(resp => resp.blob())
                .then(blob => {
                    dispatch(hideLoading());
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `accounting_${getState().events.running.name.replace(/[/\\?%*:|"<>]/g, '-')}.xlsx`;
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(() => {
                    dispatch(actionCreatorUI.setErrorMessages(["Si è verificato un errore"]));
                    dispatch(hideLoading());
                });

        });
    }

}


export const reducer: Reducer<EventAccountingState> = (state: EventAccountingState | undefined, incomingAction: EventAccountingAction): EventAccountingState => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.SET_TOTAL_COUNT:
            newState = dotProp.set(newState, "totalCount", incomingAction.totalCount);
            break;
        case TypeKeys.RESET_STATE:
            newState = { ...getBaseState() };
            break;
        case TypeKeys.SET_LIST:
            newState = dotProp.set(newState, "list", [...incomingAction.list]);
            break;
        case TypeKeys.MODULE_LOADED:
            newState = dotProp.set(newState, "moduleLoaded", incomingAction.loaded);
            break;
        case TypeKeys.SET_EVENT_ID:
            newState = dotProp.set(newState, "eventId", incomingAction.eventId);
            break;
        case TypeKeys.SET_RUNNING:
            newState = dotProp.set(newState, "running", incomingAction.model);
            break;
    }

    return newState;
}