import * as React from "react";
import { Input, Row, Col, Form, FormGroup, Label } from "reactstrap";

export interface EntriesSearchFormProps {
    searchString: string;
}

export interface EntriesSearchFormCallbacks {
    onSearch: (searchString: string) => void;

}

export class EntriesSearchForm extends React.Component<EntriesSearchFormProps & EntriesSearchFormCallbacks> {

    constructor(props) {
        super(props);
    }

    private onSearch(searchString: string) {

        this.props.onSearch(searchString);

    }

    render() {

        return (
            <React.Fragment>
                <Form className="text-eurorounders search-form">
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Cerca:</Label>
                                <Input
                                    className="bg-dark text-eurorounders"

                                    type="search"
                                    value={this.props.searchString || ''}
                                    onChange={(e) => { this.onSearch(e.target.value) }}
                                    placeholder="Cerca per nome, cognome, email, telefono"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        )
    }
}