import * as React from "react";
import { Input, Row, Col, Form, FormGroup, Label } from "reactstrap";
import { MenuItemProps } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { ConnectedAgentsList } from '../connected/connected.agentslist';
import { ConnectedAgentsListRapidProvisioning } from '../connected/connected.agentslistrapidprovisioning';
import { ListViewType } from '../../../store/agents/AgentsState';
import { ConnectedAgentSearchForm } from '../connected/connected.searchform';
import { StickyBodyHeader } from '../../base/stickybodyheader';

export interface AgentPageListProps {
    searchString: string;
    viewMode: ListViewType;
    onLoad?: () => void;
    navigateToAgent?: (agentId: string) => void;
    changeView?: (viewType: ListViewType) => void;
}

export class AgentPageList extends React.Component<AgentPageListProps> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad)
            this.props.onLoad();
    }

    render() {

        let menuItems = [
            {
                icon: "plus",
                label: "crea",
                path: "/agents/create"
            }
        ] as Array<MenuItemProps>


        let componentToRender = (<ConnectedAgentsList onRowClick={this.props.navigateToAgent} selectionEnabled={false} />);

        if (this.props.viewMode == ListViewType.Provisioning) {
            let standardViewButton = {
                icon: "list",
                label: "lista",
                isButton: true,
                onClick: () => this.props.changeView(ListViewType.Standard)
            } as MenuItemProps
            menuItems.push(standardViewButton);
            componentToRender = (<ConnectedAgentsListRapidProvisioning />);
        }
        else {
            let provisioningViewButton = {
                icon: "euro-sign",
                label: "prov.",
                isButton: true,
                onClick: () => this.props.changeView(ListViewType.Provisioning)
            } as MenuItemProps
            menuItems.push(provisioningViewButton);
        }

        return (
            <React.Fragment>
                <StickyBodyHeader>
                    <BodyHeader title="Agenti" menuItems={menuItems} />
                    <ConnectedAgentSearchForm />
                </StickyBodyHeader>
                {componentToRender}
            </React.Fragment>
        )
    }
}