import * as React from "react";
import { Anagraphic } from '../shared/anagraphic';
import AnagraphicAdditional from '../shared/anagraphicadditionalinfo';
import { Form, FormGroup, Row, Col, Button } from "reactstrap";
import Privacy from '../shared/privacyrow';
import { ChangePassword } from './shared/changepassword';
import { Profile } from '../../store/profile/Profile';
import { MenuItem } from '../menu/menuitem';

export interface CurrentProfileProps extends Profile {
    password?: string | undefined;
    confirmPassword: string | undefined;
    onSubmit?: () => void;
    onChange?: (propertyName: string, value: any) => void;
}


export class CurrentProfile extends React.Component<CurrentProfileProps>{

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <Form className="text-eurorounders"  style={{ width: "100%" }}>
                    <Anagraphic {...this.props} />
                    <ChangePassword confirmPassword={this.props.confirmPassword} password={this.props.password} onChange={(propertyName: string, value: any) => this.props.onChange(propertyName, value)} />
                    <AnagraphicAdditional {...this.props} />
                    <Privacy {...{ ...this.props }} />
                    <Row>
                        <Col>
                            <FormGroup>
                                <MenuItem isSmall={true} isButton={true} icon="save" label="salva" onClick={() => this.props.onSubmit()} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        )
    }
}