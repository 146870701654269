import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/payments/payments/PaymentState';
import { CashFundModalCallbacks, CashFundModal, CashFundModalProps } from './cashfundmodal';
import { actionsMiddleware as registerActions } from '../../../store/payments/cashregister/CashRegisterState';

const mapStateToProsp = (state: ApplicationState): CashFundModalProps => {
    return {
        cashFundData: state.payment.cashFundModal
    }
}

const mapDispatchToProps = () => (dispatch): CashFundModalCallbacks => {
    return ({
        onCloseModal: () => dispatch(actionsMiddleware.closeCashFundModal()),
        onPropertyChange: (name: string, value: any) => dispatch(actionsMiddleware.editCashFundProperty(name, value)),
        onSave: (eventId: string) => {
            dispatch(actionsMiddleware.saveCashFund()).then((response: boolean) => {
                if (response)
                    dispatch(registerActions.loadInitialState(eventId));
            })
        }
    });
}

export const ConnectedCashFundModal = connect<CashFundModalProps, CashFundModalCallbacks>(mapStateToProsp, mapDispatchToProps)(CashFundModal);