import * as React from "react";
import { BaseList } from '../../../base/baseList';
import { AgentAccounting } from '../../../../store/eventaccountings/EventAccountingEntities';
import _ from "underscore";
import moment from "moment";

import './agents-accounting.css';
import { CommissionPaymentMode } from "../../../../store/profile/Profile";

export interface AgentsAccountingProps {
    list: Array<AgentAccounting>;
}

export class AgentsAccounting extends React.Component<AgentsAccountingProps>{

    constructor(props) {
        super(props);
    }

    private getRows(list: Array<AgentAccounting>): JSX.Element[] {

        let renderList = list.map(model => {

            let commissionTypeRoom = model.commissionForRoomMode == CommissionPaymentMode.Fixed ? "€" : "%";
            let commissionTypePlayer = model.commissionForPlayerMode == CommissionPaymentMode.Fixed ? "€" : "%";

            let sourceEntryPayment = ""

            if (model.commissionForPlayerMode == CommissionPaymentMode.Percentage) {
                sourceEntryPayment = "(" + model.playerCommissionSource + ")";
            }

            return (
                <tr key={model.id}>
                    <td>
                        <div className="agents-accounting-table-cell">
                            <div>{model.lastName} {model.firstName}</div>
                            <div>{model.type} {model.mode}</div>
                        </div>
                    </td>
                    <td>
                        <div className="agents-accounting-table-cell">
                            <div>{model.commissionForRoom}{commissionTypeRoom}</div>
                            <div>{model.commissionForPlayer}{commissionTypePlayer} {sourceEntryPayment}</div>
                        </div>
                    </td>
                    <td>
                        <div className="agents-accounting-table-cell">
                            <div>{model.reservationsNumber} ({model.guestReservations}) - {model.nightsAccounted} ({model.guestNights})</div>
                            <div>{model.entriesNumber}</div>
                        </div>
                    </td>
                    <td>
                        <div className="agents-accounting-table-cell">
                            <div>{model.totalReservationsEarning}€</div>
                            <div>{model.totalEntriesEarning}€</div>
                        </div>
                    </td>
                    <td>
                        <div className="agents-accounting-table-cell">
                            <div>{model.totalEarning}€</div>
                        </div>
                    </td>
                </tr >
            )
        });

        return renderList;

    }

    private getHead(): JSX.Element {

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>
                        <div className="agents-accounting-table-cell">
                            <div>Nome</div>
                            <div>Tipo Provvigione</div>
                        </div>
                    </th>
                    <th>
                        <div className="agents-accounting-table-cell">
                            <div>Prov. x prenotaz.</div>
                            <div>Prov. x entry</div>
                        </div>
                    </th>
                    <th>
                        <div className="agents-accounting-table-cell">
                            <div>#Prenotazioni - #Notti</div>
                            <div>#Entries</div>
                        </div>
                    </th>
                    <th>
                        <div className="agents-accounting-table-cell">
                            <div>Tot. Pren.</div>
                            <div>Tot. Entries</div>
                        </div>
                    </th>
                    <th>
                        <div className="agents-accounting-table-cell">
                            <div>Totale</div>
                        </div>
                    </th>
                </tr>
            </thead>
        );

    }

    render() {

        return (
            <React.Fragment>
                <BaseList hasNextPage={false} list={this.props.list} active={false} onEndOfList={() => { }} getElements={(list: Array<AgentAccounting>) => { return this.getRows(list) }} getHead={() => { return this.getHead() }} />
            </React.Fragment>
        )
    }
}