import { Action, Reducer } from 'redux';
import { ApplicationState } from '../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest, checkRefreshToken, SaveRequest, FetchListRequest } from '../../utils/ajaxutilities';
import dotProp from "dot-prop";
import { CoreResponse } from '../serverresponse/serverresponse';
import { PAGE_SIZE } from '../../utils/commons';
import { push } from 'connected-react-router';
import _ from "underscore";
import { Location, RoomType, RoomTypeInformation, LocationType, LocactionTypeDesc } from './LocationEntities';
import $ from "jquery";
import { actionCreator as actionCreatorUi } from '../uiproperties/UIState';
import { actionCreator as locationGroupActions } from './groups/LocationGroupState';
import { LocationGroup } from './groups/LocationGroupEntities';

const debouncedActions = (dispatch) => {
    dispatch(actionCreator.setList([]));
    dispatch(actionsMiddleware.loadSearchResult(false));
    dispatch(actionsMiddleware.getTotalCount());
}

const debounceSearch = _.debounce(debouncedActions, 500);

export interface LocationState {
    list: Array<Location>;
    selectedLocation?: Location;
    totalCount: number;
    running?: Location;
    moduleLoaded: boolean;
    searchCriteria: LocationSearchCriteria;
    roomTypes: Array<RoomType>;
    locactionTypeDesc: Array<LocactionTypeDesc>;
}

export interface LocationSearchCriteria {
    searchString: string;
    orderField: string;
    pageNumber: number;
    hasNextPage: boolean;
    types: Array<LocationType>;
}

let baseState: LocationState = {
    list: [],
    selectedLocation: null,
    totalCount: 0,
    moduleLoaded: false,
    running: null,
    roomTypes: [],
    locactionTypeDesc: [],
    searchCriteria: {
        orderField: null,
        pageNumber: 0,
        searchString: null,
        hasNextPage: false,
        types: []
    }
}

export const getLocationTypesDesc = (): Array<LocactionTypeDesc> => ([
    {
        type: LocationType.Hotel,
        desc: "Hotel"
    },
    {
        type: LocationType.Casino,
        desc: "Casino"
    },
    {
        type: LocationType.HotelAndCasino,
        desc: "Hotel e Casino"
    },
    {
        type: LocationType.BedAndBreakfast,
        desc: "B&B"
    }
])


const getBaseSearch = (): LocationSearchCriteria => ({
    orderField: null,
    pageNumber: 0,
    searchString: null,
    hasNextPage: false,
    types: []
});

const getBaseState = (): LocationState => ({
    list: [],
    totalCount: 0,
    selectedLocation: null,
    moduleLoaded: false,
    running: null,
    roomTypes: [],
    locactionTypeDesc: getLocationTypesDesc(),
    searchCriteria: {
        orderField: null,
        pageNumber: 0,
        searchString: null,
        hasNextPage: false,
        types: []
    }
});

type LocationAction =
    SetRunning
    | SetList
    | AppendList
    | SetSearchCriteria
    | SetModuleLoaded
    | ResetState
    | SetTotalCount
    | UnloadRunning
    | SetRoomTypes
    | SetSelected;

enum TypeKeys {
    RESET_STATE = "LOCATION_RESET_STATE",
    SET_RUNNING = "LOCATION_SET_RUNNING",
    UNLOAD_RUNNING = "LOCATION_UNLOAD_RUNNING",
    SET_LIST = "LOCATION_SET_LIST",
    APPEND_LIST = "LOCATION_APPEND_LIST",
    SET_SEARCH_CRITERIA = "LOCATION_SET_SEARCH_CRITERIA",
    MODULE_LOADED = "LOCATION_MODULE_LOADED",
    SET_TOTAL_COUNT = "LOCATION_SET_TOTAL_COUNT",
    SET_ROOM_TYPES = "LOCATION_SET_ROOM_TYPES",
    SET_SELECTED = "LOCATION_SET_SELECTED"
}

interface UnloadRunning extends Action {
    type: TypeKeys.UNLOAD_RUNNING
}

interface SetTotalCount extends Action {
    type: TypeKeys.SET_TOTAL_COUNT;
    count: number;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE;
}

interface SetRunning extends Action {
    type: TypeKeys.SET_RUNNING;
    model: Location;
}

interface SetList extends Action {
    type: TypeKeys.SET_LIST;
    list: Array<Location>;
}

interface AppendList extends Action {
    type: TypeKeys.APPEND_LIST;
    list: Array<Location>;
}

interface SetSearchCriteria extends Action {
    type: TypeKeys.SET_SEARCH_CRITERIA;
    searchCriteria: LocationSearchCriteria;
}

interface SetModuleLoaded extends Action {
    type: TypeKeys.MODULE_LOADED;
    moduleLoaded: boolean;
}

interface SetRoomTypes extends Action {
    type: TypeKeys.SET_ROOM_TYPES,
    roomTypes: Array<RoomType>;
}

interface SetSelected extends Action {
    type: TypeKeys.SET_SELECTED;
    model?: Location;
}

export const actionCreator = {
    unloadRunning: (): UnloadRunning => ({
        type: TypeKeys.UNLOAD_RUNNING
    }),
    setTotalCount: (count: number): SetTotalCount => ({
        type: TypeKeys.SET_TOTAL_COUNT,
        count: count
    }),
    setList: (list: Array<Location>): SetList => ({
        type: TypeKeys.SET_LIST,
        list: list
    }),
    setRunning: (model: Location): SetRunning => ({
        type: TypeKeys.SET_RUNNING,
        model: model
    }),
    appendList: (models: Array<Location>): AppendList => ({
        type: TypeKeys.APPEND_LIST,
        list: models
    }),
    setSearchCriteria: (searchCriteria: LocationSearchCriteria): SetSearchCriteria => ({
        type: TypeKeys.SET_SEARCH_CRITERIA,
        searchCriteria: searchCriteria
    }),
    setModuleLoaded: (loaded: boolean): SetModuleLoaded => ({
        type: TypeKeys.MODULE_LOADED,
        moduleLoaded: loaded
    }),
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    setRommTypes: (roomTypes: Array<RoomType>): SetRoomTypes => ({
        type: TypeKeys.SET_ROOM_TYPES,
        roomTypes: roomTypes
    }),
    setSelected: (model?: Location): SetSelected => ({
        type: TypeKeys.SET_SELECTED,
        model: model
    })
}

export const actionsMiddleware = {

    loadInitialState: () => (dispatch, getState) => {
        abortAllRequest();
        dispatch(actionCreator.setModuleLoaded(false));
        let appState = (getState() as ApplicationState);
        let preservePages: boolean = true;

        dispatch(actionCreator.resetState());

        if (appState.uiProperties.previousPath === undefined || appState.uiProperties.previousPath == null || !(appState.uiProperties.previousPath.startsWith("/locations/edit") || appState.uiProperties.previousPath.startsWith("/locations/create"))) {
            dispatch(actionCreator.setSearchCriteria(getBaseSearch()));
            preservePages = false;
        }

        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false)
                return;

            $.when(dispatch(actionsMiddleware.loadSearchResult(preservePages)), dispatch(actionsMiddleware.getTotalCount()), dispatch(actionsMiddleware.getRoomTypes())).then((resp, count, rts) => {
                let respCast = (resp as unknown as CoreResponse<any>);
                if (respCast.success == true)
                    dispatch(actionCreator.setModuleLoaded(true));
            });
        });
    },
    loadInitialStateExternal: (location?: Location) => (dispatch, getState: () => ApplicationState) => {

        abortAllRequest();
        dispatch(actionCreator.resetState());
        dispatch(actionCreator.setModuleLoaded(false));
        dispatch(actionCreator.setSelected(location));
        dispatch(actionCreator.setSearchCriteria(getBaseSearch()));

        $.when(dispatch(actionsMiddleware.loadSearchResult(false))).then((resp) => {
            let respCast = (resp as unknown as CoreResponse<any>);
            if (respCast.success == true)
                dispatch(actionCreator.setModuleLoaded(true));
        });
    },
    getRoomTypes: () => (dispatch, getState: () => ApplicationState): JQueryPromise<Array<RoomType>> => {

        let promise = $.Deferred();
        let requestModel = new RequestModel(`/locations/roomtypes`, RequestMethods.GET);

        ajaxRequest<Array<RoomType>>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                return promise.resolve(response);
            }

            dispatch(actionCreator.setRommTypes(response.entity));

            return promise.resolve(response.entity);

        });

        return promise.promise();
    },
    getNewLocations: () => (dispatch, getState: () => ApplicationState): Location => {

        let roomTypes = getState().locations.roomTypes;

        let newLocation = {
            type: LocationType.Hotel
        } as Location;

        newLocation.roomTypeInformation = roomTypes.map(rt => {
            return {
                id: rt.id,
                type: rt.type,
                information: null
            } as RoomTypeInformation;
        });

        return newLocation;
    },
    getTotalCount: () => (dispatch, getState: () => ApplicationState): JQueryPromise<number> => {
        let promise = $.Deferred();
        let requestModel = new AbortableRequest("/locations/count", RequestMethods.GET, { ...getState().locations.searchCriteria });
        ajaxRequest<number>(requestModel)(dispatch, getState).then(response => {
            if (response.success == false) {
                promise.resolve(null);
                return;
            }
            dispatch(actionCreator.setTotalCount(response.entity));
            promise.resolve(response.entity);
        });

        return promise.promise();
    },
    genericSearch: (value: string) => (dispatch, getState: () => ApplicationState) => {
        abortAllRequest();
        let searchCriteria = { ...getState().locations.searchCriteria };
        //reset page number and alterate string search
        searchCriteria.pageNumber = 0;
        searchCriteria.searchString = value;
        searchCriteria.hasNextPage = false;
        dispatch(actionCreator.setSearchCriteria(searchCriteria));
        debounceSearch(dispatch);

    },
    loadNextPage: () => (dispatch, getState: () => ApplicationState) => {
        let searchCriteria = getState().locations.searchCriteria;
        searchCriteria.pageNumber += 1;
        dispatch(actionCreator.setSearchCriteria(searchCriteria));
        dispatch(actionsMiddleware.loadSearchResult(false));
    },
    loadSearchResult: (preservePages: boolean) => (dispatch, getState): JQueryPromise<CoreResponse<Array<Location>>> => {

        let promise = $.Deferred();

        let searchCriteria = (getState() as ApplicationState).locations.searchCriteria;

        let requestModel = new FetchListRequest(`/locations/search`, RequestMethods.GET, { ...searchCriteria, preservePages: preservePages });

        ajaxRequest<Array<Location>>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                return promise.resolve(response);
            }

            searchCriteria.hasNextPage = true;

            if (response.entity.length < PAGE_SIZE) {
                searchCriteria.hasNextPage = false;
            }

            if (preservePages && (response.entity.length < (searchCriteria.pageNumber + 1) * PAGE_SIZE)) {
                searchCriteria.hasNextPage = false;
            }

            dispatch(actionCreator.appendList(response.entity));

            dispatch(actionCreator.setSearchCriteria(searchCriteria));

            dispatch(actionCreatorUi.startFetchingPagedList(true));

            return promise.resolve(response);

        });

        return promise.promise();
    },
    load: (id?: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<Location> => {

        let deferred = $.Deferred();

        $.when(dispatch(locationGroupActions.loadList())).then((response: CoreResponse<Array<LocationGroup>>) => {
            if (!response || !response.success) {
                deferred.resolve(null);
                return;
            }
            if (id === undefined || id == null || id == "") {
                let newModel = dispatch(actionsMiddleware.getNewLocations());
                dispatch(actionCreator.setRunning(newModel));
                dispatch(actionCreator.setModuleLoaded(true));
                deferred.resolve(newModel);
                return;
            }

            let requestModel = new RequestModel(`/locations/${id}`, RequestMethods.GET, null, null);
            ajaxRequest<Location>(requestModel)(dispatch, getState).then(response => {
                if (response.success == false || response.entity == null || response.entity === undefined) {
                    deferred.resolve(null);
                    return;
                }
                dispatch(actionCreator.setRunning(response.entity));
                dispatch(actionCreator.setModuleLoaded(true));
            });

        });
        return deferred.promise();
    },
    navigate: (model: Location) => (dispatch, getState) => {
        dispatch(actionCreator.setModuleLoaded(false));
        dispatch(actionCreator.unloadRunning());
        dispatch(push(`/locations/edit/${model.id}`));
    },
    delete: () => (dispatch, getState: () => ApplicationState) => {

        let requestModel = new SaveRequest(`/locations/${getState().locations.running.id}`, RequestMethods.DELETE, null);

        return ajaxRequest<any>(requestModel)(dispatch, getState).then(response => {
            if (response.success == true) {
                dispatch(push("/locations"));
            }
        });
    },
    save: () => (dispatch, getState: () => ApplicationState): JQueryPromise<Location> => {

        let promise = $.Deferred();

        dispatch(actionCreatorUi.setErrorMessages([]));
        //validation
        let model: Location = getState().locations.running;

        if (model.name == "" || model.name == null || model.name === undefined) {
            dispatch(actionCreatorUi.setErrorMessages(["Name is mandatory"]));
            promise.resolve(null);
            return;
        }


        let request = new SaveRequest(`/locations`, RequestMethods.POST, model);

        ajaxRequest<Location>(request)(dispatch, getState).then(response => {
            if (response.success != true) {
                promise.resolve(null);
                return;
            }
            promise.resolve(response.entity);
            dispatch(actionCreator.setRunning(response.entity));
        });

        return promise.promise();
    },
    setRunningProp: (propertyName: string, value: any) => (dispatch, getState: () => ApplicationState) => {

        dispatch(actionCreatorUi.setErrorMessages([]));

        let running = { ...getState().locations.running };

        switch (propertyName) {
            case "name":
                running.name = value;
                break;
            case "email":
                running.email = value;
                break;
            case "phone":
                running.phone = value;
                break;
            case "city":
                running.city = value;
                break;
            case "country":
                running.country = value;
                break;
            case "zipCode":
                running.zipCode = value;
                break;
            case "stateProvince":
                running.stateProvince = value;
                break;
            case "address":
                running.address = value;
                break;
            case "taxCode":
                running.taxCode = value;
                break;
            case "vatNumber":
                running.vatNumber = value;
                break;
            case "vat":
                running.vat = value;
                break;
            case "info":
                running.info = value;
                break;
            case "type":
                running.type = value;
                break;
            case "phonePrefix":
                running.phonePrefix = value;
                break;
            case "guestsForCommunications":
                running.guestsForCommunications = value;
                break;
            case "locationGroupId":
                running.locationGroupId = value;
                break;
        }
        dispatch(actionCreator.setRunning(running));
    },
    setRunningRoomInfo: (id: string, info: string) => (dispatch, getState: () => ApplicationState) => {
        let running = { ...getState().locations.running };

        running.roomTypeInformation.forEach(x => {
            if (x.id == id) {
                x.information = info;
            }
        });

        dispatch(actionCreator.setRunning(running));
    }
}

export const reducer: Reducer<LocationState> = (state: LocationState | undefined, incomingAction: LocationAction): LocationState => {

    if (state === undefined) {
        return baseState;
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.UNLOAD_RUNNING:
            newState = dotProp.set(newState, "running", null);
            break
        case TypeKeys.SET_TOTAL_COUNT:
            newState = dotProp.set(newState, "totalCount", incomingAction.count);
            break;
        case TypeKeys.RESET_STATE:
            let stateToUpdate = getBaseState();
            stateToUpdate.searchCriteria = newState.searchCriteria;
            stateToUpdate.roomTypes = newState.roomTypes;
            newState = { ...stateToUpdate };
            break;
        case TypeKeys.SET_LIST:

            incomingAction.list.forEach(x => {
                if (newState.selectedLocation !== undefined && newState.selectedLocation != null && x.id == newState.selectedLocation.id) {
                    x.isSelected = true;
                } else {
                    x.isSelected = false;
                }
            });

            newState = dotProp.set(newState, "list", [...incomingAction.list]);
            break;
        case TypeKeys.APPEND_LIST:
            let list = newState.list.concat(incomingAction.list);
            list.forEach(x => {
                if (newState.selectedLocation !== undefined && newState.selectedLocation != null && x.id == newState.selectedLocation.id) {
                    x.isSelected = true;
                } else {
                    x.isSelected = false;
                }
            });

            newState = dotProp.set(newState, 'list', [...list]);
            break;
        case TypeKeys.SET_RUNNING:
            newState = dotProp.set(newState, "running", { ...incomingAction.model });
            break;
        case TypeKeys.SET_SEARCH_CRITERIA:
            newState = dotProp.set(newState, "searchCriteria", { ...incomingAction.searchCriteria });
            break;
        case TypeKeys.MODULE_LOADED:
            newState = dotProp.set(newState, "moduleLoaded", incomingAction.moduleLoaded);
            break;
        case TypeKeys.SET_ROOM_TYPES:
            newState = dotProp.set(newState, "roomTypes", [...incomingAction.roomTypes]);
            break;
        case TypeKeys.SET_SELECTED:
            if (newState.selectedLocation !== undefined && newState.selectedLocation != null
                && incomingAction.model !== undefined && incomingAction.model != null
                && incomingAction.model.id == newState.selectedLocation.id) {
                incomingAction.model = null;
                newState = dotProp.set(newState, "selectedLocation", incomingAction.model);
            } else {
                newState = dotProp.set(newState, "selectedLocation", { ...incomingAction.model });
            }

            if (incomingAction.model === undefined || incomingAction.model == null) {
                newState = dotProp.set(newState, "selectedLocation", null);
            }

            let selectionList = newState.list;
            selectionList.forEach(x => {
                if (incomingAction.model !== undefined && incomingAction.model != null && x.id == incomingAction.model.id) {
                    x.isSelected = true;
                } else {
                    x.isSelected = false;
                }
            });
            newState = dotProp.set(newState, 'list', [...selectionList]);
            break;
    }

    return newState;
}