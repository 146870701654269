import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionMiddleware, actionCreator } from '../../../store/reservations/ReservationState';
import { ReservationPaymentModal, ReservationPaymentModalCallbacks, ReservationPaymentModalProps } from '../base/payment/reservationpaymentmodal';


const mapStateToProsp = (state: ApplicationState): ReservationPaymentModalProps => {

    return {
        isOpen: state.reservations.running.isPaymentModalOpen,
        methods: state.paymentMethods.paymentMethods,
        model: state.reservations.running.payment
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): ReservationPaymentModalCallbacks => {
    return ({
        close: () => dispatch(actionCreator.togglePaymentModal(false)),
        onChange: (name: string, value: any) => dispatch(actionMiddleware.setRunningPaymentProp(name, value)),
        onConfirm: () => {
            if (dispatch(actionMiddleware.confirmPayment()))
                dispatch(actionCreator.togglePaymentModal(false));
        }
    });
}

export const ConnectedReservationPaymentModal = connect<ReservationPaymentModalProps, ReservationPaymentModalCallbacks>(mapStateToProsp, mapDispatchToProps)(ReservationPaymentModal);