import { Event } from "../events/EventEntities";
import { Location, RoomType } from "../locations/LocationEntities";

export enum AlertAvailabilityType {
    Warning = "Warning",
    Error = "Error"
}

export interface PeriodPricing {
    id?: string;
    event?: Event;
    location?: Location;
    dailyTax: number;
    checkIn?: string;
    checkOut?: string;
    roomPricings?: Array<RoomPricing>;
}

export interface RoomPricing {
    id?: string;
    roomType?: RoomType;
    cost?: number;
    costNotSell: number;
    availability?: number;
    sellPrice: number;
    dailyAvailabilities?: Array<DailyAvailability>;
    isAvailabilityEditable?: boolean;
    alertAvailability?: AlertAvailabilityType;
    costStaff: number;
}

export interface DailyAvailability {
    day?: string;
    roomTypeId?: string;
    locationId?: string;
    eventId?: string;
    availability?: number;
    totalAvailability?: number;
}