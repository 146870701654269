import * as React from "react";
import { Spinner } from "reactstrap"
import ReactVisibilitySensor from "react-visibility-sensor";
import { guid } from '../../utils/commons';

import './list.css';

export interface ListProps<T> {
    list?: Array<T>;
    hasNextPage?: boolean;
    onEndOfList?: () => void;
    active?: boolean;
    getHead?: () => JSX.Element
    getElements?: (list: Array<T>) => JSX.Element[];
    justHead?: boolean;
    onUnload?: () => void;
}

export class List<T> extends React.Component<ListProps<T>>{

    constructor(props) {
        super(props);
        this.loadMoreData.bind(this);
    }

    componentWillUnmount() {
        if (this.props.onUnload)
            this.props.onUnload();
    }

    private getRows(): JSX.Element[] {

        let elementArrays = this.props.getElements(this.props.list);
        elementArrays.push(this.getVisibilitySensor())
        return elementArrays;

    }

    private getList(): JSX.Element {
        let listHead = this.props.getHead ? this.props.getHead() : (<React.Fragment />);
        let listRows = this.getRows();

        return (<React.Fragment>
            {listHead}
            {listRows}
        </React.Fragment>)
    };

    private getJustHead(): JSX.Element {
        let listHead = this.props.getHead ? this.props.getHead() : (<React.Fragment />);

        return (<React.Fragment>
            {listHead}
        </React.Fragment>)
    };

    private getVisibilitySensor(): JSX.Element {

        if (!this.props.hasNextPage)
            return (
                <React.Fragment key="visibility-sensor">
                </React.Fragment>
            );

        let active = true;

        if (this.props.active == false)
            active = false;

        return (<ReactVisibilitySensor key="visibility-sensor" delayedCall partialVisibility onChange={(isVisible: boolean) => { this.loadMoreData(isVisible); }} active={active}>
            <div className="spinner-center">
                <Spinner color="primary" />
            </div>
        </ReactVisibilitySensor>);
    }

    private loadMoreData(isVisible: boolean) {

        if (!isVisible)
            return;

        if (this.props.hasNextPage == false)
            return;

        if (this.props.onEndOfList)
            this.props.onEndOfList();
    }


    render() {

        let list = this.props.justHead ? this.getJustHead() : this.getList();

        return (
            <div className="text-eurorounders" style={{ width: "100%" }}>
                {list}
            </div>
        )
    }
}