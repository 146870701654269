import * as React from "react";
import { ConfirmationModal } from '../../base/confirmationmodal';
import { MenuItemProps, MenuItem } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { Reservation } from '../../../store/reservations/ReservationEntities';
import { ConnectedReservationsEditForm } from '../connected/connected.editform';
import { ConnectedMainDocuments } from '../../documents/connected.maindocuments';
import { UnsavedChanges } from "../../base/unsavedchanges";

export interface ReservationEditPageProps {
    reservation?: Reservation;
    eventFullName: string;
}

export interface ReservationEditPageCallbacks {
    onLoad: (id?: string) => void;
    onDelete: () => void;
    onSubmit: () => void;
}


export class ReservationEditPage extends React.Component<ReservationEditPageProps & ReservationEditPageCallbacks>{
    private modalRef: React.RefObject<ConfirmationModal>;
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.onLoad) {

            let fromQuote = null;
            let id = null;

            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null) {
                fromQuote = (this.props as any).match.params.fromquote
                id = (this.props as any).match.params.id;
            }

            if (fromQuote == undefined || fromQuote == null)
                this.props.onLoad(id);
        }
    }

    private delete() {
        if (this.props.onDelete)
            this.props.onDelete();
    }

    render() {

        if (this.props.reservation === undefined || this.props.reservation == null) {
            return (<React.Fragment></React.Fragment>);
        }

        let isNew = true;

        let menuItems = [];

        if (this.props.reservation.id !== undefined && this.props.reservation.id != null) {
            menuItems = [
                {
                    icon: "trash",
                    label: "elimina",
                    isButton: true,
                    isDisabled: false,
                    onClick: () => { this.modalRef.current.toogleModal() }
                }

            ] as Array<MenuItemProps>

            isNew = false;
        }

        menuItems.push({
            icon: "ellipsis-h",
            label: "Evento",
            isButton: false,
            isSmall: true,
            path: `/events/${this.props.reservation.event.id}/menu`
        } as MenuItemProps);

        menuItems.push({
            icon: "list",
            label: "lista",
            isButton: false,
            isSmall: true,
            path: `/events/${this.props.reservation.event.id}/reservations`
        } as MenuItemProps);

        let documents = null;
        if (this.props.reservation !== undefined && this.props.reservation != null && this.props.reservation.id !== undefined && this.props.reservation.id != null)
            documents = (<ConnectedMainDocuments objectId={this.props.reservation.id} objectName={`${this.props.reservation.progressiveNumber}`} />);


        return (
            <React.Fragment>
                <UnsavedChanges confirmAction={this.props.onSubmit} />
                <BodyHeader subTitle={this.props.eventFullName} title={isNew == true ? "Crea Prenotazione" : "Modifica Prenotazione"} menuItems={menuItems} />
                <ConnectedReservationsEditForm />
                <ConfirmationModal text="Eliminare Prenotazione?" onConfirm={() => this.delete()} ref={this.modalRef} />
                {documents}
            </React.Fragment>
        )
    }
}