import { Action, Reducer } from 'redux';
import { ApplicationState } from '../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest, checkRefreshToken, SaveRequest, FetchListRequest } from '../../utils/ajaxutilities';
import { OrderDirection } from '../../utils/commons';
import dotProp from "dot-prop";
import { CoreResponse } from '../serverresponse/serverresponse';
import { PAGE_SIZE } from '../../utils/commons';
import { push } from 'connected-react-router';
import _ from "underscore";
import { EntryForCustomer, Entry } from './EntriesEntities';
import $ from "jquery";
import { actionCreator as actionCreatorUi } from '../uiproperties/UIState';
import { actionCreator as customerActions, actionsMiddleware as customerMiddleware } from '../customers/CustomerState';

const debouncedActions = (dispatch) => {
    dispatch(actionCreator.setList([]));
    dispatch(actionsMiddleware.loadSearchResult(false));
    dispatch(actionsMiddleware.getTotalCount());
}

const debounceSearch = _.debounce(debouncedActions, 500);

const updateNumber = (id: string, number: number, dispatch, getState: () => ApplicationState) => {
    let requestModel = new RequestModel(`/entries/setnumber/${id}/${number}`, RequestMethods.POST);
    ajaxRequest<any>(requestModel)(dispatch, getState).then(response => {
        dispatch(actionsMiddleware.getTotalCount());
    });
}

const debounceUpdate = _.debounce(updateNumber, 350);

export interface EntryState {
    list: Array<EntryForCustomer>;
    totalCount: number;
    players: number;
    eventId: string;
    moduleLoaded: boolean;
    searchCriteria: EntrySearchCriteria;
}

interface EntrySearchCriteria {
    searchString: string;
    pageNumber: number;
    hasNextPage: boolean;
}

export enum EntryUpdateNumberActionType {
    Increase,
    Decrease
}

const getBaseState = (eventId: string = null): EntryState => ({
    eventId: eventId,
    list: [],
    totalCount: 0,
    players: 0,
    moduleLoaded: false,
    searchCriteria: getBaseSearch()
});

const getBaseSearch = (): EntrySearchCriteria => ({
    hasNextPage: false,
    pageNumber: 0,
    searchString: null
})

enum TypeKeys {
    RESET_STATE = "ENTRY_RESET_STATE",
    SET_LIST = "ENTRY_SET_LIST",
    APPEND_LIST = "ENTRY_APPEND_LIST",
    SET_SEARCH_CRITERIA = "ENTRY_SET_SEARCH_CRITERIA",
    MODULE_LOADED = "ENTRY_MODULE_LOADED",
    SET_TOTAL_COUNT = "ENTRY_SET_TOTAL_COUNT",
    SET_EVENT_ID = "ENTRY_SET_EVENT_ID",
    SET_ENTRY = "ENTRY_SET_ENTRY"
}

type EntryAction =
    ResetState
    | SetList
    | AppendList
    | SetSearchCriteria
    | ModuleLoaded
    | SetTotalCount
    | SetEventId
    | SetEntry;

interface SetEntry extends Action {
    type: TypeKeys.SET_ENTRY;
    entry: EntryForCustomer;
}

interface SetEventId extends Action {
    type: TypeKeys.SET_EVENT_ID;
    eventId: string;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE;
}

interface SetList extends Action {
    type: TypeKeys.SET_LIST;
    entries: Array<EntryForCustomer>;
}

interface AppendList extends Action {
    type: TypeKeys.APPEND_LIST;
    entries: Array<EntryForCustomer>;
}

interface SetSearchCriteria extends Action {
    type: TypeKeys.SET_SEARCH_CRITERIA;
    searchCriteria: EntrySearchCriteria;
}

interface ModuleLoaded extends Action {
    type: TypeKeys.MODULE_LOADED;
    loaded: boolean;
}

interface SetTotalCount extends Action {
    type: TypeKeys.SET_TOTAL_COUNT;
    totalCount: number;
    totalPlayers: number;
}


export const actionCreator = {
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    setList: (entries: Array<EntryForCustomer>): SetList => ({
        type: TypeKeys.SET_LIST,
        entries: entries
    }),
    appendList: (entries: Array<EntryForCustomer>): AppendList => ({
        type: TypeKeys.APPEND_LIST,
        entries: entries
    }),
    setSearchCriteria: (searchCriteria: EntrySearchCriteria): SetSearchCriteria => ({
        type: TypeKeys.SET_SEARCH_CRITERIA,
        searchCriteria: searchCriteria
    }),
    setModuleLoaded: (loaded: boolean): ModuleLoaded => ({
        type: TypeKeys.MODULE_LOADED,
        loaded: loaded
    }),
    setTotalCount: (total: number, players: number): SetTotalCount => ({
        type: TypeKeys.SET_TOTAL_COUNT,
        totalCount: total,
        totalPlayers: players
    }),
    setEventId: (eventId: string): SetEventId => ({
        type: TypeKeys.SET_EVENT_ID,
        eventId: eventId
    }),
    setEntry: (entry: EntryForCustomer): SetEntry => ({
        type: TypeKeys.SET_ENTRY,
        entry: entry
    })
}

export const actionsMiddleware = {
    loadInitialState: (eventId: string) => (dispatch, getState): JQueryPromise<any> => {
        let deferred = $.Deferred();
        abortAllRequest();
        dispatch(actionCreator.setModuleLoaded(false));

        dispatch(actionCreator.resetState());
        dispatch(actionCreator.setSearchCriteria(getBaseSearch()));
        dispatch(actionCreator.setEventId(eventId));

        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false) {
                deferred.resolve();
                return deferred.promise();
            }

            $.when(dispatch(actionsMiddleware.loadSearchResult(false)), dispatch(actionsMiddleware.getTotalCount())).then((resp, count) => {
                let respCast = (resp as unknown as CoreResponse<any>);
                if (respCast.success == true)
                    dispatch(actionCreator.setModuleLoaded(true));
                deferred.resolve();
            });
        });

        return deferred.promise();
    },
    genericSearch: (value: string) => (dispatch, getState: () => ApplicationState) => {
        abortAllRequest();
        let searchCriteria = { ...getState().entries.searchCriteria };
        //reset page number and alterate string search
        searchCriteria.pageNumber = 0;
        searchCriteria.hasNextPage = false;
        searchCriteria.searchString = value;
        dispatch(actionCreator.setSearchCriteria(searchCriteria));
        debounceSearch(dispatch);

    },
    loadNextPage: () => (dispatch, getState: () => ApplicationState) => {
        let searchCriteria = getState().entries.searchCriteria;
        searchCriteria.pageNumber += 1;
        dispatch(actionCreator.setSearchCriteria(searchCriteria));
        dispatch(actionsMiddleware.loadSearchResult(false));
    },
    getTotalCount: () => (dispatch, getState: () => ApplicationState): JQueryPromise<number> => {
        let promise = $.Deferred();
        let requestModel = new AbortableRequest(`/entries/count`, RequestMethods.GET, { ...getState().entries.searchCriteria, eventId: getState().entries.eventId });
        ajaxRequest<any>(requestModel)(dispatch, getState).then(response => {
            if (response.success == false) {
                promise.resolve(null);
                return;
            }
            dispatch(actionCreator.setTotalCount(response.entity.count, response.entity.players));
            promise.resolve(response.entity);
        });

        return promise.promise();
    },
    loadSearchResult: (preservePages: boolean) => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<Array<EntryForCustomer>>> => {

        let promise = $.Deferred();

        let searchCriteria = { ...getState().entries.searchCriteria };

        let requestModel = new FetchListRequest(`/entries/search`, RequestMethods.GET, { ...searchCriteria, preservePages: preservePages, eventId: getState().entries.eventId });

        ajaxRequest<Array<EntryForCustomer>>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                return promise.resolve(response);
            }

            searchCriteria.hasNextPage = true;

            if (response.entity.length < PAGE_SIZE) {
                searchCriteria.hasNextPage = false;
            }

            if (preservePages && (response.entity.length < (searchCriteria.pageNumber + 1) * PAGE_SIZE)) {
                searchCriteria.hasNextPage = false;
            }

            dispatch(actionCreator.appendList(response.entity));

            dispatch(actionCreator.setSearchCriteria(searchCriteria));

            dispatch(actionCreatorUi.startFetchingPagedList(true));

            return promise.resolve(response);

        });

        return promise.promise();
    },
    onCustomersModelOpen: () => (dispatch, getState: () => ApplicationState) => {
        dispatch(customerMiddleware.loadInitialState()).then(resp => {
            dispatch(customerActions.setSelectionModalOpen());
        });
    },
    onCustomersModalConfirm: () => (dispatch, getState: () => ApplicationState) => {

        let selectedCustomers = [...getState().customers.selectedCustomers];

        let customerIds = [];

        selectedCustomers.forEach(x => customerIds.push(x.id));

        if (customerIds.length == 0)
            return;

        let requestModel = new SaveRequest(`/entries/${getState().entries.eventId}`, RequestMethods.POST, customerIds);

        return ajaxRequest<any>(requestModel)(dispatch, getState).then(response => {
            if (response.success == true) {
                //important to set module loaded false to not trigger page reload caused by infinite scroll 
                dispatch(actionCreator.setModuleLoaded(false));
                dispatch(actionCreator.setList([]));
                $.when(dispatch(actionsMiddleware.loadSearchResult(true)), dispatch(actionsMiddleware.getTotalCount())).then((resp, count) => {
                    let respCast = (resp as unknown as CoreResponse<any>);
                    if (respCast.success == true)
                        dispatch(actionCreator.setModuleLoaded(true));
                });
            }
        });

    },
    delete: (id: string) => (dispatch, getState: () => ApplicationState) => {

        let requestModel = new SaveRequest(`/entries/${id}`, RequestMethods.DELETE, null);

        return ajaxRequest<any>(requestModel)(dispatch, getState).then(response => {
            if (response.success == true) {
                dispatch(actionCreator.setList([]));
                dispatch(actionsMiddleware.loadSearchResult(true));
                dispatch(actionsMiddleware.getTotalCount());
            }
        });
    },
    updateNumber: (id: string, action: EntryUpdateNumberActionType) => (dispatch, getState: () => ApplicationState) => {
        let entryIndex = _.findIndex(getState().entries.list, (e) => e.id == id);

        if (entryIndex < 0)
            return;

        let entry = { ...getState().entries.list[entryIndex] };

        if (action == EntryUpdateNumberActionType.Increase) {
            entry.number += 1;
        } else {
            entry.number -= 1;
        }

        if (entry.number <= 0)
            return;

        dispatch(actionCreator.setEntry(entry));

        debounceUpdate(id, entry.number, dispatch, getState);

    }
}


export const reducer: Reducer<EntryState> = (state: EntryState | undefined, incomingAction: EntryAction): EntryState => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.SET_TOTAL_COUNT:
            newState = dotProp.set(newState, "totalCount", incomingAction.totalCount);
            newState = dotProp.set(newState, "players", incomingAction.totalPlayers);
            break;
        case TypeKeys.RESET_STATE:
            let stateToUpdate = getBaseState();
            stateToUpdate.searchCriteria = newState.searchCriteria;
            newState = { ...stateToUpdate };
            break;
        case TypeKeys.SET_LIST:
            newState = dotProp.set(newState, "list", [...incomingAction.entries]);
            break;
        case TypeKeys.APPEND_LIST:
            let entries = newState.list.concat(incomingAction.entries);
            newState = dotProp.set(newState, 'list', [...entries]);
            break;
        case TypeKeys.SET_SEARCH_CRITERIA:
            newState = dotProp.set(newState, "searchCriteria", { ...incomingAction.searchCriteria });
            break;
        case TypeKeys.MODULE_LOADED:
            newState = dotProp.set(newState, "moduleLoaded", incomingAction.loaded);
            break;
        case TypeKeys.SET_EVENT_ID:
            newState = dotProp.set(newState, "eventId", incomingAction.eventId);
            break;
        case TypeKeys.SET_ENTRY:
            let entryIndex = _.findIndex(newState.list, (e) => e.id == incomingAction.entry.id);
            let newArray = [...newState.list];
            newArray[entryIndex] = incomingAction.entry;
            newState = dotProp.set(newState, 'list', newArray);

            break;
    }

    return newState;
}