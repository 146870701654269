import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from '../../../store/index';
import { getEventAccountingMenu } from '../../../store/uiproperties/UIState';
import { sharedActions as itemsMiddleware } from '../../../store/payments/paymentitems/PaymentItemState';
import { sharedActions as methodsMiddleware } from '../../../store/payments/paymentmethods/PaymentMethodState';
import { EventAccountingMenu, EventAccountingMenuProps, EventAccountingMenuCallbacks } from '../pages/eventaccountingmenu';
import _ from "underscore";

const mapStateToProsp = (state: ApplicationState): EventAccountingMenuProps => {

    let menuVoices = [];

    if (state.events.running !== undefined && state.events.running != null)
        menuVoices = getEventAccountingMenu(state.events.running.id);

    let menuVoicesToSet = [];

    menuVoices.forEach(m => {
        for (let p of state.currentProfile.permissions) {
            let toBreak = false;
            for (let x of m.associatedToPermissions) {
                if (x == p.code) {
                    menuVoicesToSet.push(m);
                    toBreak = true;
                    break;
                }
            }
            if (toBreak)
                break;
        }
    });

    return {
        event: state.events.running,
        menuVoices: menuVoicesToSet
    }
}

const mapDispatchToProps = () => (dispatch): EventAccountingMenuCallbacks => {
    return ({
        onLoad: () => {
            dispatch(itemsMiddleware.loadAll());
            dispatch(methodsMiddleware.loadAll());
        }
    });
}

export const ConnectedEventAccountingMenu = withRouter(connect<EventAccountingMenuProps, EventAccountingMenuCallbacks>(mapStateToProsp, mapDispatchToProps)(EventAccountingMenu));