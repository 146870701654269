import { CommissionTemplateMode, PlayerCommissionSource, CommissionPaymentMode } from '../profile/Profile';

export interface EventAccounting {
    id: string;
    requestDate: string;
    status: EventAccountingStatus;
    isValid: boolean;
    eventId: string;
    model: AccoutingDetails;
    totalEarningByReservations: number;
    totalEarningByEntries: number;
    totalEarning: number;
    totalAgentsCostByReservations: number;
    totalAgentsCostByEntries: number;
    totalSoldNightsCost: number;
    totalUnsoldNightsCost: number;
    totalNightsTax: number;
    totalCost: number;
    totalNetGain: number;

    totalStandardBookingTax: number;
    totalStandardBookingGross: number;
    totalWebBookingTax: number;
    totalWebBookingGross: number;
    totalStaffTax: number;
    totalStaffNightsCost: number;

    totalStaffHotelNightsCost: number;
    totalGuestNightsCost: number;

    totalStaffHotelTax: number;
    totalGuestTax: number;
}

export interface AccoutingDetails {
    hotelsAccounting: Array<HotelAccounting>;
    agentsAccounting: Array<AgentAccounting>;
}

export const mapStatusToName = (status: EventAccountingStatus): string => {
    switch (status) {
        case EventAccountingStatus.Completed:
            return "Completato";
        case EventAccountingStatus.Error:
            return "Errore";
        case EventAccountingStatus.InProcess:
            return "Sto calcolando...";
        case EventAccountingStatus.Starting:
            return "Sto avviando..";
        default:
            return "In coda";
    }
}

export enum EventAccountingStatus {
    InQueue = "InQueue",
    Starting = "Starting",
    InProcess = "InProcess",
    Error = "Error",
    Completed = "Completed"
}

export interface HotelAccounting {
    id: string;
    name: string;
    standardBookingTax: number;
    staffBookingTax: number;
    staffHotelBookingTax: number;
    guestBookingTax: number;
    totalRoomCost: number;
    totalCost: number;
    hotelNightsAccountings: Array<NightsAccounting>;
}

export interface AgentAccounting {
    id: string;
    firstName: string;
    lastName: string;
    phonePrefix: string;
    phone: string;
    mode: CommissionTemplateMode;
    type: string;
    commissionForRoom: number;
    commissionForPlayer: number;
    reservationsNumber: number;
    totalReservationsEarning: number;
    entriesNumber: number;
    totalEntriesEarning: number;
    totalEarning: number;
    nightsAccounted: number;
    playerCommissionSource?: PlayerCommissionSource;
    commissionForPlayerMode?: CommissionPaymentMode;
    commissionForRoomMode?: CommissionPaymentMode;
    guestNights: number;
    guestReservations: number;
    standalonePayments?: boolean;
    paid?: number;
    toPay?: number;
    totalRow?: boolean;
}

export interface NightsAccounting {
    checkIn: string;
    checkOut: string;
    name: string;
    type: string;
    cost: number;
    costUnsold: number;
    totalNights: number;
    totalSold: number;
    totalUnsold: number;
    totalSoldCost: number;
    totalUnsoldCost: number;
    totalStaff: number;
    totalStaffCost: number;
    costStaff: number;
    totalGuest: number;
    totalGuestCost: number;
    totalStaffHotel: number;
    totalStaffHotelCost: number;
}

export interface EventAccountingV2 {
    lastUpdate?: string;
    eventId: string;
    model: AccoutingDetailsV2;
    totalEarningByReservations: number;
    totalEarningByEntries: number;
    totalEarning: number;
    totalSoldNightsCost: number;
    totalUnsoldNightsCost: number;
    totalNightsTax: number;
    totalCost: number;
    totalNetGain: number;

    totalBookingTax: number;
    totalBookingGross: number;

    totalStaffTax: number;
    totalStaffNightsCost: number;

    totalStaffHotelNightsCost: number;
    totalGuestNightsCost: number;

    totalStaffHotelTax: number;
    totalGuestTax: number;

    totalHotelsCost: number;

    totalHotelsPayment: number;

    toPayHotels: number;
}

export interface AccoutingDetailsV2 {
    hotelsAccounting: Array<HotelAccounting>;
}