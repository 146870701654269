import { Event } from '../events/EventEntities';

export interface KpiViewModel {
    eventsForBookingPrediction: Array<string>; //handled
    eventsForAverageAndConsumerPrediction: Array<string>; //hanlded
    selectedEvents: Array<Event>; //handled
    predictionCreated: boolean; //partially handled
    predictionStatus?: PredictionStatus; //partially handled
    actualAndAverageKpi: Array<ComparedKpi>;
    consumerPredictionIndex: Array<ComparedKpi>;
    warningMessages: Array<string>; //handled
    predictedConsumerFinalEntries?: number;
    predictedConsumerLowerBoundFinalEntries?: number;
    predictedConsumerUpperBoundFinalEntries?: number;
    averageBookedPercentage?: number;
    actualBookedPercentage?: number;
    differencePercentage?: number;
    predictionBookingFinalEntries?: number;
    totalNights?: number;
    players?: number;
    entriesCount?: number;
    estimatedEntries?: number;
    kpiDetails: KpiDetails
}

export interface ComparedKpi {
    date: string;
    value: number;
    comparedValue: number;
}


export interface KpiDetails {
    eventId: string;
    totalExpectedGuests: number;
    totalEstimatedSoldNights: number;
    totalEstimatedGuests: number;
    totalGuests: number;
    totalBookedNights: number;
    totalCollectedNights: number;
    totalEstimatedReservations: number;
    totalBookings: number;
    totalGuestBookings: number;
    totalGuestNights: number;
    totalSoldGuestBookings: number;
    totalSoldBookings: number;
    totalGuestOffered: number;
    totalSoldGuestOffered: number;
    totalSoldPaingGuests: number;
    totalCollectedOfferedNights: number;
    hotelDetails: Array<HotelKpiDetails>;
}

export interface HotelKpiDetails {
    id: string;
    name: string;
    roomDetails: Array<RoomKpiDetails>;
}

export interface RoomKpiDetails {
    id: string;
    type: string;
    isPaid: boolean;
    guests: number;
    bookings: number;
    nights: number;
    expectedGuests: number;
    customerType: string;
}

export enum PredictionStatus {
    Calculating = "Calculating",
    CalculatingTimeSeries = "CalculatingTimeSeries",
    Error = "Error",
    Completed = "Completed",
    PredictError = "PredictError"
}