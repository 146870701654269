import * as React from "react";
import { UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";
import { Payment } from "../../../../store/payments/payments/PaymentEntities";
import { PaymentMethod } from "../../../../store/payments/paymentmethods/PaymentMethodEntities";
import { BaseList } from '../../../base/baseList';
import _ from "underscore";
import moment from "moment";
import { guid, formatToLocaleDate } from '../../../../utils/commons';
import { ConfirmationModal } from '../../../base/confirmationmodal';

export interface ReservationPaymentListProps {
    payments: Array<Payment>;
    methods: Array<PaymentMethod>;
}

export interface ReservationPaymentListCallbacks {
    onDelete: (index: number) => void;
}

interface ReservationPaymentListState {
    paymentToDelete?: number;
}

export class ReservationPaymentList extends React.Component<ReservationPaymentListProps & ReservationPaymentListCallbacks, ReservationPaymentListState>{
    private modalRef: React.RefObject<ConfirmationModal>;
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.state = {
            paymentToDelete: null
        }
    }

    private startDelete(index: number) {
        this.setState({ paymentToDelete: index });
        this.modalRef.current.toogleModal();
    }

    private confirmDelete() {
        this.props.onDelete(this.state.paymentToDelete);
        this.modalRef.current.toogleModal();
    }

    private resetState() {
        this.setState({ paymentToDelete: null });
    }

    private getRows(list: Array<Payment>): JSX.Element[] {

        let elements = list.map((element, index) => {

            let baseStyle = { cursor: "pointer" };

            let paymentDate = "-";
            if (element.paymentDate) {
                paymentDate = formatToLocaleDate(element.paymentDate);
            }

            const paymentMethod = _.find(this.props.methods, (method) => method.id == element.methodId);
            const notesStyle = element.notes ? "text-primary" : "disable-events";
            const itemKey = guid();

            return (
                <tr key={itemKey}>
                    <td>
                        {paymentDate}
                    </td>
                    <td>
                        {paymentMethod ? paymentMethod.method : "-"}
                    </td>
                    <td>
                        {`${element.amount} €`}
                    </td>
                    <td style={baseStyle}>
                        <div className={notesStyle} id={`popover-notes${itemKey}`}>
                            <i className={`icon fas fa-info-circle fa-1x`} />
                        </div>
                        <UncontrolledPopover className="bg-dark text-eurorounders" trigger="legacy" placement="top" target={`popover-notes${itemKey}`}>
                            <PopoverHeader className="bg-dark text-eurorounders">Note:</PopoverHeader>
                            <PopoverBody className="bg-dark text-eurorounders">
                                <div>
                                    {element.notes}
                                </div>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </td>
                    <td style={baseStyle} onClick={() => this.startDelete(index)}>
                        <div>
                            <i className={`icon fas fa-trash fa-1x`} />
                        </div>
                    </td>
                </tr>
            )
        });

        return elements;

    }

    private getHead(): JSX.Element {

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>Data</th>
                    <th>Metodo</th>
                    <th>Importo</th>
                    <th>Note</th>
                    <th>Elimina</th>
                </tr>
            </thead>
        );

    }

    render() {

        if (!this.props.payments || this.props.payments.length == 0)
            return (<React.Fragment />);

        return (
            <React.Fragment>
                <BaseList hasNextPage={false} list={this.props.payments} active={false} onEndOfList={() => { }} getElements={(items: Array<Payment>) => { return this.getRows(items) }} getHead={this.getHead} />
                <ConfirmationModal text="Eliminare Pagamento?" onConfirm={() => this.confirmDelete()} ref={this.modalRef} onClosed={() => this.resetState()} />
            </React.Fragment>
        )
    }

}