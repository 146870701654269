import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router"
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/communications/CommunicationState';
import { MessageCreation, MessageCreationCallbacks, MessageCreationProps } from '../pages/messagecreation';
import { actionCreator as actionCreatorUI } from '../../../store/uiproperties/UIState';


const mapStateToProsp = (state: ApplicationState): MessageCreationProps => {

    return {
        locationName: state.communications.runningCommunication.locationName,
        message: state.communications.runningCommunication.message,
        moduleLoaded: state.communications.moduleLoaded,
        messageSentId: state.communications.runningCommunication.messageSentId,
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name.toUpperCase()}` : ''

    }
}

const mapDispatchToProps = (dispatch) => (dispatch): MessageCreationCallbacks => {
    return ({
        onCreate: (locationId: string) => dispatch(actionsMiddleware.onCreate(locationId)),
        onEdit: (messageId: string) => dispatch(actionsMiddleware.getSingleCommunication(messageId)),
        onGenerateMessage: () => {
            dispatch(actionsMiddleware.generateMessage());
            dispatch(actionCreatorUI.setChangeInProgress(true));
        },
        onSubmit: () => dispatch(actionsMiddleware.saveCommunication())
    });
}

export const MessageCreationPage = withRouter(connect<MessageCreationProps, MessageCreationCallbacks>(mapStateToProsp, mapDispatchToProps)(MessageCreation));