import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import HotelsPriceList, { HotelsPriceListProps } from '../basecomponents/hotelspricelist';

const mapStateToProsp = (state: ApplicationState): HotelsPriceListProps => {

    return {
        list: state.pricings.list
    }
}


export const ConnectedHotelPriceList = connect<HotelsPriceListProps>(mapStateToProsp)(HotelsPriceList);