import React from 'react';
import QrReader from 'react-qr-reader';
import { ApplicationState } from "../../store/index";
import { connect } from "react-redux";
import { actionCreator } from '../../store/uiproperties/UIState';
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Col } from 'reactstrap';
import { MenuItem } from '../menu/menuitem';
import { gpDecoder, GpModel } from '../../utils/commons';

interface ErQrReaderBaseProps {
    isOpen: boolean;
}

interface ErQrReaderBaseCallbacks {
    onError: () => void;
    onScan: (data: string) => void;
    closeQrReader: () => void;
}

class ErQrReaderBase extends React.Component<ErQrReaderBaseProps & ErQrReaderBaseCallbacks> {
    constructor(props) {
        super(props);
    }

    private onScan(data: string) {
        if (data) {
            this.props.onScan(data);
            this.props.closeQrReader();
        }
    }

    render() {
        if (!this.props.isOpen)
            return (<React.Fragment />);

        return (
            <React.Fragment>
                <Modal className="bg-dark" isOpen={this.props.isOpen} toggle={() => this.props.closeQrReader()} backdrop="static">
                    <ModalHeader className="text-eurorounders bg-dark text-uppercase" toggle={() => this.props.closeQrReader()}>QR Reader</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders modal-header-button-box">
                        <Row>
                            <Col lg="12" sm="12" xs="12" className="d-flex align-items-center justify-content-center">
                                <div style={{ minHeight: "300px", minWidth: "300px" }}>
                                    <QrReader
                                        delay={250}
                                        onError={this.props.onError}
                                        onScan={(data: string) => this.onScan(data)}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </Col>
                            <Col lg="12" sm="12" xs="12" className="text-center">
                                QR code non scannerizzato
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon="times" label="Chiudi" onClick={() => this.props.closeQrReader()} />
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

export interface ErQrReaderProps {
    onScan: (data: string) => void;
}

const mapStateToProps = (state: ApplicationState): ErQrReaderBaseProps => {

    let propsToReturn: ErQrReaderBaseProps = {
        isOpen: state.uiProperties.isQrReaderOpen
    };

    return propsToReturn;

}

const mapDispatchToProps = (dispatch, ownProps: ErQrReaderProps) => dispatch => {
    return ({
        onError: () => {
            dispatch(actionCreator.toogleQrReader());
            dispatch(actionCreator.setErrorMessages(["Crash durante la lettura del QR code"]));
        },
        onScan: (data: string) => ownProps.onScan(data),
        closeQrReader: () => dispatch(actionCreator.toogleQrReader())
    }) as ErQrReaderBaseCallbacks
}

export const ErQrReader = connect<ErQrReaderBaseProps, ErQrReaderBaseCallbacks, ErQrReaderProps>(mapStateToProps, mapDispatchToProps)(ErQrReaderBase);

export interface ErGpReaderProps {
    onScan: (data: GpModel) => void;
}

const mapDispatchToGPProps = (dispatch, ownProps: ErGpReaderProps) => dispatch => {
    return ({
        onScan: (data: string) => {
            let gpModel: GpModel;
            try {
                gpModel = gpDecoder(data);
            }
            catch (e) {
                dispatch(actionCreator.toogleQrReader());
                dispatch(actionCreator.setErrorMessages(["Errore nella lettura del QR Code"]));
                console.log(e);
                return;
            }
            ownProps.onScan(gpModel);
        },
        closeQrReader: () => dispatch(actionCreator.toogleQrReader()),
        onError: () => {
            dispatch(actionCreator.toogleQrReader());
            dispatch(actionCreator.setErrorMessages(["Crash durante la lettura del QR Code"]));
        }
    }) as ErQrReaderBaseCallbacks
}

export const GPReader = connect<ErQrReaderBaseProps, ErQrReaderBaseCallbacks, ErGpReaderProps>(mapStateToProps, mapDispatchToGPProps)(ErQrReaderBase);