import * as React from "react";
import { Row, Col } from "reactstrap";
import { List } from '../../../base/list';
import _ from "underscore";

import './list.css';

export class MovementsListHeader extends React.Component{


    private getHead(): JSX.Element {
        return (
            <Row key="list-header" className="text-eurorounders list-row header">
                <Col lg="2" sm="2" xs="3">
                    <div className="movements-table-cell">
                        <div>Data</div>
                    </div>
                </Col>
                <Col lg="3" sm="3" xs="3" >
                    <div className="movements-table-cell">
                        <div>Oggetto</div>
                    </div>
                </Col>
                <Col lg="3" sm="3" xs="2" >
                    <div className="movements-table-cell">
                        <div>Metodo</div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="2">
                    <div className="movements-table-cell">
                        <div>Importo</div>
                    </div>
                </Col>
                <Col lg="1" sm="1" xs="1" className="d-none">
                    <div className="movements-table-cell">
                        <div>Info</div>
                    </div>
                </Col>
                <Col lg="1" sm="1" xs="1">
                    <div className="movements-table-cell">
                        <div>Elimina</div>
                    </div>
                </Col>
            </Row>
        );

    }



    render() {

        return (
            <React.Fragment>
                <List justHead getHead={() => { return this.getHead() }} />
            </React.Fragment>
        )
    }
}