import * as React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { ConnectedRoomAvailabilityForm } from '../connected/connected.roomavailabilityform';
import { RoomPricing } from '../../../store/pricings/PricingEntities';
import { MenuItem } from "../../menu/menuitem";

export interface ModalRoomAvailabilityProps {
    roomPricing?: RoomPricing;
    isOpen: boolean;
}


export interface ModalRoomAvailabilityCallbacks {
    onSubmit: () => void;
    toogleModal: () => void;
}

export class ModalRoomAvailability extends React.Component<ModalRoomAvailabilityProps & ModalRoomAvailabilityCallbacks>{
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.roomPricing === undefined || this.props.roomPricing == null) {
            return (
                <React.Fragment></React.Fragment>
            );
        }


        return (
            <Modal className="bg-dark modal-lg" isOpen={this.props.isOpen} toggle={() => this.props.toogleModal()} backdrop="static">
                <ModalHeader className="text-eurorounders bg-dark" toggle={() => this.props.toogleModal()}>{this.props.roomPricing.roomType.type}</ModalHeader>
                <ModalBody className="bg-dark text-eurorounders modal-header-button-box">
                    <ConnectedRoomAvailabilityForm />
                </ModalBody>
                <ModalFooter>
                    <MenuItem isSmall={true} isButton={true} icon="check" label="Ok" onClick={() => this.props.onSubmit()} />
                    <MenuItem isSmall={true} isButton={true} icon="times" label="Annulla" onClick={() => this.props.toogleModal()} />
                </ModalFooter>
            </Modal>
            );
    }
}