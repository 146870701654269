import * as React from "react";
import { Input } from "reactstrap";

import './decimalinput.css';

interface PhoneNumberInputProps {
    value?: number | string | '';
    onChane: (value?: string) => void;
    className?: string;
    readOnly?: boolean;
    alignLeft?: boolean;
}

export default class PhoneNumberInput extends React.Component<PhoneNumberInputProps> {
    constructor(props) {
        super(props);
    }

    private checkChange(value: string) {
        try {

            if (value === undefined || value == null || value == '') {
                this.props.onChane(value);
                return;
            }

            let phoneNumberRegex = /^[0-9 ]+$/;

            if (!phoneNumberRegex.test(value))
                return;

            this.props.onChane(value);
        }
        catch { }
    }

    render() {
        let valueToShow = this.props.value === undefined || this.props.value == null ? '' : this.props.value;
        let className = ""

        if (this.props.className !== undefined && this.props.className != null)
            className = this.props.className;

        let readOnly = this.props.readOnly == true;

        let style = {}

        if (this.props.alignLeft == true) {
            style = { textAlign: "left" };
        }

        return (
            <React.Fragment>
                <Input style={style} readOnly={readOnly} className={`bg-dark text-eurorounders decimal-input ${className}`} type="text" value={valueToShow} onChange={(e) => this.checkChange(e.target.value)} />
            </React.Fragment>
        )
    }

}