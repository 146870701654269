import * as React from "react";
import { MenuItemProps } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { ConnectedCommunicationsList } from '../connected/connected.list';
import { ConnectedCommunicationsSentList } from '../connected/connected.sentlist';

import './listpage.css';
import { StickyBodyHeader } from "../../base/stickybodyheader";

export interface CommunicationsPageListProps {
    moduleLoaded: boolean;
    eventFullName: string;
}

export interface CommunicationsPageListCallbacks {
    onLoad: (eventId: string) => void;
}

enum TabActive {
    ToSend = "ToSend",
    Sent = "Sent"
}

interface CommunicationsPageListState {
    activeTab: TabActive;
}

export class CommunicationsPageList extends React.Component<CommunicationsPageListProps & CommunicationsPageListCallbacks, CommunicationsPageListState> {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: TabActive.ToSend
        }
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let eventId = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                eventId = (this.props as any).match.params.eventId;
            this.props.onLoad(eventId);
        }
    }

    private toogleTab(active: TabActive) {
        this.setState({ activeTab: active })
    }


    render() {

        let toSend = null;
        let sentList = null;

        if (this.props.moduleLoaded == true) {
            toSend = <ConnectedCommunicationsList />;
            sentList = <ConnectedCommunicationsSentList />;
        }

        let menuItems = [
            {
                icon: "ellipsis-h",
                label: "Evento",
                isButton: false,
                isSmall: true,
                path: `/events/${(this.props as any).match.params.eventId}/menu`
            } as MenuItemProps
        ] as Array<MenuItemProps>;

        return (
            <React.Fragment>
                <StickyBodyHeader>
                    <BodyHeader subTitle={this.props.eventFullName} bottomSpace title={`Messaggi Hotel`} menuItems={menuItems} />
                    <Nav tabs className="text-eurorounders">
                        <NavItem>
                            <NavLink
                                className={this.state.activeTab == TabActive.ToSend ? "active" : ""}
                                onClick={() => { this.toogleTab(TabActive.ToSend); }}
                            >
                                Da inviare
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={this.state.activeTab == TabActive.Sent ? "active" : ""}
                                onClick={() => { this.toogleTab(TabActive.Sent); }}
                            >
                                Inviate
                            </NavLink>
                        </NavItem>
                    </Nav>
                </StickyBodyHeader>
                <TabContent activeTab={this.state.activeTab.toString()}>
                    <TabPane tabId={TabActive.ToSend.toString()}>
                        {toSend}
                    </TabPane>
                    <TabPane tabId={TabActive.Sent.toString()}>
                        {sentList}
                    </TabPane>
                </TabContent>
            </React.Fragment>
        )
    }
}