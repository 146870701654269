import * as React from "react";
import { Form, FormGroup, Button, Input, CustomInput, Row, Col, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { LocationGroup } from '../../../../store/locations/groups/LocationGroupEntities';
import _ from "underscore";
import { BodyHeader } from '../../../base/bodyheader';
import { MenuItemProps, MenuItem } from '../../../menu/menuitem';
import { ConfirmationModal } from '../../../base/confirmationmodal';
import { UnsavedChanges } from "../../../base/unsavedchanges";

export interface LocationGroupEditProps {
    group: LocationGroup;
    pageLoaded: boolean;
    onLoad?: (id: string) => void;
    onSubmit?: () => void;
    onChange?: (propertyName: string, value: any) => void;
    onDelete?: (id: string) => void;
}



export class LocationGroupEditPage extends React.Component<LocationGroupEditProps>{
    private modalRef: React.RefObject<ConfirmationModal>;
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }

    componentDidMount() {
        let id = (this.props as any).match.params.id;
        this.props.onLoad(id);
    }

    private delete() {
        this.modalRef.current.toogleModal();
        if (this.props.onDelete)
            this.props.onDelete(this.props.group.id);
    }

    private getLocationsList(): JSX.Element[] {

        if (!this.props.group)
            return ([<React.Fragment />]);

        return this.props.group.locations.map(location => {
            return (<li key={location.id}>{location.name}</li>);
        })
    }

    render() {

        if (this.props.pageLoaded == false) {
            return (
                <React.Fragment>
                </React.Fragment>
            )
        }


        let menuItems = [];

        if (this.props.group.id !== undefined && this.props.group.id != null && this.props.group.id != '') {
            menuItems = [
                {
                    icon: "trash",
                    label: "elimina",
                    isButton: true,
                    isDisabled: false,
                    onClick: () => { this.modalRef.current.toogleModal() }
                }
            ] as Array<MenuItemProps>
        }

        menuItems.push({
            icon: "list",
            label: "lista",
            isButton: false,
            isSmall: true,
            path: "/locations/groups"
        } as MenuItemProps);

        let hotels = this.getLocationsList();

        return (
            <React.Fragment>
                <UnsavedChanges confirmAction={this.props.onSubmit} />
                <BodyHeader title={this.props.group.id !== undefined && this.props.group.id != null && this.props.group.id != '' ? "Modifica Gruppo" : "Crea Gruppo"} menuItems={menuItems} />
                <Form className="text-eurorounders" >
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input className="bg-dark text-eurorounders" type="text" value={this.props.group.name || ''} onChange={(e) => { this.props.onChange("name", e.target.value) }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div className="text-eurorounders">Lista hotels del gruppo:</div>
                            <ul className="text-eurorounders">
                                {hotels}
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <MenuItem isSmall={true} isButton={true} icon="save" label="salva" onClick={() => this.props.onSubmit()} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <ConfirmationModal text="Eliminare gruppo?" onConfirm={() => this.delete()} ref={this.modalRef} />
            </React.Fragment>
        )
    }
}
