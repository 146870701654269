import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware, actionCreator } from '../../../store/customers/CustomerState';
import { CustomerSelectionCallbacks, CustomerSelectionModal, CustomerSelectionModalProps } from '../selectionmodal/selectionmodal';
import $ from "jquery";
import { actionCreator as actionCreatorUI } from '../../../store/uiproperties/UIState';
import { GpModel } from "../../../utils/commons";

export interface ConnectedSelectionModaltProps {
    onConfirm?: () => void;
}

const mapStateToProsp = (state: ApplicationState, ownProps: ConnectedSelectionModaltProps): CustomerSelectionModalProps => {

    return {
        isModalOpen: state.customers.selectionModalOpen,
        selectedCustomers: state.customers.selectedCustomers
    }
}

const mapDispatchToProps = (dispatch, ownProps: ConnectedSelectionModaltProps) => (dispatch): CustomerSelectionCallbacks => {
    return ({
        onConfirm: () => dispatch(ownProps.onConfirm()),
        onEdit: (id) => {
            let deferred = $.Deferred();
            dispatch(actionsMiddleware.loadCustomer(id)).then(x => {
                if (x !== undefined && x != null)
                    deferred.resolve(true);
                else
                    deferred.resolve(false);
            });
            return deferred.promise();
        },
        onSave: () => {
            dispatch(actionsMiddleware.save()).then(customer => {
                if (customer !== undefined && customer != null) {
                    dispatch(actionsMiddleware.addSelectedCustomer(customer));
                }
                dispatch(actionCreatorUI.setChangeInProgress(true));
            });
        },
        onSelection: (id) => actionsMiddleware.setSelectedCustomer(id),
        toogleModal: () => dispatch(actionCreator.setSelectionModalOpen()),
        onViewList: (): JQueryPromise<any> => {
            let deferred = $.Deferred();
            dispatch(actionCreator.setList([]))
            dispatch(actionsMiddleware.loadSearchResult(true)).then(res => {
                deferred.resolve(res.success);
            });
            return deferred.promise();
        },
        onRemoveSelected: (id: string) => dispatch(actionsMiddleware.removeCustomerSelected(id)),
        toogleQRReader: () => dispatch(actionCreatorUI.toogleQrReader()),
        onScan: (data: GpModel) => dispatch(actionsMiddleware.setGPData(data))
    });
}

export const ConnectedSelectionModal = connect<CustomerSelectionModalProps, CustomerSelectionCallbacks, ConnectedSelectionModaltProps>(mapStateToProsp, mapDispatchToProps)(CustomerSelectionModal);