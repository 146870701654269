import * as React from "react";
import { UncontrolledPopover, PopoverHeader, PopoverBody, Row, Col } from "reactstrap";
import { Reservation, ReservationStatus } from "../../../store/reservations/ReservationEntities";
import { AgentRole } from "../../../store/agents/AgentEntities";
import { KeyDesc } from "../../../store/shared/KeyDesc";
import { List } from '../../base/list';
import _ from "underscore";
import moment from "moment";

import './list.css';
import GoToTop from "../../base/gototop";

export interface ReservationsListProps {
    list: Array<Reservation>;
    statusDesc: Array<KeyDesc<string>>;
    typeDesc: Array<KeyDesc<string>>;
    agentRoles: Array<AgentRole>;
    hasNextPage: boolean;
    startLoading?: boolean;
    lastClieckedId?: string;
    lastHighlightedElement?: string
}

export interface ReservationsListCallbacks {
    onRowClick: (id: string) => void;
    loadNewPage: () => void;
    resetLastClicledElement: () => void;
    onUnload: () => void;
}

export class ReservationsList extends React.Component<ReservationsListProps & ReservationsListCallbacks>{


    constructor(props) {
        super(props);
    }

    private checkDoScroll() {
        if (_.any(this.props.list, (elm) => elm.id == this.props.lastClieckedId)) {
            document.getElementById(this.props.lastClieckedId).scrollIntoView(false);
            this.props.resetLastClicledElement();
        }
    }

    componentDidUpdate() {
        this.checkDoScroll();
    }

    private getRows(list: Array<Reservation>): JSX.Element[] {

        let renderList = list.map(model => {

            let status = _.find(this.props.statusDesc, (td) => td.key == model.status);

            let statusDesc = '-';

            if (status !== undefined && status != null)
                statusDesc = status.desc;

            let type = _.find(this.props.typeDesc, (td) => td.key == model.type);

            let typeDesc = '-';

            if (type !== undefined && type != null)
                typeDesc = type.desc;

            let checkIn = "-";
            if (model.checkIn !== undefined && model.checkIn != null) {
                let momentDate = moment(model.checkIn);
                if (momentDate !== undefined && momentDate != null) {
                    checkIn = momentDate.format("L");
                }
            }

            let checkOut = "-";
            if (model.checkOut !== undefined && model.checkOut != null) {
                let momentDate = moment(model.checkOut);
                if (momentDate !== undefined && momentDate != null) {
                    checkOut = momentDate.format("L");
                }
            }

            let agents: JSX.Element[] = [];

            this.props.agentRoles.forEach(ar => {

                let agent = _.find(model.profiles, (agent) => agent.agentRole.id == ar.id);

                if (agent !== undefined && agent != null) {
                    agents.push(<div key={agent.id}>{agent.firstName} {agent.lastName}</div>);
                } else {
                    agents.push(<div key={ar.id}>-</div>);

                }

            });

            const agentsList: JSX.Element[] = this.props.agentRoles.map(ar => {
                let agent = _.find(model.profiles, (agent) => agent.agentRole.id == ar.id);
                if (agent)
                    return (<li key={agent.id}>{agent.firstName} {agent.lastName}</li>);
            });

            let notesColor = "";

            let hasNotes: boolean = model.notes !== undefined && model.notes != null && model.notes != '';

            if (hasNotes == true)
                notesColor = "text-primary";

            if (model.status == ReservationStatus.Settled && model.toPay > 0)
                notesColor = "text-danger";

            if (model.toPay < 0)
                notesColor = "text-warning";

            let statusColor: string;
            let checkInColor: string;
            let rowColor: string = "";

            switch (model.status) {
                case ReservationStatus.Settled:
                    statusColor = "text-success";
                    break;
                case ReservationStatus.Canceled:
                    statusColor = "text-danger";
                    break;
                default:
                    statusColor = "text-warning"
                    break;
            }

            switch (model.checkInDone) {
                case true:
                    checkInColor = "text-success";
                    break;
                default:
                    checkInColor = "text-warning"
                    break;
            }

            if (model.checkInDone && model.status != ReservationStatus.Settled && model.status != ReservationStatus.Canceled) {
                rowColor = "text-danger";
            }

            const guestsList: JSX.Element[] = model.customers.map(c => {
                return (<li key={c.id}>{c.lastName} {c.firstName}</li>);
            });

            let notes: JSX.Element = (<React.Fragment />);

            if (model.notes) {
                notes = (
                    <React.Fragment>
                        <div>Note:</div>
                        <div>
                            {model.notes}
                        </div>
                    </React.Fragment>
                )
            }

            let clickedRowAnimation = this.props.lastHighlightedElement == model.id ? "clicked-row" : "";
            const webPayment = model.isWebReservation == true ? "Si" : "No";

            let toPayDesc = "Da pagare";
            let toPay = model.toPay;
            if (model.toPay < 0) {
                toPayDesc = "Da rendere";
                toPay = toPay * -1;
            }

            return (
                <Row id={model.id} key={model.id} style={{ cursor: "pointer" }} className={`list-row ${rowColor} ${clickedRowAnimation}`}>
                    <Col lg="2" sm="2" xs="3" onClick={() => this.props.onRowClick(model.id)}>
                        <div className="reservation-table-cell">

                            <div>{model.progressiveNumber ?? '-'}</div>
                            <div>{model.customers[0] !== undefined && model.customers[0] != null ? model.customers[0].firstName : "-"}</div>
                            <div>{model.customers[0] !== undefined && model.customers[0] != null ? model.customers[0].lastName : "-"}</div>
                        </div>
                    </Col>
                    <Col lg="2" sm="2" xs="3" onClick={() => this.props.onRowClick(model.id)}>
                        <div className="reservation-table-cell">
                            <div>{checkIn}</div>
                            <div>{checkOut}</div>
                        </div>
                    </Col>
                    <Col lg="2" sm="2" xs="4" onClick={() => this.props.onRowClick(model.id)}>
                        <div className="reservation-table-cell">
                            <div>{model.location !== undefined && model.location != null ? model.location.name : "-"}</div>
                            <div>{model.roomType !== undefined && model.roomType != null ? model.roomType.type : "-"}</div>
                        </div>
                    </Col>
                    <Col lg="2" sm="2" xs="2" onClick={() => this.props.onRowClick(model.id)} className="d-none d-lg-block">
                        <div className="reservation-table-cell">
                            {agents}
                        </div>
                    </Col>
                    <Col lg="1" md="2" sm="2" xs="2" onClick={() => this.props.onRowClick(model.id)} className="d-none d-sm-block">
                        <div className="reservation-table-cell">
                            <div>{typeDesc}</div>
                            {model.totalPay} €
                        </div>
                    </Col>
                    <Col lg="2" md="3" sm="3" xs="2" onClick={() => this.props.onRowClick(model.id)} className="d-none d-sm-block">
                        <div className="reservation-table-cell">
                            <div className={rowColor || statusColor}>
                                {statusDesc}
                            </div>
                            <div className={rowColor || checkInColor}>
                                <div>{model.checkInDone == true ? "Si" : "No"}</div>
                            </div>
                        </div>
                    </Col>
                    <Col lg="1" sm="1" xs="2">
                        <div className={`reservation-table-cell ${notesColor}`} id={`popover-notes${model.id}`}>
                            <i className={`icon fas fa-info-circle fa-1x`} />
                        </div>
                        <UncontrolledPopover className="bg-dark text-eurorounders" trigger="legacy" placement="top" target={`popover-notes${model.id}`}>
                            <PopoverHeader className="bg-dark text-eurorounders">Info aggiuntive</PopoverHeader>
                            <PopoverBody className="bg-dark text-eurorounders">
                                <div>
                                    {notes}
                                    <div>{toPayDesc}: {toPay} €</div>
                                    <div>Ospiti:</div>
                                    <ul>
                                        {guestsList}
                                    </ul>
                                    <div className="d-lg-none d-xl-none d-md-block">
                                        <div>Agenti:</div>
                                        <ul>
                                            {agentsList}
                                        </ul>
                                    </div>
                                    <div className="d-lg-none d-xl-none d-md-none d-sm-none d-xs-block">
                                        <div>
                                            <div className="d-inline-block" style={{ paddingRight: "5px" }}>Tipo:</div><div className="d-inline-block font-weight-bold">{typeDesc}</div>
                                        </div>
                                        <div>
                                            <div className="d-inline-block" style={{ paddingRight: "5px" }}>Saldo:</div><div className="d-inline-block font-weight-bold">{model.totalPay} €</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-lg-none d-xl-none d-md-none d-sm-none d-xs-block">
                                            <div className="d-inline-block" style={{ paddingRight: "5px" }}>Stato:</div>
                                            <div className={`${rowColor || statusColor} d-inline-block font-weight-bold`}>
                                                {statusDesc}
                                            </div>
                                        </div>
                                        <div className="d-lg-none d-xl-none d-md-none d-sm-none d-xs-block">
                                            <div className="d-inline-block" style={{ paddingRight: "5px" }}>Check in:</div>
                                            <div className={`${rowColor || statusColor} d-inline-block font-weight-bold`}>
                                                <div>{model.checkInDone == true ? "Si" : "No"}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-inline-block" style={{ paddingRight: "5px" }}>Pagamento web:</div><div className="d-inline-block font-weight-bold">{webPayment}</div>
                                </div>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </Col>
                </Row>
            )
        });

        return renderList;

    }

    render() {

        return (
            <React.Fragment>
                <List onUnload={this.props.onUnload} hasNextPage={this.props.hasNextPage} list={this.props.list} active={this.props.startLoading} onEndOfList={this.props.loadNewPage} getElements={(list: Array<Reservation>) => { return this.getRows(list) }} />
                <GoToTop />
            </React.Fragment>
        )
    }
}