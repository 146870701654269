import * as React from "react";
import { Card, CardBody, CardTitle, CardSubtitle, Table } from "reactstrap";
import { PeriodPricing, AlertAvailabilityType } from '../../../store/pricings/PricingEntities';
import CollapsibleContent from '../../base/collapsiblecontent'
import { formatToLocaleDate } from '../../../utils/commons';
import PriceGrid from './hotelpricegrid';
import _ from "underscore";

export interface HotelsPriceListProps {
    list?: Array<PeriodPricing>
}

export default class HotelsPriceList extends React.Component<HotelsPriceListProps>{
    constructor(props) {
        super(props);
    }

    private getHotelList(): JSX.Element[] {

        let elements = [];

        let alreadyProcessedLocation: Array<string> = [];

        this.props.list.forEach(toRender => {

            if (_.some(alreadyProcessedLocation, (processed) => toRender.location.id == processed) == false) {
                alreadyProcessedLocation.push(toRender.location.id);
                let siblings = _.filter(this.props.list, (el) => el.location.id == toRender.location.id);

                let icon = ""
                let iconColor = "";
                let alertHotel = _.some(siblings, (check) => _.some(check.roomPricings, (room) => room.alertAvailability == AlertAvailabilityType.Error));

                if (alertHotel == true) {
                    icon = "exclamation";
                    iconColor = "text-danger";
                } else {
                    let warningHotel = _.some(siblings, (check) => _.some(check.roomPricings, (room) => room.alertAvailability == AlertAvailabilityType.Warning));

                    if (warningHotel == true) {
                        icon = "exclamation-triangle";
                        iconColor = "text-warning";
                    }

                }

                let list = siblings.map(period => {

                    return (<PriceGrid periodPricing={period} key={period.id} />)
                });

                elements.push(
                    <CollapsibleContent colorIcon={iconColor} titleIcon={icon} titleBig={true} className="bg-dark text-eurorounders text-capitalize" title={toRender.location.name} key={toRender.location.id}>
                        {list}
                    </CollapsibleContent>
                )
            }
        });

        return elements;
    }

    render() {

        if (this.props.list === undefined || this.props.list == null) {
            return (<React.Fragment></React.Fragment>);
        }

        let hotelList = this.getHotelList();

        return (
            <React.Fragment>
                {hotelList}
            </React.Fragment>
        );

    }
}