import * as React from "react";
import { Form, FormGroup, Row, Col, Input, Label, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledPopover, PopoverHeader, PopoverBody, Button } from "reactstrap";
import { KeyDesc } from '../../../../store/shared/KeyDesc';
import DecimalInput from '../../../base/decimalinput';
import { ERDatePicker as DatePicker } from '../../../base/datepicker';
import BaseSelect from '../../../base/baseselect';
import _ from "underscore";
import { MenuItem } from '../../../menu/menuitem';
import { PaymentMethod } from "../../../../store/payments/paymentmethods/PaymentMethodEntities";
import { Payment } from "../../../../store/payments/payments/PaymentEntities";

export interface ReservationPaymentModalProps {
    model: Payment;
    methods: Array<PaymentMethod>;
    isOpen: boolean;
}

export interface ReservationPaymentModalCallbacks {
    onConfirm: () => void;
    close: () => void;
    onChange: (name: string, value: any) => void;
}

export class ReservationPaymentModal extends React.Component<ReservationPaymentModalProps & ReservationPaymentModalCallbacks>{
    constructor(props) {
        super(props);
    }

    render() {

        let modalForm = (<React.Fragment />);

        if (this.props.model) {
            modalForm = (
                <Form className="text-eurorounders">
                    <Row>
                        <Col lg="4" sm="4" xs="12">
                            <FormGroup>
                                <Label>Data</Label>
                                <DatePicker selectedDate={this.props.model.paymentDate} onChange={date => this.props.onChange("paymentDate", date)} />
                            </FormGroup>
                        </Col>
                        <Col lg="4" sm="4" xs="12">
                            <FormGroup>
                                <Label>Metodo</Label>
                                <BaseSelect
                                    descField="method"
                                    idField="id"
                                    options={this.props.methods}
                                    value={this.props.model.methodId}
                                    onChange={(value: any) => { this.props.onChange("methodId", value) }} />
                            </FormGroup>
                        </Col>
                        <Col lg="4" sm="4" xs="12">
                            <FormGroup>
                                <Label>Importo</Label>
                                <InputGroup>
                                    <DecimalInput allowNegative={false} onChane={(value: string) => this.props.onChange("amount", value)} value={this.props.model.amount} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" sm="12" xs="12">
                            <FormGroup>
                                <Label>Note</Label>
                                <Input className="bg-dark text-eurorounders" type="textarea" value={this.props.model.notes || ''} onChange={(e) => { this.props.onChange("notes", e.target.value) }} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            );
        }

        return (
            <React.Fragment>
                <Modal className="bg-dark modal-lg" isOpen={this.props.isOpen} toggle={() => this.props.close()} backdrop="static">
                    <ModalHeader className="text-eurorounders bg-dark text-uppercase" toggle={() => this.props.close()}>Registra Pagamento</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders modal-header-button-box">
                        {modalForm}
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon="check" label="Conferma" onClick={() => this.props.onConfirm()} />
                        <MenuItem isSmall={true} isButton={true} icon="times" label="Annulla" onClick={() => this.props.close()} />
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }

}