import { Address, Contacts } from '../shared/Anagraphic';

export interface Location extends Address, Contacts {
    id?: string;
    name?: string;
    info?: string;
    vat?: number;
    taxCode?: string;
    vatNumber?: string;
    roomTypeInformation: Array<RoomTypeInformation>;
    isSelected?: boolean;
    type?: LocationType;
    guestsForCommunications: boolean;
    locationGroupId?: string;
}

export interface RoomType {
    id: string;
    type: string;
    capacity: number;
}

export interface RoomTypeInformation extends RoomType {
    information?: string;
}

export enum LocationType {
    Hotel = "Hotel",
    BedAndBreakfast = "BedAndBreakfast",
    HotelAndCasino = "HotelAndCasino",
    Casino = "Casino"
}

export interface LocactionTypeDesc {
    type: LocationType;
    desc: string;
}