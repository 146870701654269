import { Action, Reducer } from 'redux';
import { ApplicationState } from '../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest, checkRefreshToken, SaveRequest, FetchListRequest } from '../../utils/ajaxutilities';
import { gpDecoder, GpModel, OrderDirection } from '../../utils/commons';
import dotProp from "dot-prop";
import { CoreResponse } from '../serverresponse/serverresponse';
import { PAGE_SIZE } from '../../utils/commons';
import { push } from 'connected-react-router';
import _ from "underscore";
import { Customer } from './CustomerEntities';
import $ from "jquery";
import { actionCreator as actionCreatorUi } from '../uiproperties/UIState';

const debouncedActions = (dispatch) => {
    dispatch(actionCreator.setList([]));
    dispatch(actionsMiddleware.loadSearchResult(false));
    dispatch(actionsMiddleware.getTotalCount());
}

const debounceSearch = _.debounce(debouncedActions, 500);

export interface CustomerState {
    list: Array<Customer>;
    selectedCustomers?: Array<Customer>;
    totalCount: number;
    runningCustomer?: Customer;
    moduleLoaded: boolean;
    searchCriteria: CustomerSearchCriteria;
    selectionModalOpen: boolean;
}

interface CustomerSearchCriteria {
    searchString: string;
    orderField: string;
    pageNumber: number;
    orderDirection: OrderDirection;
    hasNextPage: boolean;
}

let baseState: CustomerState = {
    list: [],
    totalCount: 0,
    moduleLoaded: false,
    runningCustomer: null,
    searchCriteria: {
        orderField: null,
        pageNumber: 0,
        searchString: null,
        orderDirection: OrderDirection.ASC,
        hasNextPage: false
    },
    selectedCustomers: [],
    selectionModalOpen: false
}

const getNewCustomer = (): Customer => ({

} as Customer);

const getBaseSearch = (): CustomerSearchCriteria => ({
    orderField: null,
    pageNumber: 0,
    searchString: null,
    orderDirection: OrderDirection.ASC,
    hasNextPage: false
});

const getBaseState = (): CustomerState => ({
    list: [],
    moduleLoaded: false,
    totalCount: 0,
    runningCustomer: null,
    searchCriteria: {
        orderField: null,
        pageNumber: 0,
        searchString: null,
        orderDirection: OrderDirection.ASC,
        hasNextPage: false
    },
    selectedCustomers: [],
    selectionModalOpen: false
});

type CustomerAction =
    SetRunningCustomer
    | SetList
    | AppendList
    | SetSearchCriteria
    | SetModuleLoaded
    | ResetState
    | SetTotalCount
    | UnloadRunning
    | SetSelectedCustomers
    | SetSelectionModalOpen;

enum TypeKeys {
    RESET_STATE = "CUSTOMERS_RESET_STATE",
    SET_RUNNING = "CUSTOMER_SET_RUNNING",
    UNLOAD_RUNNING = "CUSTOMER_UNLOAD_RUNNING",
    SET_LIST = "CUSTOMER_SET_LIST",
    APPEND_LIST = "CUSTOMER_APPEND_LIST",
    SET_SEARCH_CRITERIA = "CUSTOMER_SET_SEARCH_CRITERIA",
    MODULE_LOADED = "CUSTOMER_MODULE_LOADED",
    SET_TOTAL_COUNT = "CUSTOMER_SET_TOTAL_COUNT",
    SET_SELECTED_CUSTOMERS = "CUSTOMER_SET_SELECTED_CUSTOMERS",
    SET_SELECTION_MODAL_OPEN = "CUSTOMER_SET_SELECTION_MODAL_OPEN",
}

interface SetSelectionModalOpen extends Action {
    type: TypeKeys.SET_SELECTION_MODAL_OPEN
}

interface UnloadRunning extends Action {
    type: TypeKeys.UNLOAD_RUNNING
}

interface SetTotalCount extends Action {
    type: TypeKeys.SET_TOTAL_COUNT;
    count: number;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE;
}

interface SetRunningCustomer extends Action {
    type: TypeKeys.SET_RUNNING;
    customer: Customer;
}

interface SetList extends Action {
    type: TypeKeys.SET_LIST;
    list: Array<Customer>;
}

interface AppendList extends Action {
    type: TypeKeys.APPEND_LIST;
    list: Array<Customer>;
}

interface SetSearchCriteria extends Action {
    type: TypeKeys.SET_SEARCH_CRITERIA;
    searchCriteria: CustomerSearchCriteria;
}

interface SetModuleLoaded extends Action {
    type: TypeKeys.MODULE_LOADED;
    moduleLoaded: boolean;
}

interface SetSelectedCustomers extends Action {
    type: TypeKeys.SET_SELECTED_CUSTOMERS;
    customers: Array<Customer>;
}

export const actionCreator = {
    unloadRunning: (): UnloadRunning => ({
        type: TypeKeys.UNLOAD_RUNNING
    }),
    setTotalCount: (count: number): SetTotalCount => ({
        type: TypeKeys.SET_TOTAL_COUNT,
        count: count
    }),
    setList: (customers: Array<Customer>): SetList => ({
        type: TypeKeys.SET_LIST,
        list: customers
    }),
    setRunningCustomer: (customer: Customer): SetRunningCustomer => ({
        type: TypeKeys.SET_RUNNING,
        customer: customer
    }),
    appendList: (customers: Array<Customer>): AppendList => ({
        type: TypeKeys.APPEND_LIST,
        list: customers
    }),
    setSearchCriteria: (searchCriteria: CustomerSearchCriteria): SetSearchCriteria => ({
        type: TypeKeys.SET_SEARCH_CRITERIA,
        searchCriteria: searchCriteria
    }),
    setModuleLoaded: (loaded: boolean): SetModuleLoaded => ({
        type: TypeKeys.MODULE_LOADED,
        moduleLoaded: loaded
    }),
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    setSelectedCustomers: (customerList: Array<Customer>): SetSelectedCustomers => ({
        type: TypeKeys.SET_SELECTED_CUSTOMERS,
        customers: customerList
    }),
    setSelectionModalOpen: (): SetSelectionModalOpen => ({
        type: TypeKeys.SET_SELECTION_MODAL_OPEN
    })
}

export const actionsMiddleware = {

    loadInitialState: (selectedCustomers?: Array<Customer>) => (dispatch, getState): JQueryPromise<any> => {
        let deferred = $.Deferred();
        abortAllRequest();
        dispatch(actionCreator.setModuleLoaded(false));
        let appState = (getState() as ApplicationState);
        let preservePages: boolean = true;

        dispatch(actionCreator.resetState());
        if (selectedCustomers !== undefined && selectedCustomers != null)
            dispatch(actionCreator.setSelectedCustomers(selectedCustomers));

        if (appState.uiProperties.previousPath === undefined || appState.uiProperties.previousPath == null || !(appState.uiProperties.previousPath.startsWith("/customers/edit") || appState.uiProperties.previousPath.startsWith("/customers/create"))) {
            dispatch(actionCreator.setSearchCriteria(getBaseSearch()));
            preservePages = false;
        }

        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false) {
                deferred.resolve();
                return deferred.promise();
            }

            $.when(dispatch(actionsMiddleware.loadSearchResult(preservePages)), dispatch(actionsMiddleware.getTotalCount())).then((resp, count) => {
                let respCast = (resp as unknown as CoreResponse<any>);
                if (respCast.success == true)
                    dispatch(actionCreator.setModuleLoaded(true));
                deferred.resolve();
            });
        });

        return deferred.promise();
    },
    getTotalCount: () => (dispatch, getState: () => ApplicationState): JQueryPromise<number> => {
        let promise = $.Deferred();
        let requestModel = new AbortableRequest("/customers/count", RequestMethods.GET, { ...getState().customers.searchCriteria });
        ajaxRequest<number>(requestModel)(dispatch, getState).then(response => {
            if (response.success == false) {
                promise.resolve(null);
                return;
            }
            dispatch(actionCreator.setTotalCount(response.entity));
            promise.resolve(response.entity);
        });

        return promise.promise();
    },
    genericSearch: (value: string) => (dispatch, getState: () => ApplicationState) => {
        abortAllRequest();
        let searchCriteria = { ...getState().customers.searchCriteria };
        //reset page number and alterate string search
        searchCriteria.pageNumber = 0;
        searchCriteria.hasNextPage = false;
        searchCriteria.searchString = value;
        dispatch(actionCreator.setSearchCriteria(searchCriteria));
        debounceSearch(dispatch);

    },
    loadNextPage: () => (dispatch, getState: () => ApplicationState) => {
        let searchCriteria = getState().customers.searchCriteria;
        searchCriteria.pageNumber += 1;
        dispatch(actionCreator.setSearchCriteria(searchCriteria));
        dispatch(actionsMiddleware.loadSearchResult(false));
    },
    loadSearchResult: (preservePages: boolean) => (dispatch, getState): JQueryPromise<CoreResponse<Array<Customer>>> => {

        let promise = $.Deferred();

        let searchCriteria = { ...(getState() as ApplicationState).customers.searchCriteria };

        let requestModel = new FetchListRequest(`/customers/search`, RequestMethods.GET, { ...searchCriteria, preservePages: preservePages });

        ajaxRequest<Array<Customer>>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                return promise.resolve(response);
            }

            searchCriteria.hasNextPage = true;

            if (response.entity.length < PAGE_SIZE) {
                searchCriteria.hasNextPage = false;
            }

            if (preservePages && (response.entity.length < (searchCriteria.pageNumber + 1) * PAGE_SIZE)) {
                searchCriteria.hasNextPage = false;
            }

            dispatch(actionCreator.appendList(response.entity));

            dispatch(actionCreator.setSearchCriteria(searchCriteria));

            dispatch(actionCreatorUi.startFetchingPagedList(true));

            return promise.resolve(response);

        });

        return promise.promise();
    },
    loadCustomer: (id?: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<Customer> => {

        let deferred = $.Deferred();

        if (id === undefined || id == null || id == "") {
            let newCustomer = getNewCustomer();
            dispatch(actionCreator.setRunningCustomer(newCustomer));
            dispatch(actionCreator.setModuleLoaded(true));
            deferred.resolve(newCustomer);
            return deferred.promise();
        }

        let requestModel = new RequestModel(`/customers/${id}`, RequestMethods.GET, null, null);
        ajaxRequest<Customer>(requestModel)(dispatch, getState).then(response => {
            if (response.success == false || response.entity == null || response.entity === undefined) {
                deferred.resolve(null);
                return;
            }
            dispatch(actionCreator.setRunningCustomer(response.entity));
            dispatch(actionCreator.setModuleLoaded(true));
            deferred.resolve(response.entity);
        });

        return deferred.promise();
    },
    navigateToCustomer: (id: string) => (dispatch, getState) => {
        dispatch(actionCreator.setModuleLoaded(false));
        dispatch(actionCreator.unloadRunning());
        dispatch(push(`/customers/edit/${id}`));
    },
    delete: () => (dispatch, getState: () => ApplicationState) => {

        let requestModel = new SaveRequest(`/customers/${getState().customers.runningCustomer.id}`, RequestMethods.DELETE, null);

        return ajaxRequest<any>(requestModel)(dispatch, getState).then(response => {
            if (response.success == true) {
                dispatch(push("/customers"));
            }
        });
    },
    save: () => (dispatch, getState: () => ApplicationState): JQueryPromise<Customer> => {

        let promise = $.Deferred();

        dispatch(actionCreatorUi.setErrorMessages([]));
        //validation
        let customer: Customer = getState().customers.runningCustomer;

        if (customer.lastName == "" || customer.lastName == null || customer.lastName === undefined) {
            dispatch(actionCreatorUi.setErrorMessages(["Cognome obbligatorio"]));
            promise.resolve(null);
            return promise.promise();
        }

        if (customer.firstName == "" || customer.firstName == null || customer.firstName === undefined) {
            dispatch(actionCreatorUi.setErrorMessages(["Nome obbligatorio"]));
            promise.resolve(null);
            return promise.promise();
        }

        let request = new SaveRequest(`/customers`, RequestMethods.POST, customer);

        ajaxRequest<Customer>(request)(dispatch, getState).then(response => {
            if (response.success != true) {
                promise.resolve(null);
                return promise.promise();
            }

            promise.resolve(response.entity);
            dispatch(actionCreator.setRunningCustomer(response.entity));
        });

        return promise.promise();
    },
    setRunningProfileProp: (propertyName: string, value: any) => (dispatch, getState: () => ApplicationState) => {

        dispatch(actionCreatorUi.setErrorMessages([]));

        let runningCustomer = { ...getState().customers.runningCustomer };

        switch (propertyName) {
            case "firstName":
                runningCustomer.firstName = value;
                break;
            case "lastName":
                runningCustomer.lastName = value;
                break;
            case "email":
                runningCustomer.email = value;
                break;
            case "phone":
                runningCustomer.phone = value;
                break;
            case "phonePrefix":
                runningCustomer.phonePrefix = value;
                break;
            case "city":
                runningCustomer.city = value;
                break;
            case "country":
                runningCustomer.country = value;
                break;
            case "zipCode":
                runningCustomer.zipCode = value;
                break;
            case "stateProvince":
                runningCustomer.stateProvince = value;
                break;
            case "address":
                runningCustomer.address = value;
                break;
            case "birthDate":
                runningCustomer.birthDate = value;
                break;
            case "taxCode":
                runningCustomer.taxCode = value;
                break;
            case "vatNumber":
                runningCustomer.vatNumber = value;
                break;
            case "privacy":
                runningCustomer.privacy = value;
                break;
            case "marketingPrivacy":
                runningCustomer.marketingPrivacy = value;
                break;
            case "notes":
                runningCustomer.notes = value;
                break;
        }
        dispatch(actionCreator.setRunningCustomer(runningCustomer));
    },
    setSelectedCustomer: (id: string) => (dispatch, getState: () => ApplicationState) => {
        let customerIndex = _.findIndex(getState().customers.selectedCustomers, (c) => c.id == id);

        let selectedCustomers = [...getState().customers.selectedCustomers];

        if (customerIndex < 0) {
            let customer = _.find(getState().customers.list, (c) => c.id == id);
            selectedCustomers.push(customer);
        } else {
            selectedCustomers.splice(customerIndex, 1);
        }

        dispatch(actionCreator.setSelectedCustomers(selectedCustomers));

    },
    addSelectedCustomer: (customer: Customer) => (dispatch, getState: () => ApplicationState) => {
        let selectedCustomers = [...getState().customers.selectedCustomers];
        if (customer !== undefined && customer != null && selectedCustomers !== undefined && selectedCustomers != null) {
            let customerIndex = _.findIndex(selectedCustomers, (c) => c.id == customer.id);
            if (customerIndex < 0)
                selectedCustomers.push(customer);
            else
                selectedCustomers[customerIndex] = customer;
        }

        dispatch(actionCreator.setSelectedCustomers(selectedCustomers));
    },
    removeCustomerSelected: (id: string) => (dispatch, getState: () => ApplicationState) => {
        let selectedCustomers = [...getState().customers.selectedCustomers];
        let customerIndex = _.findIndex(selectedCustomers, (c) => c.id == id);
        selectedCustomers.splice(customerIndex, 1);
        dispatch(actionCreator.setSelectedCustomers(selectedCustomers));
    },
    setGPData: (gpModel: GpModel) => (dispatch, getState: () => ApplicationState) => {
        dispatch(actionCreatorUi.setErrorMessages([]));
        let runningCustomer = { ...getState().customers.runningCustomer }; 
        runningCustomer.firstName = gpModel.firstName;
        runningCustomer.lastName = gpModel.lastName;
        runningCustomer.birthDate = gpModel.birthDate;
        dispatch(actionCreator.setRunningCustomer(runningCustomer));
        dispatch(actionCreatorUi.setChangeInProgress(true));
    }
}

export const reducer: Reducer<CustomerState> = (state: CustomerState | undefined, incomingAction: CustomerAction): CustomerState => {

    if (state === undefined) {
        return baseState;
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.UNLOAD_RUNNING:
            newState = dotProp.set(newState, "runningCustomer", null);
            break
        case TypeKeys.SET_TOTAL_COUNT:
            newState = dotProp.set(newState, "totalCount", incomingAction.count);
            break;
        case TypeKeys.RESET_STATE:
            let stateToUpdate = getBaseState();
            stateToUpdate.searchCriteria = newState.searchCriteria;
            newState = { ...stateToUpdate };
            break;
        case TypeKeys.SET_LIST:
            newState = dotProp.set(newState, "list", [...incomingAction.list]);
            break;
        case TypeKeys.APPEND_LIST:
            let customers = newState.list.concat(incomingAction.list);
            newState = dotProp.set(newState, 'list', [...customers]);
            break;
        case TypeKeys.SET_RUNNING:
            newState = dotProp.set(newState, "runningCustomer", { ...incomingAction.customer });
            break;
        case TypeKeys.SET_SEARCH_CRITERIA:
            newState = dotProp.set(newState, "searchCriteria", { ...incomingAction.searchCriteria });
            break;
        case TypeKeys.MODULE_LOADED:
            newState = dotProp.set(newState, "moduleLoaded", incomingAction.moduleLoaded);
            break;
        case TypeKeys.SET_SELECTED_CUSTOMERS:
            newState = dotProp.set(newState, "selectedCustomers", [...incomingAction.customers]);
            break;
        case TypeKeys.SET_SELECTION_MODAL_OPEN:
            newState = dotProp.set(newState, "selectionModalOpen", !state.selectionModalOpen);
            break;
    }

    return newState;
}