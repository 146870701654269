import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/index';
import { actionsMiddleware } from '../../../../store/payments/movements/MovementsState';
import { PaymentTypology } from '../../../../store/payments/payments/PaymentEntities';
import { MovementsSearchForm, MovementsSearchFormCallbacks, MovementsSearchFormProps } from '../base/searchform';
import _ from "underscore";
import { PaymentCategory } from "../../../../store/payments/paymentitems/PaymentItemEntities";

const mapStateToProsp = (state: ApplicationState): MovementsSearchFormProps => {
    return {
        methods: state.paymentMethods.paymentMethods,
        orderDirections: [
            {
                key: "Ascending",
                desc: "Crescente"
            },
            {
                key: "Descending",
                desc: "Decrescente"
            }
        ],
        typologies: [
            {
                key: PaymentTypology.Outbound,
                desc: "Uscite"
            },
            {
                key: PaymentTypology.Inbound,
                desc: "Entrate"
            },
            {
                key: PaymentTypology.CashFund,
                desc: "Fondo cassa"
            }
        ],
        searchCriteria: state.movements.searchCriteria,
        paymentItems: _.filter(state.paymentItems.paymentItems, (item) => {
            if (!state.movements.searchCriteria?.typology)
                return false;

            if (state.movements.searchCriteria.typology == PaymentTypology.CashFund)
                return false;

            let category: PaymentCategory = PaymentCategory.Cost;

            if (state.movements.searchCriteria.typology == PaymentTypology.Inbound)
                category = PaymentCategory.Income;

            return item.category == category;
        })
    };
}

const mapDispatchToProps = () => (dispatch): MovementsSearchFormCallbacks => {
    return ({
        onSearch: (propertyName: string, value: any) => dispatch(actionsMiddleware.onFilterChange(propertyName, value)),
        removeFilters: () => dispatch(actionsMiddleware.resetFilters())
    });
}

export const ConnectedMovementsSearchForm = connect<MovementsSearchFormProps, MovementsSearchFormCallbacks>(mapStateToProsp, mapDispatchToProps)(MovementsSearchForm);