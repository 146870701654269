import { Action, Reducer } from 'redux';
import { ApplicationState } from '../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest, checkRefreshToken, SaveRequest, HideRequest, FetchListRequest } from '../../utils/ajaxutilities';
import dotProp from "dot-prop";
import { CoreResponse, ValidationCode, ValidationLevel } from '../serverresponse/serverresponse';
import { PAGE_SIZE, formatToLocaleDate } from '../../utils/commons';
import { push } from 'connected-react-router';
import _ from "underscore";
import { Reservation, ReservationDayPricing, ReservationStatus, ReservationType } from './ReservationEntities';
import { actionCreator as agentActions, agentFunctions } from '../agents/AgentsState';
import { Agent } from '../agents/AgentEntities';
import { actionsMiddleware as locationMiddleware } from '../locations/LocationState';
import { actionsMiddleware as eventMiddleware } from '../events/EventState';
import { actionsMiddleware as customerMiddleware, actionCreator as customerActions } from '../customers/CustomerState';
import { AgentRole } from '../agents/AgentEntities';
import $ from "jquery";
import { actionCreator as actionCreatorUi } from '../uiproperties/UIState';
import moment from "moment";
import { KeyDesc } from '../shared/KeyDesc';
import { Event } from '../events/EventEntities';
import { Entry, getDefaultEntry } from '../entries/EntriesEntities';
import { batch } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { Payment, PaymentTypology } from '../payments/payments/PaymentEntities';
import { sharedActions as paymentItemAction } from '../payments/paymentitems/PaymentItemState';
import { sharedActions as paymentMethodAction } from '../payments/paymentmethods/PaymentMethodState';
import { SystemPaymentItem } from '../payments/paymentitems/PaymentItemEntities';

const _printWorker: Worker = new Worker("/webWorkers/printWorkerV2.js");

const debouncedActions = (dispatch) => {
    dispatch(actionCreator.setList([]));
    dispatch(actionMiddleware.loadSearchResult(false));
    dispatch(actionMiddleware.getTotalCount());
    dispatch(actionMiddleware.getGuestsCount());
}

const debounceSearch = _.debounce(debouncedActions, 500);

export interface ReservationState {
    totalCount: number;
    guestsCount: number;
    list: Array<Reservation>;
    searchCriteria: SearchCriteria;
    running?: RunningState;
    agentRoles: Array<AgentRole>;
    moduleLoaded: boolean;
    reservationStatusDesc: Array<KeyDesc<string>>;
    reservationTypeDesc: Array<KeyDesc<string>>;
    eventId: string;
    lastClickedId?: string;
    lastHighlightedElement?: string;
    advancedFiltersOpened: boolean;
}

export interface RunningState {
    reservation?: Reservation;
    entries: Array<Entry>;
    payment: Payment;
    skipCapacityValidation?: boolean;
    skipCustomerCheck?: boolean;
    runningAgentRole: AgentRole;
    needConfirmSkipCapacity: boolean;
    needConfirmSkipCustomer: boolean;
    isPaymentModalOpen: boolean;
}

export interface SearchCriteria {
    customerContacts?: string;
    checkInFrom?: string;
    checkInTo?: string;
    checkOutFrom?: string;
    checkOutTo?: string;
    progressiveNumber?: string;
    agentContacts?: string;
    roomTypeId?: string;
    locationId?: string;
    totalPayFrom?: number;
    totalPayTo?: number;
    createdByAgent?: boolean;
    statesSelected: Array<ReservationStatus>;
    orderField?: string;
    orderDirection?: string;
    eventId?: string;
    pageNumber?: number;
    hasNextPage: boolean;
    webReservation?: boolean;
    type?: ReservationType;
    notes?: string;
    checkInDone?: boolean;
    paymentStatus?: ReservationPaymentStatus;
    paymentMethodId: string;
}

export enum ReservationPaymentStatus {
    ToPay = "ToPay",
    GiveChange = "GiveChange"
}

export const getBaseSearchCriteria = (eventId: string): SearchCriteria => ({
    statesSelected: [ReservationStatus.Confirmed, ReservationStatus.NotConfirmed, ReservationStatus.Settled],
    agentContacts: "",
    checkInFrom: "",
    checkInTo: "",
    checkOutFrom: "",
    checkOutTo: "",
    createdByAgent: null,
    customerContacts: "",
    eventId: eventId,
    locationId: "",
    orderDirection: "",
    orderField: "",
    pageNumber: 0,
    progressiveNumber: "",
    roomTypeId: "",
    totalPayFrom: null,
    totalPayTo: null,
    hasNextPage: false,
    webReservation: null,
    notes: "",
    type: null,
    checkInDone: null,
    paymentStatus: null,
    paymentMethodId: ""
});

const getBaseRunning = (): RunningState => ({
    reservation: null,
    skipCapacityValidation: false,
    skipCustomerCheck: false,
    runningAgentRole: null,
    needConfirmSkipCapacity: false,
    needConfirmSkipCustomer: false,
    entries: [],
    payment: null,
    isPaymentModalOpen: false
});

const getBaseReservation = (eventId: string): Reservation => ({
    customers: [],
    event: {
        id: eventId
    } as Event,
    profiles: [],
    reservationDays: [],
    status: ReservationStatus.NotConfirmed,
    discount: 0,
    totalNoTax: 0,
    totalTax: 0,
    totalPay: 0,
    payments: []
} as Reservation);

const getBaseState = (eventId: string = null): ReservationState => ({
    agentRoles: [],
    list: [],
    searchCriteria: getBaseSearchCriteria(eventId),
    running: getBaseRunning(),
    moduleLoaded: false,
    totalCount: 0,
    guestsCount: 0,
    reservationStatusDesc: [
        {
            key: ReservationStatus.NotConfirmed,
            desc: "Non confermata"
        },
        {
            key: ReservationStatus.Confirmed,
            desc: "Confermata"
        },
        {
            key: ReservationStatus.Settled,
            desc: "Saldata"
        },
        {
            key: ReservationStatus.Canceled,
            desc: "Annullata"
        }
    ],
    reservationTypeDesc: [
        {
            key: ReservationType.Standard,
            desc: "Standard"
        },
        {
            key: ReservationType.Packet,
            desc: "Packet"
        },
        {
            key: ReservationType.StaffHotel,
            desc: "Staff Hotel"
        },
        {
            key: ReservationType.Guest,
            desc: "Guest"
        },
        {
            key: ReservationType.Staff,
            desc: "Staff"
        }
    ],
    eventId: null,
    lastClickedId: null,
    lastHighlightedElement: null,
    advancedFiltersOpened: false
});

enum TypeKeys {
    SET_RUNNING_STATE = "RESERVATION_SET_RUNNING_STATE",
    SET_RUNNING_RESERVATION = "RESERVATION_SET_RUNNING_RESERVATION",
    SET_LIST = "RESERVATION_SET_LIST",
    SET_SEARCH_CRITERIA = "RESERVATION_SET_SEARCH_CRITERIA",
    SET_AGENT_ROLES = "RESERVATION_SET_AGENT_ROLES",
    MODULE_LOADED = "RESERVATION_SET_MODULE_LOADED",
    RESET_STATE = "RESERVATION_RESET_STATE",
    APPEND_LIST = "RESERVATION_APPEND_LIST",
    SET_TOTAL_COUNT = "RESERVATION_SET_TOTAL_COUNT",
    SET_GUESTS_COUNT = "RESERVATION_SET_GUESTS_COUNT",
    SET_RUNNING_AGENT_ROLE = "RESERVATION_SET_RUNNING_AGENT_ROLE",
    SET_EVENT_ID = "RESERVATION_SET_EVENT_ID",
    SET_ENTRIES = "RESERVATION_SET_ENTRIES",
    SET_LAST_CLICKED_ID = "RESERVATIONS_SET_LAST_CLICKED_ID",
    RESET_LAST_CLICKED_ELEMENT = "RESERVATIONS_RESET_LAST_CLICKED_ELEMENT",
    RESET_LAST_HIGHLIGHTED_ELEMENT = "RESERVATIONS_RESET_LAST_HIGHLIGHTED_ELEMENT",
    SET_RUNNING_PAYMENT = "RESERVATIONS_SET_RUNNING_PAYMENT",
    PAYMENT_MODAL_TOGGLE = "RESERVATIONS_PAYMENT_MODAL_TOGGLE",
    ADVANCED_MODAL_TOGGLE = "RESERVATIONS_ADVANCED_MODAL_TOGGLE"
}

type ReservationAction =
    SetList
    | SetSearchCriteria
    | SetRunningState
    | SetRunningReservation
    | SetAgentRoles
    | SetModuleLoaded
    | ResetState
    | AppendList
    | SetTotalCount
    | SetGuestsCount
    | SetRunningAgentRole
    | SetEventId
    | SetEntries
    | SetLastClickedId
    | ResetLastClicked
    | ResetLastHighlightedElement
    | SetRunningPayment
    | TogglePaymentModal
    | ToggleAdvancedFiltersModal;

interface ToggleAdvancedFiltersModal extends Action {
    type: TypeKeys.ADVANCED_MODAL_TOGGLE,
    isOpen: boolean;
}

interface TogglePaymentModal extends Action {
    type: TypeKeys.PAYMENT_MODAL_TOGGLE;
    isOpen: boolean;
}

interface SetRunningPayment extends Action {
    type: TypeKeys.SET_RUNNING_PAYMENT;
    payment: Payment
}

interface ResetLastHighlightedElement extends Action {
    type: TypeKeys.RESET_LAST_HIGHLIGHTED_ELEMENT;
}

interface ResetLastClicked extends Action {
    type: TypeKeys.RESET_LAST_CLICKED_ELEMENT;
}

interface SetLastClickedId extends Action {
    type: TypeKeys.SET_LAST_CLICKED_ID;
    lastClickedId: string;
}

interface SetGuestsCount extends Action {
    type: TypeKeys.SET_GUESTS_COUNT;
    count: number;
}

interface SetEventId extends Action {
    type: TypeKeys.SET_EVENT_ID;
    id: string;
}

interface SetRunningAgentRole extends Action {
    type: TypeKeys.SET_RUNNING_AGENT_ROLE;
    role: AgentRole;
}

interface SetTotalCount extends Action {
    type: TypeKeys.SET_TOTAL_COUNT;
    count: number;
}

interface SetList extends Action {
    type: TypeKeys.SET_LIST;
    list: Array<Reservation>;
}

interface SetSearchCriteria extends Action {
    type: TypeKeys.SET_SEARCH_CRITERIA;
    searchCriteria: SearchCriteria;
}

interface SetRunningState extends Action {
    type: TypeKeys.SET_RUNNING_STATE;
    model: RunningState;
}

interface SetRunningReservation extends Action {
    type: TypeKeys.SET_RUNNING_RESERVATION;
    model: Reservation;
}

interface SetAgentRoles extends Action {
    type: TypeKeys.SET_AGENT_ROLES;
    list: Array<AgentRole>;
}

interface SetModuleLoaded extends Action {
    type: TypeKeys.MODULE_LOADED;
    loaded: boolean;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE;
}

interface AppendList extends Action {
    type: TypeKeys.APPEND_LIST;
    list: Array<Reservation>;
}

interface SetEntries extends Action {
    type: TypeKeys.SET_ENTRIES;
    entries: Array<Entry>;
}

export const utils = {
    recalculateTotal: (reservation: Reservation): Reservation => {

        if (reservation.reservationDays === undefined || reservation.reservationDays == null || reservation.reservationDays.length == 0)
            return reservation;

        let totalPay = 0;
        let totalTax = 0;

        reservation.reservationDays.forEach(x => {
            totalPay += x.sellPrice;
            totalTax += x.dailyTax;
        });

        if (reservation.roomType !== undefined && reservation.roomType != null && reservation.roomType.capacity > 0) {
            totalTax = totalTax * reservation.roomType.capacity;
        } else {
            totalTax = 0;
        }

        reservation.discount = 0;
        reservation.totalTax = totalTax;
        reservation.totalNoTax = totalPay;
        reservation.totalPay = reservation.totalTax + reservation.totalNoTax;

        if (reservation.type == ReservationType.Guest || reservation.type == ReservationType.Staff) {
            reservation.totalPay = 0;
            reservation.discount = 0;
        }

        return reservation;
    }
}

export const actionCreator = {
    setList: (list: Array<Reservation>): SetList => ({
        type: TypeKeys.SET_LIST,
        list: list
    }),
    setSearchCriteria: (searchCriteria: SearchCriteria): SetSearchCriteria => ({
        type: TypeKeys.SET_SEARCH_CRITERIA,
        searchCriteria: searchCriteria
    }),
    setRunningState: (runningState: RunningState): SetRunningState => ({
        type: TypeKeys.SET_RUNNING_STATE,
        model: runningState
    }),
    setRunningReservation: (model: Reservation): SetRunningReservation => ({
        type: TypeKeys.SET_RUNNING_RESERVATION,
        model: model
    }),
    setAgentRoles: (agentRoles: Array<AgentRole>): SetAgentRoles => ({
        type: TypeKeys.SET_AGENT_ROLES,
        list: agentRoles
    }),
    setModuleLoaded: (loaded: boolean): SetModuleLoaded => ({
        type: TypeKeys.MODULE_LOADED,
        loaded: loaded
    }),
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    appendList: (list: Array<Reservation>): AppendList => ({
        type: TypeKeys.APPEND_LIST,
        list: list
    }),
    setTotalCount: (total: number): SetTotalCount => ({
        type: TypeKeys.SET_TOTAL_COUNT,
        count: total
    }),
    setGuestsCount: (total: number): SetGuestsCount => ({
        type: TypeKeys.SET_GUESTS_COUNT,
        count: total
    }),
    setRunningAgentRole: (agentRole: AgentRole): SetRunningAgentRole => ({
        type: TypeKeys.SET_RUNNING_AGENT_ROLE,
        role: agentRole
    }),
    setEventId: (id: string): SetEventId => ({
        type: TypeKeys.SET_EVENT_ID,
        id: id
    }),
    setEntries: (entries: Array<Entry>): SetEntries => ({
        type: TypeKeys.SET_ENTRIES,
        entries: entries
    }),
    setLastClieckedId: (id: string): SetLastClickedId => ({
        type: TypeKeys.SET_LAST_CLICKED_ID,
        lastClickedId: id
    }),
    resetLastClieckedId: (): ResetLastClicked => ({
        type: TypeKeys.RESET_LAST_CLICKED_ELEMENT
    }),
    resetLastHighlightedElement: (): ResetLastHighlightedElement => ({
        type: TypeKeys.RESET_LAST_HIGHLIGHTED_ELEMENT
    }),
    setRunningPayment: (payment: Payment): SetRunningPayment => ({
        type: TypeKeys.SET_RUNNING_PAYMENT,
        payment: payment
    }),
    togglePaymentModal: (isOpen: boolean): TogglePaymentModal => ({
        type: TypeKeys.PAYMENT_MODAL_TOGGLE,
        isOpen: isOpen
    }),
    toggleAdvancedFiltersModal: (isOpen: boolean): ToggleAdvancedFiltersModal => ({
        type: TypeKeys.ADVANCED_MODAL_TOGGLE,
        isOpen: isOpen
    })
}

export const actionMiddleware = {
    loadInitialState: (eventId: string) => (dispatch, getState: () => ApplicationState) => {
        batch(() => {
            abortAllRequest();
            dispatch(actionCreator.setModuleLoaded(false));
            let appState = (getState() as ApplicationState);
            let preservePages: boolean = true;
            dispatch(actionCreator.resetState());
            dispatch(actionCreator.setEventId(eventId));
            let searchCriteria = { ...(getState() as ApplicationState).reservations.searchCriteria };
            searchCriteria.eventId = eventId;
            dispatch(actionCreator.setSearchCriteria(searchCriteria));
            if (appState.uiProperties.previousPath === undefined || appState.uiProperties.previousPath == null || !(appState.uiProperties.previousPath.startsWith(`/events/:eventId/reservations/create`) || appState.uiProperties.previousPath.startsWith(`/events/:eventId/reservations/edit/:id`))) {
                dispatch(actionCreator.setSearchCriteria(getBaseSearchCriteria(eventId)));
                dispatch(actionCreator.setLastClieckedId(null));
                preservePages = false;
            }

            checkRefreshToken()(dispatch, getState).then(x => {

                if (x == false)
                    return;

                $.when(dispatch(locationMiddleware.getRoomTypes()),
                    dispatch(eventMiddleware.setEventLocations(eventId)),
                    dispatch(actionMiddleware.loadSearchResult(preservePages)),
                    dispatch(actionMiddleware.getTotalCount()),
                    dispatch(actionMiddleware.getGuestsCount()),
                    dispatch(actionMiddleware.loadAgentRoles()),
                    dispatch(paymentMethodAction.loadAll()))
                    .then((rt,
                        locations,
                        resp,
                        count,
                        guestsCount,
                        agentRoles,
                        paymentMethods) => {
                        if (resp != null)
                            dispatch(actionCreator.setModuleLoaded(true));
                    });
            })
        });
    },
    loadInitialBaseData: (eventId: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<boolean> => {
        let deferred = $.Deferred();
        abortAllRequest();
        dispatch(actionCreator.setModuleLoaded(false));
        dispatch(actionCreator.resetState());
        dispatch(actionCreator.setEventId(eventId));

        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false) {
                deferred.resolve(false);
                return deferred.promise();
            }

            $.when(dispatch(locationMiddleware.getRoomTypes()), dispatch(eventMiddleware.setEventLocations(eventId)), dispatch(actionMiddleware.loadAgentRoles())).then((rt, locations, agentRoles) => {
                dispatch(actionCreator.setModuleLoaded(true));
                deferred.resolve(true);
            });
        });

        return deferred.promise();
    },
    loadSearchResult: (preservePages: boolean) => (dispatch, getState): JQueryPromise<Array<Reservation>> => {
        let promise = $.Deferred();

        let searchCriteria = (getState() as ApplicationState).reservations.searchCriteria;

        let requestModel = new FetchListRequest(`/reservations/search?preservePages=${preservePages}`, RequestMethods.POST, { ...searchCriteria });

        ajaxRequest<Array<Reservation>>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                return promise.resolve(null);
            }

            searchCriteria.hasNextPage = true;

            if (response.entity.length < PAGE_SIZE) {
                searchCriteria.hasNextPage = false;
            }

            if (preservePages && (response.entity.length < (searchCriteria.pageNumber + 1) * PAGE_SIZE)) {
                searchCriteria.hasNextPage = false;
            }

            dispatch(actionCreator.appendList(response.entity));

            dispatch(actionCreator.setSearchCriteria(searchCriteria));

            dispatch(actionCreatorUi.startFetchingPagedList(true));

            return promise.resolve(response.entity);

        });

        return promise.promise();
    },
    printCheckInList: () => (dispatch, getState: () => ApplicationState) => {

        let searchCriteria = (getState() as ApplicationState).reservations.searchCriteria;

        let requestModel = new AbortableRequest(`/reservations/print`, RequestMethods.POST, { ...searchCriteria });

        const eventName = getState().events.running.name ?? '';

        ajaxRequest<Array<Reservation>>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                return;
            }

            if (response.entity === undefined || response.entity == null || response.entity.length == 0) {
                dispatch(actionCreatorUi.setErrorMessages(["Nessun booking per i filtri selezionati"]));
                return;
            }

            _printWorker.onmessage = (res) => {

                if (res === undefined || res == null || res.data === undefined || res.data == null) {
                    dispatch(actionCreatorUi.setErrorMessages(["Errore generazione lista prenotazioni"]));
                    return;
                }

                const url = window.URL.createObjectURL(res.data);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `booking_list_${eventName}.pdf`;
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            };

            dispatch(actionCreatorUi.setErrorMessages(["La generazione del documento PDF avverrà in background il download inizierà una volta terminata"]));

            _printWorker.postMessage({ eventName: eventName, list: response.entity });

        });

    },
    downloadExcelList: () => (dispatch, getState: () => ApplicationState) => {
        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false)
                return;

            dispatch(showLoading());

            let searchCriteria = (getState() as ApplicationState).reservations.searchCriteria;

            let token = getState().currentProfile.authData.token;

            fetch(`api/reservations/printexcel`, {
                method: 'POST',
                body: JSON.stringify(searchCriteria),
                headers: new Headers({
                    "Authorization": token,
                    "content-type": "application/json; charset=UTF-8"
                })
            })
                .then(resp => {
                    if (!resp.ok) {
                        throw new Error();
                    }
                    return resp.blob()
                })
                .then(blob => {
                    dispatch(hideLoading());
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `bookings_${getState().events.running.name.replace(/[/\\?%*:|"<>]/g, '-')}.xlsx`;
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(() => {
                    dispatch(actionCreatorUi.setErrorMessages(["Non è possibile ottenere il file excel"]));
                    dispatch(hideLoading());
                });

        });
    },
    assignProgressiveNumbers: () => (dispatch, getState: () => ApplicationState) => {

        let searchCriteria = (getState() as ApplicationState).reservations.searchCriteria;

        let requestModel = new AbortableRequest(`/reservations/progressivenumbers`, RequestMethods.POST, { ...searchCriteria });

        ajaxRequest<any>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                return;
            }

            dispatch(actionCreatorUi.setErrorMessages(["Assegnazione progressivi avvenuta con successo"]));

            dispatch(actionCreator.resetLastClieckedId());

            debounceSearch(dispatch);
        });

    },
    getTotalCount: () => (dispatch, getState: () => ApplicationState): JQueryPromise<number> => {
        let promise = $.Deferred();
        let requestModel = new AbortableRequest("/reservations/count", RequestMethods.POST, { ...getState().reservations.searchCriteria });
        ajaxRequest<number>(requestModel)(dispatch, getState).then(response => {
            if (response.success == false) {
                promise.resolve(null);
                return;
            }
            dispatch(actionCreator.setTotalCount(response.entity));
            promise.resolve(response.entity);
        });

        return promise.promise();
    },
    getGuestsCount: () => (dispatch, getState: () => ApplicationState): JQueryPromise<number> => {
        let promise = $.Deferred();
        let requestModel = new AbortableRequest("/reservations/guestscount", RequestMethods.POST, { ...getState().reservations.searchCriteria });
        ajaxRequest<number>(requestModel)(dispatch, getState).then(response => {
            if (response.success == false) {
                promise.resolve(null);
                return;
            }
            dispatch(actionCreator.setGuestsCount(response.entity));
            promise.resolve(response.entity);
        });

        return promise.promise();
    },
    loadAgentRoles: () => (dispatch, getState: () => ApplicationState): JQueryPromise<Array<AgentRole>> => {

        let deferred = $.Deferred();

        agentFunctions.agentRoles()(dispatch, getState).then(response => {
            if (response.success == false) {
                deferred.resolve(null);
                return;
            }
            dispatch(actionCreator.setAgentRoles(response.entity));
            deferred.resolve(response.entity);
        });

        return deferred.promise();
    },
    loadNextPage: () => (dispatch, getState: () => ApplicationState) => {
        let searchCriteria = getState().reservations.searchCriteria;
        searchCriteria.pageNumber += 1;
        dispatch(actionCreator.setSearchCriteria(searchCriteria));
        dispatch(actionMiddleware.loadSearchResult(false));
    },
    doSearch: (propertyName: string, value: any) => (dispatch, getState: () => ApplicationState) => {

        abortAllRequest();

        let searchCriteria = { ...getState().reservations.searchCriteria };

        switch (propertyName) {
            case "agentContacts":
                searchCriteria.agentContacts = value;
                break;
            case "checkInFrom":
                searchCriteria.checkInFrom = value;
                if (moment(value).isValid()) {
                    searchCriteria.checkInFrom = moment(value).format("YYYY-MM-DDTHH:mm:ss");
                }
                break;
            case "checkInTo":
                searchCriteria.checkInTo = value;
                if (moment(value).isValid()) {
                    searchCriteria.checkInTo = moment(value).format("YYYY-MM-DDTHH:mm:ss");
                }
                break;
            case "checkOutFrom":
                searchCriteria.checkOutFrom = value;
                if (moment(value).isValid()) {
                    searchCriteria.checkOutFrom = moment(value).format("YYYY-MM-DDTHH:mm:ss");
                }
                break;
            case "checkOutTo":
                searchCriteria.checkOutTo = value;
                if (moment(value).isValid()) {
                    searchCriteria.checkOutTo = moment(value).format("YYYY-MM-DDTHH:mm:ss");
                }
                break;
            case "createdByAgent":
                value = value == "" ? null : value;
                searchCriteria.createdByAgent = value;
                break;
            case "customerContacts":
                searchCriteria.customerContacts = value;
                break;
            case "locationId":
                searchCriteria.locationId = value;
                break;
            case "orderDirection":
                searchCriteria.orderDirection = value;
                break;
            case "orderField":
                searchCriteria.orderField = value;
                break;
            case "progressiveNumber":
                searchCriteria.progressiveNumber = value;
                break;
            case "roomTypeId":
                searchCriteria.roomTypeId = value;
                break;
            case "totalPayFrom":
                searchCriteria.totalPayFrom = value;
                break;
            case "totalPayTo":
                searchCriteria.totalPayTo = value;
                break;
            case "statesSelected":
                let stateSelectedIndex = _.findIndex(searchCriteria.statesSelected, (state) => state == value);
                if (stateSelectedIndex < 0) {
                    searchCriteria.statesSelected.push(value);
                    searchCriteria.statesSelected = [...searchCriteria.statesSelected];
                } else {
                    searchCriteria.statesSelected.splice(stateSelectedIndex, 1);
                    searchCriteria.statesSelected = [...searchCriteria.statesSelected]
                }
                break;
            case "webReservation":
                value = value == "" ? null : value;
                searchCriteria.webReservation = value;
                break;
            case "checkInDone":
                value = value == "" ? null : value;
                searchCriteria.checkInDone = value;
                break;
            case "type":
                value = value == "" ? null : value;
                searchCriteria.type = value;
                break;
            case "notes":
                searchCriteria.notes = value;
                break;
            case "paymentStatus":
                searchCriteria.paymentStatus = value;
                break;
            case "paymentMethodId":
                searchCriteria.paymentMethodId = value;
                break;
        }

        searchCriteria.pageNumber = 0;
        searchCriteria.hasNextPage = false;
        dispatch(actionCreator.setSearchCriteria(searchCriteria));

        debounceSearch(dispatch);
        dispatch(actionCreator.resetLastHighlightedElement());


    },
    delete: () => (dispatch, getState: () => ApplicationState) => {

        let requestModel = new SaveRequest(`/reservations/${getState().reservations.running.reservation.id}`, RequestMethods.DELETE, null);

        return ajaxRequest<any>(requestModel)(dispatch, getState).then(response => {
            if (response.success == true) {
                dispatch(push(`/events/${getState().reservations.eventId}/reservations`));
            }
        });
    },
    onNavigate: (id: string) => (dispatch, getState: () => ApplicationState) => {
        dispatch(push(`/events/${getState().reservations.eventId}/reservations/edit/${id}`));
        dispatch(actionCreator.setLastClieckedId(id));
    },
    load: (id: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<Reservation> => {
        let deferred = $.Deferred();

        let requestModel = new RequestModel(`/reservations/${id}`, RequestMethods.GET, null, null);

        ajaxRequest<Reservation>(requestModel)(dispatch, getState).then(response => {
            if (response.success == false || response.entity == null || response.entity === undefined) {
                deferred.resolve(null);
                return;
            }
            deferred.resolve(response.entity);
        });

        return deferred.promise();
    },
    loadEntries: (id: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<Reservation> => {
        let deferred = $.Deferred();

        let eventId = getState().reservations.eventId;

        if (eventId === undefined || eventId == null) {
            deferred.resolve(null);
            return deferred.promise();
        }

        let requestModel = new RequestModel(`/entries/getbyreservation/${id}/${eventId}`, RequestMethods.GET, null, null);

        ajaxRequest<Array<Entry>>(requestModel)(dispatch, getState).then(response => {
            if (response.success == false || response.entity == null || response.entity === undefined) {
                deferred.resolve(null);
                return;
            }
            deferred.resolve(response.entity);
        });

        return deferred.promise();
    },
    setRunningState: (id?: string) => (dispatch, getState: () => ApplicationState) => {
        $.when(dispatch(paymentItemAction.loadAll()), dispatch(paymentMethodAction.loadAll())).then((items: CoreResponse<any>, methods: CoreResponse<any>) => {
            if (items.success && methods.success) {
                let runningState = getBaseRunning();
                dispatch(actionCreator.setRunningState(runningState));
                if (id === undefined || id == null) {
                    let newReservation = getBaseReservation(getState().reservations.eventId);
                    dispatch(actionCreator.setRunningReservation(newReservation));
                } else {
                    checkRefreshToken()(dispatch, getState).then(x => {
                        if (x == false)
                            return;

                        $.when(dispatch(actionMiddleware.load(id)), dispatch(actionMiddleware.loadEntries(id))).then((entity: Reservation, entries: Array<Entry>) => {
                            if (entity != null) {
                                dispatch(actionCreator.setRunningReservation(entity));
                                dispatch(actionCreator.setEntries(entries))
                            }
                        });
                    });
                }
            }
        });
    },
    createNewPayment: () => (dispatch, getState: () => ApplicationState) => {
        const reservationPaymentItem = _.find(getState().paymentItems.paymentItems, (item) => item.systemItem == SystemPaymentItem.Reservation);
        const payment = {
            amount: 0,
            eventId: getState().reservations.eventId,
            methodId: null,
            paymentItemId: reservationPaymentItem.id,
            typology: PaymentTypology.Inbound,
            paymentDate: moment().toISOString(),
            notes: null
        } as Payment;

        dispatch(actionCreator.setRunningPayment(payment));
    },
    setRunningPaymentProp: (propertyName: string, value: any) => (dispatch, getState: () => ApplicationState) => {

        let runningPayment = { ...getState().reservations.running.payment };

        switch (propertyName) {
            case "methodId":
                runningPayment.methodId = value;
                break;
            case "amount":
                runningPayment.amount = value;
                break;
            case "paymentDate":
                runningPayment.paymentDate = value;
                if (moment(value).isValid()) {
                    runningPayment.paymentDate = moment(value).format("YYYY-MM-DDTHH:mm:ss");
                }
                break;
            case "notes":
                runningPayment.notes = value;
                break;
        }

        dispatch(actionCreator.setRunningPayment(runningPayment));

    },
    deletePayment: (index: number) => (dispatch, getState: () => ApplicationState) => {
        const runningReservation = { ...getState().reservations.running.reservation }
        runningReservation.payments.splice(index, 1);
        runningReservation.payments = [...runningReservation.payments];
        dispatch(actionCreator.setRunningReservation(runningReservation));
    },
    confirmPayment: () => (dispatch, getState: () => ApplicationState): boolean => {
        const runningPayment = getState().reservations.running.payment;

        if (!runningPayment.methodId || runningPayment.methodId == "") {
            dispatch(actionCreatorUi.setErrorMessages(["Inserire il metodo di pagamento"]));
            return false;
        }

        if (!runningPayment.paymentDate || runningPayment.paymentDate == "") {
            dispatch(actionCreatorUi.setErrorMessages(["Inserire la data di pagamento"]));
            return false;
        }

        if (!runningPayment.amount || runningPayment.amount <= 0) {
            dispatch(actionCreatorUi.setErrorMessages(["Inserire un importo valido"]));
            return false;
        }

        const runningReservation = { ...getState().reservations.running.reservation }

        runningReservation.payments.push(runningPayment);

        runningReservation.payments = [...runningReservation.payments];

        dispatch(actionCreator.setRunningReservation(runningReservation));

        dispatch(actionCreatorUi.setChangeInProgress(true));

        return true;
    },
    setRunningStateFromQuote: (reservation: Reservation) => (dispatch, getState: () => ApplicationState) => {
        $.when(dispatch(paymentItemAction.loadAll()), dispatch(paymentMethodAction.loadAll())).then((items: CoreResponse<any>, methods: CoreResponse<any>) => {
            if (items.success && methods.success) {
                let runningState = getBaseRunning();
                dispatch(actionCreator.setRunningState(runningState));
                reservation.id = undefined;
                dispatch(actionCreator.setRunningReservation(reservation));
                dispatch(push(`/events/${getState().reservations.eventId}/reservations/createfromquote/quote`));
            }
        });
    },
    onChange: (propertyName: string, value: any) => (dispatch, getState: () => ApplicationState) => {

        let running = JSON.parse(JSON.stringify(getState().reservations.running.reservation)) as Reservation;
        let getAvailability: boolean = false;

        switch (propertyName) {

            case "status":

                if (running.status == ReservationStatus.Canceled)
                    getAvailability = true;

                running.status = value;
                break;
            case "checkIn":
                running.checkIn = value;
                if (moment(value).isValid()) {
                    running.checkIn = moment(value).format("YYYY-MM-DDTHH:mm:ss");
                    getAvailability = true;
                }
                break;
            case "checkOut":
                running.checkOut = value;
                if (moment(value).isValid()) {
                    running.checkOut = moment(value).format("YYYY-MM-DDTHH:mm:ss");
                    getAvailability = true;
                }
                break;
            case "location":
                let location = _.find(getState().events.runningEventLocations, (l) => l.id == value);
                if (location !== undefined && location != null) {
                    running.location = location;
                } else {
                    running.location = null;
                }
                getAvailability = true;
                break;
            case "roomType":
                let roomType = _.find(getState().locations.roomTypes, (rt) => rt.id == value);

                if (roomType !== undefined && roomType != null) {
                    running.roomType = roomType;
                } else {
                    running.roomType = null;
                }

                getAvailability = true;
                break;
            case "discount":
                running.discount = value;

                if (value === undefined || value == null || value == '')
                    value = 0;
                try {
                    let discount: number = parseFloat(value.toString());
                    let totalPay: number = running.totalNoTax + running.totalTax;
                    running.totalPay = totalPay - discount;
                } catch { }
                break;
            case "totalPay":
                running.totalPay = value;

                if (value === undefined || value == null || value == '')
                    value = 0;

                try {
                    let totalPay: number = parseFloat(value.toString());
                    running.discount = running.totalNoTax + running.totalTax - totalPay;
                } catch {

                }
                break;
            case "notes":
                running.notes = value;
                break;
            case "type":
                let oldType = running.type;
                running.type = value;
                if (oldType == ReservationType.Guest || oldType == ReservationType.Staff) {
                    getAvailability = true;
                }

                if (running.type == ReservationType.Guest || running.type == ReservationType.Staff) {
                    getAvailability = true;
                    running.status = ReservationStatus.Settled;
                }

                break;
            case "isWebReservation":
                {
                    running.isWebReservation = value;
                    let statusBefore = running.status;
                    if (value == true) {
                        running.status = ReservationStatus.Settled;
                        if (statusBefore == ReservationStatus.Canceled)
                            getAvailability = true;
                    }
                }
                break;
            case "checkInDone":
                {
                    running.checkInDone = value;
                    let statusBefore = running.status;
                    if (value == true) {
                        if (statusBefore != ReservationStatus.Settled)
                            running.status = ReservationStatus.Confirmed;
                        if (statusBefore == ReservationStatus.Canceled)
                            getAvailability = true;
                    }
                }
                break;

        }

        dispatch(actionCreator.setRunningReservation(running));

        if (getAvailability == true)
            dispatch(actionMiddleware.getReservationPricingAvailability());
    },
    getReservationPricingAvailability: () => (dispatch, getState: () => ApplicationState): JQueryPromise<Array<ReservationDayPricing>> => {

        let deferred = $.Deferred();

        let runningReservation = JSON.parse(JSON.stringify(getState().reservations.running.reservation)) as Reservation;

        if (runningReservation.roomType === undefined ||
            runningReservation.roomType == null ||
            runningReservation.roomType.id == '' ||
            runningReservation.location === undefined ||
            runningReservation.location == null ||
            runningReservation.location.id == '' ||
            runningReservation.event === undefined ||
            runningReservation.event == null ||
            runningReservation.checkIn === undefined ||
            runningReservation.checkIn == null ||
            runningReservation.checkOut === undefined ||
            runningReservation.checkOut == null) {
            deferred.resolve([]);
            return deferred.promise();
        }

        let checkIn = moment(runningReservation.checkIn);
        let checkOut = moment(runningReservation.checkOut);

        if (!checkIn.isValid() || !checkOut.isValid()) {
            deferred.resolve([]);
            return deferred.promise();
        }

        if (checkIn.isBefore(moment("2000-01-01")) || checkOut.isBefore(moment("2000-01-01"))) {
            deferred.resolve([]);
            return deferred.promise();
        }

        let requestModel = new HideRequest(`/reservations/CheckAvailability`, RequestMethods.GET, {
            checkIn: runningReservation.checkIn,
            checkOut: runningReservation.checkOut,
            locationId: runningReservation.location.id,
            roomTypeId: runningReservation.roomType.id,
            eventId: runningReservation.event.id,
            reservationId: runningReservation.id
        });

        ajaxRequest<Array<ReservationDayPricing>>(requestModel)(dispatch, getState).then(response => {

            let valueToSet: Array<ReservationDayPricing> = [];

            if (response.success == true)
                valueToSet = response.entity;

            runningReservation.reservationDays = valueToSet;
            runningReservation = utils.recalculateTotal(runningReservation);
            dispatch(actionCreator.setRunningReservation(runningReservation));

            let notififyOverbooking: boolean = false;

            valueToSet.forEach(x => {
                if (x.availability <= 0)
                    notififyOverbooking = true;
            });

            if (notififyOverbooking != false) {
                dispatch(actionCreatorUi.setErrorMessages(["Saving this reservation this hotel will be overbooking check the availabilities for more details"]));
            }

            if (response.success == false) {
                deferred.resolve(null);
            } else {
                deferred.resolve(response.entity);
            }

        });

        return deferred.promise();
    },
    onRemoveCustomer: (id: string) => (dispatch, getState: () => ApplicationState) => {

        let reservation = { ...getState().reservations.running.reservation };
        let entries = [...getState().reservations.running.entries];

        let entryIndex = _.findIndex(entries, (entry) => entry.customerId == id);

        if (entryIndex > -1) {
            entries.splice(entryIndex, 1);
        }

        let customerIndex = _.findIndex(reservation.customers, (c) => c.id == id);
        if (customerIndex < 0)
            return;
        reservation.customers.splice(customerIndex, 1);
        dispatch(actionCreator.setRunningReservation(reservation));
        dispatch(actionCreator.setEntries(entries));

    },
    onRemoveAgent: (id: number) => (dispatch, getState: () => ApplicationState) => {

        let agentRoleIndex = _.findIndex(getState().reservations.running.reservation.profiles, (agent) => agent.agentRole != null && agent.agentRole.id == id);

        if (agentRoleIndex < 0)
            return;

        let reservation = { ...getState().reservations.running.reservation };

        reservation.profiles.splice(agentRoleIndex, 1);

        dispatch(actionCreator.setRunningReservation(reservation));

    },
    onAgentModalOpen: (agentRole: AgentRole, isOpen: boolean) => (dispatch, getState: () => ApplicationState) => {

        dispatch(actionCreator.setRunningAgentRole(agentRole));

        if (isOpen == false) {
            dispatch(agentActions.setSelectedAgent(null));
            return;
        }

        let agentForRole = _.find(getState().reservations.running.reservation.profiles, (p) => p.agentRole !== undefined && p.agentRole != null && p.agentRole.id == agentRole.id);

        dispatch(agentActions.loadInitialState(agentForRole));

    },
    onConfirmAgent: () => (dispatch, getState: () => ApplicationState) => {

        let selectedAgent = getState().agents.selectedAgent;
        let runningReservation = { ...getState().reservations.running.reservation };

        let roleIndex = _.findIndex(runningReservation.profiles, (p) => p != null && p.agentRole.id == getState().reservations.running.runningAgentRole.id);

        if (selectedAgent === undefined || selectedAgent == null) {
            if (roleIndex < 0)
                return;
            runningReservation.profiles.splice(roleIndex, 1);
            dispatch(actionCreator.setRunningReservation(runningReservation));
            return;
        }

        (selectedAgent as Agent).agentRole = getState().reservations.running.runningAgentRole;

        if (roleIndex < 0)
            runningReservation.profiles.push(selectedAgent as Agent);
        else
            runningReservation.profiles[roleIndex] = selectedAgent as Agent;

        dispatch(actionCreator.setRunningReservation(runningReservation));

    },
    onCustomersModelOpen: () => (dispatch, getState: () => ApplicationState) => {
        dispatch(customerMiddleware.loadInitialState(getState().reservations.running.reservation.customers)).then(resp => {
            dispatch(customerActions.setSelectionModalOpen());
        });
    },
    confirmSkipValidation: (type: string) => (dispatch, getState: () => ApplicationState) => {
        let runningState = { ...getState().reservations.running };

        if (type == "customer") {
            runningState.skipCustomerCheck = true;
            runningState.needConfirmSkipCustomer = false;
            dispatch(actionCreator.setRunningState(runningState));
            dispatch(actionMiddleware.onSubmit());
        }

        if (type == "capacity") {
            runningState.skipCapacityValidation = true;
            runningState.needConfirmSkipCapacity = false;
            dispatch(actionCreator.setRunningState(runningState));
            dispatch(actionMiddleware.onSubmit());
        }
    },
    onSubmit: () => (dispatch, getState: () => ApplicationState): JQueryPromise<Reservation> => {
        let promise = $.Deferred();

        dispatch(actionCreatorUi.setErrorMessages([]));
        //validation
        let model: Reservation = getState().reservations.running.reservation;
        let entries = getState().reservations.running.entries;
        let request = new SaveRequest(`/reservations/save?skipCapacityValidation=${getState().reservations.running.skipCapacityValidation}&skipCustomerCheck=${getState().reservations.running.skipCustomerCheck}`, RequestMethods.POST, { reservation: model, entries: entries });

        ajaxRequest<any>(request)(dispatch, getState).then(response => {
            if (response.success != true) {
                promise.resolve(null);
                return promise.promise();
            }

            if (response.validationLevel !== undefined && response.validationLevel != null && response.validationLevel == ValidationLevel.Fail) {

                let runningState = { ...getState().reservations.running };

                if (response.validationCodes !== undefined && response.validationCodes != null && response.validationCodes[0] == ValidationCode.WrongCapacity) {
                    runningState.needConfirmSkipCapacity = true;
                }
                else if (response.validationCodes !== undefined && response.validationCodes != null && response.validationCodes[0] == ValidationCode.CustomersAlreadyHaveReservations) {
                    runningState.needConfirmSkipCustomer = true;
                }

                dispatch(actionCreator.setRunningState(runningState));

                promise.resolve(null);
                return promise.promise();

            }

            promise.resolve(response.entity);
            dispatch(actionCreator.setRunningReservation(response.entity.reservation));
            dispatch(actionCreator.setLastClieckedId(response.entity.reservation.id));
            dispatch(actionCreator.setEntries(response.entity.entries));
        });

        return promise.promise();
    },
    onCustomersModalConfirm: () => (dispatch, getState: () => ApplicationState) => {
        let runninngReservation = { ...getState().reservations.running.reservation };
        let selectedCustomers = [...getState().customers.selectedCustomers];
        runninngReservation.customers = selectedCustomers;
        dispatch(actionCreator.setRunningReservation(runninngReservation));

        let customerIds = [];

        getState().reservations.running.reservation.customers.forEach(x => customerIds.push(x.id));

        let hideRequest: HideRequest = new HideRequest(`/entries/getbycustomersinevent/${getState().reservations.eventId}`, RequestMethods.POST, customerIds);

        ajaxRequest<Array<Entry>>(hideRequest)(dispatch, getState).then(response => {
            if (response.success == false)
                return;
            getState().reservations.running.entries.forEach(oldEntry => {
                let newEntry = _.find(response.entity, (newEntry) => oldEntry.customerId == newEntry.customerId);

                if (newEntry !== undefined && newEntry != null) {
                    newEntry.isDeleted = oldEntry.isDeleted;
                } else {
                    response.entity.push(oldEntry);
                }

            });

            dispatch(actionCreator.setEntries(response.entity));
            dispatch(actionCreatorUi.setChangeInProgress(true));

        });

    },
    setNeedConfirmSkip: (type: string) => (dispatch, getState: () => ApplicationState) => {

        let runningState = { ...getState().reservations.running };

        if (type == "customer") {
            runningState.needConfirmSkipCustomer = false;
            dispatch(actionCreator.setRunningState(runningState));
        }

        if (type == "capacity") {
            runningState.needConfirmSkipCapacity = false;
            dispatch(actionCreator.setRunningState(runningState));
        }

    },
    onChangeCustomerEntry: (customerId: string, isChecked: boolean) => (dispatch, getState: () => ApplicationState) => {
        let entries = [...getState().reservations.running.entries];

        let customerIndex = _.findIndex(entries, (entry) => entry.customerId == customerId);

        if (isChecked == true) {
            if (customerIndex < 0) {
                entries.push(getDefaultEntry(customerId, getState().reservations.eventId));
            } else {
                entries[customerIndex].isDeleted = false;
            }

        } else {
            if (customerIndex > -1) {
                entries[customerIndex].isDeleted = true;
            }
        }

        dispatch(actionCreator.setEntries(entries));
    },
    resetFilters: () => (dispatch, getState: () => ApplicationState) => {

        const baseState = getBaseSearchCriteria(getState().reservations.eventId);
        baseState.pageNumber = 0;
        baseState.hasNextPage = false;
        dispatch(actionCreator.setSearchCriteria(baseState));
        debounceSearch(dispatch);
        dispatch(actionCreator.resetLastHighlightedElement());
    }
}

export const reducer: Reducer<ReservationState> = (state: ReservationState | undefined, incomingAction: ReservationAction): ReservationState => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.RESET_STATE:
            let stateToUpdate = getBaseState();
            stateToUpdate.searchCriteria = newState.searchCriteria;
            stateToUpdate.lastClickedId = newState.lastClickedId;
            stateToUpdate.lastHighlightedElement = newState.lastHighlightedElement;
            newState = { ...stateToUpdate };
            break;
        case TypeKeys.SET_AGENT_ROLES:
            newState = dotProp.set(newState, "agentRoles", [...incomingAction.list]);
            break;
        case TypeKeys.APPEND_LIST:
            let list = newState.list.concat(incomingAction.list);
            newState = dotProp.set(newState, 'list', [...list]);
            break;
        case TypeKeys.SET_LIST:
            newState = dotProp.set(newState, "list", [...incomingAction.list]);
            break;
        case TypeKeys.SET_SEARCH_CRITERIA:
            newState = dotProp.set(newState, "searchCriteria", { ...incomingAction.searchCriteria });
            break;
        case TypeKeys.MODULE_LOADED:
            newState = dotProp.set(newState, "moduleLoaded", incomingAction.loaded);
            break;
        case TypeKeys.SET_RUNNING_STATE:
            newState = dotProp.set(newState, "running", { ...incomingAction.model });
            break;
        case TypeKeys.SET_RUNNING_RESERVATION:
            newState = dotProp.set(newState, "running.reservation", { ...incomingAction.model });
            break;
        case TypeKeys.SET_RUNNING_AGENT_ROLE:
            newState = dotProp.set(newState, "running.runningAgentRole", { ...incomingAction.role });
            break;
        case TypeKeys.SET_TOTAL_COUNT:
            newState = dotProp.set(newState, "totalCount", incomingAction.count);
            break;
        case TypeKeys.SET_GUESTS_COUNT:
            newState = dotProp.set(newState, "guestsCount", incomingAction.count);
            break;
        case TypeKeys.SET_EVENT_ID:
            newState = dotProp.set(newState, "eventId", incomingAction.id);
            break;
        case TypeKeys.SET_ENTRIES:
            if (incomingAction.entries === undefined || incomingAction.entries == null)
                newState = dotProp.set(newState, "running.entries", []);
            else
                newState = dotProp.set(newState, "running.entries", [...incomingAction.entries]);
            break;
        case TypeKeys.SET_LAST_CLICKED_ID:
            newState = dotProp.set(newState, "lastClickedId", incomingAction.lastClickedId);
            newState = dotProp.set(newState, "lastHighlightedElement", incomingAction.lastClickedId);
            break;
        case TypeKeys.RESET_LAST_CLICKED_ELEMENT:
            newState = dotProp.set(newState, "lastClickedId", null);
            break;
        case TypeKeys.RESET_LAST_HIGHLIGHTED_ELEMENT:
            newState = dotProp.set(newState, "lastHighlightedElement", null);
            break;
        case TypeKeys.SET_RUNNING_PAYMENT:
            newState = dotProp.set(newState, "running.payment", incomingAction.payment);
            break;
        case TypeKeys.PAYMENT_MODAL_TOGGLE:
            newState = dotProp.set(newState, "running.isPaymentModalOpen", incomingAction.isOpen);
            break;
        case TypeKeys.ADVANCED_MODAL_TOGGLE:
            newState = dotProp.set(newState, "advancedFiltersOpened", incomingAction.isOpen);
            break;

    }

    return newState;
}