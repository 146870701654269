import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { PaymentCategory, PaymentItem } from '../../store/payments/paymentitems/PaymentItemEntities';
import BaseSelect from "../base/baseselect";
import _ from "underscore";

interface ItemsDropDownComponentPros {
    Items: Array<PaymentItem>;
    selectedValue?: string;
}

interface ItemsDropDownCallback {
    onChange: (id: string) => void;
}

class ItemsDropDownComponent extends React.Component<ItemsDropDownComponentPros & ItemsDropDownCallback>{
    constructor(props) {
        super(props);
    }

    render() {

        if (!this.props.Items)
            return (<React.Fragment />)

        return (<BaseSelect descField="description" idField="id" options={this.props.Items} onChange={(value: string) => this.props.onChange(value)} value={this.props.selectedValue} />)
    }
}

interface ItemsDropDownProps {
    onChange: (id: string) => void;
    selected?: string;
    category: PaymentCategory
}

const mapStateToProsp = (state: ApplicationState, ownProps: ItemsDropDownProps): ItemsDropDownComponentPros => {
    return {
        Items: _.filter(state.paymentItems.paymentItems, (item) => item.category == ownProps.category && item.isVisible),
        selectedValue: ownProps.selected
    }
}

const mapDispatchToProps = () => (dispatch, ownProps: ItemsDropDownProps): ItemsDropDownCallback => {
    return ({
        onChange: (value: string) => ownProps.onChange(value)
    });
}

export const ItemsDropDown = connect<ItemsDropDownComponentPros, ItemsDropDownCallback, ItemsDropDownProps>(mapStateToProsp, mapDispatchToProps)(ItemsDropDownComponent);