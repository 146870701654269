import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState, reducers } from './';
import { loadingBarReducer } from 'react-redux-loading-bar';

export default function configureStore(history: History, initialState?: ApplicationState) {
    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history),
        loadingBar: loadingBarReducer
    });

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware))
    );

}
