import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router"
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/events/EventState';
import { EventEditPage, EventEditPageCallbacks, EventEditPageProps } from '../pages/editpage';

const mapStateToProsp = (state: ApplicationState): EventEditPageProps => {

    return {
        event: state.events.running
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): EventEditPageCallbacks => {
    return ({
        onDelete: () => dispatch(actionsMiddleware.delete()),
        onLoad: (id: string) => dispatch(actionsMiddleware.load(id)),
        onSubmit: () => dispatch(actionsMiddleware.save())
    });
}

export const ConnectedEventEdit = withRouter(connect<EventEditPageProps, EventEditPageCallbacks>(mapStateToProsp, mapDispatchToProps)(EventEditPage));