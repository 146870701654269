import * as React from "react";
import { MenuItemProps } from '../../../menu/menuitem';
import { BodyHeader } from '../../../base/bodyheader';
import { AccountingSummary, PaymentsSummaryCompareReadModel } from '../../../../store/payments/accountingsummary/AccountingSummaryEntities';
import { StickyBodyHeader } from "../../../base/stickybodyheader";
import { List } from "../../../base/list";
import { Col, Row } from "reactstrap";

import './list.css';
import GoToTop from "../../../base/gototop";
import { formatToLocaleDateTime, guid } from "../../../../utils/commons";
import { PaymentCategory } from "../../../../store/payments/paymentitems/PaymentItemEntities";

export interface AccountingSummaryPageProps {
    eventFullName: string;
    isFreezed: boolean;
    summary: AccountingSummary;
}

export interface AccountingSummaryPageCallbacks {
    onLoad: (id: string) => void;
    onOpen: (id: string) => void;
    onClose: (id: string) => void;
}

export class AccountingSummaryPage extends React.Component<AccountingSummaryPageProps & AccountingSummaryPageCallbacks> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let id = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                id = (this.props as any).match.params.eventId;
            this.props.onLoad(id);
        }
    }

    private getHead(): JSX.Element {
        return (
            <Row key="list-header" className="text-eurorounders list-row header">
                <Col lg="3" sm="3" xs="3" >
                    <div className="summary-table-cell">
                        <div>Oggetto</div>
                    </div>
                </Col>
                <Col lg="3" sm="3" xs="3">
                    <div className="summary-table-cell">
                        <div>Tipo</div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="2" >
                    <div className="summary-table-cell">
                        <div>Calcolato</div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="2">
                    <div className="summary-table-cell">
                        <div>Effettivo</div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="2">
                    <div className="summary-table-cell">
                        <div>Residuo</div>
                    </div>
                </Col>
            </Row>
        );

    }

    private getRows(list: Array<PaymentsSummaryCompareReadModel>): JSX.Element[] {

        let toReturn = list.map(payment => {

            let residualColor = "";

            if (payment.residual && payment.calculatedAmount) {
                residualColor = "text-warning";
            }

            if (payment.residual && payment.calculatedAmount && payment.residual < 0) {
                residualColor = "text-danger";
            }

            return (
                <Row key={guid()} className="text-eurorounders list-row">
                    <Col lg="3" sm="3" xs="3" >
                        <div className="summary-table-cell">
                            <div>{payment.description}</div>
                        </div>
                    </Col>
                    <Col lg="3" sm="3" xs="3">
                        <div className="summary-table-cell">
                            <div>{payment.category == PaymentCategory.Cost ? "Costo" : "Entrata"}</div>
                        </div>
                    </Col>
                    <Col lg="2" sm="2" xs="2" >
                        <div className="summary-table-cell">
                            <div>{!payment.calculatedAmount ? "" : `${payment.calculatedAmount}€`}</div>
                        </div>
                    </Col>
                    <Col lg="2" sm="2" xs="2">
                        <div className="summary-table-cell">
                            <div>{payment.amount}€</div>
                        </div>
                    </Col>
                    <Col lg="2" sm="2" xs="2">
                        <div className={`summary-table-cell ${residualColor}`}>
                            <div>{!payment.residual || !payment.calculatedAmount ? "" : `${payment.residual}€`}</div>
                        </div>
                    </Col>
                </Row>
            );
        });

        toReturn.push((
            <Row key={guid()} className="list-row font-weight-bold text-warning">
                <Col lg="3" sm="3" xs="3">
                    <div className="summary-table-cell">
                        <div>Totale</div>
                    </div>
                </Col>
                <Col lg="3" sm="3" xs="3" >
                    <div className="summary-table-cell">
                        <div>Costi</div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="2" >
                    <div className="summary-table-cell">
                        <div></div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="2">
                    <div className="summary-table-cell">
                        <div>{this.props.summary.totalCost}€</div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="2">
                    <div className="summary-table-cell">
                        <div></div>
                    </div>
                </Col>
            </Row>
        ));

        toReturn.push((
            <Row key={guid()} className="list-row font-weight-bold text-success">
                <Col lg="3" sm="3" xs="3">
                    <div className="summary-table-cell">
                        <div>Totale</div>
                    </div>
                </Col>
                <Col lg="3" sm="3" xs="3" >
                    <div className="summary-table-cell">
                        <div>Entrate</div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="2" >
                    <div className="summary-table-cell">
                        <div></div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="2">
                    <div className="summary-table-cell">
                        <div>{this.props.summary.totalIncome}€</div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="2">
                    <div className="summary-table-cell">
                        <div></div>
                    </div>
                </Col>
            </Row>
        ));

        let totalColor = this.props.summary.total < 0 ? "text-danger" : "text-success";

        toReturn.push((
            <Row key={guid()} className={`list-row font-weight-bold ${totalColor}`}>
                <Col lg="3" sm="3" xs="3">
                    <div className="summary-table-cell">
                        <div>Totale</div>
                    </div>
                </Col>
                <Col lg="3" sm="3" xs="3" >
                    <div className="summary-table-cell">
                        <div></div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="2" >
                    <div className="summary-table-cell">
                        <div></div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="2">
                    <div className="summary-table-cell">
                        <div>{this.props.summary.total}€</div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="2">
                    <div className="summary-table-cell">
                        <div></div>
                    </div>
                </Col>
            </Row>
        ));

        return toReturn;
    }


    render() {
        let menuItems = [];

        if (this.props.isFreezed == true) {
            menuItems.push({
                icon: "lock",
                label: "riapri",
                isButton: true,
                isSmall: true,
                onClick: () => this.props.onOpen((this.props as any).match.params.eventId)
            })
        } else {
            menuItems.push(
                {
                    icon: "lock-open",
                    label: "chiudi",
                    isButton: true,
                    isSmall: true,
                    onClick: () => this.props.onClose((this.props as any).match.params.eventId)
                }
            );
        }

        menuItems.push({
            icon: "hand-holding-usd",
            label: "conto",
            isButton: false,
            isSmall: true,
            path: `/events/${(this.props as any).match.params.eventId}/v2/accounting`
        } as MenuItemProps);

        return (
            <React.Fragment>
                <StickyBodyHeader>
                    <BodyHeader bottomSpace subTitle={`${this.props.eventFullName} Ultimo aggiornamento: ${formatToLocaleDateTime(this.props.summary.lastUpdate)}`} title={`Riepilogo`} menuItems={menuItems} />
                    <List justHead getHead={() => { return this.getHead() }} />
                </StickyBodyHeader>
                <List hasNextPage={false} list={this.props.summary.summaries} active={false} getElements={(list: Array<PaymentsSummaryCompareReadModel>) => { return this.getRows(list) }} />
                <GoToTop />
            </React.Fragment>
        )
    }
}