import * as React from "react";
import { ConfirmationModal } from '../../base/confirmationmodal';
import { MenuItemProps, MenuItem } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { Customer } from '../../../store/customers/CustomerEntities';
import { ConnectedCustomerForm } from '../connected/connected.form';
import { UnsavedChanges } from "../../base/unsavedchanges";
import { GPReader } from "../../base/qrreader";
import { GpModel } from "../../../utils/commons";

export interface CustomerEditPageProps {
    customer?: Customer;
    onLoad?: (id: string) => void;
    onDelete?: () => void;
    onSubmit?: () => void;
    toogleQRReader?: () => void;
    onScan?: (data: GpModel) => void;
}


export class CustomerEditPage extends React.Component<CustomerEditPageProps>{
    private modalRef: React.RefObject<ConfirmationModal>;
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let id = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                id = (this.props as any).match.params.id;
            this.props.onLoad(id);
        }
    }

    private delete() {
        if (this.props.onDelete)
            this.props.onDelete();
    }

    render() {

        if (this.props.customer === undefined || this.props.customer == null) {
            return (<React.Fragment></React.Fragment>);
        }

        let isNew = true;

        let menuItems = [];

        menuItems.push({
            icon: "qrcode",
            label: "GP",
            isButton: true,
            isDisabled: false,
            onClick: () => { this.props.toogleQRReader() }
        } as MenuItemProps);

        if (this.props.customer.id !== undefined && this.props.customer.id != null) {
            menuItems.push({
                icon: "trash",
                label: "elimina",
                isButton: true,
                isDisabled: false,
                onClick: () => { this.modalRef.current.toogleModal() }
            } as MenuItemProps);

            isNew = false;
        }

        menuItems.push({
            icon: "list",
            label: "lista",
            isButton: false,
            isSmall: true,
            path: "/customers"
        } as MenuItemProps);

        return (
            <React.Fragment>
                <UnsavedChanges confirmAction={this.props.onSubmit} />
                <BodyHeader title={isNew == true ? "Crea Cliente" : "Modifica Cliente"} menuItems={menuItems} />
                <GPReader onScan={(data: GpModel) => { this.props.onScan(data) }} />
                <ConnectedCustomerForm isSaveButtonEnabled={true} />
                <ConfirmationModal text="Eliminare cliente?" onConfirm={() => this.delete()} ref={this.modalRef} />
            </React.Fragment>
        )
    }
}