import * as React from "react";
import { Event } from '../../../store/events/EventEntities';
import { KeyDesc } from '../../../store/shared/KeyDesc';
import { MenuItem } from '../../menu/menuitem';
import { Form, FormGroup, Row, Col, Input, Label, InputGroupAddon, InputGroup, InputGroupText, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import DecimalInput from '../../base/decimalinput';
import moment from "moment";
import { ConnectedLocationsList } from '../../locations/connected/connected.list';
import { ConnectedLocationSearchForm } from '../../locations/connected/connected.searchform';
import { Location } from '../../../store/locations/LocationEntities';
import { ConnectedEventsSearchForm } from '../connected/connected.searchform';
import { ConnectedEventsList } from '../connected/connected.list';
import IntegerInput from "../../base/integerinput";
import { ERDatePicker as DatePicker } from '../../base/datepicker';


export interface EventFormProps {
    event?: Event;
}

export interface EventFormCallbacks {
    onSubmit: () => void;
    onChange: (propertyName: string, value: any) => void;
    loadExternalModule: () => void;
    confirmSelection: () => void;
    onSelect: (model: Location) => void;
    onSelectEvent: (id: string) => void;
}

interface EventFormState {
    isModalOpen: boolean;
}

export class EventForm extends React.Component<EventFormProps & EventFormCallbacks, EventFormState>{
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false
        }
    }

    private toogleModal() {

        if (!this.state.isModalOpen == true) {
            //opening modal triggher location module
            this.props.loadExternalModule();
        }

        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    render() {

        if (this.props.event === undefined || this.props.event == null) {
            return (<React.Fragment></React.Fragment>)
        }

        return (
            <React.Fragment>
                <Form className="text-eurorounders" >
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input className="bg-dark text-eurorounders" type="text" value={this.props.event.name || ''} onChange={(e) => { this.props.onChange("name", e.target.value) }} />
                            </FormGroup>
                        </Col>
                        <Col lg="6" sm="6" xs="12">
                            <Label>Tipo</Label>
                            <Input className="bg-dark text-eurorounders" type="text" value={this.props.event.type || ''} onChange={(e) => { this.props.onChange("type", e.target.value) }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Data inizio</Label>
                                <DatePicker selectedDate={this.props.event.dateFrom} onChange={date => this.props.onChange("dateFrom", date)} />
                            </FormGroup>
                        </Col>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Data fine</Label>
                                <DatePicker selectedDate={this.props.event.dateTo} onChange={date => this.props.onChange("dateTo", date)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Data di lancio</Label>
                                <DatePicker selectedDate={this.props.event.launchDate} onChange={date => this.props.onChange("launchDate", date)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" sm="12" xs="12">
                            <FormGroup>
                                <Label>Location</Label>
                                <InputGroup>
                                    <Input readOnly={true} className="bg-dark text-eurorounders" type="text" value={this.props.event.location !== undefined && this.props.event.location != null && this.props.event.location.name !== undefined && this.props.event.location.name != null ? this.props.event.location.name : ''} onClick={() => this.toogleModal()} />
                                    <InputGroupAddon addonType="append">
                                        <Button onClick={() => this.props.onChange("location", null)}>
                                            <i className="icon fas fa-times fa-xs" />
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Valore garantito</Label>
                                <InputGroup>
                                    <DecimalInput onChane={(value: string) => this.props.onChange("guaranteedValue", value)} value={this.props.event.guaranteedValue} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Buy-in</Label>
                                <InputGroup>
                                    <DecimalInput onChane={(value: string) => this.props.onChange("buyIn", value)} value={this.props.event.buyIn} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>A montepremi</Label>
                                <InputGroup>
                                    <DecimalInput onChane={(value: string) => this.props.onChange("toPrizePoolValue", value)} value={this.props.event.toPrizePoolValue} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="3" xs="12">
                            <FormGroup>
                                <Label>Fee</Label>
                                <InputGroup>
                                    <DecimalInput onChane={(value: string) => this.props.onChange("feePerPartecipant", value)} value={this.props.event.feePerPartecipant} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="3" xs="12">
                            <FormGroup>
                                <Label>Fee ER %</Label>
                                <InputGroup>
                                    <DecimalInput onChane={(value: string) => this.props.onChange("earningFeePercentage", value)} value={this.props.event.earningFeePercentage} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">%</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Totale Entries</Label>
                                <InputGroup>
                                    <IntegerInput
                                        onChane={(value?: number) => this.props.onChange("finalEntries", value)}
                                        value={this.props.event.finalEntries}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">#</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Totale Players</Label>
                                <InputGroup>
                                    <IntegerInput
                                        onChane={(value?: number) => this.props.onChange("finalPartecipants", value)}
                                        value={this.props.event.finalPartecipants}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">#</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <MenuItem isSmall={true} isButton={true} icon="save" label="salva" onClick={() => this.props.onSubmit()} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <Modal className="bg-dark modal-lg" isOpen={this.state.isModalOpen} toggle={() => this.toogleModal()} backdrop="static">
                    <ModalHeader className="text-eurorounders bg-dark" toggle={() => this.toogleModal()}>Seleziona location</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders">
                        <ConnectedLocationSearchForm />
                        <ConnectedLocationsList selectionEnabled={true} onRowClick={this.props.onSelect} />
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon="check" label="Conferma" onClick={() => { this.props.confirmSelection(); this.toogleModal(); }} />
                        <MenuItem isSmall={true} isButton={true} icon="times" label="Annulla" onClick={() => this.toogleModal()} />
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}