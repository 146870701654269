import * as React from "react";
import { ConfirmationModal } from '../../base/confirmationmodal';
import { MenuItemProps } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { ConnectedHotelPriceList } from '../connected/connected.hotelpricelist';
import { StickyBodyHeader } from "../../base/stickybodyheader";

export interface HotelsPricingListProps {
    eventFullName: string;

}

export interface HotelsPricingListCallbacks {
    onLoad: (eventId: string) => void;
}


export default class HotelsPricingListPage extends React.Component<HotelsPricingListProps & HotelsPricingListCallbacks>{
    private modalRef: React.RefObject<ConfirmationModal>;
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let eventId = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                eventId = (this.props as any).match.params.eventId;
            this.props.onLoad(eventId);
        }
    }


    render() {

        let eventId = "";
        if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
            eventId = (this.props as any).match.params.eventId;
        else
            return (<React.Fragment></React.Fragment>);

        let menuItems = [
            {
                icon: "plus",
                label: "crea",
                isDisabled: false,
                path: `/events/${eventId}/hotels/create`
            },
            {
                icon: "battery-half",
                label: "stato",
                isDisabled: false,
                path: `/events/${eventId}/hotels/bookingstatus`
            },
            {
                icon: "ellipsis-h",
                label: "Evento",
                isButton: false,
                isSmall: true,
                path: `/events/${eventId}/menu`
            } as MenuItemProps
        ] as Array<MenuItemProps>


        return (
            <React.Fragment>
                <StickyBodyHeader>
                    <BodyHeader subTitle={this.props.eventFullName} title="Lista configurazioni hotel" menuItems={menuItems} />
                </StickyBodyHeader>
                <ConnectedHotelPriceList />
            </React.Fragment>
        )
    }
}