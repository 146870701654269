import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { HotelAccountingList, HotelAccountingListProps } from '../base/accountingdetails/hotelsaccounting';


const mapStateToProsp = (state: ApplicationState): HotelAccountingListProps => {

    return {
        list: state.eventAccountings.running !== undefined && state.eventAccountings.running != null && state.eventAccountings.running.model !== undefined && state.eventAccountings.running.model != null ? state.eventAccountings.running.model.hotelsAccounting : []
    }
}



export const ConnectedHotelsAccountingList = connect<HotelAccountingListProps>(mapStateToProsp, {})(HotelAccountingList);