import * as React from 'react';
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { Anagraphic as AnagraphicState } from '../../store/shared/Anagraphic';
import ContactForm from './contacts';

export interface AnagraphicProps extends AnagraphicState {
    customerContext?: boolean;
    onChange?: (propertyName: string, value: any) => void;
}

export class Anagraphic extends React.Component<AnagraphicProps>{
    constructor(props) {
        super(props);
    }

    private setStateValue(propertyName: string, value: any) {
        if (this.props.onChange)
            this.props.onChange(propertyName, value);
    }

    render() {

        let emailLabel = "Email/Username";

        if (this.props.customerContext == true)
            emailLabel = "Email";

        return (
            <React.Fragment>
                <Row>
                    <Col lg="6" sm="6" xs="12">
                        <FormGroup>
                            <Label>Nome</Label>
                            <Input className="bg-dark text-eurorounders"  type="text" value={this.props.firstName || ''} onChange={(e) => { this.setStateValue("firstName", e.target.value) }} />
                        </FormGroup>
                    </Col>
                    <Col lg="6" sm="6" xs="12">
                        <FormGroup>
                            <Label>Cognome</Label>
                            <Input className="bg-dark text-eurorounders"  type="text" value={this.props.lastName || ''} onChange={(e) => { this.setStateValue("lastName", e.target.value) }} />
                        </FormGroup>
                    </Col>
                </Row>
                <ContactForm email={this.props.email} emailLabel={emailLabel} onChange={(propertyName: string, value: any) => this.setStateValue(propertyName, value)} phone={this.props.phone} phonePrefix={this.props.phonePrefix} />
            </React.Fragment>
        )
    }

}