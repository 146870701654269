import * as React from "react";
import { MenuItemProps } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { ConnectedReservationSearchFormBaseList } from '../connected/connected.searchformbase';
import { ConnectedReservationSearchFormAdvanced } from '../connected/connected.searchformadvanced';
import { ConnectedReservationsList } from '../connected/connected.list';
import { StickyBodyHeader } from "../../base/stickybodyheader";
import { ConnectedReservationsListHeader } from "../connected/connected.listheader";

export interface ReservationsPageListProps {
    totalCount: number;
    guestsCount: number;
    eventFullName: string;
    isProgressiveAssignmentEnabled: boolean;
}

export interface ReservationsPageListCallbacks {
    onLoad: (eventId: string) => void;
    onPrint: () => void;
    onExcelExtraction: () => void;
    onGenerateProgressiveNumbers: () => void;
}

export class ReservationsPageList extends React.Component<ReservationsPageListProps & ReservationsPageListCallbacks> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let eventId = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                eventId = (this.props as any).match.params.eventId;
            this.props.onLoad(eventId);
        }
    }

    

    render() {

        let eventId = null;
        if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
            eventId = (this.props as any).match.params.eventId;

        let menuItems = [
            {
                icon: "hashtag",
                label: "ass. prog.",
                isButton: true,
                isSmall: true,
                onClick: () => this.props.onGenerateProgressiveNumbers(),
                isDisabled: !this.props.isProgressiveAssignmentEnabled
            } as MenuItemProps,
            {
                icon: "file-excel",
                label: "EXCEL",
                isButton: true,
                isSmall: true,
                onClick: () => this.props.onExcelExtraction(),
                isDisabled: this.props.totalCount < 1
            } as MenuItemProps,
            {
                icon: "file-pdf",
                label: "PDF",
                isButton: true,
                isSmall: true,
                onClick: () => this.props.onPrint()
            } as MenuItemProps,
            {
                icon: "plus",
                label: "crea",
                path: `/events/${eventId}/reservations/create`
            },
            {
                icon: "ellipsis-h",
                label: "Evento",
                isButton: false,
                isSmall: true,
                path: `/events/${(this.props as any).match.params.eventId}/menu`
            } as MenuItemProps
        ] as Array<MenuItemProps>;

        return (
            <React.Fragment>
                <StickyBodyHeader>
                    <BodyHeader subTitle={this.props.eventFullName} title={`Prenotazioni (${this.props.totalCount}) Ospiti (${this.props.guestsCount})`} menuItems={menuItems} />
                    <ConnectedReservationSearchFormBaseList />
                    <ConnectedReservationsListHeader />
                </StickyBodyHeader>
                <ConnectedReservationsList />
                <ConnectedReservationSearchFormAdvanced />
            </React.Fragment>
        )
    }
}