import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { withRouter } from "react-router";
import { CurrentProfile, CurrentProfileProps } from '../currentprofile';
import { Profile, Account } from '../../../store/profile/Profile';
import { actionCreators } from '../../../store/profile/CurrentProfile';
import { Anagraphic } from '../../../store/shared/Anagraphic';


const mapStateToProsp = (state: ApplicationState): CurrentProfileProps => {

    return {
        ...state.currentProfile.editingProfile,
        password: state.currentProfile.editingProfile.account.password,
        confirmPassword: state.currentProfile.editingProfile.account.confirmPassword
    }
}

const mapDispatchToProps = () => (dispatch, getState) => {
    return ({
        onSubmit: () => {
            dispatch(actionCreators.updateProfile());
        },
        onChange: (propertyName: string, value: any) => {
            dispatch(actionCreators.setUiProfileProp(propertyName, value));
        }
    });
}

export const ConnectedCurrentProfile = withRouter(connect(mapStateToProsp, mapDispatchToProps)(CurrentProfile));
