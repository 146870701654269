import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ApplicationState } from '../../../store/index';
import { actionCreator } from '../../../store/profile/ProfilesSate';
import { actionCreator as actionCreatorsUI } from '../../../store/uiproperties/UIState';
import { ProfileEditPage, ProfileEditPageProps } from '../pages/profileeditpage';

const mapStateToProps = (state: ApplicationState): ProfileEditPageProps => {
    return {
        isNew: state.profiles.runningProfile == undefined || state.profiles.runningProfile == null || state.profiles.runningProfile.id === undefined || state.profiles.runningProfile.id == null || state.profiles.runningProfile.id == '',
        pageLoaded: state.uiProperties.pageInitialized,
        profile: state.profiles.runningProfile
    }
}

const mapDispatchToProps = () => dispatch => {
    return {
        onLoad: (id: string) => {
            dispatch(actionCreator.loadRunningProfile(id));
        },
        onDelete: () => {
            dispatch(actionCreator.delete());
        },
        onSubmit: () => dispatch(actionCreator.saveProfile())
    }
}

export const ConnectedProfileEditPage = withRouter(connect<ProfileEditPageProps>(mapStateToProps, mapDispatchToProps)(ProfileEditPage))