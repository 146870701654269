import * as React from "react";
import { EventAccounting, mapStatusToName, EventAccountingStatus } from "../../../store/eventaccountings/EventAccountingEntities";
import { ConfirmationModal } from '../../base/confirmationmodal';
import { BaseList } from '../../base/baseList';
import _ from "underscore";
import { guid, formatToLocaleDateTime } from '../../../utils/commons';
import GoToTop from "../../base/gototop";


export interface EventAccountListProps {
    list: Array<EventAccounting>;
}

export interface EventAccountListCallbacks {
    onRowClick: (id: string) => void;
}

export class EventAccountingList extends React.Component<EventAccountListProps & EventAccountListCallbacks>{

    constructor(props) {
        super(props);
    }


    private getRows(list: Array<EventAccounting>): JSX.Element[] {

        let customerArray = list.map(accounting => {

            let requestDate = formatToLocaleDateTime(accounting.requestDate);
            let status = mapStatusToName(accounting.status);
            let result = "-"

            if (accounting.totalNetGain !== undefined && accounting.totalNetGain != null)
                result = accounting.totalNetGain.toString();

            let style = {};

            if (accounting.status == EventAccountingStatus.Completed)
                style = { cursor: "pointer" };

            return (
                <tr key={accounting.id} style={style} onClick={() => {
                    if (accounting.status == EventAccountingStatus.Completed)
                        this.props.onRowClick(accounting.id);
                }}>
                    <td>
                        {requestDate}
                    </td>
                    <td>
                        {status}
                    </td>
                    <td>
                        {result}
                    </td>
                </tr >
            )
        });

        return customerArray;

    }

    private getHead(): JSX.Element {

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>Data</th>
                    <th>Stato</th>
                    <th>Totale</th>
                </tr>
            </thead>
        );

    }


    render() {

        return (
            <React.Fragment>
                <BaseList hasNextPage={false} list={this.props.list} active={false} onEndOfList={() => { }} getElements={(list: Array<EventAccounting>) => { return this.getRows(list) }} getHead={() => { return this.getHead() }} />
                <GoToTop />
            </React.Fragment>
        )
    }
}