import * as React from 'react';
import { connect } from 'react-redux';

import { withRouter } from "react-router";
import { ApplicationState } from '../../store';
import { Login, LoginProps } from './Login';
import { actionCreators } from '../../store/profile/CurrentProfile';

const mapDispatchToProps = () => dispatch => {
    return ({
        onSubmit: (signInName: string, password: string, rememberMe: boolean) => {
            dispatch(actionCreators.login(signInName, password, rememberMe));
        }
    });
}

export const ConnectedLogin = withRouter(connect(null, mapDispatchToProps)(Login));