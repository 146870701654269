import * as React from "react";
import { MenuItemProps } from '../../../menu/menuitem';
import { BodyHeader } from '../../../base/bodyheader';
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { ConnectedHotelsSummary } from './connected.hotelssummary';
import { ConnectedHotelsAccountingV2List } from './connected.hotelaccountingv2';
import { ConnectedOutcomeModal } from "../../../payments/outcome/connected.outcomemodal";
import { formatToLocaleDateTime } from "../../../../utils/commons";


export interface HotelsPaymentsPageProps {
    eventFullName: string;
    lastUpdate: string;
}

export interface HotelsPaymentsPageCallbacks {
    onLoad: (id: string) => void;
    openOutcomeModal: (eventId: string) => void;
}

enum TabActive {
    Total = "Total",
    Hotels = "Hotels"
}

interface AccountingDetailsPageState {
    activeTab: TabActive;
}

export class HotelsPaymentsPage extends React.Component<HotelsPaymentsPageProps & HotelsPaymentsPageCallbacks, AccountingDetailsPageState> {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: TabActive.Total
        }
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let id = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                id = (this.props as any).match.params.eventId;
            this.props.onLoad(id);
        }
    }

    private toogleTab(active: TabActive) {
        this.setState({ activeTab: active })
    }


    render() {


        let menuItems = [
            {
                icon: "arrow-up",
                label: "uscita",
                isButton: true,
                isSmall: true,
                onClick: () => this.props.openOutcomeModal((this.props as any).match.params.eventId)
            } as MenuItemProps,
            {
                icon: "hand-holding-usd",
                label: "conto",
                isButton: false,
                isSmall: true,
                path: `/events/${(this.props as any).match.params.eventId}/v2/accounting`
            } as MenuItemProps
        ] as Array<MenuItemProps>;

        return (
            <React.Fragment>
                <BodyHeader bottomSpace subTitle={`${this.props.eventFullName} Ultimo aggiornamento: ${formatToLocaleDateTime(this.props.lastUpdate)}`} title={`Hotels`} menuItems={menuItems} />
                <Nav tabs className="text-eurorounders">
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab == TabActive.Total ? "active" : ""}
                            onClick={() => { this.toogleTab(TabActive.Total); }}
                        >
                            Sommario
                            </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab == TabActive.Hotels ? "active" : ""}
                            onClick={() => { this.toogleTab(TabActive.Hotels); }}
                        >
                            Dettagli
                            </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab.toString()}>
                    <TabPane tabId={TabActive.Total.toString()}>
                        <ConnectedHotelsSummary />
                    </TabPane>
                    <TabPane tabId={TabActive.Hotels.toString()}>
                        <ConnectedHotelsAccountingV2List />
                    </TabPane>
                </TabContent>
                <ConnectedOutcomeModal afterSave={(eventId: string) => this.props.onLoad(eventId)} />
            </React.Fragment>
        )
    }
}