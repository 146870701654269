import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionCreator } from '../../../store/agents/AgentsState';
import { actionCreator as uiActions } from '../../../store/uiproperties/UIState';
import { AgentsList, AgentsListProps } from '../shared/agentslist';

export interface ConnectedAgentListProps {
    onRowClick?: (id: string) => void;
    selectionEnabled: boolean;
}

const mapStateToProsp = (state: ApplicationState, ownProps: ConnectedAgentListProps): AgentsListProps => {

    return {
        hasNextPage: state.agents.searchState.hasNextPage,
        list: state.agents.agentsList,
        startLoading: state.uiProperties.startFetchingPagedList,
        selectionEnabled: ownProps.selectionEnabled,
        selectedAgentId: state.agents.selectedAgent !== undefined && state.agents.selectedAgent != null ? state.agents.selectedAgent.id : null
    }
}

const mapDispatchToProps = (dispatch, ownProps: ConnectedAgentListProps) => dispatch => {
    return ({
        loadNewPage: () => {
            dispatch(actionCreator.loadNextPage());
        },
        onRowClick: (agentId: string) => {
            dispatch(ownProps.onRowClick(agentId));
        },
        onUnload: () => dispatch(uiActions.startFetchingPagedList(false)),
        restartLoading: () => dispatch(uiActions.startFetchingPagedList(true))
    });
}

export const ConnectedAgentsList = connect<AgentsListProps, {}, ConnectedAgentListProps>(mapStateToProsp, mapDispatchToProps)(AgentsList);