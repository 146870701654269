export interface PaymentItem {
    id?: string;
    category?: PaymentCategory;
    description?: string;
    isCustom?: boolean;
    isVisible?: boolean;
    systemItem?: SystemPaymentItem;
}

export enum PaymentCategory {
    Income = "Income",
    Cost = "Cost",
    Refund = "Refund"
}

export enum SystemPaymentItem {
    Agent = "Agent",
    Reservation = "Reservation",
    Hotel = "Hotel",
    Entries = "Entries",
    Transfer = "Transfer"
}