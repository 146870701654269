import { connect } from 'react-redux';
import { withRouter } from "react-router"
import { ApplicationState } from '../../../../store/index';
import { PaymentCategory } from '../../../../store/payments/paymentitems/PaymentItemEntities';
import { actionsMiddleware } from '../../../../store/payments/paymentitems/PaymentItemState';
import { PaymentItemListPageCallbacks, PaymentItemListPageProps, PaymentItemsPageList } from '../pages/listpage';

export interface PaymentItemsListPageOwnProps {
    side: PaymentCategory;
}

const mapStateToProsp = (state: ApplicationState, ownProps: PaymentItemsListPageOwnProps): PaymentItemListPageProps => {
    return {
        loaded: state.paymentItems.loadCompleted,
        side: ownProps.side
    }
}

const mapDispatchToProps = () => (dispatch): PaymentItemListPageCallbacks => {
    return ({
        onLoad: () => dispatch(actionsMiddleware.loadInitialState())
    });
}

export const ConnectedPaymentItemPageList = withRouter(connect<PaymentItemListPageProps, PaymentItemListPageCallbacks, any>(mapStateToProsp, mapDispatchToProps)(PaymentItemsPageList));