import * as React from "react";
import { Profile, CommissionTemplate, CommissionTemplateModeTraslation } from '../../../store/profile/Profile';
import { Anagraphic } from '../../shared/anagraphic';
import AnagraphicAdditional from '../../shared/anagraphicadditionalinfo';
import AccountDataForm from '../../profile/shared/accountdata';
import Privacy from '../../shared/privacyrow';
import { MenuItem } from '../../menu/menuitem';
import AddRemove from '../../base/addremove';
import { Form, FormGroup, Row, Col, Label, Input, InputGroup, InputGroupAddon, Button } from "reactstrap";
import { ConfirmationModal } from '../../base/confirmationmodal';
import { ConnectedEditCommmisionTemplate } from '../commisiontemplates/connected.editcommissiontemplate';
import _ from "underscore";

import './agenteditform.css';

export interface AgentEditProps {
    agent: Profile;
    allCommisionTemplate: Array<CommissionTemplate>;
    onSubmit?: () => void;
    onChange?: (propertyName: string, value: any) => void;
    onAddAccount?: () => void;
    onRemoveAccount?: () => void;
    openModal?: () => void;
}

export class AgentEdit extends React.Component<AgentEditProps>{

    private modalRef: React.RefObject<ConfirmationModal>;
    private temporaryCommissionId: string = null;

    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }

    private onClosedModal() {
        this.temporaryCommissionId = null;
    }

    private changeCommission(commissionId: string, forceChange: boolean = false) {
        if (forceChange == true || this.props.agent.commisionTemplate === undefined || this.props.agent.commisionTemplate == null) {
            this.props.onChange("commissionTemplate", commissionId);
            return;
        }

        let previousSelected = _.findIndex(this.props.allCommisionTemplate, (comm) => comm.id == this.props.agent.commisionTemplate.id);

        if (previousSelected > -1) {
            this.props.onChange("commissionTemplate", commissionId);
            return;
        }

        this.temporaryCommissionId = commissionId;
        this.modalRef.current.toogleModal();
    }

    private getCommissionComponent(): JSX.Element {

        let commmissionArray = this.props.allCommisionTemplate.map(comm => {
            return (<option key={comm.id} value={comm.id}>{comm.type} {CommissionTemplateModeTraslation[comm.mode]}</option>)
        });

        if (commmissionArray === undefined || commmissionArray == null)
            commmissionArray = [];

        commmissionArray.push(<option key="commission-template-manual" value="manual">Manuale</option>);

        commmissionArray.unshift(<option key="commission-template-key" value={undefined}>-</option>);

        let commSelected = '';

        if (this.props.agent.commisionTemplate !== undefined && this.props.agent.commisionTemplate != null) {
            commSelected = this.props.agent.commisionTemplate.id;
            if (_.findIndex(this.props.allCommisionTemplate, (comm) => comm.id == commSelected) == -1) {
                commSelected = "manual";
            }
        }

        let disableEdit = "disable-events";

        if (commSelected == "manual")
            disableEdit = "";

        return (
            <Col lg="6" sm="6" xs="12">
                <FormGroup className="text-eurorounders">
                    <Label>Profilo provvigione</Label>
                    <InputGroup>
                        <Input type="select" value={commSelected} className="bg-dark text-eurorounders" onChange={(e) => this.changeCommission(e.target.value)}>
                            {commmissionArray}
                        </Input>
                        <InputGroupAddon addonType="append"><Button className={disableEdit} onClick={() => this.props.openModal()}><i className="icon fas fa-pen fa-xs" /></Button></InputGroupAddon>
                    </InputGroup>
                </FormGroup>
            </Col>

        )
    }

    render() {

        if (this.props.agent === undefined || this.props.agent == null) {
            return (<React.Fragment></React.Fragment>);
        }

        let commissionComponent = this.getCommissionComponent();

        return (
            <React.Fragment>
                <Form className="text-eurorounders agent-edit" >
                    <Anagraphic {...{ ...this.props.agent, onChange: (propertyName: string, value: object) => this.props.onChange(propertyName, value) }} />
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Area di competenza</Label>
                                <Input className="bg-dark text-eurorounders"  type="text" value={this.props.agent.agentArea || ''} onChange={(e) => { this.props.onChange("agentArea", e.target.value) }} />
                            </FormGroup>
                        </Col>
                        {commissionComponent}
                    </Row>
                    <AddRemove isAdded={this.props.agent.account !== undefined && this.props.agent.account != null} onAdd={() => this.props.onAddAccount()} onRemove={() => this.props.onRemoveAccount()} title="Account" />
                    <AccountDataForm
                        account={this.props.agent.account}
                        onChange={(propertyName: string, value: any) => this.props.onChange(propertyName, value)}
                        profileType={this.props.agent.type}
                    />
                    <Privacy {...{ ...this.props.agent, onChange: (propertyName: string, value: object) => this.props.onChange(propertyName, value) }} />
                    <AnagraphicAdditional {...{ ...this.props.agent, onChange: (propertyName: string, value: object) => this.props.onChange(propertyName, value) }} />
                    <Row>
                        <Col>
                            <FormGroup>
                                <MenuItem isSmall={true} isButton={true} icon="save" label="salva" onClick={() => this.props.onSubmit()} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <ConfirmationModal confirmIcon="check" text="Cambiando profilo provvigionale si perderanno i dati del manuale, continuare?" onConfirm={() => this.changeCommission(this.temporaryCommissionId, true)} ref={this.modalRef} onClosed={() => this.onClosedModal()} />
                <ConnectedEditCommmisionTemplate />
            </React.Fragment>
        )
    }
}