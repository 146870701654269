import moment from "moment";
import cbor from "cbor";
import base45 from "base45";
import pako from "pako"

export const formatToLocaleDate = (date: string): string => {
    if (date !== undefined && date != null) {
        let momentDate = moment(date);
        if (momentDate !== undefined && momentDate != null && momentDate.isValid()) {
            return momentDate.format("L");
        }
    }
    return date;
}

export const round = (value?: number, precision?: number): number => {
    if (value === undefined || value == null)
        return null;

    let p = precision || 2;

    return parseFloat(value.toFixed(p));
}

export const formatToLocaleDateTime = (date: string): string => {
    if (date !== undefined && date != null) {
        let momentDate = moment(date);
        if (momentDate !== undefined && momentDate != null && momentDate.isValid()) {
            return `${momentDate.format("L")} ${momentDate.format("LT")}`;
        }
    } else {
        date = "-"
    }
    return date;
}

export const guid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export enum OrderDirection {
    ASC = "Ascending",
    DESC = 'Descending'
}

export const PAGE_SIZE: number = 10;

export enum EnvironmentType {
    Stage = "stage environment",
    Production = "production environment"
}

export const getLocale = (): string => {

    let languageToSet = 'it';
    if (navigator.language !== undefined && navigator.language != null) {
        try {

            if (navigator.language.length > 2) {
                let splitted = navigator.language.split("-");

                if (splitted.length > 0)
                    languageToSet = splitted[0];

            } else if (navigator.language.length == 2) {
                languageToSet = navigator.language;
            }

        } catch { }
    }
    return languageToSet;
}

export const ENVIRONMENT: EnvironmentType = EnvironmentType.Production;

export interface GpModel {
    firstName: string;
    lastName: string;
    birthDate?: string;
}

export const gpDecoder = (data: string): GpModel => {
    if (!data)
        return null;
    const greenpassBody = data.substr(4);
    const decodedData = base45.decode(greenpassBody);
    const output = pako.inflate(decodedData);
    const results = cbor.decodeAllSync(output);
    let [headers1, headers2, cbor_data, signature]: any[] = results[0].value;
    const greenpassData = cbor.decodeAllSync(cbor_data);
    const gpData = greenpassData[0].get(-260).get(1);
    return {
        firstName: gpData.nam.gn,
        lastName: gpData.nam.fn,
        birthDate: gpData.dob
    } as GpModel;

}

const containsAll = (arr1, arr2) =>
    arr2.every(arr2Item => arr1.includes(arr2Item))

export const sameMembers = (arr1, arr2) =>
    containsAll(arr1, arr2) && containsAll(arr2, arr1);