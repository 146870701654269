import * as React from "react";
import { Form, FormGroup, Button, Input, CustomInput, Row, Col, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Permission, Role } from '../../../../store/profile/Role';
import { guid } from '../../../../utils/commons';
import _ from "underscore";
import { BodyHeader } from '../../../base/bodyheader';
import { MenuItemProps, MenuItem } from '../../../menu/menuitem';
import { ConfirmationModal } from '../../../base/confirmationmodal';
import { UnsavedChanges } from "../../../base/unsavedchanges";

export interface EditRoleProps {
    allPermission: Array<Permission>;
    role: Role;
    pageLoaded: boolean;
    onLoad?: (roleId: string) => void;
    onSubmit?: () => void;
    onChange?: (propertyName: string, value: any) => void;
    onSwitch?: (permission: Permission, checked: boolean) => void;
    onDelete?: (id: string) => void;
}



export class RoleEdit extends React.Component<EditRoleProps>{
    private modalRef: React.RefObject<ConfirmationModal>;
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }

    componentDidMount() {
        let id = (this.props as any).match.params.id;
        this.props.onLoad(id);
    }

    private getCustomInputs(): JSX.Element[] {

        return this.props.allPermission.map(perm => {

            let checked = _.some(this.props.role.permissions, (permission) => { return permission.id == perm.id })

            return (
                <CustomInput key={perm.id} id={perm.id.toString()} type="switch" label={perm.description} checked={checked} onChange={(e) => { this.props.onSwitch(perm, e.target.checked) }} />
            )
        });
    }

    private delete() {
        this.modalRef.current.toogleModal();
        if (this.props.onDelete)
            this.props.onDelete(this.props.role.id);
    }

    render() {

        if (this.props.pageLoaded == false) {
            return (
                <React.Fragment>
                </React.Fragment>
            )
        }

        let permissions = this.getCustomInputs();

        let menuItems = [];

        if (this.props.role.id !== undefined && this.props.role.id != null && this.props.role.id != '') {
            menuItems = [
                {
                    icon: "trash",
                    label: "elimina",
                    isButton: true,
                    isDisabled: false,
                    onClick: () => { this.modalRef.current.toogleModal() }
                }
            ] as Array<MenuItemProps>
        }

        menuItems.push({
            icon: "list",
            label: "lista",
            isButton: false,
            isSmall: true,
            path: "/profiles/roles"
        } as MenuItemProps);

        return (
            <React.Fragment>
                <UnsavedChanges confirmAction={this.props.onSubmit} />
                <BodyHeader title={this.props.role.id !== undefined && this.props.role.id != null && this.props.role.id != '' ? "Modifica Ruolo" : "Crea Ruolo"} menuItems={menuItems} />
                <Form className="text-eurorounders" >
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input className="bg-dark text-eurorounders"  type="text" value={this.props.role.name || ''} onChange={(e) => { this.props.onChange("name", e.target.value) }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <FormGroup>
                                {permissions}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <MenuItem isSmall={true} isButton={true} icon="save" label="salva" onClick={() => this.props.onSubmit()} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <ConfirmationModal text="Eliminare ruolo?" onConfirm={() => this.delete()} ref={this.modalRef} />
            </React.Fragment>
        )
    }
}
