import * as React from "react";
import { UncontrolledPopover, PopoverHeader, PopoverBody, Row, Col } from "reactstrap";
import { PaymentSearchReadModel, PaymentTypology } from "../../../../store/payments/payments/PaymentEntities";
import { List } from '../../../base/list';
import _ from "underscore";
import GoToTop from "../../../base/gototop";
import { formatToLocaleDate } from "../../../../utils/commons";
import { SystemPaymentItem, PaymentItem } from "../../../../store/payments/paymentitems/PaymentItemEntities";

import './list.css';
import { ConfirmationModal } from "../../../base/confirmationmodal";


export interface MovementsListProps {
    list: Array<PaymentSearchReadModel>;
    paymentItems: Array<PaymentItem>;
    hasNextPage: boolean;
    startLoading: boolean;
}

export interface MovementsListCallbacks {
    loadNewPage: () => void;
    onDelete: () => void;
    registerDelete: (id: string) => void;
}

export class MovementsList extends React.Component<MovementsListProps & MovementsListCallbacks>{

    private modalRef: React.RefObject<ConfirmationModal>;

    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }

    private onDeleteIntent(id: string) {
        this.props.registerDelete(id);
        this.modalRef.current.toogleModal();
    }

    private onDeleteConfirm() {
        this.props.onDelete();
        this.modalRef.current.toogleModal();
    }

    private getRows(list: Array<PaymentSearchReadModel>): JSX.Element[] {

        let renderList = list.map(model => {

            let itemDesc = model.itemDescription;

            if (model.systemItem == SystemPaymentItem.Agent)
                itemDesc = `${itemDesc} ${model.agentLastName} ${model.agentFirstName}`;

            if (model.typology == PaymentTypology.CashFund)
                itemDesc = "Fondo cassa";

            let notesColor = "";

            let hasNotes: boolean = model.notes !== undefined && model.notes != null && model.notes != '';

            if (hasNotes == true)
                notesColor = "text-primary";

            let notes: JSX.Element = (<React.Fragment />);

            if (model.notes) {
                notes = (
                    <React.Fragment>
                        <div>Note:</div>
                        <div>
                            {model.notes}
                        </div>
                    </React.Fragment>
                )
            }



            let amountColor = "text-danger font-weight-bold";

            if (model.typology == PaymentTypology.CashFund)
                amountColor = "text-warning font-weight-bold";

            if (model.typology == PaymentTypology.Inbound)
                amountColor = "text-success font-weight-bold";


            let trasferColumn = (
                <Col lg="1" sm="1" xs="1" style={{ cursor: "pointer" }} onClick={() => this.onDeleteIntent(model.id)}>
                    <div className="movements-table-cell">
                        <i className={`icon fas fa-trash fa-1x`} />
                    </div>
                </Col>
            );
            let trasferItem = _.find(this.props.paymentItems, (pItem) => pItem.systemItem == SystemPaymentItem.Transfer);

            if (trasferItem?.id == model.paymentItemId) {
                trasferColumn = (
                    <Col lg="1" sm="1" xs="1">
                        <div className="movements-table-cell">
                            -
                        </div>
                    </Col>
                );

                amountColor = "";
            }

            return (
                <Row id={model.id} key={model.id} className="list-row">
                    <Col lg="2" sm="2" xs="3">
                        <div className="movements-table-cell">
                            <div>{formatToLocaleDate(model.paymentDate)}</div>
                        </div>
                    </Col>
                    <Col lg="3" sm="3" xs="3" >
                        <div className="movements-table-cell">
                            <div>{itemDesc ?? "-"}</div>
                        </div>
                    </Col>
                    <Col lg="3" sm="3" xs="2" >
                        <div className="movements-table-cell">
                            <div>{model.methodDescription}</div>
                        </div>
                    </Col>
                    <Col lg="2" sm="2" xs="2">
                        <div className={`movements-table-cell ${amountColor}`}>
                            {model.typology == PaymentTypology.Outbound ? `-${model.amount}` : `+${model.amount}`}€
                        </div>
                    </Col>
                    <Col lg="1" sm="1" xs="1" className="d-none">
                        <div style={{ cursor: "pointer" }} className={`movements-table-cell ${notesColor}`} id={`popover-notes${model.id}`}>
                            <i className={`icon fas fa-info-circle fa-1x`} />
                        </div>
                        <UncontrolledPopover className="bg-dark text-eurorounders" trigger="legacy" placement="top" target={`popover-notes${model.id}`}>
                            <PopoverHeader className="bg-dark text-eurorounders">Info aggiuntive</PopoverHeader>
                            <PopoverBody className="bg-dark text-eurorounders">
                                <div>
                                    {notes}
                                </div>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </Col>
                    {trasferColumn}
                </Row>
            )
        });

        return renderList;

    }

    render() {

        return (
            <React.Fragment>
                <List hasNextPage={this.props.hasNextPage} list={this.props.list} active={this.props.startLoading} onEndOfList={this.props.loadNewPage} getElements={(list: Array<PaymentSearchReadModel>) => { return this.getRows(list) }} />
                <GoToTop />
                <ConfirmationModal onClosed={() => this.props.registerDelete(null)} text="Eliminare Movimento?" onConfirm={() => this.onDeleteConfirm()} ref={this.modalRef} />
            </React.Fragment>
        )
    }
}