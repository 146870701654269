import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { ReservationsListHeader, ReservationsListHeaderProps } from '../base/listheader';


const mapStateToProsp = (state: ApplicationState): ReservationsListHeaderProps => {

    return {
        agentRoles: state.reservations.agentRoles
    }
}


export const ConnectedReservationsListHeader = connect<ReservationsListHeaderProps>(mapStateToProsp)(ReservationsListHeader);