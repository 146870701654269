import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/locations/LocationState';
import { LocationForm, LocationFormCallbacks, LocationFormProps } from '../shared/editform';
import { actionCreator as actionCreatorUI } from '../../../store/uiproperties/UIState';


const mapStateToProsp = (state: ApplicationState): LocationFormProps => {

    return {
        locationTypesDesc: state.locations.locactionTypeDesc,
        location: state.locations.running,
        locationGroups: state.locationGroups.locationGroups
    }
}

const mapDispatchToProps = () => (dispatch): LocationFormCallbacks => {
    return {
        onChange: (propertyName: string, value: any) => {
            dispatch(actionsMiddleware.setRunningProp(propertyName, value));
            dispatch(actionCreatorUI.setChangeInProgress(true));
        },
        onSubmit: () => dispatch(actionsMiddleware.save()),
        onRoomTypeInfoChange: (id: string, value: string) => dispatch(actionsMiddleware.setRunningRoomInfo(id, value))
    }
}

export const ConnectedLocationForm = connect<LocationFormProps, LocationFormCallbacks>(mapStateToProsp, mapDispatchToProps)(LocationForm);