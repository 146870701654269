import * as React from "react";
import { MenuItem, MenuItemProps } from '../menu/menuitem';
import { BodyHeader } from '../base/bodyheader';
import { ComparedKpi, HotelKpiDetails, KpiViewModel, PredictionStatus } from '../../store/kpis/KpiEntities';
import { BaseList } from '../base/baseList';
import { Event } from '../../store/events/EventEntities';
import moment from "moment";
import { Modal, ModalHeader, ModalBody, ModalFooter, Nav, TabContent, TabPane, NavItem, NavLink, ListGroup, ListGroupItem, Row, Col } from "reactstrap";
import { ResponsiveContainer, Line, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceLine } from "recharts";
import { formatToLocaleDate, guid, round } from "../../utils/commons";
import { ConnectedEventsSearchForm } from "../events/connected/connected.searchform";
import { ConnectedEventsList } from "../events/connected/connected.list";
import _ from "underscore";
import './kpi.css';
import CollapsibleContent from "../base/collapsiblecontent";

interface KpiRoom {
    id: string;
    type: string;
    settled: KpiRoomTotals;
    notSettled: KpiRoomTotals;
}

interface KpiRoomTotals {
    bookings?: number;
    nights?: number;
    guests?: number;
    offeredBooking?: number;
    offeredNights?: number;
    offeredGuests?: number;
}

interface KpiPageStte {
    isEventsSelectionModalOpen: boolean;
    isWarningModalOpen: boolean;
    isEventsSelectedModalOpen: boolean;
    activeTab: TabActive;
}

export interface KpiPageCallbacks {
    onEventsConfirmed: () => void;
    onSelectEvent: (id: string) => void;
    loadExternalModule: () => void;
    onLoad: (eventId: string) => void;
    onDelete: (eventId: string) => void;
}

export interface KpiPageProps {
    dataLoaded: boolean;
    eventFullName: string;
    model: KpiViewModel;
}

enum Modals {
    EventsSelection,
    EventsSelected,
    WarningMessages
}

enum TabActive {
    Details = "Details",
    Kpi = "Kpi",
    Forecast = "Forecast",
}

export class KpiPage extends React.Component<KpiPageCallbacks & KpiPageProps, KpiPageStte> {
    constructor(props) {
        super(props);
        this.state = {
            isEventsSelectionModalOpen: false,
            isWarningModalOpen: false,
            isEventsSelectedModalOpen: false,
            activeTab: TabActive.Details
        }
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let eventId = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                eventId = (this.props as any).match.params.eventId;
            this.props.onLoad(eventId);
        }
    }

    private toogleTab(active: TabActive) {
        this.setState({ activeTab: active })
    }

    private toogleModal(modal: Modals) {

        switch (modal) {
            case Modals.EventsSelection:
                if (!this.state.isEventsSelectionModalOpen == true) {
                    //opening modal triggher location module
                    this.props.loadExternalModule();
                }

                this.setState({ isEventsSelectionModalOpen: !this.state.isEventsSelectionModalOpen });
                break;
            case Modals.EventsSelected:
                this.setState({ isEventsSelectedModalOpen: !this.state.isEventsSelectedModalOpen });
                break;
            case Modals.WarningMessages:
                this.setState({ isWarningModalOpen: !this.state.isWarningModalOpen });
                break;
        }
    }

    private onEventConfirmed() {
        this.toogleModal(Modals.EventsSelection);
        this.props.onEventsConfirmed();
    }

    private getBaseMenuItems(): Array<MenuItemProps> {
        let menuItems = [] as Array<MenuItemProps>;

        if (this.props.model.predictionCreated == true) {
            let eventId = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                eventId = (this.props as any).match.params.eventId;
            menuItems.push(
                {
                    icon: "exclamation-triangle",
                    label: "Warnings",
                    isButton: true,
                    isSmall: true,
                    onClick: () => this.toogleModal(Modals.WarningMessages)
                } as MenuItemProps,
                {
                    icon: "check-double",
                    label: "Eventi",
                    isButton: true,
                    isSmall: true,
                    onClick: () => this.toogleModal(Modals.EventsSelected)
                } as MenuItemProps,
                {
                    icon: "trash",
                    label: "Elimina",
                    isButton: true,
                    isSmall: true,
                    onClick: () => this.props.onDelete(eventId)
                } as MenuItemProps
            )
        } else {
            menuItems.push(
                {
                    icon: "plus",
                    label: "Previsione",
                    isButton: true,
                    isSmall: true,
                    onClick: () => this.toogleModal(Modals.EventsSelection)
                } as MenuItemProps
            )

        }

        return menuItems;
    }

    private getWarningMessages(): JSX.Element[] {

        if (this.props.model.warningMessages.length == 0) {
            return [(<ListGroupItem key="noMessages" className="bg-dark border">No warning messages</ListGroupItem>)]

        }

        return this.props.model.warningMessages.map(x => {
            return (<ListGroupItem key={guid()} className="bg-dark border">{x}</ListGroupItem>)
        })
    }

    private getTableBody(events: Array<Event>): JSX.Element[] {
        return events.map(event => {

            let average: JSX.Element = (<i style={{ position: "relative", left: "5px" }} className="fas fa-times fa-1x"></i>);
            let consumerBasedPrediction: JSX.Element = (<i style={{ position: "relative", left: "5px" }} className="fas fa-times fa-1x"></i>);
            let bookingBasedPrediction: JSX.Element = (<i style={{ position: "relative", left: "5px" }} className="fas fa-times fa-1x"></i>);


            switch (this.props.model.predictionStatus) {
                case PredictionStatus.Error:
                    bookingBasedPrediction = (<i style={{ position: "relative", left: "5px" }} className="fas fa-exclamation-circle fa-1x"></i>);
                    consumerBasedPrediction = (<i style={{ position: "relative", left: "5px" }} className="fas fa-exclamation-circle fa-1x"></i>);
                    average = (<i style={{ position: "relative", left: "5px" }} className="fas fa-exclamation-circle fa-1x"></i>);
                    break;
                case PredictionStatus.Calculating:
                    average = (<i style={{ position: "relative", left: "5px" }} className="fas fa-cogs fa-1x"></i>);
                    consumerBasedPrediction = (<i style={{ position: "relative", left: "5px" }} className="fas fa-cogs fa-1x"></i>);
                    bookingBasedPrediction = (<i style={{ position: "relative", left: "5px" }} className="fas fa-cogs fa-1x"></i>);
                    break;
            }

            if (this.props.model.predictionStatus != PredictionStatus.Error && this.props.model.predictionStatus != PredictionStatus.Calculating) {
                if (_.any(this.props.model.eventsForAverageAndConsumerPrediction, x => x == event.id)) {

                    average = (<i style={{ position: "relative", left: "5px" }} className="fas fa-check fa-1x"></i>);
                    consumerBasedPrediction = (<i style={{ position: "relative", left: "5px" }} className="fas fa-check fa-1x"></i>);

                    if (this.props.model.predictionStatus == PredictionStatus.CalculatingTimeSeries)
                        consumerBasedPrediction = (<i style={{ position: "relative", left: "5px" }} className="fas fa-cogs fa-1x"></i>);
                    if (this.props.model.predictionStatus == PredictionStatus.PredictError)
                        consumerBasedPrediction = (<i style={{ position: "relative", left: "5px" }} className="fas fa-exclamation-circle fa-1x"></i>);
                }

                if (_.any(this.props.model.eventsForBookingPrediction, x => x == event.id)) {
                    bookingBasedPrediction = (<i style={{ position: "relative", left: "5px" }} className="fas fa-check fa-1x"></i>);
                }
            }

            return (
                <tr key={event.id}>
                    <td>{event.name}</td>
                    <td>{average}</td>
                    <td>{bookingBasedPrediction}</td>
                    <td>{consumerBasedPrediction}</td>
                </tr>
            )
        })
    }

    private getHead(): JSX.Element {

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>Evento</th>
                    <th>Media</th>
                    <th>Previsione prenotaz.</th>
                    <th>Previsioni players</th>
                </tr>
            </thead>
        );

    }

    private getKpiTab(): JSX.Element {

        if (!this.props.model.actualBookedPercentage && !this.props.model.averageBookedPercentage)
            return (<React.Fragment><div className="text-eurorounders">Non ci sono ancora kpis disponibili</div></React.Fragment>);

        let graph = (<React.Fragment></React.Fragment>);


        if (this.props.model.actualAndAverageKpi && this.props.model.actualAndAverageKpi.length > 0) {
            graph = (<Row className="text-eurorounders">
                <Col lg="12" sm="12" xs="12">
                    <p >Trend notti prenotate</p>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                            data={this.props.model.actualAndAverageKpi}
                            syncId="anyId"
                            margin={{
                                top: 10, right: 10, left: 10, bottom: 30,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis stroke="#F5F3F2" dataKey="date" />
                            <YAxis stroke="#F5F3F2" type="number" domain={[dataMin => 0, dataMax => (dataMax < 100 ? 100 : dataMax + 20)]} />
                            <Tooltip labelFormatter={(value: string) => (<span className="text-secondary">{"Data: " + value}</span>)} formatter={(label) => label + "%"} />
                            <Legend />
                            <ReferenceLine y={100} stroke="#dc3545" />
                            <ReferenceLine x={0} stroke="#dc3545" />
                            <Line name={this.props.eventFullName} type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
                            <Line name="Average events" type="monotone" dataKey="comparedValue" stroke="#82ca9d" fill="#82ca9d" />
                        </LineChart>
                    </ResponsiveContainer>
                </Col>
            </Row>);
        }

        let actualPercentage = (
            <Col lg="4" sm="4" xs="4" >
                <div>Stato prenotazioni</div>
                <div className="kpi-value">{this.props.model.actualBookedPercentage ? round(this.props.model.actualBookedPercentage) + "%" : "-"}</div>
            </Col>
        )

        let averageBookingPercentage = (
            <Col lg="4" sm="4" xs="4" >
                <div>Media percentuale</div>
                <div className="kpi-value">{this.props.model.averageBookedPercentage ? round(this.props.model.averageBookedPercentage) + "%" : "-"}</div>
            </Col>
        )

        let differenceValue = "-"

        if (this.props.model.differencePercentage > 0)
            differenceValue = "+" + round(this.props.model.differencePercentage) + "%"
        else if (this.props.model.differencePercentage < 0)
            differenceValue = "-" + round(this.props.model.differencePercentage) + "%"
        else if (this.props.model.differencePercentage == 0)
            differenceValue = round(this.props.model.differencePercentage) + "%"

        let difference = (
            <Col lg="4" sm="4" xs="4" >
                <div>Percentuale comparata</div>
                <div className="kpi-value">{differenceValue}</div>
            </Col>
        )


        return (<React.Fragment>
            {graph}
            <h5 className="text-eurorounders">Dettagli:</h5>
            <Row className="text-eurorounders font-weight-bold text-center" style={{ marginTop: "15px", marginBottom: "15px" }}>
                {actualPercentage}
                {averageBookingPercentage}
                {difference}
            </Row>
        </React.Fragment>);
    }

    private getForecastTab(): JSX.Element {

        let bookingBasedForecast = (
            <Col lg="4" sm="4" xs="4" >
                <div>Numero entries previsto</div>
                <div className="kpi-value">{this.props.model.predictionBookingFinalEntries ? round(this.props.model.predictionBookingFinalEntries) : "-"}</div>
            </Col>
        )

        let consumerBasedForecast = (
            <Col lg="4" sm="4" xs="4" >
                <div>Numero entries previsto</div>
                <div className="kpi-value">{this.props.model.predictedConsumerFinalEntries ? round(this.props.model.predictedConsumerFinalEntries) : "-"}</div>
            </Col>
        )

        let consumerBasedForecastLowerbound = (
            <Col lg="4" sm="4" xs="4" >
                <div>Numero entries previsto caso peggiore</div>
                <div className="kpi-value">{this.props.model.predictedConsumerLowerBoundFinalEntries ? round(this.props.model.predictedConsumerLowerBoundFinalEntries) : "-"}</div>
            </Col>
        )

        let consumerBasedForecastUpperbound = (
            <Col lg="4" sm="4" xs="4" >
                <div>Numero entries previsto caso migliore</div>
                <div className="kpi-value">{this.props.model.predictedConsumerUpperBoundFinalEntries ? round(this.props.model.predictedConsumerUpperBoundFinalEntries) : "-"}</div>
            </Col>
        )


        return (<React.Fragment>
            <h5 className="text-eurorounders">Stima su base storico entries finali</h5>
            <Row className="text-eurorounders font-weight-bold text-center" style={{ marginTop: "15px", marginBottom: "15px" }}>
                {bookingBasedForecast}
            </Row>
            <h5 className="text-eurorounders">Stima su base trend prenotazioni e rating</h5>
            <Row className="text-eurorounders font-weight-bold text-center" style={{ marginTop: "15px", marginBottom: "15px" }}>
                {consumerBasedForecast}
                {consumerBasedForecastLowerbound}
                {consumerBasedForecastUpperbound}
            </Row>
        </React.Fragment>);
    }

    private getHotelHead(): JSX.Element {
        return (
            <thead>
                <tr className="text-eurorounders">
                    <th colSpan={1}></th>
                    <th colSpan={3}>Non pagate</th>
                    <th colSpan={3}>Pagate</th>
                </tr>
                <tr className="text-eurorounders">
                    <th>Camera</th>
                    <th>Prenotazioni</th>
                    <th>Notti</th>
                    <th>Ospiti</th>
                    <th>Prenotazioni</th>
                    <th>Notti</th>
                    <th>Ospiti</th>
                </tr>
            </thead>
        );
    }

    private getHotelsRow(rooms: Array<KpiRoom>): JSX.Element[] {
        return rooms.map(r => {
            return (
                <tr key={r.id}>
                    <td>{r.type}</td>
                    <td>{r.notSettled.bookings} ({r.notSettled.offeredBooking})</td>
                    <td>{r.notSettled.nights} ({r.notSettled.offeredNights})</td>
                    <td>{r.notSettled.guests} ({r.notSettled.offeredGuests})</td>
                    <td>{r.settled.bookings} ({r.settled.offeredBooking})</td>
                    <td>{r.settled.nights} ({r.settled.offeredNights})</td>
                    <td>{r.settled.guests} ({r.settled.offeredGuests})</td>
                </tr>
            );
        });
    }

    private getHotelDetails(hotel: HotelKpiDetails): JSX.Element {

        let rooms: Array<KpiRoom> = [];

        let i = hotel.roomDetails.length;

        while (i--) {

            const actualRoom = hotel.roomDetails[i];
            const roomIndex = _.findIndex(rooms, r => r.id == actualRoom.id);
            if (roomIndex > -1) {
                let roomToModify = rooms[roomIndex];

                if (actualRoom.isPaid) {
                    if (actualRoom.customerType == 'Guest') {
                        roomToModify.settled.offeredBooking = actualRoom.bookings;
                        roomToModify.settled.offeredGuests = actualRoom.guests;
                        roomToModify.settled.offeredNights = actualRoom.nights;
                    }

                    roomToModify.settled.bookings += actualRoom.bookings;
                    roomToModify.settled.guests += actualRoom.guests;
                    roomToModify.settled.nights += actualRoom.nights;

                } else {
                    if (actualRoom.customerType == 'Guest') {
                        roomToModify.notSettled.offeredBooking = actualRoom.bookings;
                        roomToModify.notSettled.offeredGuests = actualRoom.guests;
                        roomToModify.notSettled.offeredNights = actualRoom.nights;
                    }

                    roomToModify.notSettled.bookings += actualRoom.bookings;
                    roomToModify.notSettled.guests += actualRoom.guests;
                    roomToModify.notSettled.nights += actualRoom.nights;
                }
                rooms[roomIndex] = roomToModify;
            }
            else {
                let roomToInsert = {
                    id: actualRoom.id,
                    type: actualRoom.type,
                    notSettled: {
                        bookings: 0,
                        guests: 0,
                        nights: 0,
                        offeredBooking: 0,
                        offeredGuests: 0,
                        offeredNights: 0
                    },
                    settled: {
                        bookings: 0,
                        guests: 0,
                        nights: 0,
                        offeredBooking: 0,
                        offeredGuests: 0,
                        offeredNights: 0
                    }
                } as KpiRoom

                if (actualRoom.isPaid) {
                    if (actualRoom.customerType == 'Guest') {
                        roomToInsert.settled.offeredBooking = actualRoom.bookings;
                        roomToInsert.settled.offeredGuests = actualRoom.guests;
                        roomToInsert.settled.offeredNights = actualRoom.nights;
                    }

                    roomToInsert.settled.bookings += actualRoom.bookings;
                    roomToInsert.settled.guests += actualRoom.guests;
                    roomToInsert.settled.nights += actualRoom.nights;

                } else {
                    if (actualRoom.customerType == 'Guest') {
                        roomToInsert.notSettled.offeredBooking = actualRoom.bookings;
                        roomToInsert.notSettled.offeredGuests = actualRoom.guests;
                        roomToInsert.notSettled.offeredNights = actualRoom.nights;
                    }
                    roomToInsert.notSettled.bookings += actualRoom.bookings;
                    roomToInsert.notSettled.guests += actualRoom.guests;
                    roomToInsert.notSettled.nights += actualRoom.nights;

                }
                rooms.push(roomToInsert);
            }

            hotel.roomDetails.splice(i, 1);
        }

        rooms = rooms.reverse();

        return (
            <React.Fragment key={hotel.id}>
                <CollapsibleContent className="bg-dark text-eurorounders text-capitalize" title={hotel.name} key={hotel.id}>
                    <BaseList hasNextPage={false} list={rooms} active={false} onEndOfList={() => { }} getElements={(list: Array<KpiRoom>) => { return this.getHotelsRow(list) }} getHead={() => { return this.getHotelHead() }} />
                </CollapsibleContent>
            </React.Fragment>
        )
    }

    private getDetailsTab(): JSX.Element {
        if (!this.props.model.kpiDetails) {
            return (<React.Fragment>
                <Row className="text-eurorounders font-weight-bold text-center" style={{ marginBottom: "15px" }}>
                    <Col lg="4" sm="4" xs="4" >
                        <div>Totale notti</div>
                        <div className="kpi-value">{this.props.model.totalNights ?? "-"}</div>
                    </Col>
                </Row>
            </React.Fragment>);
        }

        const hotels = this.props.model.kpiDetails.hotelDetails.map(h => {
            return this.getHotelDetails(h);
        })

        return (
            <React.Fragment>
                <h5 className="text-eurorounders">Prenotazioni:</h5>
                <Row className="text-eurorounders font-weight-bold text-center" style={{ marginBottom: "15px" }}>
                    <Col lg="4" sm="4" xs="4" >
                        <div>Totale</div>
                        <div className="kpi-value">{this.props.model.kpiDetails.totalBookings} ({this.props.model.kpiDetails.totalGuestBookings})</div>
                    </Col>
                    <Col lg="4" sm="4" xs="4" >
                        <div>Vendute</div>
                        <div className="kpi-value">{this.props.model.kpiDetails.totalSoldBookings} ({this.props.model.kpiDetails.totalSoldGuestBookings})</div>
                    </Col>
                    <Col lg="4" sm="4" xs="4" >
                        <div>Previsione su attuali</div>
                        <div className="kpi-value">{this.props.model.kpiDetails.totalEstimatedReservations}</div>
                    </Col>
                </Row>
                <hr />
                <h5 className="text-eurorounders">Entries:</h5>
                <Row className="text-eurorounders font-weight-bold text-center" style={{ marginBottom: "15px" }}>
                    <Col lg="4" sm="4" xs="4" >
                        <div>Players</div>
                        <div className="kpi-value">{this.props.model.players}</div>
                    </Col>
                    <Col lg="4" sm="4" xs="4" >
                        <div>Entries</div>
                        <div className="kpi-value">{this.props.model.entriesCount}</div>
                    </Col>
                    <Col lg="4" sm="4" xs="4" >
                        <div>Previsione su attuali</div>
                        <div className="kpi-value">{this.props.model.estimatedEntries}</div>
                    </Col>
                </Row>
                <hr />
                <h5 className="text-eurorounders">Notti:</h5>
                <Row className="text-eurorounders font-weight-bold text-center" style={{ marginBottom: "15px" }}>
                    <Col lg="4" sm="4" xs="4" >
                        <div>Notti prenotate</div>
                        <div className="kpi-value">{this.props.model.kpiDetails.totalBookedNights} ({this.props.model.kpiDetails.totalGuestNights}) / {this.props.model.totalNights}</div>
                    </Col>
                    <Col lg="4" sm="4" xs="4" >
                        <div>Vendute</div>
                        <div className="kpi-value">{this.props.model.kpiDetails.totalCollectedNights} ({this.props.model.kpiDetails.totalCollectedOfferedNights})</div>
                    </Col>
                    <Col lg="4" sm="4" xs="4" >
                        <div>Previsione su attuali</div>
                        <div className="kpi-value">{this.props.model.kpiDetails.totalEstimatedSoldNights}</div>
                    </Col>
                </Row>
                <hr />
                <h5 className="text-eurorounders">Ospiti:</h5>
                <Row className="text-eurorounders font-weight-bold text-center" style={{ marginBottom: "15px" }}>
                    <Col lg="4" sm="4" xs="4" >
                        <div>Ospiti</div>
                        <div className="kpi-value">{this.props.model.kpiDetails.totalGuests} ({this.props.model.kpiDetails.totalGuestOffered}) / {this.props.model.kpiDetails.totalExpectedGuests}</div>
                    </Col>
                    <Col lg="4" sm="4" xs="4" >
                        <div>Paganti</div>
                        <div className="kpi-value">{this.props.model.kpiDetails.totalSoldPaingGuests} ({this.props.model.kpiDetails.totalSoldGuestOffered})</div>
                    </Col>
                    <Col lg="4" sm="4" xs="4" >
                        <div>Previsione su attuali</div>
                        <div className="kpi-value">{this.props.model.kpiDetails.totalEstimatedGuests}</div>
                    </Col>
                </Row>
                <hr />
                <h5 className="text-eurorounders">Dettagli Hotels:</h5>
                {hotels}
            </React.Fragment>
        )
    }

    render() {

        if (this.props.dataLoaded != true) {
            return (<React.Fragment></React.Fragment>)
        }

        let menuItems = this.getBaseMenuItems();

        menuItems.push(

            {
                icon: "ellipsis-h",
                label: "Evento",
                isButton: false,
                isSmall: true,
                path: `/events/${(this.props as any).match.params.eventId}/menu`
            } as MenuItemProps
        )

        let selectedEvents = [];

        if (this.props.model.selectedEvents)
            selectedEvents = this.props.model.selectedEvents;

        return (
            <React.Fragment>
                <BodyHeader bottomSpace title={`Indicatori di performance`} menuItems={menuItems} subTitle={this.props.eventFullName} />
                <Nav tabs className="text-eurorounders" style={{ marginBottom: "15px" }}>
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab == TabActive.Details ? "active" : ""}
                            onClick={() => { this.toogleTab(TabActive.Details); }}
                        >
                            Dettaglio
                            </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab == TabActive.Kpi ? "active" : ""}
                            onClick={() => { this.toogleTab(TabActive.Kpi); }}
                        >
                            Confronto
                            </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab == TabActive.Forecast ? "active" : ""}
                            onClick={() => { this.toogleTab(TabActive.Forecast); }}
                        >
                            Previsione
                            </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab.toString()}>
                    <TabPane tabId={TabActive.Details.toString()}>
                        {this.getDetailsTab()}
                    </TabPane>
                    <TabPane tabId={TabActive.Kpi.toString()}>
                        {this.getKpiTab()}
                    </TabPane>
                    <TabPane tabId={TabActive.Forecast.toString()}>
                        {this.getForecastTab()}
                    </TabPane>
                </TabContent>
                <Modal className="bg-dark modal-lg" isOpen={this.state.isEventsSelectionModalOpen} toggle={() => this.toogleModal(Modals.EventsSelection)} backdrop="static">
                    <ModalHeader className="text-eurorounders bg-dark" toggle={() => this.toogleModal(Modals.EventsSelection)}>Seleziona eventi per confronto e previsione</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders">
                        <ConnectedEventsSearchForm />
                        <ConnectedEventsList selectionEnabled={true} onRowClick={(id) => this.props.onSelectEvent(id)} />
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon="check" label="Confirm" onClick={() => this.onEventConfirmed()} />
                        <MenuItem isSmall={true} isButton={true} icon="times" label="Cancel" onClick={() => this.toogleModal(Modals.EventsSelection)} />
                    </ModalFooter>
                </Modal>
                <Modal className="bg-dark modal-lg" isOpen={this.state.isWarningModalOpen} toggle={() => this.toogleModal(Modals.WarningMessages)} backdrop="static">
                    <ModalHeader className="text-eurorounders bg-dark" toggle={() => this.toogleModal(Modals.WarningMessages)}>Messaggi di warning</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders">
                        <ListGroup>
                            {this.getWarningMessages()}
                        </ListGroup>
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon="times" label="Cancel" onClick={() => this.toogleModal(Modals.WarningMessages)} />
                    </ModalFooter>
                </Modal>
                <Modal className="bg-dark modal-lg" isOpen={this.state.isEventsSelectedModalOpen} toggle={() => this.toogleModal(Modals.EventsSelected)} backdrop="static">
                    <ModalHeader className="text-eurorounders bg-dark" toggle={() => this.toogleModal(Modals.EventsSelected)}>Eventi selezionati</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders">
                        <BaseList list={selectedEvents} getElements={(list: Array<Event>) => this.getTableBody(list)} getHead={() => this.getHead()} active={false} hasNextPage={false} />
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon="times" label="Cancel" onClick={() => this.toogleModal(Modals.EventsSelected)} />
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }

}