import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionCreator, ListViewType } from '../../../store/agents/AgentsState';
import { AgentPageList, AgentPageListProps } from '../pages/agentslistpage';

const mapStateToProsp = (state: ApplicationState): AgentPageListProps => {

    return {
        searchString: state.agents.searchState.searchString,
        viewMode: state.agents.listViewType
    }
}

const mapDispatchToProps = () => dispatch => {
    return ({
        onLoad: () => {
            dispatch(actionCreator.loadInitialState());
        },
        changeView: (viewType: ListViewType) => dispatch(actionCreator.switchView(viewType)),
        navigateToAgent: actionCreator.navigateToRunningAgent
    });
}

export const ConnectedAgentsPageList = connect<AgentPageListProps>(mapStateToProsp, mapDispatchToProps)(AgentPageList);