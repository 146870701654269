import { Action, Reducer } from 'redux';
import { ApplicationState } from '../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest, checkRefreshToken, SaveRequest } from '../../utils/ajaxutilities';
import dotProp from "dot-prop";
import _ from "underscore";
import { actionsMiddleware as locationMiddleware } from '../locations/LocationState';
import { actionsMiddleware as eventMiddleware } from '../events/EventState';
import { CommunicationListModel, Communication, CommunicationType, CommunicationSent } from './CommunicationEntities'
import $ from "jquery";
import { KeyDesc } from '../shared/KeyDesc';
import { push } from 'connected-react-router';

export interface CommunicationState {
    list: Array<CommunicationListModel>;
    moduleLoaded: boolean;
    eventId: string;
    runningCommunication: RunningCommunication;
    communicationTypesDesc: Array<KeyDesc<string>>;
    listSents: Array<CommunicationSent>;
}

export interface RunningCommunication {
    communicationList: Array<Communication>;
    message: string;
    selectedCommunications: Array<Communication>;
    locationName: string;
    locationId: string;
    messageSentId: string;
}

enum TypeKeys {
    SET_LIST = "COMMUNICATION_SET_LIST",
    MODULE_LOADED = "COMMUNICATION_MODULE_LOADED",
    RESET_STATE = "COMMUNICATION_RESET_STATE",
    SET_EVENT_ID = "COMMUNICATION_SET_EVENT_ID",
    SET_RUNNING_STATE = "COMMUNICATION_SET_RUNNING_STATE",
    SET_SENTS_LIST = "COMMUNICATION_SET_SENTS_LIST"
}

type CommunicationActions = SetList
    | SetModuleLoaded
    | ResetState
    | SetEventId
    | SetRunningState
    | SetSentsList;

interface SetSentsList extends Action {
    type: TypeKeys.SET_SENTS_LIST;
    listSents: Array<CommunicationSent>;
}

interface SetRunningState extends Action {
    type: TypeKeys.SET_RUNNING_STATE;
    runningState: RunningCommunication;
}

interface SetEventId extends Action {
    type: TypeKeys.SET_EVENT_ID;
    eventId: string;
}

interface SetList extends Action {
    type: TypeKeys.SET_LIST;
    list: Array<CommunicationListModel>;
}

interface SetModuleLoaded extends Action {
    type: TypeKeys.MODULE_LOADED;
    loaded: boolean;
}

interface ResetState {
    type: TypeKeys.RESET_STATE;
}

const getBaseRunning = (): RunningCommunication => ({
    communicationList: [],
    message: null,
    selectedCommunications: [],
    locationName: null,
    locationId: null,
    messageSentId: null
});

const getBaseState = (): CommunicationState => ({
    list: [],
    moduleLoaded: false,
    eventId: null,
    runningCommunication: getBaseRunning(),
    communicationTypesDesc: [
        {
            key: CommunicationType.New,
            desc: "New"
        },
        {
            key: CommunicationType.Modified,
            desc: "Modified"
        },
        {
            key: CommunicationType.Canceled,
            desc: "Canceled"
        }
    ],
    listSents: []
});


export const actionCreator = {
    setList: (list: Array<CommunicationListModel>): SetList => ({
        type: TypeKeys.SET_LIST,
        list: list
    }),
    setModuleLoaded: (loaded: boolean): SetModuleLoaded => ({
        type: TypeKeys.MODULE_LOADED,
        loaded: loaded
    }),
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    setEventId: (eventId: string): SetEventId => ({
        type: TypeKeys.SET_EVENT_ID,
        eventId: eventId
    }),
    setRunningState: (running: RunningCommunication): SetRunningState => ({
        type: TypeKeys.SET_RUNNING_STATE,
        runningState: running
    }),
    setSentsList: (listSents: Array<CommunicationSent>): SetSentsList => ({
        type: TypeKeys.SET_SENTS_LIST,
        listSents: listSents
    })
}


export const actionsMiddleware = {
    loadInitialState: (eventId: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<boolean> => {
        let deferred = $.Deferred();
        abortAllRequest();
        dispatch(actionCreator.setModuleLoaded(false));
        dispatch(actionCreator.resetState());
        dispatch(actionCreator.setEventId(eventId));

        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false) {
                deferred.resolve(false);
                return deferred.promise();
            }

            $.when(dispatch(locationMiddleware.getRoomTypes()), dispatch(eventMiddleware.setEventLocations(eventId)), dispatch(actionsMiddleware.loadCommunicationList(eventId)), dispatch(actionsMiddleware.loadCommunicationSentList(eventId))).then((rt, locations) => {
                dispatch(actionCreator.setModuleLoaded(true));
                deferred.resolve(true);
            });
        });

        return deferred.promise();
    },
    loadCommunicationList: (eventId: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<Array<CommunicationListModel>> => {
        let deferred = $.Deferred();

        let request = new RequestModel(`/communications/list/${eventId}`, RequestMethods.GET);

        ajaxRequest<Array<CommunicationListModel>>(request)(dispatch, getState).then(response => {
            if (response.success == false) {
                deferred.resolve(null);
                return deferred.promise();
            }

            dispatch(actionCreator.setList(response.entity));
            deferred.resolve(response.entity);
        });

        return deferred.promise();
    },
    loadCommunicationSentList: (eventId: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<Array<CommunicationSent>> => {
        let deferred = $.Deferred();

        let request = new RequestModel(`/communications/listsents/${eventId}`, RequestMethods.GET);

        ajaxRequest<Array<CommunicationSent>>(request)(dispatch, getState).then(response => {
            if (response.success == false) {
                deferred.resolve(null);
                return deferred.promise();
            }

            dispatch(actionCreator.setSentsList(response.entity));
            deferred.resolve(response.entity);
        });

        return deferred.promise();
    },
    navigateToCreate: (locationId: string) => (dispatch, getState: () => ApplicationState) => {
        dispatch(actionCreator.setModuleLoaded(false));
        dispatch(push(`/events/${getState().communications.eventId}/communications/create/${locationId}`));
    },
    navigateToEdit: (messageId: string) => (dispatch, getState: () => ApplicationState) => {
        dispatch(actionCreator.setModuleLoaded(false));
        dispatch(push(`/events/${getState().communications.eventId}/communications/edit/${messageId}`));
    },
    onCreate: (locationId: string) => (dispatch, getState: () => ApplicationState) => {

        dispatch(actionCreator.setModuleLoaded(false));

        let deferred = $.Deferred();

        let eventId = getState().communications.eventId;

        if (eventId === undefined || eventId == null || locationId === undefined || locationId == null)
            return;

        let request = new RequestModel(`/communications/tosendforlocation/${eventId}/${locationId}`, RequestMethods.GET);

        ajaxRequest<Array<Communication>>(request)(dispatch, getState).then(response => {
            if (response.success == false) {
                deferred.resolve(null);
                return deferred.promise();
            }

            let runningState = getBaseRunning();

            runningState.communicationList = [...response.entity];
            runningState.selectedCommunications = [...response.entity];
            runningState.locationId = locationId;

            let location = _.find(getState().events.runningEventLocations, (l) => l.id == locationId);

            if (location !== undefined && location != null)
                runningState.locationName = location.name;

            runningState.message = null;

            dispatch(actionCreator.setRunningState(runningState));
            deferred.resolve(response.entity);
            dispatch(actionCreator.setModuleLoaded(true));
        });

        return deferred.promise();
    },
    generateMessage: () => (dispatch, getState: () => ApplicationState) => {

        let deferred = $.Deferred();

        let eventId = getState().communications.eventId;
        let locationId = getState().communications.runningCommunication.locationId;

        if (eventId === undefined || eventId == null || locationId === undefined || locationId == null)
            return;

        let ids = [];

        getState().communications.runningCommunication.selectedCommunications.forEach(x => ids.push(x.id));

        let request = new RequestModel(`/communications/message/${eventId}/${locationId}`, RequestMethods.POST, ids);

        ajaxRequest<string>(request)(dispatch, getState).then(response => {
            if (response.success == false) {
                deferred.resolve(null);
                return deferred.promise();
            }

            let runningState = getState().communications.runningCommunication;

            runningState.message = response.entity;

            dispatch(actionCreator.setRunningState(runningState));
            deferred.resolve(response.entity);
        });

        return deferred.promise();
    },
    onSelectCommunication: (communication: Communication) => (dispatch, getState: () => ApplicationState) => {

        let runningState = { ...getState().communications.runningCommunication };

        let selecteIndex = _.findIndex(runningState.selectedCommunications, (c) => c.id == communication.id);

        if (selecteIndex < 0) {
            runningState.selectedCommunications.push(communication);
        } else {
            runningState.selectedCommunications.splice(selecteIndex, 1);
        }

        runningState.selectedCommunications = [...runningState.selectedCommunications];

        dispatch(actionCreator.setRunningState(runningState));

    },
    saveCommunication: () => (dispatch, getState: () => ApplicationState) => {
        let idsToSend = getState().communications.runningCommunication.selectedCommunications.map(x => { return x.id });

        if (idsToSend === undefined || idsToSend == null)
            idsToSend = [];

        let communiaction: CommunicationSent = {
            eventId: getState().communications.eventId,
            locationId: getState().communications.runningCommunication.locationId,
            text: getState().communications.runningCommunication.message
        } as CommunicationSent;

        let modelToSend = {
            communicationIds: idsToSend,
            communicationSent: communiaction
        }

        let request = new SaveRequest(`/communications`, RequestMethods.POST, modelToSend);

        ajaxRequest<string>(request)(dispatch, getState).then(response => {
            if (response.success != true) {
                return;
            }
            let running = { ...getState().communications.runningCommunication };
            running.messageSentId = response.entity;
            dispatch(actionCreator.setRunningState(running));
            //navigate to sent id
            dispatch(push(`/events/${getState().communications.eventId}/communications`))
        });

    },
    getSingleCommunication: (id: string) => (dispatch, getState: () => ApplicationState) => {
        dispatch(actionCreator.setModuleLoaded(false));
        let deferred = $.Deferred();

        let request = new RequestModel(`/communications/communicationsent/${id}`, RequestMethods.GET);

        ajaxRequest<CommunicationSent>(request)(dispatch, getState).then(response => {
            if (response.success == false) {
                deferred.resolve(null);
                return deferred.promise();
            }
            let runningState = { ...getBaseRunning() };

            runningState.message = response.entity.text;
            runningState.communicationList = response.entity.communicationList;
            runningState.locationId = response.entity.locationId;
            runningState.locationName = response.entity.locationName;
            runningState.messageSentId = response.entity.id;

            dispatch(actionCreator.setRunningState(runningState));
            dispatch(actionCreator.setModuleLoaded(true));

            deferred.resolve(response.entity);
        });

        return deferred.promise();
    }
}


export const reducer: Reducer<CommunicationState> = (state: CommunicationState | undefined, incomingAction: CommunicationActions): CommunicationState => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {

        case TypeKeys.RESET_STATE:
            newState = { ...getBaseState() };
            break;
        case TypeKeys.SET_LIST:
            newState = dotProp.set(newState, "list", [...incomingAction.list]);
            break;
        case TypeKeys.MODULE_LOADED:
            newState = dotProp.set(newState, "moduleLoaded", incomingAction.loaded);
            break;
        case TypeKeys.SET_EVENT_ID:
            newState = dotProp.set(newState, "eventId", incomingAction.eventId);
            break;
        case TypeKeys.SET_RUNNING_STATE:
            newState = dotProp.set(newState, "runningCommunication", { ...incomingAction.runningState });
            break;
        case TypeKeys.SET_SENTS_LIST:
            newState = dotProp.set(newState, "listSents", [...incomingAction.listSents]);
            break;
    }


    return newState;

}