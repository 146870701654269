import * as React from "react";
import { Profile } from '../../../store/profile/Profile';
import { Role } from '../../../store/profile/Role';
import { Anagraphic } from '../../shared/anagraphic';
import Privacy from '../../shared/privacyrow';
import AnagraphicAdditional from '../../shared/anagraphicadditionalinfo';
import AccountDataForm from '../shared/accountdata';
import { MenuItem } from '../../menu/menuitem';
import { Form, FormGroup, Row, Col } from "reactstrap";


export interface ProfileEditProps {
    profile: Profile;
    allRoles: Array<Role>;
    onSubmit?: () => void;
    onChange?: (propertyName: string, value: any) => void;
}

export class ProfileEdit extends React.Component<ProfileEditProps>{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <Form className="text-eurorounders" >
                    <Anagraphic {...{ ...this.props.profile, onChange: (propertyName: string, value: object) => this.props.onChange(propertyName, value) }} />
                    <AccountDataForm
                        account={this.props.profile.account}
                        allRoles={this.props.allRoles}
                        onChange={(propertyName: string, value: any) => this.props.onChange(propertyName, value)}
                        profileType={this.props.profile.type}
                    />
                    <Privacy {...{ ...this.props.profile, onChange: (propertyName: string, value: object) => this.props.onChange(propertyName, value) }} />
                    <AnagraphicAdditional {...{ ...this.props.profile, onChange: (propertyName: string, value: object) => this.props.onChange(propertyName, value) }} />
                    <Row>
                        <Col>
                            <FormGroup>
                                <MenuItem isSmall={true} isButton={true} icon="save" label="salva" onClick={() => this.props.onSubmit()} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        )
    }
}