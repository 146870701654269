import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionMiddleware, getBaseSearchCriteria, SearchCriteria, actionCreator as actionCreatorReservations } from '../../../store/reservations/ReservationState';
import { ReservationSearchFormBase, ReservationSearchFormBaseCallbacks, ReservationSearchFormBaseProps } from '../base/searchformbase';
import { actionCreator } from '../../../store/uiproperties/UIState';
import { sameMembers } from "../../../utils/commons";

const searchCriteriaChanged: (actual: SearchCriteria) => boolean = (actual: SearchCriteria) => {

    const base = getBaseSearchCriteria(actual.eventId);

    if (actual.agentContacts != base.agentContacts ||
        actual.checkInDone != base.checkInDone ||
        actual.checkInFrom != base.checkInFrom ||
        actual.checkInTo != base.checkInTo ||
        actual.checkOutFrom != base.checkOutFrom ||
        actual.checkOutTo != base.checkOutTo ||
        actual.createdByAgent != base.createdByAgent ||
        actual.customerContacts != base.customerContacts ||
        actual.locationId != base.locationId ||
        actual.notes != base.notes ||
        actual.orderDirection != base.orderDirection ||
        actual.orderField != base.orderField ||
        actual.progressiveNumber != base.progressiveNumber ||
        actual.roomTypeId != base.roomTypeId ||
        !sameMembers(actual.statesSelected, base.statesSelected) ||
        actual.totalPayFrom != base.totalPayFrom ||
        actual.type != base.type ||
        actual.webReservation != base.webReservation ||
        actual.paymentStatus != base.paymentStatus ||
        actual.paymentMethodId != base.paymentMethodId)
        return true;

    return false;
}

const mapStateToProsp = (state: ApplicationState): ReservationSearchFormBaseProps => {

    return {
        moduleLoaded: state.reservations.moduleLoaded,
        searchCriteria: state.reservations.searchCriteria,
        isSearchCriteriaChanged: searchCriteriaChanged(state.reservations.searchCriteria)
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): ReservationSearchFormBaseCallbacks => {
    return ({
        onSearch: (propertyName: string, value: any) => dispatch(actionMiddleware.doSearch(propertyName, value)),
        toogleQRReader: () => dispatch(actionCreator.toogleQrReader()),
        removeFilters: () => dispatch(actionMiddleware.resetFilters()),
        toggleAdvancedFilters: () => dispatch(actionCreatorReservations.toggleAdvancedFiltersModal(true))
    });
}

export const ConnectedReservationSearchFormBaseList = connect<ReservationSearchFormBaseProps, ReservationSearchFormBaseCallbacks>(mapStateToProsp, mapDispatchToProps)(ReservationSearchFormBase);