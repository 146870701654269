import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ApplicationState } from '../../../../store/index';
import { actionsMiddleware } from '../../../../store/payments/agentspayments/AgentsPaymentsState';
import { actionsMiddleware as paymentsMiddleware } from '../../../../store/payments/payments/PaymentState';
import { AgentsAccounting, AgentsAccountingProps, AgentsAccountingCallbacks } from './agentspayments';


const mapStateToProsp = (state: ApplicationState): AgentsAccountingProps => {

    return {
        list: state.agentsPayments.list,
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name}` : '',
    }
}

const mapDispatchToProps = () => (dispatch): AgentsAccountingCallbacks => {
    return ({
        onLoad: (eventId: string) => dispatch(actionsMiddleware.loadInitialState(eventId)),
        openOutcomeModal: (eventId: string) => dispatch(paymentsMiddleware.openOutcomeModal(eventId))
    });
}

export const ConnectedAgentsPayments = withRouter(connect<AgentsAccountingProps, AgentsAccountingCallbacks>(mapStateToProsp, mapDispatchToProps)(AgentsAccounting));