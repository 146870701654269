import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware, actionCreator } from '../../../store/pricings/PricingState';
import { BookingStatusPage, BookingStatusPageProps, BookingStatusPageCallbacks } from '../pages/bookingcheckstatus';

const mapStateToProsp = (state: ApplicationState): BookingStatusPageProps => {

    return {
        roomTypes: state.locations.roomTypes,
        locations: state.events.runningEventLocations,
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name.toUpperCase()}` : '',
        selectedLocationId: state.pricings.checkBookingState.selectedLocationId,
        selectedRoomTypeId: state.pricings.checkBookingState.selectedRoomId
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): BookingStatusPageCallbacks => {
    return ({
        onPropertyChange: (propertyName: string, value: any) => dispatch(actionsMiddleware.setRunningBookingCheckProp(propertyName, value)),
        onSearch: () => dispatch(actionsMiddleware.downloadExcel()),
        onLoad: () => dispatch(actionCreator.setBookingStatus({ selectedLocationId: null, selectedRoomId: null }))
    });
}

export const ConnectedBookingCheckStatus = withRouter(connect<BookingStatusPageProps, BookingStatusPageCallbacks>(mapStateToProsp, mapDispatchToProps)(BookingStatusPage));