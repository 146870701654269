import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware} from '../../../store/events/EventState';
import { EventsPageList, EventsPageListCallbacks, EventsPageListProps } from '../pages/listpage';
import _ from "underscore";

const mapStateToProsp = (state: ApplicationState): EventsPageListProps => {

    return {
        totalCount: state.events.totalCount,
        canCreateEvents: _.some(state.currentProfile.permissions, p => p.code == "Events")
    }
}

const mapDispatchToProps = () => (dispatch): EventsPageListCallbacks => {
    return ({
        onLoad: () => {
            dispatch(actionsMiddleware.loadInitialState());
        },
        navigate: actionsMiddleware.navigate
    });
}

export const ConnectedEventsPageList = connect<EventsPageListProps, EventsPageListCallbacks>(mapStateToProsp, mapDispatchToProps)(EventsPageList);