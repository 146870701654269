import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { Role } from '../../../../store/profile/Role';
import { actionCreator } from '../../.././../store/profile/RolesState';
import { actionCreator as actionCreatorsUI } from '../../.././../store/uiproperties/UIState';
import { RoleList, RoleListProps } from '../basecomponents/roleslist';


const mapStateToProsp = (state: ApplicationState): RoleListProps => {

    return {
        list: state.roles.rolesList
    }
}

const mapDispatchToProps = () => dispatch => {
    return ({
        onRowClick: (roleId: string) => {
            dispatch(actionCreator.navigateToRunningRole(roleId));
        }
    });
}

export const ConnectedRoleList = connect<RoleListProps>(mapStateToProsp, mapDispatchToProps)(RoleList);