import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { actionCreator } from '../../../../store/locations/groups/LocationGroupState';
import { LocationGroupList, LocationGroupListProps } from '../basecomponents/grouplist'

const mapStateToProsp = (state: ApplicationState): LocationGroupListProps => {

    return {
        list: state.locationGroups.locationGroups
    }
}

const mapDispatchToProps = () => dispatch => {
    return ({
        onRowClick: (id: string) => {
            dispatch(actionCreator.navigateToRunning(id));
        }
    });
}

export const ConnectedLocationGroupList = connect<LocationGroupListProps>(mapStateToProsp, mapDispatchToProps)(LocationGroupList);