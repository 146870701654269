import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/payments/payments/PaymentState';
import { IncomeModalCallbacks, IncomeModal, IncomeModalProps } from './incomemodal';
import { actionsMiddleware as registerActions } from '../../../store/payments/cashregister/CashRegisterState';

const mapStateToProsp = (state: ApplicationState): IncomeModalProps => {
    return {
        incomeData: state.payment.incomeModal
    }
}

const mapDispatchToProps = () => (dispatch): IncomeModalCallbacks => {
    return ({
        onCloseModal: () => dispatch(actionsMiddleware.closeIncomeModal()),
        onPropertyChange: (name: string, value: any) => dispatch(actionsMiddleware.editIncomeProperty(name, value)),
        onSave: (eventId: string) => {
            dispatch(actionsMiddleware.saveIncome()).then((response: boolean) => {
                if (response)
                    dispatch(registerActions.loadInitialState(eventId));
            })
        }
    });
}

export const ConnectedIncomeModal = connect<IncomeModalProps, IncomeModalCallbacks>(mapStateToProsp, mapDispatchToProps)(IncomeModal);