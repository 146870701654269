import * as React from "react";
import { Communication } from "../../../store/communications/CommunicationEntities";
import { Location, RoomType } from "../../../store/locations/LocationEntities";
import { KeyDesc } from "../../../store/shared/KeyDesc";
import { BaseList } from '../../base/baseList';
import { CheckBox } from '../../base/checkbox';
import _ from "underscore";
import moment from "moment";
import GoToTop from "../../base/gototop";


export interface CommunicationsSelectionListProps {
    selectionEnabled: boolean;
    reservationStatus: Array<KeyDesc<string>>;
    communicationTypes: Array<KeyDesc<string>>;
    locations: Array<Location>;
    roomTypes: Array<RoomType>;
    slectedCommunications?: Array<Communication>;
    list: Array<Communication>;
}

export interface CommunicationsSelectionListCallback {
    onRowClick: (communication: Communication) => void;
}

export class CommunicationsSelectionList extends React.Component<CommunicationsSelectionListProps & CommunicationsSelectionListCallback>{

    constructor(props) {
        super(props);
    }

    private getRows(list: Array<Communication>): JSX.Element[] {

        let communicationArray = list.map(model => {

            let checkbox = (<React.Fragment></React.Fragment>);

            let isSelected = false;

            if (this.props.slectedCommunications !== undefined && this.props.slectedCommunications != null && this.props.slectedCommunications.length > 0) {
                isSelected = _.some(this.props.slectedCommunications, (c) => c.id == model.id);
            }

            if (this.props.selectionEnabled == true) {
                checkbox = (
                    <td>
                        <CheckBox isChecked={isSelected} onCheck={(checked: boolean) => { }} />
                    </td>
                )
            }

            let checkIn = "-";
            if (model.actCheckIn !== undefined && model.actCheckIn != null) {
                let momentDate = moment(model.actCheckIn);
                if (momentDate !== undefined && momentDate != null) {
                    checkIn = momentDate.format("L");
                }
            }

            let checkOut = "-";
            if (model.actCheckOut !== undefined && model.actCheckOut != null) {
                let momentDate = moment(model.actCheckOut);
                if (momentDate !== undefined && momentDate != null) {
                    checkOut = momentDate.format("L");
                }
            }

            let prevCheckIn = "-";
            if (model.prevCheckIn !== undefined && model.prevCheckIn != null) {
                let momentDate = moment(model.prevCheckIn);
                if (momentDate !== undefined && momentDate != null) {
                    prevCheckIn = momentDate.format("L");
                }
            }

            let prevCheckOut = "-";
            if (model.prevCheckOut !== undefined && model.prevCheckOut != null) {
                let momentDate = moment(model.prevCheckOut);
                if (momentDate !== undefined && momentDate != null) {
                    prevCheckOut = momentDate.format("L");
                }
            }

            let actualRoomType = "-";
            let foundRoomType = _.find(this.props.roomTypes, (rt) => rt.id == model.actRoomType);

            if (foundRoomType !== undefined && foundRoomType != null)
                actualRoomType = foundRoomType.type;

            let previousRoomType = "-";

            foundRoomType = _.find(this.props.roomTypes, (rt) => rt.id == model.prevRoomType);

            if (foundRoomType !== undefined && foundRoomType != null)
                previousRoomType = foundRoomType.type;

            let staffBooking = model.isStaffBooking == true ? "(Staff)" : "";

            let communicationType = "-";

            let foundCommType = _.find(this.props.communicationTypes, (ct) => ct.key == model.communicationType)

            if (foundCommType !== undefined && foundCommType != null)
                communicationType = foundCommType.desc;

            return (
                <tr key={model.id} style={{ cursor: "pointer" }} onClick={() => this.props.onRowClick(model)}>
                    {checkbox}
                    <td>
                        <div className="communications-table-cell">
                            <div>{model.progressiveNumber}</div>
                            <div>{communicationType} {staffBooking}</div>
                        </div>
                    </td>
                    <td>
                        <div className="communications-table-cell">
                            <div>{checkIn}</div>
                            <div>{checkOut}</div>
                        </div>
                    </td>
                    <td>
                        <div className="communications-table-cell d-none d-md-table-cell">
                            <div>{prevCheckIn}</div>
                            <div>{prevCheckOut}</div>
                        </div>
                    </td>
                    <td>
                        <div className="communications-table-cell">
                            <div>{previousRoomType}</div>
                            <div>{actualRoomType}</div>
                        </div>
                    </td>
                </tr>
            )
        });

        return communicationArray;

    }

    private getHead(): JSX.Element {

        let thSelection = (<React.Fragment></React.Fragment>);

        if (this.props.selectionEnabled == true) {
            thSelection = (<th>Sel.</th>);
        }

        return (
            <thead>
                <tr className="text-eurorounders">
                    {thSelection}
                    <th>
                        <div className="communications-table-cell">
                            <div>Id</div>
                            <div>Tipo</div>
                        </div>
                    </th>
                    <th>
                        <div className="communications-table-cell">
                            <div>Check In</div>
                            <div>Check Out</div>
                        </div>
                    </th>
                    <th>
                        <div className="communications-table-cell d-none d-md-table-cell">
                            <div>C.I. Prec.</div>
                            <div>C.O. Prec.</div>
                        </div>
                    </th>
                    <th>
                        <div className="communications-table-cell">
                            <div>Camera prec.</div>
                            <div>Camera</div>
                        </div>
                    </th>
                </tr>
            </thead>
        );

    }


    render() {

        return (
            <React.Fragment>
                <BaseList hasNextPage={false} list={this.props.list} active={false} onEndOfList={() => { }} getElements={(list: Array<Communication>) => { return this.getRows(list) }} getHead={() => { return this.getHead() }} />
                <GoToTop />
            </React.Fragment>
        )
    }
}