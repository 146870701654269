import * as React from "react";
import { ConnectedProfileList } from '../connected/connected.profilelist';
import { Input, Row, Col, Form, FormGroup, Label } from "reactstrap";
import { MenuItemProps } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { StickyBodyHeader } from "../../base/stickybodyheader";

export interface ProfilePageListProps {
    searchString: string;
    onSearch?: (searchString: string) => void;
    onLoad?: () => void;
}

export class ProfilePageList extends React.Component<ProfilePageListProps> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad)
            this.props.onLoad();
    }

    private onSearch(searchString: string) {

        if (this.props.onSearch)
            this.props.onSearch(searchString);

    }

    render() {

        let menuItems = [
            {
                icon: "plus",
                label: "crea",
                path: "/profiles/create"
            },
            {
                icon: "user-tag",
                label: "ruoli",
                path: "/profiles/roles"
            }
        ] as Array<MenuItemProps>

        return (
            <React.Fragment>
                <StickyBodyHeader>
                    <BodyHeader title="Utenti" menuItems={menuItems} />
                    <Form className="text-eurorounders search-form">
                        <Row>
                            <Col lg={{ size: 6, order: 0 }} sm={{ size: 6, order: 0 }} xs={{ size: 12 }}>
                                <FormGroup>
                                    <Label>Cerca:</Label>
                                    <Input
                                        className="bg-dark text-eurorounders"

                                        type="search"
                                        value={this.props.searchString || ''}
                                        onChange={(e) => { this.onSearch(e.target.value) }}
                                        placeholder="Cerca per nome, cognome, email e telefono"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </StickyBodyHeader>
                <ConnectedProfileList />
            </React.Fragment>
        )
    }
}