import * as React from "react";
import { Row, Col, Card, CardTitle, CardText, FormGroup } from "reactstrap";
import { MenuItem } from '../../menu/menuitem';
import { SearchCriteria } from '../../../store/reservations/ReservationQuoteState';
import { Reservation } from '../../../store/reservations/ReservationEntities';
import { Location, RoomType } from '../../../store/locations/LocationEntities';
import moment from "moment";
import _ from "underscore";
import { guid } from '../../../utils/commons';

import './quotelist.css';

export interface ReservationQuoteListProps {
    list: Array<Reservation>;
    locations: Array<Location>;
    searchPerformed: boolean;
    roomTypes: Array<RoomType>;

}

export interface ReservationQuoteListCallbacks {
    create: (res: Reservation) => void;
}


export class ReservationQuoteList extends React.Component<ReservationQuoteListProps & ReservationQuoteListCallbacks>{

    constructor(props) {
        super(props);
    }



    private getList(): JSX.Element[] {

        return this.props.list.map(reservation => {

            let totalTaxPerPerson = 0;
            let location = "";
            let totalPaySimulation = reservation.totalPay;
            let capacity = 0;
            let roomType: RoomType = null;
            if (reservation.reservationDays !== undefined && reservation.reservationDays != null && reservation.reservationDays.length > 0) {
                reservation.reservationDays.forEach(x => {
                    totalTaxPerPerson += x.dailyTax;
                });

                roomType = _.find(this.props.roomTypes, (rt) => rt.id == reservation.roomType.id);

                if (roomType !== undefined && roomType != null) {
                    totalPaySimulation += (totalTaxPerPerson * roomType.capacity);
                    capacity = roomType.capacity;
                }
            }

            if (this.props.locations !== undefined && this.props.locations != null && this.props.locations.length > 0) {
                location = _.find(this.props.locations, (l) => l.id == reservation.location.id).name;
            }

            return (
                <Row key={guid()}>
                    <Col>
                        <Card body className="bg-dark text-eurorounders card-border card-spacing">
                            <CardTitle className="bg-dark text-eurorounders text-capitalize font-weight-bold hotel-title">{location} - {roomType?.type}</CardTitle>
                            <CardText className="bg-dark text-eurorounders"><span>Prezzo camera: </span><span className="font-weight-bold">{reservation.totalPay} €</span></CardText>
                            <CardText className="bg-dark text-eurorounders"><span>Tasse: </span><span className="font-weight-bold">{totalTaxPerPerson} € per ospite</span></CardText>
                            <CardText className="bg-dark text-eurorounders"><span className="font-weight-bold total-title">Totale: {totalPaySimulation} € </span><span>*tasse calcolate per {capacity} ospiti</span></CardText>
                            <FormGroup className="menu-button-form-group">
                                <MenuItem isSmall={true} isButton={true} icon="plus" label="Crea" onClick={() => this.props.create(reservation)} />
                            </FormGroup>
                        </Card>
                    </Col>
                </Row>
            );
        });
    }


    render() {
        let list = this.getList();

        if (list === undefined || list == null) {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }

        if (list.length == 0 && this.props.searchPerformed == true) {
            return (
                <React.Fragment>
                    <div className="bg-dark text-eurorounders">
                        Nessun risultato disponibile
                    </div>
                </React.Fragment>
            );
        }

        return (
            <div>
                {list}
            </div>
        );
    }
}