import * as React from "react";
import { Input } from "reactstrap";

import './decimalinput.css';

interface IntegerInputProps {
    value?: number | string | '';
    onChane: (value?: number) => void;
    className?: string;
    readOnly?: boolean;
    alignLeft?: boolean;
}

export default class IntegerInput extends React.Component<IntegerInputProps> {
    constructor(props) {
        super(props);
    }

    private checkChange(value: string) {
        try {

            let toReturn: number = null;

            if (value === undefined || value == null || value == '') {
                this.props.onChane(toReturn);
                return;
            }

            let numbeRegex = /^\d+$/;

            if (!numbeRegex.test(value))
                return;

            try {
                toReturn = parseInt(value);
            } catch {
                return;
            }

            this.props.onChane(toReturn);
        }
        catch { }
    }

    render() {
        let valueToShow = this.props.value === undefined || this.props.value == null ? '' : this.props.value;
        let className = ""

        if (this.props.className !== undefined && this.props.className != null)
            className = this.props.className;

        let readOnly = this.props.readOnly == true;

        let style = {}

        if (this.props.alignLeft == true) {
            style = { textAlign: "left" };
        }

        return (
            <React.Fragment>
                <Input style={style} readOnly={readOnly}  className={`bg-dark text-eurorounders decimal-input ${className}`} type="text" value={valueToShow} onChange={(e) => this.checkChange(e.target.value)} />
            </React.Fragment>
        )
    }

}