import { Action, Reducer } from 'redux';
import { Profile, Account, ProfileType } from './Profile';
import { AppThunkAction, ApplicationState } from '../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest, SaveRequest, FetchListRequest } from '../../utils/ajaxutilities';
import { OrderDirection } from '../../utils/commons';
import dotprop from "dot-prop";
import { actionCreator as actionCreatorUi } from '../uiproperties/UIState';
import { CoreResponse } from '../serverresponse/serverresponse';
import { PAGE_SIZE } from '../../utils/commons';
import { push } from 'connected-react-router';
import { Role } from './Role';
import { actionCreator as actionCreatorRoles } from './RolesState'
import _ from "underscore";

const debouncedActions = (dispatch) => {
    dispatch(actionCreator.setProfileList([]));
    dispatch(actionCreator.loadData(false, false));
}

const debounceSearch = _.debounce(debouncedActions, 500);

export interface ProfilesSatate {
    profilesList: Array<Profile>;
    runningProfile: Profile;
    searchState: ProfilesSearchState;
}

interface ProfilesSearchState {
    searchString: string;
    orderField: string;
    pageNumber: number;
    orderDirection: OrderDirection;
    hasNextPage: boolean;
}

enum TypeKeys {
    SET_PROFILES_FILTER = "PROFILES_STATE_SET_PROFILES_FILTER",
    SET_PROFILES_LIST = "PROFILES_STATE_SET_PROFILES_LIST",
    APPEND_PROFILE_LIST = "PROFILES_STATE_APPEND_PROFILE_LIST",
    RESET_STATE = "PROFILES_STATE_RESET_STATE",
    SET_RUNNING_PROFILE = "PROFILES_STATE_SET_RUNNING_PROFILE",
    UNLOAD_RUNNING = "PROFILES_UNLOAD_RUNNING"
}

type ActionTypes = SetProfilesFilter | SetProfileList | AppendProfileList | ResetState | SetRunningProfile | UnloadRunning;

interface UnloadRunning extends Action {
    type: TypeKeys.UNLOAD_RUNNING
}

interface SetProfilesFilter extends Action {
    type: TypeKeys.SET_PROFILES_FILTER;
    searchState: ProfilesSearchState;
}

interface SetProfileList extends Action {
    type: TypeKeys.SET_PROFILES_LIST;
    profiles: Array<Profile>;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE
}

interface AppendProfileList extends Action {
    type: TypeKeys.APPEND_PROFILE_LIST;
    profiles: Array<Profile>;
}

interface SetRunningProfile extends Action {
    type: TypeKeys.SET_RUNNING_PROFILE;
    profile: Profile;
}


export const profilesFunction = {
    search: (module: string, searchState: ProfilesSearchState, preservePages: boolean) => (dispatch, getState): JQueryPromise<CoreResponse<Array<Profile>>> => {
        let searchObject = { ...searchState, preservePages: preservePages };
        let requestModel = new FetchListRequest(`/${module}/search`, RequestMethods.GET, searchObject);
        return ajaxRequest<Array<Profile>>(requestModel)(dispatch, getState);
    },
    getProfileById: (id: string) => (dispatch, getState): JQueryPromise<CoreResponse<Profile>> => {
        let requestModel = new RequestModel(`/profiles/${id}`, RequestMethods.GET, null, null);
        return ajaxRequest<Profile>(requestModel)(dispatch, getState);
    },
    delete: (id: string) => (dispatch, getState): JQueryPromise<CoreResponse<any>> => {

        let requestModel = new SaveRequest(`/profiles/${id}`, RequestMethods.DELETE, null);

        return ajaxRequest<any>(requestModel)(dispatch, getState);

    },
    save: (module: string, profileToSave: Profile) => (dispatch, getState): JQueryPromise<CoreResponse<Profile>> => {
        let request = new SaveRequest(`/${module}/save`, RequestMethods.POST, profileToSave);
        return ajaxRequest<Profile>(request)(dispatch, getState);
    },
};

export const actionCreator = {
    loadInitialState: (): AppThunkAction<any> => (dispatch, getState) => {
        abortAllRequest();
        dispatch(actionCreatorUi.setPageLoaded(false));

        let appState = (getState() as ApplicationState);
        let preservePages: boolean = true;

        dispatch(actionCreator.resetState());

        if (appState.uiProperties.previousPath === undefined || appState.uiProperties.previousPath == null || !(appState.uiProperties.previousPath.startsWith("/profiles/edit") || appState.uiProperties.previousPath.startsWith("/profiles/create"))) {
            dispatch(actionCreator.setProfilesFilter(0, "", "", OrderDirection.ASC));
            preservePages = false;
        }

        dispatch(actionCreator.loadData(preservePages, true));
    },
    genericSearch: (value: string): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {
        abortAllRequest();
        let profileFilter = { ...getState().profiles.searchState };
        //reset page number and alterate string search
        profileFilter.pageNumber = 0;
        profileFilter.searchString = value;
        profileFilter.hasNextPage = false;
        dispatch(actionCreator.setProfilesFilterObject(profileFilter));

        debounceSearch(dispatch);
    },
    setProfilesFilterObject: (searchState: ProfilesSearchState): SetProfilesFilter => ({
        type: TypeKeys.SET_PROFILES_FILTER,
        searchState: searchState
    }),
    setProfilesFilter: (pageNumber: number, orderField: string, searchString: string, orderDirection: OrderDirection, hasNextPage: boolean = true): SetProfilesFilter => ({
        type: TypeKeys.SET_PROFILES_FILTER,
        searchState: {
            orderField: orderField,
            pageNumber: pageNumber,
            searchString: searchString,
            orderDirection: orderDirection,
            hasNextPage: hasNextPage
        }
    }),
    loadNextPage: (): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {
        let searchState = getState().profiles.searchState;
        searchState.pageNumber += 1;
        dispatch(actionCreator.setProfilesFilterObject(searchState));
        dispatch(actionCreator.loadData(false, false));
    },
    loadData: (preservePages: boolean, firstLoad: boolean): AppThunkAction<any> => (dispatch, getState) => {

        let searchState = (getState() as ApplicationState).profiles.searchState;

        profilesFunction.search("profiles", searchState, preservePages)(dispatch, getState).then(response => {
            if (response.success == false) {
                dispatch(actionCreatorUi.setErrorMessages(response.messages));
                return;
            }

            searchState.hasNextPage = true;

            if (response.entity.length < PAGE_SIZE) {
                searchState.hasNextPage = false;
            }

            if (preservePages && (response.entity.length < (searchState.pageNumber + 1) * PAGE_SIZE)) {
                searchState.hasNextPage = false;
            }

            dispatch(actionCreator.appendProfileList(response.entity));
            dispatch(actionCreator.setProfilesFilterObject(searchState));

            if (firstLoad)
                dispatch(actionCreatorUi.setPageLoaded(true));

            dispatch(actionCreatorUi.startFetchingPagedList(true));

        });
    },
    setProfileList: (profiles: Array<Profile>): SetProfileList => ({
        type: TypeKeys.SET_PROFILES_LIST,
        profiles: profiles
    }),
    appendProfileList: (profiles: Array<Profile>): AppendProfileList => ({
        type: TypeKeys.APPEND_PROFILE_LIST,
        profiles: profiles
    }),
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    unloadRunning: (): UnloadRunning => ({
        type: TypeKeys.UNLOAD_RUNNING
    }),
    navigateToRunningProfile: (profileId: string): AppThunkAction<any> => (dispatch, getState) => {
        dispatch(actionCreator.unloadRunning());
        dispatch(push(`/profiles/edit/${profileId}`));
    },
    loadRunningProfile: (id?: string): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {

        if (getState().roles.rolesList === undefined || getState().roles.rolesList == null || getState().roles.rolesList.length == 0) {
            dispatch(actionCreatorRoles.loadAllRoles());
        }


        if (id === undefined || id == null || id == "") {
            dispatch(actionCreator.setRunningProfile(getNewProfile()));
            dispatch(actionCreatorUi.setPageLoaded(true));
            return;
        }
        profilesFunction.getProfileById(id)(dispatch, getState).then(profileResponse => {
            if (profileResponse.success == false || profileResponse.entity == null || profileResponse.entity === undefined)
                return;
            dispatch(actionCreator.setRunningProfile(profileResponse.entity));
            dispatch(actionCreatorUi.setPageLoaded(true));
        });

    },
    setRunningProfileProp: (propertyName: string, value: any): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {

        dispatch(actionCreatorUi.setErrorMessages([]));

        let runningProfile = { ...getState().profiles.runningProfile };

        switch (propertyName) {
            case "firstName":
                runningProfile.firstName = value;
                break;
            case "lastName":
                runningProfile.lastName = value;
                break;
            case "email":
                runningProfile.email = value;
                break;
            case "phone":
                runningProfile.phone = value;
                break;
            case "phonePrefix":
                runningProfile.phonePrefix = value;
                break;
            case "city":
                runningProfile.city = value;
                break;
            case "country":
                runningProfile.country = value;
                break;
            case "zipCode":
                runningProfile.zipCode = value;
                break;
            case "stateProvince":
                runningProfile.stateProvince = value;
                break;
            case "address":
                runningProfile.address = value;
                break;
            case "birthDate":
                runningProfile.birthDate = value;
                break;
            case "taxCode":
                runningProfile.taxCode = value;
                break;
            case "vatNumber":
                runningProfile.vatNumber = value;
                break;
            case "password":
                runningProfile.account.password = value;
                break;
            case "confirmPassword":
                runningProfile.account.confirmPassword = value;
                break;
            case "isDisabled":
                runningProfile.account.isDisabled = value;
                break;
            case "role":
                let allRoles = getState().roles.rolesList;
                let role = _.find(allRoles, (role) => role.id == value);
                runningProfile.account.role = role;
                break;
            case "privacy":
                runningProfile.privacy = value;
                break;
        }

        dispatch(actionCreator.setRunningProfile(runningProfile));


    },
    setRunningProfile: (profile: Profile): SetRunningProfile => ({
        type: TypeKeys.SET_RUNNING_PROFILE,
        profile: profile
    }),
    delete: (): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {


        profilesFunction.delete(getState().profiles.runningProfile.id)(dispatch, getState).then(response => {
            if (response.success == true) {
                dispatch(push("/profiles"));
            }
        });
    },
    saveProfile: (): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {
        dispatch(actionCreatorUi.setErrorMessages([]));
        //validation
        let profileToUpdate: Profile = getState().profiles.runningProfile;

        if (profileToUpdate.lastName == "" || profileToUpdate.lastName == null || profileToUpdate.lastName === undefined) {
            dispatch(actionCreatorUi.setErrorMessages(["Cognome obbligatorio"]));
            return;
        }

        profilesFunction.save("profiles", profileToUpdate)(dispatch, getState).then(response => {
            if (response.success == false) {
                return;
            }

            dispatch(actionCreator.setRunningProfile(response.entity));
        });
    },
};

const getNewProfile = (): Profile => {
    return {
        type: ProfileType.User,
        account: {
            role: {

            } as Role
        } as Account
    } as Profile
}

let baseState: ProfilesSatate = {
    profilesList: [],
    runningProfile: getNewProfile(),
    searchState: {
        orderField: null,
        pageNumber: 0,
        searchString: null,
        orderDirection: OrderDirection.ASC,
        hasNextPage: false
    }
}

export const reducer: Reducer<ProfilesSatate> = (state: ProfilesSatate | undefined, incomingAction: ActionTypes): ProfilesSatate => {

    if (state === undefined) {
        return baseState;
    }

    let newState: ProfilesSatate = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.UNLOAD_RUNNING:
            newState = dotprop.set(newState, 'runningProfile', null);
            break;
        case TypeKeys.RESET_STATE:
            newState = dotprop.set(newState, 'profilesList', []);
            newState = dotprop.set(newState, 'runningProfile', { ...getNewProfile() });
            break;
        case TypeKeys.SET_PROFILES_FILTER:
            newState = dotprop.set(newState, 'searchState', { ...incomingAction.searchState });
            break;
        case TypeKeys.SET_PROFILES_LIST:
            newState = dotprop.set(newState, 'profilesList', incomingAction.profiles);
            break;
        case TypeKeys.APPEND_PROFILE_LIST:
            let profiles = newState.profilesList.concat(incomingAction.profiles)
            newState = dotprop.set(newState, 'profilesList', profiles);
            break;
        case TypeKeys.SET_RUNNING_PROFILE:
            newState = dotprop.set(newState, 'runningProfile', { ...incomingAction.profile });
            break;
    }


    return newState;
};