import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/eventaccountings/EventAccountingState';
import { actionsMiddleware as eventActions } from '../../../store/events/EventState';
import { EventAccountPageList, EventAccountPageListCallbacks, EventAccountPageListProps } from '../pages/listpage';

const mapStateToProsp = (state: ApplicationState): EventAccountPageListProps => {

    return {
        totalCount: state.eventAccountings.totalCount,
        isFreezed: state.events.running !== undefined && state.events.running != null ? state.events.running.isFreezed : false,
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name.toUpperCase()}` : ''
    }
}

const mapDispatchToProps = () => (dispatch): EventAccountPageListCallbacks => {
    return ({
        onLoad: (eventId: string) => {
            dispatch(actionsMiddleware.loadInitialState(eventId));
        },
        onOpen: () => {
            dispatch(eventActions.reOpenRunning());
        }
    });
}

export const ConnectedEventAccountPageList = withRouter(connect<EventAccountPageListProps, EventAccountPageListCallbacks>(mapStateToProsp, mapDispatchToProps)(EventAccountPageList));