import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/index';
import { actionCreator } from '../../../../store/locations/groups/LocationGroupState';
import { actionCreator as actionCreatorsUI } from '../../../../store/uiproperties/UIState';
import { LocationGroupEditPage, LocationGroupEditProps } from '../pages/groupedit';
import { withRouter } from 'react-router';

const mapStateToProsp = (state: ApplicationState): LocationGroupEditProps => {
    return {
        group: state.locationGroups.runningLocationGroup,
        pageLoaded: state.uiProperties.pageInitialized
    }
}

const mapDispatchToProps = () => dispatch => {
    return ({
        onLoad: (roleId: string) => {
            dispatch(actionCreator.findRunning(roleId));
        },
        onChange: (propertyName: string, value: any) => {
            dispatch(actionCreator.setProperty(propertyName, value));
            dispatch(actionCreatorsUI.setChangeInProgress(true));
        },
        onSubmit: () => dispatch(actionCreator.save()),
        onDelete: (id: string) => {
            dispatch(actionCreator.delete(id));
        }
    });
}

export const ConnectedLocationGroupEdit = withRouter(connect<LocationGroupEditProps>(mapStateToProsp, mapDispatchToProps)(LocationGroupEditPage));