import * as React from "react";
import { Row, Col } from "reactstrap";
import { EventAccountingV2 } from '../../../../store/eventaccountings/EventAccountingEntities';

export interface HotelsSummaryProps {
    accounting: EventAccountingV2;
}


export class HotelsSummary extends React.Component<HotelsSummaryProps> {

    constructor(props) {
        super(props);
    }

    render() {

        if (this.props.accounting === undefined || this.props.accounting == null)
            return (<React.Fragment />);

        return (
            <Row className="text-eurorounders" style={{ marginTop: "15px" }}>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            COSTO NOTTI VENDUTE
                        </span>
                        <span className="float-right">
                            {this.props.accounting.totalSoldNightsCost} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            COSTO NOTTI INVENDUTE
                        </span>
                        <span className="float-right">
                            {this.props.accounting.totalUnsoldNightsCost} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            COSTO NOTTI STAFF
                        </span>
                        <span className="float-right">
                            {this.props.accounting.totalStaffNightsCost} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            COSTO NOTTI STAFF HOTEL
                        </span>
                        <span className="float-right">
                            {this.props.accounting.totalStaffHotelNightsCost} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            COSTO NOTTI GUEST
                        </span>
                        <span className="float-right">
                            {this.props.accounting.totalGuestNightsCost} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            TASSE NOTTI "CASH"
                        </span>
                        <span className="float-right">
                            {this.props.accounting.totalBookingTax} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            TASSE NOTTI STAFF
                        </span>
                        <span className="float-right">
                            {this.props.accounting.totalStaffTax} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            TASSE NOTTI STAFF HOTEL
                        </span>
                        <span className="float-right">
                            {this.props.accounting.totalStaffHotelTax} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            TASSE NOTTI GUEST
                        </span>
                        <span className="float-right">
                            {this.props.accounting.totalGuestTax} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase text-warning" style={{ width: "100%" }}>
                        <span className="float-left">
                            costo totale hotel
                        </span>
                        <span className={`float-right`}>
                            {this.props.accounting.totalHotelsCost} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase text-warning" style={{ width: "100%" }}>
                        <span className="float-left">
                            Totale Pagato
                        </span>
                        <span className={`float-right`}>
                            {this.props.accounting.totalHotelsPayment} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase text-danger" style={{ width: "100%" }}>
                        <span className="float-left">
                            da pagare
                        </span>
                        <span className={`float-right`}>
                            {this.props.accounting.toPayHotels} €
                        </span>
                    </div>
                </Col>
            </Row>
        )
    }
}