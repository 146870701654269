import * as React from "react";
import { Form, FormGroup, Row, Col, Input, Label, InputGroup, InputGroupText, InputGroupAddon, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ReservationPaymentStatus, SearchCriteria } from '../../../store/reservations/ReservationState';
import { Location, RoomType } from '../../../store/locations/LocationEntities';
import { KeyDesc } from '../../../store/shared/KeyDesc';
import DecimalInput from '../../base/decimalinput';
import { CheckBox } from '../../base/checkbox';
import { ERDatePicker as DatePicker } from '../../base/datepicker';
import BaseSelect from '../../base/baseselect';
import _ from "underscore";

import './searchform.css';

import { PaymentMethod } from "../../../store/payments/paymentmethods/PaymentMethodEntities";
import { MenuItem } from "../../menu/menuitem";

export interface ReservationSearchFormAdvancedProps {
    searchCriteria: SearchCriteria;
    locations: Array<Location>;
    roomTypes: Array<RoomType>;
    reservationStates: Array<KeyDesc<string>>;
    reservationType: Array<KeyDesc<string>>;
    orderFields: Array<KeyDesc<string>>;
    orderDirections: Array<KeyDesc<string>>;
    paymentStatusOptions: Array<KeyDesc<ReservationPaymentStatus>>;
    paymentMethods: Array<PaymentMethod>;
    isOpen: boolean;
}

export interface ReservationSearchFormAdvancedCallbacks {
    onSearch: (propertyName: string, value: any) => void;
    close: () => void;
}

export class SearchFormAdvanced extends React.Component<ReservationSearchFormAdvancedProps & ReservationSearchFormAdvancedCallbacks>{
    constructor(props) {
        super(props);
    }

    private getCheckboxList(): JSX.Element[] {

        return this.props.reservationStates.map(state => {

            let isChecked: boolean = _.some(this.props.searchCriteria.statesSelected, (selected) => selected == state.key);

            return (
                <Col lg="3" sm="6" xs="6" key={state.key}>
                    <CheckBox
                        isChecked={isChecked}
                        onCheck={(selected: boolean) => this.props.onSearch("statesSelected", state.key)}
                        key={state.key}
                        label={state.desc}
                        className="adaptable-checkbox-width"
                    />
                </Col>
            );
        });
    }

    render() {

        let yesNoOptions: Array<KeyDesc<boolean>> = [
            {
                desc: "Si",
                key: true
            },
            {
                desc: "No",
                key: false
            }
        ]

        let checkboxList = this.getCheckboxList();

        let disableCheckOutTo = true;

        if (this.props.searchCriteria?.checkOutFrom)
            disableCheckOutTo = false;

        let modalForm = (<React.Fragment />);

        modalForm = (
            <Form className="text-eurorounders">
                <Row>
                    <Col lg="4" sm="4" xs="12">
                        <FormGroup>
                            <Label>Agente</Label>
                            <Input
                                className="bg-dark text-eurorounders"

                                type="search"
                                value={this.props.searchCriteria.agentContacts || ''}
                                onChange={(e) => { this.props.onSearch("agentContacts", e.target.value) }}
                                placeholder="Cerca per nome cognome telefono e email"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="4" sm="4" xs="6">
                        <FormGroup>
                            <Label>Hotel</Label>
                            <BaseSelect
                                descField="name"
                                idField="id"
                                options={this.props.locations}
                                value={this.props.searchCriteria.locationId}
                                onChange={(value: any) => { this.props.onSearch("locationId", value) }} />
                        </FormGroup>
                    </Col>
                    <Col lg="4" sm="4" xs="6">
                        <FormGroup>
                            <Label>Camera</Label>
                            <BaseSelect
                                descField="type"
                                idField="id"
                                options={this.props.roomTypes}
                                value={this.props.searchCriteria.roomTypeId}
                                onChange={(value: any) => { this.props.onSearch("roomTypeId", value) }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" sm="4" xs="4">
                        <FormGroup>
                            <Label>Tipo</Label>
                            <BaseSelect
                                descField="desc"
                                idField="key"
                                options={this.props.reservationType}
                                value={this.props.searchCriteria.type}
                                onChange={(value: any) => { this.props.onSearch("type", value) }} />
                        </FormGroup>
                    </Col>
                    <Col lg="4" sm="4" xs="4">
                        <FormGroup>
                            <Label>Prenotazione web</Label>
                            <BaseSelect
                                descField="desc"
                                idField="key"
                                options={yesNoOptions}
                                value={this.props.searchCriteria.webReservation}
                                onChange={(value: any) => { this.props.onSearch("webReservation", value) }} />
                        </FormGroup>
                    </Col>
                    <Col lg="4" sm="4" xs="4">
                        <FormGroup>
                            <Label>Check in</Label>
                            <BaseSelect
                                descField="desc"
                                idField="key"
                                options={yesNoOptions}
                                value={this.props.searchCriteria.checkInDone}
                                onChange={(value: any) => { this.props.onSearch("checkInDone", value) }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="6" xs="6">
                        <FormGroup>
                            <Label>Stato Pagamento</Label>
                            <BaseSelect
                                descField="desc"
                                idField="key"
                                options={this.props.paymentStatusOptions}
                                value={this.props.searchCriteria.paymentStatus}
                                onChange={(value: any) => { this.props.onSearch("paymentStatus", value) }} />
                        </FormGroup>
                    </Col>
                    <Col lg="6" sm="6" xs="6">
                        <FormGroup>
                            <Label>Metodo Pagamento</Label>
                            <BaseSelect
                                descField="method"
                                idField="id"
                                options={this.props.paymentMethods}
                                value={this.props.searchCriteria.paymentMethodId}
                                onChange={(value: any) => { this.props.onSearch("paymentMethodId", value) }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" sm="12" xs="12">
                        <FormGroup>
                            <Label>Note</Label>
                            <Input
                                className="bg-dark text-eurorounders"
                                type="search"
                                value={this.props.searchCriteria.notes || ''}
                                onChange={(e) => { this.props.onSearch("notes", e.target.value) }}
                                placeholder="Search in notes"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{ marginTop: "15px", marginBottom: "15px" }}>
                    {checkboxList}
                </Row>
                <Row>
                    <Col lg="3" sm="6" xs="6">
                        <FormGroup>
                            <Label>Check Out da</Label>
                            <DatePicker selectedDate={this.props.searchCriteria.checkOutFrom} onChange={date => this.props.onSearch("checkOutFrom", date)} />
                        </FormGroup>
                    </Col>
                    <Col lg="3" sm="6" xs="6">
                        <FormGroup>
                            <Label>Check Out a</Label>
                            <DatePicker disabled={disableCheckOutTo} selectedDate={this.props.searchCriteria.checkOutTo} onChange={date => this.props.onSearch("checkOutTo", date)} />
                        </FormGroup>
                    </Col>
                    <Col lg="3" sm="3" xs="6">
                        <FormGroup>
                            <Label>Saldo da</Label>
                            <InputGroup>
                                <DecimalInput
                                    onChane={(value: string) => this.props.onSearch("totalPayFrom", value)}
                                    value={this.props.searchCriteria.totalPayFrom}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col lg="3" sm="3" xs="6">
                        <FormGroup>
                            <Label>Saldo a</Label>
                            <InputGroup>
                                <DecimalInput
                                    onChane={(value: string) => this.props.onSearch("totalPayTo", value)}
                                    value={this.props.searchCriteria.totalPayTo}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="6" xs="6">
                        <FormGroup>
                            <Label>Campo ordinamento</Label>
                            <BaseSelect
                                descField="desc"
                                idField="key"
                                options={this.props.orderFields}
                                value={this.props.searchCriteria.orderField}
                                onChange={(value: any) => { this.props.onSearch("orderField", value) }} />
                        </FormGroup>
                    </Col>
                    <Col lg="6" sm="6" xs="6">
                        <FormGroup>
                            <Label>Direzione ordinamento</Label>
                            <BaseSelect
                                descField="desc"
                                idField="key"
                                options={this.props.orderDirections}
                                value={this.props.searchCriteria.orderDirection}
                                onChange={(value: any) => { this.props.onSearch("orderDirection", value) }} />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>)


        return (
            <React.Fragment>
                <Modal className="bg-dark modal-lg" isOpen={this.props.isOpen} toggle={() => this.props.close()} backdrop="static">
                    <ModalHeader className="text-eurorounders bg-dark text-uppercase" toggle={() => this.props.close()}>Filtri Avanzati</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders modal-header-button-box">
                        {modalForm}
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon="times" label="Chiudi" onClick={() => this.props.close()} />
                    </ModalFooter>
                </Modal>
            </React.Fragment >
        );
    }

}