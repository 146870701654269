import * as React from "react";
import { Profile } from "../../store/profile/Profile";
import { BaseList } from '../base/baseList';
import GoToTop from "../base/gototop";


export interface ProfileListProps {
    list: Array<Profile>;
    hasNextPage: boolean;
    loadNewPage?: () => void;
    startLoading?: boolean;
    onRowClick?: (profileId: string) => void;
    onUnload?: () => void;
}

export class ProfileList extends React.Component<ProfileListProps>{

    constructor(props) {
        super(props);
    }

    private getRows(profileList: Array<Profile>): JSX.Element[] {

        let profileArray = profileList.map(profile => {

            let roleName = profile.account !== undefined && profile.account != null
                && profile.account.role !== undefined && profile.account.role != null ? profile.account.role.name : "-";
            return (
                <tr key={profile.id} style={{ cursor: "pointer" }} onClick={() => this.props.onRowClick(profile.id)}>
                    <td>
                        {profile.lastName} {profile.firstName}
                    </td>
                    <td className="d-none d-md-table-cell">
                        {profile.email}
                    </td>
                    <td>
                        {profile.phone}
                    </td>
                    <td className="d-none d-md-table-cell">
                        {roleName}
                    </td>
                </tr >
            )
        });

        return profileArray;

    }

    private getHead(): JSX.Element {

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>Nome</th>
                    <th className="d-none d-md-table-cell">Email</th>
                    <th>Tel.</th>
                    <th className="d-none d-md-table-cell">Ruolo</th>
                </tr>
            </thead>
        );

    }


    render() {

        return (
            <React.Fragment>
                <BaseList hasNextPage={this.props.hasNextPage} list={this.props.list} active={this.props.startLoading} onEndOfList={this.props.loadNewPage} getElements={(profileList: Array<Profile>) => { return this.getRows(profileList) }} getHead={this.getHead} />
                <GoToTop />
            </React.Fragment>
        )
    }
}