import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/events/EventState';
import { EventsSearchForm, SearchFormCallbacks, SearchFormProps } from '../shared/searchform';
import _ from "underscore";

const mapStateToProsp = (state: ApplicationState): SearchFormProps => {

    return {
        searchString: state.events.searchCriteria.search,
        dateFrom: state.events.searchCriteria.dateFrom,
        dateTo: state.events.searchCriteria.dateTo,
        type: state.events.searchCriteria.type
    }
}

const mapDispatchToProps = () => (dispatch): SearchFormCallbacks => {
    return ({
        onSearch: (searchString?: string, dateFrom?: string, dateTo?: string, type?: string) => dispatch(actionsMiddleware.genericSearch(searchString, dateFrom, dateTo, type)),
    });
}

export const ConnectedEventsSearchForm = connect<SearchFormProps, SearchFormCallbacks>(mapStateToProsp, mapDispatchToProps)(EventsSearchForm);