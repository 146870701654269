import { Action, Reducer } from 'redux';
import { ApplicationState } from '../../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest, checkRefreshToken, SaveRequest } from '../../../utils/ajaxutilities';
import dotProp from "dot-prop";
import _ from "underscore";
import { AgentAccounting } from '../../eventaccountings/EventAccountingEntities'
import $ from "jquery";
import { CoreResponse } from '../../serverresponse/serverresponse';


export interface AgentsPaymentsState {
    list: Array<AgentAccounting>;
}

const getBaseState = (): AgentsPaymentsState => {
    return {
        list: []
    } as AgentsPaymentsState;
}

enum TypeKeys {
    SET_LIST = "AGENTSPAYMENTS_SET_LIST",
    RESET_STATE = "AGENTSPAYMENTS_RESET_STATE"
}

interface SetList extends Action {
    type: TypeKeys.SET_LIST;
    list: Array<AgentAccounting>;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE;
}

type AgentsAccountingsActions = SetList | ResetState;

const actionsCreator = {
    setList: (list: Array<AgentAccounting>): SetList => ({
        list: list,
        type: TypeKeys.SET_LIST
    }),
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    })
}


const sharedActions = {
    load: (eventId: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<Array<AgentAccounting>>> => {
        let promise = $.Deferred();

        let requestModel = new AbortableRequest(`/accountings/agents/${eventId}`, RequestMethods.GET);

        ajaxRequest<Array<AgentAccounting>>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                promise.resolve(response);
            }

            promise.resolve(response);
        });

        return promise.promise();
    }
}

export const actionsMiddleware = {
    loadInitialState: (eventId: string) => (dispatch, getState) => {
        dispatch(actionsCreator.resetState());
        $.when(dispatch(sharedActions.load(eventId))).then((response: CoreResponse<Array<AgentAccounting>>) => {
            if (response.success)
                dispatch(actionsCreator.setList(response.entity));
        });
    }
}


export const reducer: Reducer<AgentsPaymentsState> = (state: AgentsPaymentsState | undefined, incomingAction: AgentsAccountingsActions): AgentsPaymentsState => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.RESET_STATE:
            let stateToUpdate = getBaseState();
            newState = { ...stateToUpdate };
            break;

        case TypeKeys.SET_LIST:
            newState = dotProp.set(newState, "list", [...incomingAction.list]);
            break;

    }

    return newState;
}
