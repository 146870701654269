import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/events/EventState';
import { actionCreator } from '../../../store/uiproperties/UIState';
import { EventsList, EventsListCallbacks, EventsListProps } from '../shared/list';

export interface ConnectedEventsListProps {
    onRowClick?: (id: string) => void;
    selectionEnabled: boolean;
}

const mapStateToProsp = (state: ApplicationState, ownProps: ConnectedEventsListProps): EventsListProps => {

    return {
        hasNextPage: state.events.searchCriteria.hasNextPage,
        list: state.events.list,
        selectionEnabled: ownProps.selectionEnabled,
        startLoading: state.uiProperties.startFetchingPagedList,
        selectedElements: state.events.selectedEvents
    }
}

const mapDispatchToProps = (dispatch, ownProps: ConnectedEventsListProps) => (dispatch): EventsListCallbacks => {
    return ({
        loadNewPage: () => {
            dispatch(actionsMiddleware.loadNextPage());
        },
        onRowClick: (id: string) => {
            dispatch(ownProps.onRowClick(id));
        },
        onUnload: () => dispatch(actionCreator.startFetchingPagedList(false))
    });
}

export const ConnectedEventsList = connect<EventsListProps, EventsListCallbacks, ConnectedEventsListProps>(mapStateToProsp, mapDispatchToProps)(EventsList);