import * as React from "react";
import { Input } from "reactstrap";

export interface BaseSelectProps {
    options: Array<any>;
    value?: any;
    idField: string;
    descField: string;
    defaultOptionDisabled?: boolean;
    className?: string;
}

export interface BaseSelectCallbacks {
    onChange: (value: any) => void;
}

export default class BaseSelect extends React.Component<BaseSelectProps & BaseSelectCallbacks>{
    constructor(props) {
        super(props);
    }

    render() {

        let options = this.props.options.map(option => {
            return (<option key={option[this.props.idField]} value={option[this.props.idField]}>{option[this.props.descField]}</option>)
        });

        let defaultOption = [];

        if (this.props.defaultOptionDisabled != true)
            defaultOption.push(<option key="base-select-key" value=''>-</option>)

        let allOption = defaultOption.concat(options);

        let selectedOption = '';

        if (this.props.value !== undefined && this.props.value != null) {
            selectedOption = this.props.value;
        }

        let defaultClasses = "bg-dark text-eurorounders";

        if (this.props.className)
            defaultClasses = `${defaultClasses} ${this.props.className}`;

        return (
            <React.Fragment>
                <Input type="select" value={selectedOption} className={defaultClasses} onChange={(e) => this.props.onChange(e.target.value)}>
                    {allOption}
                </Input>
            </React.Fragment>
        )
    }
}