import * as React from "react";
import { MenuItem } from '../../menu/menuitem';
import { Form, FormGroup, Row, Col, Input, Label, InputGroupAddon, InputGroup, InputGroupText, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import DecimalInput from '../../base/decimalinput';
import IntegerInput from '../../base/integerinput';
import { CheckBox } from '../../base/checkbox';
import { ERDatePicker as DatePicker } from '../../base/datepicker';
import { ConnectedLocationsList } from '../../locations/connected/connected.list';
import { ConnectedLocationSearchForm } from '../../locations/connected/connected.searchform';
import { Location } from '../../../store/locations/LocationEntities';
import { PeriodPricing, AlertAvailabilityType } from '../../../store/pricings/PricingEntities'
import { RoomType } from '../../../store/locations/LocationEntities';
import _ from "underscore";

import './hotelspriceform.css'

export interface HotelsPriceFormProps {
    periodPricing?: PeriodPricing;
    periodPricingBackup?: PeriodPricing;
    roomTypes?: Array<RoomType>
}

export interface HotelsPriceFormCallbacks {
    onSubmit: () => void;
    onChange: (propertyName: string, value: any, roomTypeId?: string) => void;
    loadExternalModule: () => void;
    confirmSelection: () => void;
    onSelect: (model: Location) => void;
    onCheckRoomType: (roomTypeId?: string) => void;
    openAvailabilityModal: (roomPricingId?: string) => void;
}

interface HotelsPriceFormState {
    isModalOpen: boolean;
}

export class HotelsPriceForm extends React.Component<HotelsPriceFormProps & HotelsPriceFormCallbacks, HotelsPriceFormState>{
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false
        }
    }

    private toogleModal() {

        if (!this.state.isModalOpen == true) {
            //opening modal triggher location module
            this.props.loadExternalModule();
        }

        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    private getRoomPricingForm(): JSX.Element[] {

        let elements = [];

        elements = this.props.roomTypes.map(rt => {

            let rp = _.find(this.props.periodPricing.roomPricings, (roomPricing) => roomPricing.roomType.id == rt.id);

            if (rp === undefined || rp == null) {
                return (
                    <React.Fragment key={rt.id}>
                        <h5 className="room-type-title">
                            <CheckBox isChecked={false} onCheck={(checked: boolean) => this.props.onCheckRoomType(rt.id)} />
                            <span className="room-type-desc">{rt.type}</span>
                        </h5>
                        <Row>
                        </Row>
                    </React.Fragment>
                )
            }


            let icon = "info";
            let iconColor = "text-eurorounders";

            if (rp.alertAvailability == AlertAvailabilityType.Warning) {
                icon = "exclamation-triangle";
                iconColor = "text-warning";
            } else if (rp.alertAvailability == AlertAvailabilityType.Error) {
                icon = "exclamation";
                iconColor = "text-danger";
            }


            return (
                <React.Fragment key={rt.id}>
                    <h5 className="room-type-title">
                        <CheckBox isChecked={true} onCheck={(checked: boolean) => this.props.onCheckRoomType(rt.id)} />
                        <span className="room-type-desc">{rp.roomType.type}</span>
                    </h5>
                    <Row>
                        <Col lg="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Prezzo al pubblico</Label>
                                <InputGroup>
                                    <DecimalInput onChane={(value: string) => this.props.onChange("sellPrice", value, rp.roomType.id)} value={rp.sellPrice} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Costo</Label>
                                <InputGroup>
                                    <DecimalInput onChane={(value: string) => this.props.onChange("cost", value, rp.roomType.id)} value={rp.cost} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Costo invenduta</Label>
                                <InputGroup>
                                    <DecimalInput onChane={(value: string) => this.props.onChange("costNotSell", value, rp.roomType.id)} value={rp.costNotSell} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Disponibilità</Label>
                                <InputGroup>
                                    <IntegerInput className={rp.isAvailabilityEditable == false ? "disable-events" : ""} onChane={(value?: number) => this.props.onChange("availability", value, rp.roomType.id)} value={rp.availability} />
                                    <InputGroupAddon addonType="append">
                                        <Button onClick={() => this.props.openAvailabilityModal(rp.id)} className={rp.id === undefined
                                            || rp.id == null
                                            || this.props.periodPricing.checkIn != this.props.periodPricingBackup.checkIn
                                            || this.props.periodPricing.checkOut != this.props.periodPricingBackup.checkOut
                                            ? "disable-events" : ""}>
                                            <i className={`${iconColor} icon fas fa-${icon} fa-xs`} />
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                </React.Fragment>
            );

        });

        return elements;
    }

    render() {

        if (this.props.periodPricing === undefined || this.props.periodPricing == null) {
            return (<React.Fragment></React.Fragment>)
        }

        let formElements = this.getRoomPricingForm();

        return (
            <React.Fragment>
                <Form className="text-eurorounders" >
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Check In</Label>
                                <DatePicker selectedDate={this.props.periodPricing.checkIn} onChange={date => this.props.onChange("checkIn", date)} />
                            </FormGroup>
                        </Col>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Check Out</Label>
                                <DatePicker selectedDate={this.props.periodPricing.checkOut} onChange={date => this.props.onChange("checkOut", date)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Hotel</Label>
                                <InputGroup>
                                    <Input readOnly={true} className="bg-dark text-eurorounders"  type="text" value={this.props.periodPricing.location ? this.props.periodPricing.location.name : ''} onClick={() => this.toogleModal()} />
                                    <InputGroupAddon addonType="append">
                                        <Button onClick={() => this.props.onChange("location", null)}>
                                            <i className="icon fas fa-times fa-xs" />
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Tassa soggiorno</Label>
                                <InputGroup>
                                    <DecimalInput onChane={(value: string) => this.props.onChange("dailyTax", value)} value={this.props.periodPricing.dailyTax} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    {formElements}
                    <Row>
                        <Col>
                            <FormGroup>
                                <MenuItem isSmall={true} isButton={true} icon="save" label="save" onClick={() => this.props.onSubmit()} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <Modal className="bg-dark modal-lg" isOpen={this.state.isModalOpen} toggle={() => this.toogleModal()} backdrop="static">
                    <ModalHeader className="text-eurorounders bg-dark" toggle={() => this.toogleModal()}>Seleziona hotel</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders">
                        <ConnectedLocationSearchForm />
                        <ConnectedLocationsList selectionEnabled={true} onRowClick={this.props.onSelect} />
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon="check" label="Ok" onClick={() => { this.props.confirmSelection(); this.toogleModal(); }} />
                        <MenuItem isSmall={true} isButton={true} icon="times" label="Annulla" onClick={() => this.toogleModal()} />
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}