import { SystemPaymentItem } from '../paymentitems/PaymentItemEntities';

export interface Payment {
    id: string;
    typology: PaymentTypology;
    methodId: string;
    notes: string;
    amount: number;
    paymentDate: string;
    createdDate: string;
    createdBy: string;
    eventId: string;
    paymentItemId: string;
    agentId: string;
    reservationId: string;
}

export enum PaymentTypology {
    Inbound = "Inbound",
    Outbound = "Outbound",
    CashFund = "CashFund"
}

export interface TrasferCommand {
    notes?: string;
    sourceMethod?: string;
    destinationMethod?: string;
    amount?: number;
    eventId: string;
}

export interface PaymentSearchReadModel extends Payment {
    agentFirstName?: string;
    agentLastName?: string;
    methodDescription?: string;
    itemDescription?: string;
    systemItem?: SystemPaymentItem;
}


export interface PaymentsSearchCriteria {
    eventId?: string
    typology?: PaymentTypology,
    paymentMethod?: string;
    paymentItem?: string;
    date?: string;
    notes: string;
    orderDirection?: string;
    pageNumber: number;
    pageSize: number;
    hasNextPage: boolean;
}