import * as React from "react";
import { Profile, CommissionTemplate, CommissionTemplateMode } from "../../../store/profile/Profile";
import { BaseList } from '../../base/baseList';
import { CheckBox } from '../../base/checkbox';
import { guid } from '../../../utils/commons';
import { ConfirmationModal } from '../../base/confirmationmodal';
import _ from "underscore";
import { ConnectedEditCommmisionTemplate } from "../commisiontemplates/connected.editcommissiontemplate";
import { ConnectedEditingJustCommmisionTemplate } from "../commisiontemplates/connected.editjustcommission";


import './agentslistrapidprovisioning.css';
import GoToTop from "../../base/gototop";

export interface AgentsListRapidProvisioningProps {
    list: Array<Profile>;
    allCommisionTemplate: Array<CommissionTemplate>;
    hasNextPage: boolean;
    loadNewPage?: () => void;
    startLoading?: boolean;
    onProvisioningProfileCheck?: (agentId: string, commissionProfileId: string, isDefaultCommission: boolean) => void;
    onAgentNameClick?: (agentId: string) => void;
    setRunningDefaultCommission?: (commissionId: string) => void;
    setRunningManualCommissionForAgent?: (agentId: string) => void;
    onUnload?: () => void;
    restartLoading?: () => void;
}

export class AgentsListRapidProvisioning extends React.Component<AgentsListRapidProvisioningProps>{
    private modalRef: React.RefObject<ConfirmationModal>;
    private temporaryCommissionId: string = null;
    private temporaryAgentClicked: string = null;
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }

    componentDidUpdate() {
        if (this.props.list.length > 0 && this.props.hasNextPage && !this.props.startLoading) {
            this.props.restartLoading();
        }
    }

    private getCellsForAgent(agent: Profile): JSX.Element[] {
        let elements = [];

        let isDefaultChecked = false;

        elements = this.props.allCommisionTemplate.map(ct => {
            let checked = false;
            if (agent.commisionTemplate !== undefined && agent.commisionTemplate != null && agent.commisionTemplate.id == ct.id) {
                checked = true;
                isDefaultChecked = true;
            }

            return (
                <td key={guid()}>
                    <CheckBox isChecked={checked} onCheck={(checked: boolean) => { this.changeCommission(agent.id, ct.id) }} />
                </td>
            )

        });

        let disabledManualEdit = ""

        if (isDefaultChecked)
            disabledManualEdit = "disable-events";

        elements.push(
            <td key={guid()}>
                <div className="manual-edit">
                    <CheckBox isChecked={!isDefaultChecked} onCheck={(checked: boolean) => { this.changeCommission(agent.id, "manual") }} />
                    <div onClick={() => this.props.setRunningManualCommissionForAgent(agent.id)} className={`text-eurorounders fake-link manual-edit-button ${disabledManualEdit}`}><i className="fas fa-pen" /></div>
                </div>
            </td>
        )

        elements.unshift(
            <td className="fake-link" key={guid()} onClick={() => this.props.onAgentNameClick(agent.id)}>
                <div className="agent-name-column">{agent.lastName} {agent.firstName}</div>
            </td>
        )

        return elements;
    }


    private getRows(agentsList: Array<Profile>): JSX.Element[] {

        let profileArray = agentsList.map(agent => {

            let cells = this.getCellsForAgent(agent);

            return (
                <tr key={agent.id}>
                    {cells}
                </tr >
            )
        });

        return profileArray;

    }

    private getCommissionTemplatesDefaultHead(): JSX.Element[] {
        return this.props.allCommisionTemplate.map(ct => {
            return (
                <th key={ct.id} onClick={() => this.props.setRunningDefaultCommission(ct.id)}>
                    <span className="fake-link">{ct.type} {ct.mode == CommissionTemplateMode.Fixed ? "F." : "%"} <i className="fas fa-pen fa-xs editing-icon" /></span>
                </th>
            )
        });
    }

    private getHead(): JSX.Element {

        let defaultHeading = this.getCommissionTemplatesDefaultHead();

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>
                        Nome
                    </th>
                    {defaultHeading}
                    <th>Manuale</th>
                </tr>
            </thead>
        );

    }

    private changeCommission(agentId: string, commissionId: string, forceChange: boolean = false) {

        let agent = _.find(this.props.list, (agent) => agent.id == agentId);
        let isDefaultCommission = _.any(this.props.allCommisionTemplate, c => c.id == commissionId);

        if (forceChange == true || agent.commisionTemplate === undefined || agent.commisionTemplate == null) {
            this.props.onProvisioningProfileCheck(agentId, commissionId, isDefaultCommission);
            return;
        }

        let previousSelected = _.findIndex(this.props.allCommisionTemplate, (comm) => comm.id == agent.commisionTemplate.id);

        if (previousSelected > -1) {
            this.props.onProvisioningProfileCheck(agentId, commissionId, isDefaultCommission);
            return;
        }

        this.temporaryCommissionId = commissionId;
        this.temporaryAgentClicked = agentId;
        this.modalRef.current.toogleModal();
    }

    private onClosedModal() {
        this.temporaryCommissionId = null;
        this.temporaryAgentClicked = null;
    }


    render() {
        return (
            <React.Fragment>
                <BaseList onUnload={this.props.onUnload} className="rapid-provisioning-table" hasNextPage={this.props.hasNextPage} list={this.props.list} active={this.props.startLoading} onEndOfList={this.props.loadNewPage} getElements={(agentsList: Array<Profile>) => { return this.getRows(agentsList) }} getHead={() => { return this.getHead() }} />
                <ConfirmationModal text="Cambiando profilo provvigionale si perderanno i dati del manuale, continuare?" onConfirm={() => this.changeCommission(this.temporaryAgentClicked, this.temporaryCommissionId, true)} ref={this.modalRef} onClosed={() => this.onClosedModal()} />
                <ConnectedEditCommmisionTemplate {...{ saveOnConfirm: true }} />
                <ConnectedEditingJustCommmisionTemplate />
                <GoToTop />
            </React.Fragment>
        )
    }
}