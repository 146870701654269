import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware, actionCreator } from '../../../store/entries/EntryState';
import { EntriesPageList, EntriesPageListCallbacks, EntriesPageListProps } from '../pages/listpage';

const mapStateToProsp = (state: ApplicationState): EntriesPageListProps => {

    return {
        totalCount: state.entries.totalCount,
        totalPlayers: state.entries.players,
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name.toUpperCase()}` : ''

    }
}

const mapDispatchToProps = () => (dispatch): EntriesPageListCallbacks => {
    return ({
        onLoad: (eventId: string) => {
            dispatch(actionsMiddleware.loadInitialState(eventId));
        },
        onCustomersModelOpen: () => dispatch(actionsMiddleware.onCustomersModelOpen()),
        onCustomersModalConfirm: () => actionsMiddleware.onCustomersModalConfirm()
    });
}

export const ConnectedEntriesPageList = withRouter(connect<EntriesPageListProps, EntriesPageListCallbacks>(mapStateToProsp, mapDispatchToProps)(EntriesPageList));