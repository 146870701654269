import * as React from "react";
import { Form, FormGroup, Button, Input, CustomInput, Row, Col, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { PaymentMethod } from '../../../../store/payments/paymentmethods/PaymentMethodEntities';
import _ from "underscore";
import { BodyHeader } from '../../../base/bodyheader';
import { MenuItemProps, MenuItem } from '../../../menu/menuitem';
import { ConfirmationModal } from '../../../base/confirmationmodal';
import { UnsavedChanges } from "../../../base/unsavedchanges";

export interface EditPaymentMethodProps {
    paymentMethod: PaymentMethod;
}

export interface EditPaymentMethodCallbacks {
    onLoad?: (roleId: string) => void;
    onSubmit?: () => void;
    onChange?: (desc: string) => void;
    onDelete?: (id: string) => void;
}



export class PaymentMethodEditPage extends React.Component<EditPaymentMethodProps & EditPaymentMethodCallbacks>{
    private modalRef: React.RefObject<ConfirmationModal>;
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }

    componentDidMount() {
        let id = (this.props as any).match.params.id;
        this.props.onLoad(id);
    }

    private delete() {
        this.modalRef.current.toogleModal();
        if (this.props.onDelete)
            this.props.onDelete(this.props.paymentMethod.id);
    }

    render() {

        if (!this.props.paymentMethod) {
            return (
                <React.Fragment>
                </React.Fragment>
            )
        }


        let menuItems = [];

        if (this.props.paymentMethod.id !== undefined && this.props.paymentMethod.id != null && this.props.paymentMethod.id != '') {
            menuItems = [
                {
                    icon: "trash",
                    label: "elimina",
                    isButton: true,
                    isDisabled: false,
                    onClick: () => { this.modalRef.current.toogleModal() }
                }
            ] as Array<MenuItemProps>
        }

        menuItems.push({
            icon: "list",
            label: "lista",
            isButton: false,
            isSmall: true,
            path: "/events/configurations/paymentmethods"
        } as MenuItemProps);

        return (
            <React.Fragment>
                <UnsavedChanges confirmAction={this.props.onSubmit} />
                <BodyHeader title={this.props.paymentMethod.id !== undefined && this.props.paymentMethod.id != null && this.props.paymentMethod.id != '' ? "Modifica Metodo di Pagamento" : "Crea Metodo di Pagamento"} menuItems={menuItems} />
                <Form className="text-eurorounders" >
                    <Row>
                        <Col lg="6" sm="6" xs="12">
                            <FormGroup>
                                <Label>Metodo</Label>
                                <Input className="bg-dark text-eurorounders" type="text" value={this.props.paymentMethod.method || ''} onChange={(e) => { this.props.onChange(e.target.value) }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <MenuItem isSmall={true} isButton={true} icon="save" label="salva" onClick={() => this.props.onSubmit()} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <ConfirmationModal text="Eliminare Metodo di Pagamento?" onConfirm={() => this.delete()} ref={this.modalRef} />
            </React.Fragment>
        )
    }
}
