import * as React from "react";
import { Customer } from '../../../store/customers/CustomerEntities';
import { Anagraphic } from '../../shared/anagraphic';
import CustomerStatistics from '../../shared/customerstatistics';
import AnagraphicAdditional from '../../shared/anagraphicadditionalinfo';
import Privacy from '../../shared/privacyrow';
import { MenuItem } from '../../menu/menuitem';
import { Form, FormGroup, Row, Col, Label, Input } from "reactstrap";
import { ConnectedMainDocuments } from '../../documents/connected.maindocuments';


export interface CustomerFormProps {
    customer?: Customer;
    onSubmit?: () => void;
    isSaveButtonVisible: boolean;
    onChange?: (propertyName: string, value: any) => void;
}

export class CustomerForm extends React.Component<CustomerFormProps>{
    constructor(props) {
        super(props);
    }

    private getSaveButton(): JSX.Element {
        if (this.props.isSaveButtonVisible == true) {
            return (
                <Row>
                    <Col>
                        <FormGroup>
                            <MenuItem isSmall={true} isButton={true} icon="save" label="salva" onClick={() => this.props.onSubmit()} />
                        </FormGroup>
                    </Col>
                </Row>
            );
        }

        return (<React.Fragment></React.Fragment>);
    }

    render() {

        if (this.props.customer === undefined || this.props.customer == null) {
            return (<React.Fragment></React.Fragment>)
        }

        let saveButton = this.getSaveButton();
        let documents = null;

        if (this.props.customer.id !== undefined && this.props.customer.id != null)
            documents = (<ConnectedMainDocuments objectId={this.props.customer.id} objectName={`${this.props.customer.firstName !== undefined && this.props.customer.firstName != null ? this.props.customer.firstName : ""} ${this.props.customer.lastName} `} />)


        let customerStatistics = (<React.Fragment>*To show statistic "Profiling Privacy" must be checked</React.Fragment>);

        customerStatistics = (<CustomerStatistics {...this.props.customer} />);

        return (
            <React.Fragment>
                <Form className="text-eurorounders" >
                    <Anagraphic {...{ ...this.props.customer, onChange: (propertyName: string, value: object) => this.props.onChange(propertyName, value), customerContext: true }} />
                    <Privacy {...{ ...this.props.customer, onChange: (propertyName: string, value: object) => this.props.onChange(propertyName, value), customerContext: true }} />
                    <Row>
                        <Col lg="12" sm="12" xs="12">
                            <FormGroup>
                                <Label>Notes</Label>
                                <Input className="bg-dark text-eurorounders" type="textarea" value={this.props.customer.notes || ''} onChange={(e) => { this.props.onChange("notes", e.target.value) }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    {customerStatistics}
                    <AnagraphicAdditional {...{ ...this.props.customer, onChange: (propertyName: string, value: object) => this.props.onChange(propertyName, value) }} />
                    {saveButton}
                    {documents}
                </Form>
            </React.Fragment>
        )
    }
}