import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/eventaccountings/EventAccountingState';
import { AccountingDetailsPage, AccountingDetailsPageProps, AccountingDetailsPageCallbacks } from '../pages/detailspage';


const mapStateToProsp = (state: ApplicationState): AccountingDetailsPageProps => {

    return {
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name.toUpperCase()}` : ''
    }
}

const mapDispatchToProps = () => (dispatch): AccountingDetailsPageCallbacks => {
    return {
        onLoad: (id) => dispatch(actionsMiddleware.load(id)),
        downloadExcel: () => dispatch(actionsMiddleware.downloadExcel())
    }
}



export const ConnectedEventAccountingsDetails = withRouter(connect<AccountingDetailsPageProps, AccountingDetailsPageCallbacks>(mapStateToProsp, mapDispatchToProps)(AccountingDetailsPage));