import * as React from "react";
import { Spinner, Table } from "reactstrap"
import ReactVisibilitySensor from "react-visibility-sensor";
import { guid } from '../../utils/commons';

import './baselist.css';

export interface BaseListProps<T> {
    list: Array<T>;
    hasNextPage: boolean;
    onEndOfList?: () => void;
    active: boolean;
    getHead?: () => JSX.Element
    getElements?: (list: Array<T>) => JSX.Element[];
    className?: string;
    onUnload?: () => void;
}

export class BaseList<T> extends React.Component<BaseListProps<T>>{

    constructor(props) {
        super(props);
        this.loadMoreData.bind(this);
    }

    componentWillUnmount() {
        if (this.props.onUnload)
            this.props.onUnload();
    }

    private getRows(): JSX.Element[] {

        let elementArrays = this.props.getElements(this.props.list);
        elementArrays.push(this.getVisibilitySensorForTable())
        return elementArrays;

    }

    private getTable(): JSX.Element {
        let tableHead = this.props.getHead();
        let tableRows = this.getRows();

        let customClass = "";

        if (this.props.className !== undefined && this.props.className != null)
            customClass = this.props.className;

        return (<Table responsive dark className={customClass}>
            {tableHead}
            <tbody>
                {tableRows}
            </tbody>
        </Table>)
    };

    private getVisibilitySensorForTable(): JSX.Element {
        let visibilitySensor = this.getVisibilitySensor();
        return (
            <tr key="visibility-sensor-profile-list-table">
                <td colSpan={1000}>
                    {visibilitySensor}
                </td>
            </tr>
        )
    }

    private getVisibilitySensor(): JSX.Element {

        if (!this.props.hasNextPage)
            return (
                <React.Fragment key="visibility-sensor">
                </React.Fragment>
            );

        let active = true;

        if (this.props.active == false)
            active = false;

        return (<ReactVisibilitySensor key="visibility-sensor" delayedCall partialVisibility onChange={(isVisible: boolean) => { this.loadMoreData(isVisible); }} active={active}>
            <div className="spinner-center">
                <Spinner color="primary" />
            </div>
        </ReactVisibilitySensor>);
    }

    private loadMoreData(isVisible: boolean) {

        if (!isVisible)
            return;

        if (this.props.hasNextPage == false)
            return;

        if (this.props.onEndOfList)
            this.props.onEndOfList();
    }


    render() {

        let table = this.getTable();

        return (
            <React.Fragment>
                {table}
            </React.Fragment>
        )
    }
}