import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/index';
import { HotelAccountingList, HotelAccountingListProps } from '../../base/accountingdetails/hotelsaccounting';


const mapStateToProsp = (state: ApplicationState): HotelAccountingListProps => {

    return {
        list: state.hotelsAccounting.running !== undefined && state.hotelsAccounting.running != null && state.hotelsAccounting.running.model !== undefined && state.hotelsAccounting.running.model != null ? state.hotelsAccounting.running.model.hotelsAccounting : []
    }
}



export const ConnectedHotelsAccountingV2List = connect<HotelAccountingListProps>(mapStateToProsp, {})(HotelAccountingList);