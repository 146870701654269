import * as React from "react";
import { MenuItemProps } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { ConnectedEventAccountingsList } from '../connected/connected.list';
import { StickyBodyHeader } from "../../base/stickybodyheader";

export interface EventAccountPageListProps {
    totalCount: number;
    isFreezed: boolean;
    eventFullName: string;

}

export interface EventAccountPageListCallbacks {
    onLoad: (eventId: string) => void;
    onOpen: () => void;
}

export class EventAccountPageList extends React.Component<EventAccountPageListProps & EventAccountPageListCallbacks> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let eventId = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                eventId = (this.props as any).match.params.eventId;
            this.props.onLoad(eventId);
        }
    }

    render() {

        let menuItems = [];

        if (this.props.isFreezed == true) {
            menuItems.push({
                icon: "door-open",
                label: "riapri",
                isButton: true,
                isSmall: true,
                onClick: () => this.props.onOpen()
            })
        }

        menuItems.push(
            {
                icon: "ellipsis-h",
                label: "Evento",
                isButton: false,
                isSmall: true,
                path: `/events/${(this.props as any).match.params.eventId}/menu`
            } as MenuItemProps);

        return (
            <React.Fragment>
                <StickyBodyHeader>
                    <BodyHeader subTitle={this.props.eventFullName} bottomSpace title={`Rendicontazioni (${this.props.totalCount})`} menuItems={menuItems} />
                </StickyBodyHeader>
                <ConnectedEventAccountingsList />
            </React.Fragment>
        )
    }
}