import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from '../../../../store/index';
import { actionsMiddleware } from '../../../../store/eventaccountings/HotelsAccountingState';
import { actionsMiddleware as paymentsMiddleware } from '../../../../store/payments/payments/PaymentState';
import { HotelsPaymentsPage, HotelsPaymentsPageCallbacks, HotelsPaymentsPageProps } from './hotelspayments';


const mapStateToProsp = (state: ApplicationState): HotelsPaymentsPageProps => {

    return {
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name.toUpperCase()}` : '',
        lastUpdate: state.hotelsAccounting.running?.lastUpdate
    }
}

const mapDispatchToProps = () => (dispatch): HotelsPaymentsPageCallbacks => {
    return {
        onLoad: (id) => dispatch(actionsMiddleware.load(id)),
        openOutcomeModal: (eventId: string) => dispatch(paymentsMiddleware.openOutcomeModal(eventId))
    }
}



export const ConnectedHotelsPayments = withRouter(connect<HotelsPaymentsPageProps, HotelsPaymentsPageCallbacks>(mapStateToProsp, mapDispatchToProps)(HotelsPaymentsPage));