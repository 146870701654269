import * as React from 'react';
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { AnagraphicProps } from './anagraphic';
import Collapsible from '../base/collapsiblecontent';
import { ERDatePicker as DatePicker } from '../base/datepicker';
import AddressForm from './address';

export default class AnagraphicAdditional extends React.Component<AnagraphicProps>{
    constructor(props) {
        super(props);
    }

    private setStateValue(propertyName: string, value: any) {
        if (this.props.onChange)
            this.props.onChange(propertyName, value);
    }

    render() {

        return (
            <React.Fragment>
                <Collapsible title="Informazioni aggiuntive">
                    <Row>
                        <Col lg="4" sm="4" xs="12">
                            <FormGroup>
                                <Label>Data nascita</Label>
                                <DatePicker selectedDate={this.props.birthDate} onChange={date => this.setStateValue("birthDate", date)} />
                            </FormGroup>
                        </Col>
                        <Col lg="4" sm="4" xs="12">
                            <FormGroup>
                                <Label>Codice fiscale</Label>
                                <Input className="bg-dark text-eurorounders"  type="text" value={this.props.taxCode || ''} onChange={(e) => { this.setStateValue("taxCode", e.target.value) }} />
                            </FormGroup>
                        </Col>
                        <Col lg="4" sm="4" xs="12">
                            <FormGroup>
                                <Label>P. IVA</Label>
                                <Input className="bg-dark text-eurorounders"  type="email" value={this.props.vatNumber || ''} onChange={(e) => { this.setStateValue("vatNumber", e.target.value) }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <AddressForm {...this.props} />
                </Collapsible>
            </React.Fragment>
        )
    }

}