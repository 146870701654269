import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/index';
import { actionsMiddleware } from '../../../../store/payments/paymentitems/PaymentItemState';
import { PaymentCategory } from '../../../../store/payments/paymentitems/PaymentItemEntities';
import { PaymentItemsList, PaymentItemsListCallbacks, PaymentItemsListProps } from '../base/list';

export interface PaymentItemsOwnProps {
    side: PaymentCategory;
}

const mapStateToProsp = (state: ApplicationState, ownProps: PaymentItemsOwnProps): PaymentItemsListProps => {
    return {
        list: state.paymentItems.paymentItems.filter(m => {
            if (m.category == ownProps.side)
                return m;
        }),
        side: ownProps.side
    }
}

const mapDispatchToProps = () => (dispatch): PaymentItemsListCallbacks => {
    return ({
        onRowClick: (id: string, side: PaymentCategory) => dispatch(actionsMiddleware.navigateToRunning(id, side))
    });
}

export const ConnectedPaymentItemList = connect<PaymentItemsListProps, PaymentItemsListCallbacks, PaymentItemsOwnProps>(mapStateToProsp, mapDispatchToProps)(PaymentItemsList);