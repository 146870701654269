import { Action, Reducer } from 'redux';
import { ApplicationState } from '../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest } from '../../utils/ajaxutilities';
import dotProp from "dot-prop";
import _ from "underscore";
import { Reservation } from './ReservationEntities';
import { actionMiddleware as reservationMiddleware, utils } from './ReservationState';
import $ from "jquery";
import moment from "moment";

export interface ReservationQuoteState {
    list: Array<Reservation>;
    eventId: string;
    searchCriteria: SearchCriteria;
    moduleLoaded: boolean;
    searchPerformed: boolean;
}

export interface SearchCriteria {
    checkIn: string;
    checkOut: string;
    roomTypeId: string;
}

const getBaseState = (eventId?: string): ReservationQuoteState => ({
    eventId: eventId,
    list: [],
    searchCriteria: {
        checkIn: null,
        checkOut: null,
        roomTypeId: null
    },
    moduleLoaded: false,
    searchPerformed: false
});

enum TypeKeys {
    SET_EVENT_ID = "QUOTE_RES_SET_EVENT_ID",
    SET_LIST = "QUOTE_RES_SET_LIST",
    SET_SEARCH_CRITERIA = "QUOTE_RES_SET_SEARCH_CRITERIA",
    RESET_STATE = "QUOTE_RES_RESET_STATE",
    MODULE_LOADED = "QUOTE_RES_SET_MODULE_LOADED",
    SET_SEARCH_PERFORMED = "QUOTE_RES_SET_SEARCH_PERFORMED"
}

type ReservationQuoteActions = SetEventId
    | SetList
    | SetSearchCriteria
    | ResetState
    | SetModuleLoaded
    | SetSearchPerformed;

interface SetSearchPerformed extends Action {
    type: TypeKeys.SET_SEARCH_PERFORMED;
    performed: boolean;
}

interface SetModuleLoaded extends Action {
    type: TypeKeys.MODULE_LOADED;
    loaded: boolean;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE
}

interface SetEventId extends Action {
    type: TypeKeys.SET_EVENT_ID;
    id: string;
}

interface SetList extends Action {
    type: TypeKeys.SET_LIST;
    list: Array<Reservation>;
}

interface SetSearchCriteria extends Action {
    type: TypeKeys.SET_SEARCH_CRITERIA;
    searchCriteria: SearchCriteria;
}


export const actionsCreator = {
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    setSearchCriteria: (searchCriteria: SearchCriteria): SetSearchCriteria => ({
        type: TypeKeys.SET_SEARCH_CRITERIA,
        searchCriteria: searchCriteria
    }),
    setEventId: (id: string): SetEventId => ({
        type: TypeKeys.SET_EVENT_ID,
        id: id
    }),
    setList: (list: Array<Reservation>): SetList => ({
        type: TypeKeys.SET_LIST,
        list: list
    }),
    setModuleLoaded: (loaded: boolean): SetModuleLoaded => ({
        type: TypeKeys.MODULE_LOADED,
        loaded: loaded
    }),
    setSearchPerformed: (performed: boolean): SetSearchPerformed => ({
        type: TypeKeys.SET_SEARCH_PERFORMED,
        performed: performed
    })

}


export const actionsMiddleware = {

    loadInitialState: (eventId) => (dispatch, getState: () => ApplicationState) => {
        abortAllRequest();
        dispatch(actionsCreator.resetState());
        dispatch(actionsCreator.setEventId(eventId));
        dispatch(reservationMiddleware.loadInitialBaseData(eventId)).then(res => {
            dispatch(actionsCreator.setModuleLoaded(true));
        });
    },
    propertyChange: (propertyName: string, value: any) => (dispatch, getState: () => ApplicationState) => {

        abortAllRequest();

        let searchCriteria = { ...getState().reservationQuote.searchCriteria };

        switch (propertyName) {
            case "checkIn":
                searchCriteria.checkIn = value;
                if (moment(value).isValid()) {
                    searchCriteria.checkIn = moment(value).format("YYYY-MM-DDTHH:mm:ss");
                }
                break;
            case "checkOut":
                searchCriteria.checkOut = value;
                if (moment(value).isValid()) {
                    searchCriteria.checkOut = moment(value).format("YYYY-MM-DDTHH:mm:ss");
                }
                break;
            case "roomTypeId":
                searchCriteria.roomTypeId = value;
                break;
        }

        dispatch(actionsCreator.setSearchCriteria(searchCriteria));
    },
    doSearch: () => (dispatch, getState: () => ApplicationState) => {

        let searchCriteria = getState().reservationQuote.searchCriteria;

        let requestModel = new AbortableRequest(`/reservations/getavailablereservations`, RequestMethods.GET, { ...searchCriteria, eventId: getState().reservationQuote.eventId });

        ajaxRequest<Array<Reservation>>(requestModel)(dispatch, getState).then(response => {

            if (response.success == false) {
                return;
            }

            let arrayRecalculated = response.entity.map(x => utils.recalculateTotal(x));

            dispatch(actionsCreator.setList(arrayRecalculated));
            dispatch(actionsCreator.setSearchPerformed(true));
        });
    }

}


export const reducer: Reducer<ReservationQuoteState> = (state: ReservationQuoteState | undefined, incomingAction: ReservationQuoteActions): ReservationQuoteState => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.RESET_STATE:
            newState = { ...getBaseState() };
            break;
        case TypeKeys.SET_LIST:
            newState = dotProp.set(newState, "list", [...incomingAction.list]);
            break;
        case TypeKeys.SET_SEARCH_CRITERIA:
            newState = dotProp.set(newState, "searchCriteria", { ...incomingAction.searchCriteria });
            break;
        case TypeKeys.SET_EVENT_ID:
            newState = dotProp.set(newState, "eventId", incomingAction.id);
            break;
        case TypeKeys.MODULE_LOADED:
            newState = dotProp.set(newState, "moduleLoaded", incomingAction.loaded);
            break;
        case TypeKeys.SET_SEARCH_PERFORMED:
            newState = dotProp.set(newState, "searchPerformed", incomingAction.performed);
            break;

    }

    return newState;
}