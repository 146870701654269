import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware, actionsCreator } from '../../../store/reservations/ReservationQuoteState';
import { QuotePage, QuotePageProps, QuotePageCallbacks } from '../pagesquote/quotepage';


const mapStateToProsp = (state: ApplicationState): QuotePageProps => {

    return {
        moduleLoaded: state.reservationQuote.moduleLoaded,
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name.toUpperCase()}` : ''

    }
}

const mapDispatchToProps = (dispatch) => (dispatch): QuotePageCallbacks => {
    return ({
        onLoad: (eventId: string) => dispatch(actionsMiddleware.loadInitialState(eventId)),
        onUnmount: () => dispatch(actionsCreator.resetState())
    });
}

export const ConnectedQuotePage = withRouter(connect<QuotePageProps, QuotePageCallbacks>(mapStateToProsp, mapDispatchToProps)(QuotePage));