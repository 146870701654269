import { Agent } from '../agents/AgentEntities';
import { Customer } from '../customers/CustomerEntities';
import { Location, RoomTypeInformation } from '../locations/LocationEntities';
import { Event } from '../events/EventEntities';
import { Payment } from '../payments/payments/PaymentEntities';

export interface ReservationDayPricing {
    day: string;
    roomTypeId: string;
    locationId: string;
    eventId: string;
    sellPrice: number;
    dailyTax: number;
    availability: number;
}

export enum ReservationStatus {
    NotConfirmed = "NotConfirmed",
    Confirmed = "Confirmed",
    Canceled = "Canceled",
    Settled = "Settled"
}

export interface Reservation {
    id: string;
    progressiveNumber: string;
    checkIn: string;
    checkOut: string;
    status: ReservationStatus;
    discount: number;
    notes: string;
    alarm: string;
    event: Event;
    roomType: RoomTypeInformation;
    location: Location;
    totalPay: number;
    customers: Array<Customer>;
    profiles: Array<Agent>;
    createdBy: string;
    createdDate: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
    createdByAgent: boolean;
    reservationDays: Array<ReservationDayPricing>;
    rating: number;
    totalTax: number;
    totalNoTax: number;
    isWebReservation: boolean;
    checkInDone: boolean;
    paid?: number;
    toPay?: number;
    type: ReservationType;
    payments: Array<Payment>;
}

export enum ReservationType {
    Standard = "Standard",
    Packet = "Packet",
    Guest = "Guest",
    Staff = "Staff",
    StaffHotel = "StaffHotel"
}