import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from '../../../../store/index';
import { actionsMiddleware } from '../../../../store/payments/accountingsummary/AccountingSummaryState';
import { actionsMiddleware as eventActions } from '../../../../store/events/EventState';
import { AccountingSummaryPage, AccountingSummaryPageCallbacks, AccountingSummaryPageProps } from './accountingsummary';


const mapStateToProsp = (state: ApplicationState): AccountingSummaryPageProps => {

    return {
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name.toUpperCase()}` : '',
        summary: state.accountingSummary.totals,
        isFreezed: state.events.running !== undefined && state.events.running != null ? state.events.running.isFreezed : false
    }
}

const mapDispatchToProps = () => (dispatch): AccountingSummaryPageCallbacks => {
    return {
        onLoad: (id) => dispatch(actionsMiddleware.loadInitialState(id)),
        onOpen: (id: string) => dispatch(eventActions.reOpen(id)),
        onClose: (id: string) => dispatch(eventActions.close(id))
    }
}



export const ConnectedAccountingSummary = withRouter(connect<AccountingSummaryPageProps, AccountingSummaryPageCallbacks>(mapStateToProsp, mapDispatchToProps)(AccountingSummaryPage));