import * as React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Row, Col, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import _ from "underscore";
import { MenuItem } from '../../menu/menuitem';
import { MethodsDropDown } from '../methoddropdown';
import { ItemsDropDown } from '../itemdropdown';
import DecimalInput from "../../base/decimalinput";
import { IncomeModalState } from "../../../store/payments/payments/PaymentState";
import { ERDatePicker as DatePicker } from '../../base/datepicker';
import { PaymentCategory } from "../../../store/payments/paymentitems/PaymentItemEntities";


export interface IncomeModalProps {
    incomeData?: IncomeModalState;
}

export interface IncomeModalCallbacks {
    onPropertyChange: (propertyName: string, value: any) => void;
    onCloseModal: () => void;
    onSave: (eventId: string) => void;
}

export class IncomeModal extends React.Component<IncomeModalProps & IncomeModalCallbacks> {

    constructor(props) {
        super(props);
    }

    render() {

        if (!this.props.incomeData)
            return (<React.Fragment />);
        return (
            <Modal className="bg-dark modal-lg" isOpen={this.props.incomeData.modalOpen} toggle={() => this.props.onCloseModal()} backdrop="static">
                <ModalHeader className="text-eurorounders bg-dark" toggle={() => this.props.onCloseModal()}>Entrata</ModalHeader>
                <ModalBody className="bg-dark text-eurorounders">
                    <Form className="text-eurorounders" >
                        <Row>
                            <Col lg="6" sm="6" xs="12">
                                <FormGroup>
                                    <Label>Oggetto</Label>
                                    <ItemsDropDown category={PaymentCategory.Income} onChange={(value: string) => this.props.onPropertyChange("paymentItemId", value)} selected={this.props.incomeData.data.paymentItemId} />
                                </FormGroup>
                            </Col>
                            <Col lg="6" sm="6" xs="12">
                                <FormGroup>
                                    <Label>Metodo</Label>
                                    <MethodsDropDown onChange={(value: string) => this.props.onPropertyChange("methodId", value)} selected={this.props.incomeData.data.methodId} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" sm="6" xs="12">
                                <FormGroup>
                                    <Label>Data</Label>
                                    <DatePicker selectedDate={this.props.incomeData.data.paymentDate} onChange={date => this.props.onPropertyChange("paymentDate", date)} />                                </FormGroup>
                            </Col>
                            <Col lg="6" sm="6" xs="12">
                                <FormGroup>
                                    <Label>Importo</Label>
                                    <InputGroup>
                                        <DecimalInput onChane={(value: string) => { this.props.onPropertyChange("amount", value) }} value={this.props.incomeData.data.amount} />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" sm="12" xs="12">
                                <FormGroup>
                                    <Label>Note</Label>
                                    <Input className="bg-dark text-eurorounders" type="textarea" value={this.props.incomeData.data.notes || ''} onChange={(e) => { this.props.onPropertyChange("notes", e.target.value) }} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <MenuItem isSmall={true} isButton={true} icon="save" label="Salva" onClick={() => this.props.onSave(this.props.incomeData.data.eventId)} />
                    <MenuItem isSmall={true} isButton={true} icon="times" label="Annulla" onClick={() => this.props.onCloseModal()} />
                </ModalFooter>
            </Modal>
        );
    }
}