import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/entries/EntryState';
import { EntriesSearchForm, EntriesSearchFormProps, EntriesSearchFormCallbacks } from '../base/searchform';

const mapStateToProsp = (state: ApplicationState): EntriesSearchFormProps => {

    return {
        searchString: state.entries.searchCriteria.searchString
    }
}

const mapDispatchToProps = () => (dispatch): EntriesSearchFormCallbacks => {
    return ({
        onSearch: (searchString: string) => dispatch(actionsMiddleware.genericSearch(searchString))
    });
}

export const ConnectedSearchSearchForm = connect<EntriesSearchFormProps, EntriesSearchFormCallbacks>(mapStateToProsp, mapDispatchToProps)(EntriesSearchForm);