import moment from "moment";
import { CoreResponse, ValidationCode, ValidationLevel } from '../store/serverresponse/serverresponse';
import { ApplicationState } from '../store/index';
import { actionCreator } from '../store/uiproperties/UIState';
import { Document } from '../store/documents/DocumentsEntities';
import { actionCreators as userActions, AuthData } from '../store/profile/CurrentProfile';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import * as $ from "jquery";
import { push } from "connected-react-router";

let abortableRequests: JQuery.jqXHR[] = [];

export const abortAllRequest = () => {
    abortableRequests.forEach(x => {
        if (x.state() == 'pending') {
            x.abort();
        }
    });
    abortableRequests = [];
}

export enum RequestMethods {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE"
}

export class RequestModel {
    constructor(url: string, method: RequestMethods, body: object = null, contentType: string = null, errorScope = null, abortable: boolean = false) {
        this.body = body;
        if (body !== undefined && body != null && (method == RequestMethods.POST || method == RequestMethods.PUT))
            this.body = JSON.stringify(body);
        this.url = `/api${url}`;
        this.contentType = "application/json; charset=utf-8";
        if (contentType !== undefined && contentType != null)
            this.contentType = contentType;
        this.method = method;
        this.errorScope = errorScope;
        this.abortable = abortable;
    }

    readonly url: string;
    readonly body: any;
    readonly contentType: string;
    readonly method: RequestMethods;
    readonly errorScope: string;
    readonly abortable: boolean;
}

export class AbortableRequest extends RequestModel {
    constructor(url: string, method: RequestMethods, body: object = null) {
        super(url, method, body, null, null, true);
    }

}

export class FetchListRequest extends AbortableRequest {
    constructor(url: string, method: RequestMethods, body: object = null) {
        super(url, method, body);
    }

}

export class SaveRequest extends RequestModel {
    constructor(url: string, method: RequestMethods, body: object) {
        super(url, method, body);
    }
}

export class HideRequest extends RequestModel {
    constructor(url: string, method: RequestMethods, body: object) {
        super(url, method, body);
    }
}


export const checkRefreshToken = () => (dispatch: any, getState: any): JQueryPromise<boolean> => {

    let deferred = $.Deferred();


    let authData = (getState() as ApplicationState).currentProfile.authData;

    let tokenExpireDate = moment(authData.expires);

    if (!tokenExpireDate.isValid()) {

        deferred.resolve(false);
        dispatch(userActions.logout());
        return deferred.promise();
    }

    if (moment().isSameOrAfter(tokenExpireDate)) {
        let deferred = $.Deferred();

        dispatch(userActions.refreshToken()).then(response => {
            deferred.resolve(response);
        });

        return deferred.promise();

    }

    deferred.resolve(true);

    return deferred.promise();

}

export const uploadDocumentRequest = (file: FormData, objectId: string, objectName: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<Document>> => {

    let authData = (getState() as ApplicationState).currentProfile.authData;

    let tokenExpireDate = moment(authData.expires);

    if (!tokenExpireDate.isValid()) {
        let deferred = $.Deferred();

        deferred.resolve({
            success: false,
            messages: ["Token expire date not valid"],
            entity: null
        } as CoreResponse<Document>);
        dispatch(userActions.logout());
        return deferred.promise();
    }

    if (moment().isSameOrAfter(tokenExpireDate)) {
        let deferred = $.Deferred();

        dispatch(userActions.refreshToken()).then(response => {
            if (response == true) {
                let authData = (getState() as ApplicationState).currentProfile.authData;
                uploadDocument(file, objectId, objectName, authData.token)(dispatch, getState).then(x => deferred.resolve(x));
            }
        });

        return deferred.promise();

    }

    return uploadDocument(file, objectId, objectName, authData.token)(dispatch, getState);

}


export const ajaxRequest = <T>(requestModel: RequestModel) => (dispatch: any, getState: any): JQueryPromise<CoreResponse<T>> => {

    let authData = (getState() as ApplicationState).currentProfile.authData;

    let tokenExpireDate = moment(authData.expires);

    if (!tokenExpireDate.isValid()) {
        let deferred = $.Deferred();

        deferred.resolve({
            success: false,
            messages: ["Token expire date not valid"],
            entity: null
        } as CoreResponse<T>);
        dispatch(userActions.logout());
        return deferred.promise();
    }

    if (moment().isSameOrAfter(tokenExpireDate)) {
        let deferred = $.Deferred();

        dispatch(userActions.refreshToken()).then(response => {
            if (response == true) {
                let authData = (getState() as ApplicationState).currentProfile.authData;
                performAjax<T>(requestModel, authData.token)(dispatch, getState).then(x => deferred.resolve(x));
            }
        });

        return deferred.promise();

    }

    return performAjax<T>(requestModel, authData.token)(dispatch, getState);

}

export const loginRequest = (signInName: string, password: string) => (dispatch, getState): JQueryPromise<CoreResponse<any>> => {

    let request = new HideRequest("/profiles/authenticate", RequestMethods.POST, { username: signInName, password: password });


    return performAjax<any>(request)(dispatch, getState);

}

export const refreshTokenRequest = (request: RequestModel) => (dispatch, getState): JQueryPromise<CoreResponse<AuthData>> => {


    return performAjax<any>(request)(dispatch, getState);

}

const uploadDocument = (file: FormData, objectId: string, objectName: string, token: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<Document>> => {

    let headers: any = {};

    if (token !== undefined && token != null)
        headers["authorization"] = token;

    var deferred = $.Deferred();
    dispatch(showLoading());
    dispatch(actionCreator.saveInProcess(true));
    let request = $.ajax({
        headers: headers,
        url: `/api/documents/upload/${objectId}/${objectName}`,
        method: RequestMethods.POST,
        data: file,
        processData: false,
        contentType: false,
        cache: false,
        success: (response) => {
            dispatch(hideLoading());
            dispatch(actionCreator.saveInProcess(false));

            let responsePrased = (response as CoreResponse<Document>);

            if (responsePrased.success == false) {
                dispatch(actionCreator.setErrorMessages(responsePrased.messages));
            }
            return deferred.resolve(response);
        },
        error: (xhr, ajaxOptions, thrownError) => {
            dispatch(actionCreator.saveInProcess(false));
            dispatch(hideLoading());
            if (xhr.status === 0) {
                if (xhr.statusText === 'abort') {
                    deferred.reject();
                    return;
                }
            }

            dispatch(actionCreator.setErrorMessages(["Si è verificato un errore"]));

            deferred.resolve({
                entity: null,
                messages: ["Errore generico"],
                success: false,
                validationCodes: [],
                validationLevel: null
            } as CoreResponse<Document>)

            ping()(dispatch, getState);

        }
    });

    return deferred.promise(request);

}

const performAjax = <T>(requestModel: RequestModel, token: string = null) => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<T>> => {

    let headers: any = {};

    if (requestModel.contentType !== undefined && requestModel.contentType != null)
        headers["content-type"] = requestModel.contentType;

    if (token !== undefined && token != null)
        headers["authorization"] = token;

    var deferred = $.Deferred();

    if (requestModel instanceof SaveRequest) {

        if (getState().uiProperties.saveRequestInProcess == true) {
            deferred.resolve({
                entity: null,
                messages: ["Salvataggio in corso.."],
                success: false,
                validationCodes: [],
                validationLevel: null
            } as CoreResponse<T>);
            return deferred.promise();
        }
        dispatch(actionCreator.saveInProcess(true));
    } else if (requestModel instanceof HideRequest) {
        dispatch(actionCreator.saveInProcess(true));
    } else if (requestModel instanceof FetchListRequest) {
        dispatch(actionCreator.startFetchingPagedList(false));
    }

    dispatch(showLoading());

    let request = $.ajax({
        headers: headers,
        method: requestModel.method,
        url: requestModel.url,
        data: requestModel.body,
        cache: false,
        success: (response) => {
            dispatch(hideLoading());
            dispatch(actionCreator.saveInProcess(false));
            let responsePrased = (response as CoreResponse<T>);

            if (responsePrased !== undefined && responsePrased != null) {
                if (responsePrased.success == false)
                    dispatch(actionCreator.setErrorMessages(responsePrased.messages, requestModel.errorScope));

                if (requestModel instanceof SaveRequest && responsePrased.success == true && responsePrased.validationLevel != ValidationLevel.Fail) {

                    if (!responsePrased.messages)
                        responsePrased.messages = [];
                    responsePrased.messages.push("Salvataggio completato con successo")

                    dispatch(actionCreator.setErrorMessages(responsePrased.messages));

                    dispatch(actionCreator.setChangeInProgress(false));

                } else {

                    if (responsePrased.messages && responsePrased.messages.length > 0)
                        dispatch(actionCreator.setErrorMessages(responsePrased.messages));

                }

            }
            return deferred.resolve(response);
        },
        error: (xhr, ajaxOptions, thrownError) => {
            dispatch(actionCreator.saveInProcess(false));
            dispatch(actionCreator.setChangeInProgress(false));
            dispatch(hideLoading());

            if (xhr.status === 0) {
                if (xhr.statusText === 'abort') {
                    deferred.reject();
                    return;
                }
            }

            dispatch(actionCreator.setErrorMessages(["Si è verificato un errore"], requestModel.errorScope));

            deferred.resolve({
                entity: null,
                messages: ["Errore generico"],
                success: false,
                validationCodes: [],
                validationLevel: null
            } as CoreResponse<T>)

            ping()(dispatch, getState);
        }
    });

    if (requestModel.abortable == true)
        abortableRequests.push(request);

    return deferred.promise(request);

}


export const ping = () => (dispatch, getState: () => ApplicationState) => {
    let request = $.ajax({
        method: RequestMethods.GET,
        url: "/api/ping",
        cache: false,
        success: (response) => {
        },
        error: (xhr, ajaxOptions, thrownError) => {
            dispatch(push("/offline"));
        }
    });
}