import * as React from "react";
import { MenuItemProps, MenuItem } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { Link } from "react-router-dom";


export class EventConfigurations extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let menuItems = [];


        menuItems.push({
            icon: "list",
            label: "lista",
            isButton: false,
            isSmall: true,
            path: "/events"
        } as MenuItemProps);

        return (
            <React.Fragment>
                <BodyHeader title="Configurazioni eventi" menuItems={menuItems} bottomSpace />
                <ul className="text-eurorounders" style={{ fontWeight: "bold" }}>
                    <li>
                        <Link to="/events/configurations/paymentmethods" className="text-eurorounders">
                            Metodi di pagamento
                        </Link>
                    </li>
                    <li>
                        <Link to="/events/configurations/costs" className="text-eurorounders">
                            Voci di costo
                        </Link>
                    </li>
                    <li>
                        <Link to="/events/configurations/incomings" className="text-eurorounders">
                            Voci incasso
                        </Link>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}