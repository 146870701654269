import * as React from "react";
import { Input, Row, Col, Form, FormGroup, Label } from "reactstrap";
import { MenuItem } from '../../../menu/menuitem';
import { ConnectedRoleList } from '../connected/connected.rolelist';
import { BodyHeader } from '../../../base/bodyheader';
import { MenuItemProps } from '../../../menu/menuitem';



export interface RolePageListProps {
    elementsSelected?: boolean;
    onSelectElement?: () => void;
    onDelete?: () => void;
    onLoad?: () => void;
}

export class RolePageList extends React.Component<RolePageListProps> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad)
            this.props.onLoad();
    }

    render() {
        let menuItems = [
            {
                icon: "plus",
                label: "crea",
                path: "/profiles/roles/create"
            },
            {
                icon: "user-lock",
                label: "utenti",
                path: "/profiles"
            }
        ] as Array<MenuItemProps>
        return (
            <React.Fragment>
                <BodyHeader title="Ruoli" menuItems={menuItems} bottomSpace={true} />
                <ConnectedRoleList />
            </React.Fragment>
        )
    }
}