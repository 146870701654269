import * as React from "react";
import { Button, Input, InputGroupAddon } from "reactstrap";
import moment from "moment";
import './datepicker.css';
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

interface ERDatePickerProps {
    disabled?: boolean;
    selectedDate?: string;
    onChange: (date?: string) => void;
    supportNative: boolean;
}

interface ERDatePickerCallbacks {
    onChange: (date?: string) => void;
}

class ERWrapperDatePicker extends React.Component<ERDatePickerProps & ERDatePickerCallbacks> {
    constructor(props) {
        super(props);
    }

    render() {

        let className = "";

        if (this.props.disabled)
            className = "disable-events";

        if (this.props.supportNative == true) {

            let dateToRender = null;

            if (this.props.selectedDate) {
                dateToRender = moment(this.props.selectedDate).format("YYYY-MM-DD");
            }

            return (<Input className={`bg-dark text-eurorounders ${className}`} type="date" value={dateToRender || ''} onChange={(e) => { this.props.onChange(e.target.value) }} />);
        }

        return (
            <React.Fragment>
                <DatePicker isClearable={true} className={`bg-dark form-control text-eurorounders ${className}`} value={this.props.selectedDate ? moment(this.props.selectedDate).calendar() : ''} onChange={(v, f) => this.props.onChange(moment(v).toISOString())} dateFormat="dd/MM/yyyy" />
                <div style={{ display: "inline-block" }}>
                    <InputGroupAddon addonType="append">
                        <Button onClick={() => this.props.onChange('')}>
                            <i className={`icon fas fa-times fa-xs`} />
                        </Button>
                    </InputGroupAddon>
                </div>
            </React.Fragment>
        );
    }
}


export interface DatePickerProps {
    selectedDate?: string;
    disabled?: boolean;
    onChange: (date?: string) => void;
}

const mapStateToProsp = (state: ApplicationState, ownProps: DatePickerProps): ERDatePickerProps => {

    return {
        onChange: ownProps.onChange,
        selectedDate: ownProps.selectedDate,
        supportNative: state.uiProperties.supportNativeDateInput,
        disabled: ownProps.disabled
    }
}

const mapDispatchToProps = (dispatch, ownProps: DatePickerProps) => (dispatch): ERDatePickerCallbacks => {
    return ({
        onChange: ownProps.onChange
    });
}

export const ERDatePicker = connect<ERDatePickerProps, ERDatePickerCallbacks, DatePickerProps>(mapStateToProsp, mapDispatchToProps)(ERWrapperDatePicker);