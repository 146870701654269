import * as React from "react";
import { Input } from "reactstrap";

import './decimalinput.css';

interface DecimalInputProps {
    value?: number;
    onChane: (value: string) => void;
    className?: string;
    readOnly?: boolean;
    allowNegative?: boolean;
}

export default class DecimalInput extends React.Component<DecimalInputProps> {
    constructor(props) {
        super(props);
    }

    private checkChange(value: string) {

        try {

            let splitted = value.split(".");

            if (splitted === undefined || splitted == null)
                return;

            if (splitted.length > 2)
                return;


            if (splitted[0] == "") {
                this.props.onChane(null);
                return;
            }

            if (splitted[0].length == 1 && splitted[0] == "-")
                this.props.onChane("-");

            let firstPartNumberRegex = /^\d+$/;

            if (this.props.allowNegative == true) {
                firstPartNumberRegex = /^-?\d+$/;
            }

            let numbeRegex = /^\d+$/;

            if (!firstPartNumberRegex.test(splitted[0]))
                return;

            try {
                parseInt(splitted[0]);
            } catch {
                return;
            }

            if (splitted.length == 1) {
                this.props.onChane(splitted[0]);
                return;
            }

            if (splitted[1] == "") {
                this.props.onChane(`${splitted[0]}.`);
            }

            if (splitted[1].length > 2)
                splitted[1] = splitted[1].substr(0, 2);

            if (!numbeRegex.test(splitted[1])) {
                return;
            }

            try {
                parseInt(splitted[1]);
            } catch {
                return;
            }

            this.props.onChane(`${splitted[0]}.${splitted[1]}`);
        }
        catch { }
    }

    render() {
        let valueToShow = this.props.value === undefined || this.props.value == null ? '' : this.props.value;
        let className = ""

        if (this.props.className !== undefined && this.props.className != null)
            className = this.props.className;

        let readOnly = this.props.readOnly == true;

        return (
            <React.Fragment>
                <Input readOnly={readOnly}  className={`bg-dark text-eurorounders decimal-input ${className}`} type="text" value={valueToShow} onChange={(e) => this.checkChange(e.target.value)} />
            </React.Fragment>
        )
    }

}