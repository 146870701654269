import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/communications/CommunicationState';
import { CommunicationsSelectionList, CommunicationsSelectionListProps, CommunicationsSelectionListCallback } from '../base/selectionlist';


export interface ConnectedCommunicationsSelectionListProps {
    selectionEnabled: boolean;
}

const mapStateToProsp = (state: ApplicationState, ownProps: ConnectedCommunicationsSelectionListProps): CommunicationsSelectionListProps => {

    return {
        list: state.communications.runningCommunication.communicationList,
        locations: state.events.runningEventLocations,
        reservationStatus: state.reservations.reservationStatusDesc,
        roomTypes: state.locations.roomTypes,
        slectedCommunications: state.communications.runningCommunication.selectedCommunications,
        selectionEnabled: ownProps.selectionEnabled,
        communicationTypes: state.communications.communicationTypesDesc
    }
}

const mapDispatchToProps = (dispatch, ownProps: ConnectedCommunicationsSelectionListProps) => (dispatch): CommunicationsSelectionListCallback => {
    return ({
        onRowClick: (communication) => {

            if (ownProps.selectionEnabled == false)
                return;

            dispatch(actionsMiddleware.onSelectCommunication(communication));
        }
    });
}

export const ConnectedCommunicationsSelectionList = connect<CommunicationsSelectionListProps, CommunicationsSelectionListCallback, ConnectedCommunicationsSelectionListProps>(mapStateToProsp, mapDispatchToProps)(CommunicationsSelectionList);