import * as React from "react";
import { BodyHeader } from '../../base/bodyheader';
import { ConnectedReservationQuoteList } from '../connectedquote/connected.quotelist';
import { ConnectedReservationQuoteSearchForm } from '../connectedquote/connected.quotesearchform';
import { MenuItemProps } from "../../menu/menuitem";


export interface QuotePageProps {
    moduleLoaded: boolean;
    eventFullName: string;

}

export interface QuotePageCallbacks {
    onLoad: (eventId: string) => void;
    onUnmount: () => void;
}


export class QuotePage extends React.Component<QuotePageProps & QuotePageCallbacks>{
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let eventId = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                eventId = (this.props as any).match.params.eventId;
            this.props.onLoad(eventId);
        }
    }

    componentWillUnmount() {
        this.props.onUnmount();
    }

    render() {
        if (this.props.moduleLoaded != true) {
            return (<React.Fragment></React.Fragment>);
        }

        let menuItems = [
            {
                icon: "ellipsis-h",
                label: "Evento",
                isButton: false,
                isSmall: true,
                path: `/events/${(this.props as any).match.params.eventId}/menu`
            } as MenuItemProps];

        return (
            <React.Fragment>
                <BodyHeader subTitle={this.props.eventFullName} title="Cerca disponibilità" menuItems={menuItems} />
                <ConnectedReservationQuoteSearchForm />
                <ConnectedReservationQuoteList />
            </React.Fragment>
        )
    }
}