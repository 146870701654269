import { UV_UDP_REUSEADDR } from "constants";
import * as React from "react";
import { Customer } from '../../store/customers/CustomerEntities';
import RankingStars from '../base/rankingstars';
import { round } from '../../utils/commons';

const customerstatistics = (customer: Customer): JSX.Element => {

    if (customer === undefined || customer == null)
        return (<React.Fragment></React.Fragment>);


    let paidReservations = customer.paidReservations !== undefined && customer.paidReservations != null ? customer.paidReservations : 0;
    let totalReservations = customer.totalReservations !== undefined && customer.totalReservations != null ? customer.totalReservations : 0
    let averageBooking = customer.averageBooking !== undefined && customer.averageBooking != null ? customer.averageBooking * 100 : 0;
    let totalBuyEntries = customer.totalBuyEntries !== undefined && customer.totalBuyEntries != null ? customer.totalBuyEntries : 0;
    let totalEventsPlayed = customer.totalEventsPlayed !== undefined && customer.totalEventsPlayed != null ? customer.totalEventsPlayed : 0;
    let averageEntries = customer.averageEntries !== undefined && customer.averageEntries != null ? customer.averageEntries : 0;
    let totalEventsInvolved = customer.totalEventsInvolved !== undefined && customer.totalEventsInvolved != null ? customer.totalEventsInvolved : 0;
    let trustedAverageBooking = customer.trustedAverageBooking !== undefined && customer.trustedAverageBooking != null ? customer.trustedAverageBooking * 100 : 0;
    let trustedAverageEntries = customer.trustedAverageEntries !== undefined && customer.trustedAverageEntries != null ? customer.trustedAverageEntries : 0;
    let rating = customer.rating !== undefined && customer.rating != null ? customer.rating : 0;
    let trustedAverageEntriesOnBooking = customer.trustedAverageEntriesOnBooking !== undefined && customer.trustedAverageEntriesOnBooking != null ? customer.trustedAverageEntriesOnBooking : 0;

    let customerTrust = "Bassa";

    if (totalEventsInvolved >= 4) {
        customerTrust = "Alta";
    }
    else if (totalEventsInvolved >= 3) {
        customerTrust = "Media";
    }


    return (
        <React.Fragment>
            <dl className="row" style={{ marginTop: "15px" }}>
                <dt className="text-eurorounders col-sm-4">Prenotaz. pagate / totale prenotaz.:</dt>
                <dd className="text-eurorounders col-sm-8">{paidReservations} / {totalReservations}</dd>

                <dt className="text-eurorounders col-sm-4">Entries / eventi giocati:</dt>
                <dd className="text-eurorounders col-sm-8">{totalBuyEntries} / {totalEventsPlayed}</dd>

                <dt className="text-eurorounders col-sm-4">Eventi coinvolti:</dt>
                <dd className="text-eurorounders col-sm-8">{totalEventsInvolved}</dd>

                <dt className="text-eurorounders col-sm-4">Grado affidabilità:</dt>
                <dd className="text-eurorounders col-sm-8">{customerTrust}</dd>

                <dt className="text-eurorounders col-sm-4">Affidabilità prenotazione:</dt>
                <dd className="text-eurorounders col-sm-8">{round(trustedAverageBooking)}%</dd>

                <dt className="text-eurorounders col-sm-4">Stima #entries senza prenotazione:</dt>
                <dd className="text-eurorounders col-sm-8">{round(trustedAverageEntries)}</dd>

                <dt className="text-eurorounders col-sm-4">Stima #entries per pronotazione:</dt>
                <dd className="text-eurorounders col-sm-8">{round(trustedAverageEntriesOnBooking)}</dd>

                <dt className="text-eurorounders col-sm-4">Rating:</dt>
                <dd className="text-eurorounders col-sm-8">
                    <RankingStars ranking={rating} />
                </dd>
            </dl>
        </React.Fragment>
    )
};

export default customerstatistics;