import * as React from "react";

import './addremove.css';

interface AddRemoveProps {
    isAdded: boolean;
    title: string;
    onRemove: () => void;
    onAdd: () => void;
}

export default class AddRemove extends React.Component<AddRemoveProps> {
    constructor(props) {
        super(props);
    }

    handleClick() {
        if (this.props.isAdded)
            this.props.onRemove();
        else
            this.props.onAdd();
    }

    render() {

        let title = this.props.title;
        let iconClassName = "plus";

        if (this.props.isAdded) {
            iconClassName = "trash";
            title = `Elimina ${title}`;
        }
        else {
            title = `Aggiungi ${title}`;
        }

        return (
            <React.Fragment>
                <div className="addremove-content">
                    <div onClick={() => this.handleClick()} className="title-container">
                        <span className="title">{title}</span>
                        <i className={`icon fas fa-${iconClassName} fa-xs`}></i>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}