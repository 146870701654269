import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionCreator } from '../../../store/agents/AgentsState';
import EditCommmisionTemplate, { EditCommmisionTemplateProps } from './editcommissiontemplate';

const mapStateToProps = (state: ApplicationState, ownProps: any): EditCommmisionTemplateProps => {
    return {
        commissionTemplate: state.agents.editingCommisionTemplate,
        isOpen: state.agents.isCommissionTemplateModalOpen,
        saveOnConfirm: ownProps.saveOnConfirm
    }
}

const mapDispatchToProps = () => dispatch => {
    return {
        onChange: (propertyName: string, value: any) => dispatch(actionCreator.setRunningCommissionTemplateProp(propertyName, value)),
        onSubmit: (saveOnConfirm?: boolean) => {
            dispatch(actionCreator.confirmCommissionTemplateForAgent());
            dispatch(actionCreator.toogleCommissionTemplateModal(false));
            if (saveOnConfirm == true) {
                dispatch(actionCreator.saveAgent()).then(response => {
                    if (response !== undefined && response != null)
                        dispatch(actionCreator.setRefreshAgentInList());
                });
            }
        },
        closeModal: () => dispatch(actionCreator.toogleCommissionTemplateModal(false))
    }
}

export const ConnectedEditCommmisionTemplate = connect<EditCommmisionTemplateProps>(mapStateToProps, mapDispatchToProps)(EditCommmisionTemplate)