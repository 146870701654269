import * as React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Row, Col, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import _ from "underscore";
import { MenuItem } from '../../menu/menuitem';
import { MethodsDropDown } from '../methoddropdown';
import { ItemsDropDown } from '../itemdropdown';
import DecimalInput from "../../base/decimalinput";
import { OutcomeModalState } from "../../../store/payments/payments/PaymentState";
import { ERDatePicker as DatePicker } from '../../base/datepicker';
import { PaymentCategory, PaymentItem, SystemPaymentItem } from "../../../store/payments/paymentitems/PaymentItemEntities";
import { ConnectedAgentsList } from '../../agents/connected/connected.agentslist';
import { ConnectedAgentSearchForm } from '../../agents/connected/connected.searchform';

export interface OutcomeModalProps {
    outcomeData?: OutcomeModalState;
    paymentItems: Array<PaymentItem>;
}

export interface OutcomeModalCallbacks {
    onPropertyChange: (propertyName: string, value: any) => void;
    onCloseModal: () => void;
    onSave: (eventId: string) => void;
    toggleAgentModal: () => void;
    onConfirmAgent: () => void;
    onAgentRowClick: (id: string) => void;
}

export class OutcomeModal extends React.Component<OutcomeModalProps & OutcomeModalCallbacks> {

    constructor(props) {
        super(props);
    }

    private getAgentSelection(): JSX.Element {

        if (!this.props.outcomeData)
            return (<React.Fragment />);

        if (this.props.outcomeData.data?.paymentItemId != _.find(this.props.paymentItems, (item) => item.systemItem == SystemPaymentItem.Agent)?.id)
            return (<React.Fragment />);

        return (
            <Row>
                <Col lg="12" sm="12" xs="12">
                    <FormGroup>
                        <Label>Agente</Label>
                        <InputGroup>
                            <Input
                                readOnly
                                className="bg-dark text-eurorounders"
                                type="text"
                                value={this.props.outcomeData.agent ? `${this.props.outcomeData.agent.firstName} ${this.props.outcomeData.agent.lastName}` : ""}
                                onClick={() => { this.props.toggleAgentModal(); }}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>);
    }

    render() {

        if (!this.props.outcomeData)
            return (<React.Fragment />);

        const agentComponent = this.getAgentSelection();

        return (
            <React.Fragment>
                <Modal className="bg-dark modal-lg" isOpen={this.props.outcomeData.modalOpen} toggle={() => this.props.onCloseModal()} backdrop="static">
                    <ModalHeader className="text-eurorounders bg-dark" toggle={() => this.props.onCloseModal()}>Uscita</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders">
                        <Form className="text-eurorounders" >
                            <Row>
                                <Col lg="6" sm="6" xs="12">
                                    <FormGroup>
                                        <Label>Oggetto</Label>
                                        <ItemsDropDown category={PaymentCategory.Cost} onChange={(value: string) => this.props.onPropertyChange("paymentItemId", value)} selected={this.props.outcomeData.data.paymentItemId} />
                                    </FormGroup>
                                </Col>
                                <Col lg="6" sm="6" xs="12">
                                    <FormGroup>
                                        <Label>Metodo</Label>
                                        <MethodsDropDown onChange={(value: string) => this.props.onPropertyChange("methodId", value)} selected={this.props.outcomeData.data.methodId} />
                                    </FormGroup>
                                </Col>

                            </Row>
                            {agentComponent}
                            <Row>
                                <Col lg="6" sm="6" xs="12">
                                    <FormGroup>
                                        <Label>Data</Label>
                                        <DatePicker selectedDate={this.props.outcomeData.data.paymentDate} onChange={date => this.props.onPropertyChange("paymentDate", date)} />                                </FormGroup>
                                </Col>
                                <Col lg="6" sm="6" xs="12">
                                    <FormGroup>
                                        <Label>Importo</Label>
                                        <InputGroup>
                                            <DecimalInput onChane={(value: string) => { this.props.onPropertyChange("amount", value) }} value={this.props.outcomeData.data.amount} />
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText className="text-eurorounders bg-dark">€</InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" sm="12" xs="12">
                                    <FormGroup>
                                        <Label>Note</Label>
                                        <Input className="bg-dark text-eurorounders" type="textarea" value={this.props.outcomeData.data.notes || ''} onChange={(e) => { this.props.onPropertyChange("notes", e.target.value) }} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon="save" label="Salva" onClick={() => this.props.onSave(this.props.outcomeData.data.eventId)} />
                        <MenuItem isSmall={true} isButton={true} icon="times" label="Annulla" onClick={() => this.props.onCloseModal()} />
                    </ModalFooter>
                </Modal>
                <Modal className="bg-dark modal-lg" isOpen={this.props.outcomeData.isAgentModalOpen} toggle={() => this.props.toggleAgentModal()} backdrop="static">
                    <ModalHeader className="text-eurorounders bg-dark" toggle={() => this.props.toggleAgentModal()}>Seleziona Agente</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders">
                        <ConnectedAgentSearchForm />
                        <ConnectedAgentsList selectionEnabled={true} onRowClick={this.props.onAgentRowClick} />
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon="check" label="ok" onClick={() => { this.props.onConfirmAgent(); }} />
                        <MenuItem isSmall={true} isButton={true} icon="times" label="annulla" onClick={() => this.props.toggleAgentModal()} />
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}