import * as React from "react";
import { MenuItem, MenuItemProps } from '../menu/menuitem';
import { Row, Col } from "reactstrap";
import { guid } from '../../utils/commons';
import "./bodyheader.css";

export interface BodyHeaderProps {
    className?: string;
    titleClassName?: string;
    title: string;
    subTitle?: string;
    menuItems: Array<MenuItemProps>;
    bottomSpace?: boolean;
}

export class BodyHeader extends React.Component<BodyHeaderProps>{
    constructor(props) {
        super(props);
    }

    private getMenuItems(): JSX.Element[] {

        let menuItems = [];

        menuItems = this.props.menuItems.map(menuItem => {
            return (
                <MenuItem
                    key={guid()}
                    isSmall={true}
                    className="menu-item-space"
                    icon={menuItem.icon}
                    label={menuItem.label}
                    path={menuItem.path}
                    isButton={menuItem.isButton}
                    onClick={() => {
                        if (menuItem.onClick)
                            menuItem.onClick()
                    }}
                    isDisabled={menuItem.isDisabled}
                />
            )
        })

        return menuItems;

    }


    render() {

        let menuItems = this.getMenuItems();

        let className = "";

        if (this.props.className)
            className = `${className} ${this.props.className}`;

        if (this.props.bottomSpace == true)
            className = `${className} bottom-space`;

        let subTitle = (<React.Fragment></React.Fragment>);

        if (this.props.subTitle !== undefined && this.props.subTitle != null && this.props.subTitle != '') {
            subTitle = (<h6 className="text-eurorounders">{this.props.subTitle}</h6>);
        }

        let titleClass = this.props.titleClassName ?? "";

        let withSubtitle = this.props.subTitle ? "with-subtitle" : "";

        return (
            <React.Fragment>
                <Row className={`body-header-container ${withSubtitle} ${className}`}>
                    <Col lg={{ size: 6 }} sm={{ size: 6 }} xs={{ size: 12 }}>
                        <h3 className={`text-eurorounders ${titleClass}`}>
                            {this.props.title}
                        </h3>
                        {subTitle}
                    </Col>
                    <Col lg={{ size: 6 }} sm={{ size: 6 }} xs={{ size: 12}}>
                        <div className="right-side-body-header-container">
                            {menuItems}
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}