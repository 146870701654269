import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/payments/payments/PaymentState';
import { TraferModal, TraferModalProps, TrasferModalCallbacks } from './trasfermodal';
import { actionsMiddleware as registerActions } from '../../../store/payments/cashregister/CashRegisterState';

const mapStateToProsp = (state: ApplicationState): TraferModalProps => {
    return {
        trasferData: state.payment.transferModal
    }
}

const mapDispatchToProps = () => (dispatch): TrasferModalCallbacks => {
    return ({
        onCloseModal: () => dispatch(actionsMiddleware.closeTrasferModal()),
        onPropertyChange: (name: string, value: any) => dispatch(actionsMiddleware.editTrasferProperty(name, value)),
        onSave: (eventId: string) => {
            dispatch(actionsMiddleware.saveTrasfer()).then((response: boolean) => {
                if (response)
                    dispatch(registerActions.loadInitialState(eventId));
            })
        }
    });
}

export const ConnectedTrasferModal = connect<TraferModalProps, TrasferModalCallbacks>(mapStateToProsp, mapDispatchToProps)(TraferModal);