import * as React from "react";

import './checkbox.css';

export interface CheckBoxProps {
    isChecked: boolean;
    onCheck: (checked: boolean) => void;
    label?: string;
    customComponent?: JSX.Element;
    className?: string;
}


export class CheckBox extends React.Component<CheckBoxProps>{
    constructor(props) {
        super(props);
    }

    render() {

        let checked = '';

        if (this.props.isChecked)
            checked = "checked";

        let componentToRender = null;
        let label = null;

        if (this.props.customComponent !== undefined && this.props.customComponent != null)
            componentToRender = this.props.customComponent;

        if (this.props.label !== undefined && this.props.label != null)
            label = this.props.label;

        let className = '';
        if (this.props.className !== undefined && this.props.className != null)
            className = this.props.className;

        return (
            <div className={`checkbox-component ${className}`} onClick={() => this.props.onCheck(!this.props.isChecked)}>
                <div className={`check ${checked}`}>
                </div>
                <div>
                    {label}
                    {componentToRender}
                </div>
            </div>
        )
    }
}