import * as React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import _ from "underscore";
import { MenuItem, MenuItemProps } from '../../menu/menuitem';
import { Customer } from '../../../store/customers/CustomerEntities';
import { ConnectedCustomerForm } from '../connected/connected.form';
import { ConnectedCustomerSearchForm } from '../connected/connected.searchform';
import { ConnectedCustomersList } from '../connected/connected.list';
import { BaseList } from '../../base/baseList';
import { BodyHeader } from "../../base/bodyheader";
import { GPReader } from "../../base/qrreader";
import { GpModel } from "../../../utils/commons";

export interface CustomerSelectionModalProps {
    selectedCustomers?: Array<Customer>;
    isModalOpen: boolean;
}

export interface CustomerSelectionCallbacks {
    onConfirm: () => void;
    onSave: () => void;
    toogleModal: () => void;
    onEdit: (id?: string) => JQueryPromise<boolean>;
    onSelection: (id?: string) => void;
    onViewList: () => JQueryPromise<boolean>;
    onRemoveSelected: (id: string) => void;
    toogleQRReader: () => void;
    onScan: (data: GpModel) => void;
}

enum ViewType {
    List,
    SelectedList,
    Edit,
    Create
}

interface CustomerSelectionState {
    viewType: ViewType;
}

export class CustomerSelectionModal extends React.Component<CustomerSelectionModalProps & CustomerSelectionCallbacks, CustomerSelectionState> {

    constructor(props) {
        super(props);
        this.state = {
            viewType: ViewType.List
        }
    }

    private switchView(viewType: ViewType) {

        if (viewType == ViewType.List) {
            this.props.onViewList().then(res => {
                if (res == true)
                    this.setState({ viewType: viewType });
            });
            return;
        }

        this.setState({ viewType: viewType });
    }

    private getSelectedList(): JSX.Element[] {
        return this.props.selectedCustomers.map(customer => {
            return (
                <tr key={customer.id} style={{ cursor: "pointer" }} onClick={() => { }}>
                    <td>
                        {customer.lastName} {customer.firstName}
                    </td>
                    <td>
                        {customer.phone}
                    </td>
                    <td>
                        <div>
                            <span onClick={() => this.editCustomer(customer.id)} style={{ height: "25px", width: "25px", display: "inline-block", position: "relative", marginRight: "10px" }}>
                                <i style={{ position: "relative", left: "5px" }} className="fas fa-pen fa-1x"></i>
                            </span>
                            <span onClick={() => this.props.onRemoveSelected(customer.id)} style={{ height: "25px", width: "25px", display: "inline-block", position: "relative" }}>
                                <i style={{ position: "relative", left: "5px" }} className="fas fa-trash fa-1x"></i>
                            </span>
                        </div>
                    </td>
                </tr >
            );
        });
    }

    private getHead(): JSX.Element {

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>Nome</th>
                    <th>Tel.</th>
                    <th>Azioni</th>
                </tr>
            </thead>
        );

    }

    private getBody(): JSX.Element {

        switch (this.state.viewType) {
            case ViewType.List:
                return (
                    <React.Fragment>
                        <ConnectedCustomerSearchForm key="search-form" />
                        <ConnectedCustomersList key="list" selectionEnabled={true} onRowClick={this.props.onSelection} />
                    </React.Fragment>
                );
            case ViewType.Create:
            case ViewType.Edit:
                const menuItems = [{
                    icon: "qrcode",
                    label: "GP",
                    isButton: true,
                    isDisabled: false,
                    onClick: () => { this.props.toogleQRReader() }
                } as MenuItemProps]
                return (
                    <React.Fragment>
                        <BodyHeader title="Leggi QR" menuItems={menuItems} />
                        <GPReader onScan={(data: GpModel) => { this.props.onScan(data) }} />
                        <ConnectedCustomerForm isSaveButtonEnabled={false} />
                    </React.Fragment>
                );
            case ViewType.SelectedList:
                return (
                    <React.Fragment>
                        <BaseList
                            hasNextPage={false}
                            list={this.props.selectedCustomers}
                            getHead={() => this.getHead()}
                            getElements={(list) => this.getSelectedList()}
                            active={false}
                        />
                    </React.Fragment>
                )
        }

        return null;
    }

    private editCustomer(id: string) {

        this.props.onEdit(id).then(x => {
            if (x == true)
                this.switchView(ViewType.Edit);
        });
    }

    private createCustomer() {
        this.props.onEdit(null).then(x => {
            if (x == true)
                this.switchView(ViewType.Create);
        });
    }

    private getButtons(): JSX.Element {
        switch (this.state.viewType) {
            case ViewType.SelectedList:
                return (
                    <React.Fragment>
                        <MenuItem isSmall={true} isButton={true} icon="bars" label="Lista" onClick={() => this.switchView(ViewType.List)} />
                        <MenuItem isSmall={true} isButton={true} icon="plus" label="Crea" onClick={() => this.createCustomer()} />
                        <MenuItem isSmall={true} isButton={true} icon="check" label="Conferma" onClick={() => { this.props.onConfirm(); this.props.toogleModal(); }} />
                    </React.Fragment>
                );
            case ViewType.List:
                return (
                    <React.Fragment>
                        <MenuItem isSmall={true} isButton={true} icon="user-check" label={this.props.selectedCustomers !== undefined && this.props.selectedCustomers != null ? this.props.selectedCustomers.length.toString() : "0"} onClick={() => this.switchView(ViewType.SelectedList)} />
                        <MenuItem isSmall={true} isButton={true} icon="plus" label="Crea" onClick={() => { this.props.onEdit(null); this.switchView(ViewType.Create); }} />
                        <MenuItem isSmall={true} isButton={true} icon="check" label="Conferma" onClick={() => { this.props.onConfirm(); this.props.toogleModal(); }} />
                    </React.Fragment>
                );
            case ViewType.Create:
            case ViewType.Edit:
                return (
                    <React.Fragment>
                        <MenuItem isSmall={true} isButton={true} icon="user-check" label={this.props.selectedCustomers !== undefined && this.props.selectedCustomers != null ? this.props.selectedCustomers.length.toString() : "0"} onClick={() => this.switchView(ViewType.SelectedList)} />
                        <MenuItem isSmall={true} isButton={true} icon="bars" label="Lista" onClick={() => this.switchView(ViewType.List)} />
                        <MenuItem isSmall={true} isButton={true} icon="save" label="Salva" onClick={() => this.props.onSave()} />
                    </React.Fragment>
                );
        }

        return null;
    }

    render() {

        let body = this.getBody();
        let buttons = this.getButtons();
        return (
            <Modal className="bg-dark modal-lg" isOpen={this.props.isModalOpen} toggle={() => this.props.toogleModal()} backdrop="static">
                <ModalHeader className="text-eurorounders bg-dark" toggle={() => this.props.toogleModal()}>Selezione Clienti</ModalHeader>
                <ModalBody className="bg-dark text-eurorounders">
                    {body}
                </ModalBody>
                <ModalFooter>
                    {buttons}
                    <MenuItem isSmall={true} isButton={true} icon="times" label="Annulla" onClick={() => this.props.toogleModal()} />
                </ModalFooter>
            </Modal>
        );
    }
}