import * as React from "react";
import { Row, Col } from "reactstrap";
import { AgentRole } from "../../../store/agents/AgentEntities";
import { List } from '../../base/list';
import _ from "underscore";

export interface ReservationsListHeaderProps {
    agentRoles: Array<AgentRole>;
}

export class ReservationsListHeader extends React.Component<ReservationsListHeaderProps>{

    constructor(props) {
        super(props);
    }

    private getHead(): JSX.Element {

        let agentRoles = this.props.agentRoles.map(ar => {
            return (<div key={ar.id}>{ar.roleDescription}</div>)
        });

        return (
            <Row key="list-header" className="text-eurorounders list-row header">
                <Col lg="2" sm="2" xs="3">
                    <div className="reservation-table-cell">
                        <div>Id</div>
                        <div>Nome</div>
                        <div>Cognome</div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="3">
                    <div className="reservation-table-cell">
                        <div>Check In</div>
                        <div>Check Out</div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="4">
                    <div className="reservation-table-cell">
                        <div>Hotel</div>
                        <div>Camera</div>
                    </div>
                </Col>
                <Col lg="2" sm="2" xs="2" className="d-none d-lg-block">
                    <div className="reservation-table-cell">
                        {agentRoles}
                    </div>
                </Col>
                <Col lg="1" md="2" sm="2" xs="2" className="d-none d-sm-block">
                    <div className="reservation-table-cell">
                        <div>Tipo</div>
                        <div>Saldo</div>
                    </div>
                </Col>
                <Col lg="2" md="3" sm="3" xs="2" className="d-none d-sm-block">
                    <div className="reservation-table-cell">
                        <div>Stato</div>
                        <div>Check in</div>
                    </div>
                </Col>
                <Col lg="1" sm="1" xs="2">
                    <div className="reservation-table-cell">
                        <div>Info</div>
                    </div>
                </Col>
            </Row>
        );

    }



    render() {

        return (
            <React.Fragment>
                <List justHead getHead={() => { return this.getHead() }} />
            </React.Fragment>
        )
    }
}