import * as React from "react";
import { MenuItem, MenuItemProps } from '../../menu/menuitem';
import { Form, FormGroup, Row, Col, Input, Label } from "reactstrap";
import { ConnectedCommunicationsSelectionList } from '../connected/connected.selectionlist';
import { BodyHeader } from '../../base/bodyheader';
import { ConfirmationModal } from '../../base/confirmationmodal';
import { ConnectedMainDocuments } from '../../documents/connected.maindocuments';
import { UnsavedChanges } from "../../base/unsavedchanges";

enum CommunicationViewType {
    List,
    Message
}

export interface MessageCreationProps {
    message: string;
    locationName: string;
    moduleLoaded: boolean;
    messageSentId: string;
    eventFullName: string;
}

export interface MessageCreationCallbacks {
    onSubmit: () => void;
    onCreate: (locationId: string) => void;
    onEdit: (messageId: string) => void;
    onGenerateMessage: () => void;
}

interface MessageCreationState {
    viewType: CommunicationViewType;
}

export class MessageCreation extends React.Component<MessageCreationProps & MessageCreationCallbacks, MessageCreationState>{
    private modalRef: React.RefObject<ConfirmationModal>;
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.state = {
            viewType: (this.props as any).match.params.actionToPerform == "create" ? CommunicationViewType.List : CommunicationViewType.Message
        }
    }

    componentDidMount() {

        if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null) {
            if ((this.props as any).match.params.actionToPerform == "create") {
                this.props.onCreate((this.props as any).match.params.id);
            } else if ((this.props as any).match.params.actionToPerform == "edit") {
                this.props.onEdit((this.props as any).match.params.id);
            }
        }
    }

    private copyToClipboard() {
        var textField = document.createElement('textarea');
        textField.textContent = this.props.message;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();

        if ((this.props as any).match.params.actionToPerform == "create" || this.props.messageSentId === undefined || this.props.messageSentId == null) {
            this.modalRef.current.toogleModal();
        }
    }

    private generateMessage() {
        this.toogleView(CommunicationViewType.Message);

        if ((this.props as any).match.params.actionToPerform == "create" || this.props.messageSentId === undefined || this.props.messageSentId == null) {
            this.props.onGenerateMessage();
        }
    }

    private toogleView(viewType: CommunicationViewType) {
        this.setState({ viewType: viewType });
    }

    private getMessageForm(): JSX.Element {

        return (
            <React.Fragment>
                <Form className="text-eurorounders" >
                    <Row>
                        <Col lg="12" sm="12" xs="12">
                            <FormGroup>
                                <Label>Messagio</Label>
                                <Input rows="12" className="bg-dark text-eurorounders"  type="textarea" value={this.props.message || ''} readOnly />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <MenuItem isSmall={true} isButton={true} icon="bars" label="Prenotaz." onClick={() => this.toogleView(CommunicationViewType.List)} />
                                <MenuItem isSmall={true} isButton={true} icon="copy" label="Copia" onClick={() => this.copyToClipboard()} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        )
    }

    private getList(): JSX.Element {
        return (
            <React.Fragment>
                <ConnectedCommunicationsSelectionList selectionEnabled={(this.props as any).match.params.actionToPerform == "create" ? true : false} />
                <Form className="text-eurorounders" >
                    <Row>
                        <Col>
                            <FormGroup>
                                <MenuItem isSmall={true} isButton={true} icon="envelope-open" label="messaggio" onClick={() => this.generateMessage()} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment >
        );
    }

    render() {

        if (this.props.moduleLoaded != true)
            return (<React.Fragment></React.Fragment>);

        let pageTitle = `Crea messaggio per ${this.props.locationName}`;

        if ((this.props as any).match.params.actionToPerform == "edit")
            pageTitle = `Vedi messaggio inviato a ${this.props.locationName}`;

        let runningElement = null;

        if (this.state.viewType == CommunicationViewType.List)
            runningElement = this.getList();
        else
            runningElement = this.getMessageForm();

        let documents = null;
        if (this.props.messageSentId !== undefined && this.props.messageSentId != null)
            documents = (<ConnectedMainDocuments objectId={this.props.messageSentId} objectName={`${this.props.locationName} `} />);

        let menuItems = [];

        menuItems.push({
            icon: "ellipsis-h",
            label: "Evento",
            isButton: false,
            isSmall: true,
            path: `/events/${(this.props as any).match.params.eventId}/menu`
        } as MenuItemProps);

        menuItems.push({
            icon: "list",
            label: "mess.",
            isButton: false,
            isSmall: true,
            path: `/events/${(this.props as any).match.params.eventId}/communications`
        } as MenuItemProps);

        return (
            <React.Fragment>
                <UnsavedChanges confirmAction={this.props.onSubmit} />
                <BodyHeader subTitle={this.props.eventFullName} bottomSpace title={pageTitle} menuItems={menuItems} />
                {runningElement}
                <ConfirmationModal text="Confermi che sarà inviato il messaggio?" onConfirm={() => this.props.onSubmit()} ref={this.modalRef} />
                {documents}
            </React.Fragment>
        )
    }
}