import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { Profile, Account } from '../../../store/profile/Profile';
import { actionCreator } from '../../../store/profile/ProfilesSate';
import { actionCreator as actionCreatorsUI } from '../../../store/uiproperties/UIState';
import { ProfileList, ProfileListProps } from '../profilelist';

const mapStateToProsp = (state: ApplicationState): ProfileListProps => {

    return {
        hasNextPage: state.profiles.searchState.hasNextPage,
        list: state.profiles.profilesList,
        startLoading: state.uiProperties.startFetchingPagedList
    }
}

const mapDispatchToProps = () => dispatch => {
    return ({
        loadNewPage: () => {
            dispatch(actionCreator.loadNextPage());
        },
        onRowClick: (profileId: string) => {
            dispatch(actionCreator.navigateToRunningProfile(profileId));
        },
        onUnload: () => dispatch(actionCreatorsUI.startFetchingPagedList(false))
    });
}

export const ConnectedProfileList = connect<ProfileListProps>(mapStateToProsp, mapDispatchToProps)(ProfileList);