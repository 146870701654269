import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionMiddleware } from '../../../store/reservations/ReservationState';
import { Reservation } from '../../../store/reservations/ReservationEntities';
import { ReservationQuoteList, ReservationQuoteListProps, ReservationQuoteListCallbacks } from '../basequote/quotelist';


const mapStateToProsp = (state: ApplicationState): ReservationQuoteListProps => {

    return {
        list: state.reservationQuote.list,
        searchPerformed: state.reservationQuote.searchPerformed,
        locations: state.events.runningEventLocations,
        roomTypes: state.locations.roomTypes
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): ReservationQuoteListCallbacks => {
    return ({
        create: (reservation: Reservation) => dispatch(actionMiddleware.setRunningStateFromQuote(reservation))
    });
}

export const ConnectedReservationQuoteList = connect<ReservationQuoteListProps, ReservationQuoteListCallbacks>(mapStateToProsp, mapDispatchToProps)(ReservationQuoteList);