import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ApplicationState } from '../../../store/index';
import { actionCreator } from '../../../store/agents/AgentsState';
import { actionCreator as actionCreatorsUI } from '../../../store/uiproperties/UIState';
import { AgentEditPage, AgentEditPageProps } from '../pages/agenteditpage';

const mapStateToProps = (state: ApplicationState): AgentEditPageProps => {
    return {
        isNew: state.agents.runningAgent == undefined || state.agents.runningAgent == null || state.agents.runningAgent.id === undefined || state.agents.runningAgent.id == null || state.agents.runningAgent.id == '',
        pageLoaded: state.agents.moduleLoaded,
        agent: state.agents.runningAgent
    }
}

const mapDispatchToProps = () => dispatch => {
    return {
        onLoad: (id: string) => {
            dispatch(actionCreator.loadRunningAgent(id));
        },
        onDelete: () => {
            dispatch(actionCreator.delete());
        },
        onSubmit: () => dispatch(actionCreator.saveAgent())
    }
}

export const ConnectedAgentEditPage = withRouter(connect<AgentEditPageProps>(mapStateToProps, mapDispatchToProps)(AgentEditPage))