import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionMiddleware, actionCreator } from '../../../store/reservations/ReservationState';
import { actionCreator as uiActions } from '../../../store/uiproperties/UIState';
import { ReservationsList, ReservationsListCallbacks, ReservationsListProps } from '../base/list';


const mapStateToProsp = (state: ApplicationState): ReservationsListProps => {

    return {
        hasNextPage: state.reservations.searchCriteria.hasNextPage,
        list: state.reservations.list,
        agentRoles: state.reservations.agentRoles,
        startLoading: state.uiProperties.startFetchingPagedList,
        statusDesc: state.reservations.reservationStatusDesc,
        typeDesc: state.reservations.reservationTypeDesc,
        lastClieckedId: state.reservations.lastClickedId,
        lastHighlightedElement: state.reservations.lastHighlightedElement
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): ReservationsListCallbacks => {
    return ({
        loadNewPage: () => {
            dispatch(actionMiddleware.loadNextPage());
        },
        onRowClick: (id: string) => {
            dispatch(actionMiddleware.onNavigate(id));
        },
        resetLastClicledElement: () => {
            dispatch(actionCreator.resetLastClieckedId());
        },
        onUnload: () => dispatch(uiActions.startFetchingPagedList(false))
    });
}

export const ConnectedReservationsList = connect<ReservationsListProps, ReservationsListCallbacks>(mapStateToProsp, mapDispatchToProps)(ReservationsList);