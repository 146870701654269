import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/pricings/PricingState';
import HotelsPricingListPage, { HotelsPricingListCallbacks, HotelsPricingListProps } from '../pages/hotelspricepage';

const mapStateToProsp = (state: ApplicationState): HotelsPricingListProps => {
    return {
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name.toUpperCase()}` : ''
    } as HotelsPricingListProps;
}

const mapDispatchToProps = (dispatch) => (dispatch): HotelsPricingListCallbacks  => {
    return {
        onLoad: (eventId: string) => dispatch(actionsMiddleware.loadInitialState(eventId))
    };
}

export const ConnectedHotelPriceListPage = withRouter(connect<HotelsPricingListProps, HotelsPricingListCallbacks>(mapStateToProsp, mapDispatchToProps)(HotelsPricingListPage));