import * as React from "react";

import './gototop.css';


export default class GoToTop extends React.Component{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <div className="text-eurorounders on-top-button bg-dark" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
                    <div>
                        <i className="icon fas fa-angle-up fa-2x" />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}