import * as CurrentProfile from './profile/CurrentProfile';
import * as UiProperties from './uiproperties/UIState';
import * as Profiles from './profile/ProfilesSate';
import * as Roles from './profile/RolesState';
import * as Agents from './agents/AgentsState';
import * as Customers from './customers/CustomerState';
import * as Locations from './locations/LocationState';
import * as Events from './events/EventState';
import * as Pricings from './pricings/PricingState';
import * as Reservations from './reservations/ReservationState';
import * as ReservationQuote from './reservations/ReservationQuoteState';
import * as Communication from './communications/CommunicationState';
import * as Documents from './documents/DocumentState';
import * as Entries from './entries/EntryState';
import * as EventAccountings from './eventaccountings/EventAccountingState';
import * as KpiEvent from './kpis/KpiState';
import * as LocationGroup from './locations/groups/LocationGroupState';
import * as PaymentMethods from './payments/paymentmethods/PaymentMethodState';
import * as PaymentItems from './payments/paymentitems/PaymentItemState';
import * as CashRegister from './payments/cashregister/CashRegisterState';
import * as Payment from './payments/payments/PaymentState';
import * as Movements from './payments/movements/MovementsState';
import * as AgentsPayments from './payments/agentspayments/AgentsPaymentsState';
import * as HotelsAccounting from './eventaccountings/HotelsAccountingState';
import * as AccountingSummary from './payments/accountingsummary/AccountingSummaryState';


// The top-level state object
export interface ApplicationState {
    currentProfile: CurrentProfile.CurrentProfileState | undefined;
    uiProperties: UiProperties.UiPropertiesState | undefined;
    profiles: Profiles.ProfilesSatate | undefined;
    roles: Roles.RolesState | undefined;
    agents: Agents.AgentsSatate | undefined;
    customers: Customers.CustomerState | undefined;
    locations: Locations.LocationState | undefined;
    events: Events.EventState | undefined;
    pricings: Pricings.PricingState | undefined;
    reservations: Reservations.ReservationState | undefined;
    reservationQuote: ReservationQuote.ReservationQuoteState | undefined;
    communications: Communication.CommunicationState | undefined;
    documents: Documents.DocumentState | undefined;
    entries: Entries.EntryState | undefined;
    eventAccountings: EventAccountings.EventAccountingState | undefined;
    kpis: KpiEvent.KpiState | undefined;
    locationGroups: LocationGroup.LocationGroupsState | undefined;
    paymentMethods: PaymentMethods.PaymentMethodState | undefined;
    paymentItems: PaymentItems.PaymentItemState | undefined;
    cashRegister: CashRegister.CashRegisterState | undefined;
    payment: Payment.PaymentState | undefined;
    movements: Movements.MovementState | undefined;
    agentsPayments: AgentsPayments.AgentsPaymentsState | undefined;
    hotelsAccounting: HotelsAccounting.EventAccountingV2State | undefined;
    accountingSummary: AccountingSummary.AccountingSummaryState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    currentProfile: CurrentProfile.reducer,
    uiProperties: UiProperties.reducer,
    profiles: Profiles.reducer,
    roles: Roles.reducer,
    agents: Agents.reducer,
    customers: Customers.reducer,
    locations: Locations.reducer,
    events: Events.reducer,
    pricings: Pricings.reducer,
    reservations: Reservations.reducer,
    reservationQuote: ReservationQuote.reducer,
    communications: Communication.reducer,
    documents: Documents.reducer,
    entries: Entries.reducer,
    eventAccountings: EventAccountings.reducer,
    kpis: KpiEvent.reducer,
    locationGroups: LocationGroup.reducer,
    paymentMethods: PaymentMethods.reducer,
    paymentItems: PaymentItems.reducer,
    cashRegister: CashRegister.reducer,
    payment: Payment.reducer,
    movements: Movements.reducer,
    agentsPayments: AgentsPayments.reducer,
    hotelsAccounting: HotelsAccounting.reducer,
    accountingSummary: AccountingSummary.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
