import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { TotalAccounting, TotalAccountingProps } from '../base/accountingdetails/totalaccounting';


const mapStateToProsp = (state: ApplicationState): TotalAccountingProps => {

    return {
        accounting: state.eventAccountings.running
    }
}



export const ConnectedTotalAccountingList = connect<TotalAccountingProps>(mapStateToProsp, {})(TotalAccounting);