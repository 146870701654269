import * as React from "react";
import { ConfirmationModal } from '../base/confirmationmodal';
import { MenuItem } from '../menu/menuitem';
import { Document } from '../../store/documents/DocumentsEntities';
import { BaseList } from '../base/baseList';
import { Row, Col } from "reactstrap"
import { ConnectedEditDocuments } from './connected.editmodal';

import './maindocuments.css';

export interface MainDocumentsProps {
    objectId: string;
    objectName: string;
    list: Array<Document>;
}

export interface MainDocumentsCallbacks {
    onUpload: (file: FormData, objectId: string, objectName: string) => void;
    onLoad: (objectId: string, objectName: string) => void;
    onDelete: (id: string, objectId: string) => void;
    onOpenEditModal: (id: string, objectId: string, name: string) => void;
    onDownloadDocument: (id: string, name: string, extension: string) => void;
}

interface MainDocumentsState {
    clickedDocId?: string;
}

export class MainDocument extends React.Component<MainDocumentsProps & MainDocumentsCallbacks, MainDocumentsState> {

    private deleteConfirmation: React.RefObject<ConfirmationModal>;

    constructor(props) {
        super(props);

        this.deleteConfirmation = React.createRef();

        this.state = {
            clickedDocId: null
        };
    }


    componentDidMount() {
        if (this.props.objectId !== undefined && this.props.objectId != null) {
            this.props.onLoad(this.props.objectId, this.props.objectName);
        }
    }

    private getRows(list: Array<Document>): JSX.Element[] {

        let customerArray = list.map(doc => {
            
            return (
                <tr key={doc.id}>
                    <td>
                        {doc.name}
                    </td>
                    <td>
                        {doc.extension}
                    </td>
                    <td>
                        <div>
                            <span onClick={() => this.props.onDownloadDocument(doc.id, doc.name, doc.extension)} style={{ height: "25px", width: "25px", display: "inline-block", position: "relative", marginRight: "10px", cursor: "pointer" }}>
                                <i style={{ position: "relative", left: "5px" }} className="fas fa-file-download fa-1x"></i>
                            </span>
                            <span onClick={() => this.props.onOpenEditModal(doc.id, doc.objectId, doc.name)}  style={{ height: "25px", width: "25px", display: "inline-block", position: "relative", marginRight: "10px", cursor: "pointer" }}>
                                <i style={{ position: "relative", left: "5px" }} className="fas fa-pen fa-1x"></i>
                            </span>
                            <span onClick={() => this.openModal(doc.id)} style={{ height: "25px", width: "25px", display: "inline-block", position: "relative", cursor: 'pointer' }}>
                                <i style={{ position: "relative", left: "5px" }} className="fas fa-trash fa-1x"></i>
                            </span>
                        </div>
                    </td>
                </tr >
            )
        });

        return customerArray;

    }

    private getHead(): JSX.Element {

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>Nome</th>
                    <th>Tipo</th>
                    <th>Azioni</th>
                </tr>
            </thead>
        );

    }

    private onDelete() {
        this.props.onDelete(this.state.clickedDocId, this.props.objectId);
        this.deleteConfirmation.current.toogleModal();
    }

    private openModal(id: string) {
        this.setState({ clickedDocId: id });
        this.deleteConfirmation.current.toogleModal();
    }

    private onCloseModal() {
        this.setState({ clickedDocId: null });
    }

    render() {
        
        if (this.props.objectId === undefined || this.props.objectId == null) {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }


        return (
            <React.Fragment>
                <hr />
                <Row className={`documents-hedare-container`}>
                    <Col lg={{ size: 6 }} sm={{ size: 6 }} xs={{ size: 12 }}>
                        <h4 className="text-eurorounders">
                            Allegati
                        </h4>
                    </Col>
                    <Col lg={{ size: 6 }} sm={{ size: 6 }} xs={{ size: 12 }}>
                        <div className="right-side-documents-header-container">
                            <MenuItem icon="file-upload" label="upload" isFileUploader isSmall onFileUpload={(file: FormData) => this.props.onUpload(file, this.props.objectId, this.props.objectName)} />
                        </div>
                    </Col>
                </Row>
                <BaseList hasNextPage={false} list={this.props.list} active={false} onEndOfList={() => { }} getElements={(list: Array<Document>) => { return this.getRows(list) }} getHead={() => { return this.getHead() }} />
                <ConfirmationModal ref={this.deleteConfirmation} onConfirm={() => this.onDelete()} text="Eliminare allegato?" confirmIcon="save" onClosed={() => this.onCloseModal()} />
                <ConnectedEditDocuments />
            </React.Fragment>
        );
    }
}