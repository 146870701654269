import * as React from "react";
import { Profile, CommissionTemplateModeTraslation } from "../../../store/profile/Profile";
import { BaseList } from '../../base/baseList';
import { CheckBox } from '../../base/checkbox';
import GoToTop from "../../base/gototop";

export interface AgentsListProps {
    selectionEnabled: boolean;
    selectedAgentId?: string;
    list: Array<Profile>;
    hasNextPage: boolean;
    loadNewPage?: () => void;
    startLoading?: boolean;
    onRowClick?: (profileId: string) => void;
    onUnload?: () => void;
    restartLoading?: () => void;
}

export class AgentsList extends React.Component<AgentsListProps>{

    constructor(props) {
        super(props);
    }

    componentDidUpdate() {
        if (this.props.list.length > 0 && this.props.hasNextPage && !this.props.startLoading) {
            this.props.restartLoading();
        }
    }

    private getRows(agentsList: Array<Profile>): JSX.Element[] {

        let profileArray = agentsList.map(agent => {

            let commisionTemplate = agent.commisionTemplate !== undefined && agent.commisionTemplate != null ? `${agent.commisionTemplate.type} ${CommissionTemplateModeTraslation[agent.commisionTemplate.mode]}` : 'not set';

            let checkbox = (<React.Fragment></React.Fragment>);

            if (this.props.selectionEnabled == true) {
                checkbox = (
                    <td>
                        <CheckBox isChecked={agent.id == this.props.selectedAgentId} onCheck={(checked: boolean) => { }} />
                    </td>
                )
            }

            return (
                <tr key={agent.id} style={{ cursor: "pointer" }} onClick={() => this.props.onRowClick(agent.id)}>
                    {checkbox}
                    <td>
                        {agent.lastName} {agent.firstName}
                    </td>
                    <td className="d-none d-md-table-cell">
                        {agent.email}
                    </td>
                    <td className="d-none d-md-table-cell">
                        {agent.phone}
                    </td>
                    <td>
                        {commisionTemplate}
                    </td>
                </tr >
            )
        });

        return profileArray;

    }

    private getHead(): JSX.Element {

        let thSelection = (<React.Fragment></React.Fragment>);

        if (this.props.selectionEnabled == true) {
            thSelection = (<th>Sel.</th>);
        }

        return (
            <thead>
                <tr className="text-eurorounders">
                    {thSelection}
                    <th>Nome</th>
                    <th className="d-none d-md-table-cell">Email</th>
                    <th className="d-none d-md-table-cell">Tel.</th>
                    <th>Provvigione</th>
                </tr>
            </thead>
        );

    }


    render() {

        return (
            <React.Fragment>
                <BaseList onUnload={this.props.onUnload} hasNextPage={this.props.hasNextPage} list={this.props.list} active={this.props.startLoading} onEndOfList={this.props.loadNewPage} getElements={(agentsList: Array<Profile>) => { return this.getRows(agentsList) }} getHead={() => { return this.getHead() }} />
                <GoToTop />
            </React.Fragment>
        )
    }
}