import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/eventaccountings/EventAccountingState';
import { EventAccountingList, EventAccountListProps, EventAccountListCallbacks } from '../base/list';


const mapStateToProsp = (state: ApplicationState): EventAccountListProps => {

    return {
        list: state.eventAccountings.list
    }
}

const mapDispatchToProps = () => (dispatch): EventAccountListCallbacks => {
    return {
        onRowClick: (id) => dispatch(actionsMiddleware.onNavigate(id))
    }
}



export const ConnectedEventAccountingsList = connect<EventAccountListProps, EventAccountListCallbacks>(mapStateToProsp, mapDispatchToProps)(EventAccountingList);