import * as React from "react";
import { Event } from "../../../store/events/EventEntities";
import { KeyDesc } from "../../../store/shared/KeyDesc";
import { BaseList } from '../../base/baseList';
import { CheckBox } from '../../base/checkbox';
import _ from "underscore";
import moment from "moment";
import GoToTop from "../../base/gototop";

export interface EventsListProps {
    selectionEnabled: boolean;
    selectedElements?: Array<string>;
    list: Array<Event>;
    hasNextPage: boolean;
    startLoading?: boolean;
}

export interface EventsListCallbacks {
    onRowClick: (id: string) => void;
    loadNewPage: () => void;
    onUnload: () => void;
}

export class EventsList extends React.Component<EventsListProps & EventsListCallbacks>{

    constructor(props) {
        super(props);
    }

    private getRows(list: Array<Event>): JSX.Element[] {

        let renderList = list.map(model => {

            let checkbox = (<React.Fragment></React.Fragment>);

            if (this.props.selectionEnabled == true) {

                let isSelected = false;

                if (this.props.selectedElements !== undefined &&
                    this.props.selectedElements != null) {
                    let exists = _.findIndex(this.props.selectedElements, (e) => e == model.id)

                    if (exists > -1)
                        isSelected = true;

                }
                checkbox = (
                    <td>
                        <CheckBox isChecked={isSelected} onCheck={(checked: boolean) => { }} />
                    </td>
                )
            }

            let start = "-";
            if (model.dateFrom !== undefined && model.dateFrom != null) {
                let momentDate = moment(model.dateFrom);
                if (momentDate !== undefined && momentDate != null) {
                    start = momentDate.format("L");
                }
            }

            let end = "-";
            if (model.dateTo !== undefined && model.dateTo != null) {
                let momentDate = moment(model.dateTo);
                if (momentDate !== undefined && momentDate != null) {
                    end = momentDate.format("L");
                }
            }

            let location = "-";
            if (model.location !== undefined && model.location != null) {
                location = model.location.name;
            }

            return (
                <tr key={model.id} style={{ cursor: "pointer" }} onClick={() => this.props.onRowClick(model.id)}>
                    {checkbox}
                    <td>
                        {model.type}
                    </td>
                    <td>
                        {model.name}
                    </td>
                    <td>
                        {start}
                    </td>
                    <td>
                        {end}
                    </td>
                    <td className="d-none d-md-table-cell">
                        {location}
                    </td>
                </tr >
            )
        });

        return renderList;

    }

    private getHead(): JSX.Element {

        let thSelection = (<React.Fragment></React.Fragment>);

        if (this.props.selectionEnabled == true) {
            thSelection = (<th>Sel.</th>);
        }

        return (
            <thead>
                <tr className="text-eurorounders">
                    {thSelection}
                    <th>Tipo</th>
                    <th>Nome</th>
                    <th>Inizio</th>
                    <th>Fine</th>
                    <th className="d-none d-md-table-cell">Location</th>
                </tr>
            </thead>
        );

    }


    render() {

        return (
            <React.Fragment>
                <BaseList onUnload={this.props.onUnload} hasNextPage={this.props.hasNextPage} list={this.props.list} active={this.props.startLoading} onEndOfList={this.props.loadNewPage} getElements={(list: Array<Event>) => { return this.getRows(list) }} getHead={() => { return this.getHead() }} />
                <GoToTop />
            </React.Fragment>
        )
    }
}