import { Action, Reducer } from 'redux';
import { ApplicationState } from '../';
import { ajaxRequest, RequestMethods, abortAllRequest, AbortableRequest } from '../../utils/ajaxutilities';
import dotProp from "dot-prop";
import { CoreResponse } from '../serverresponse/serverresponse';
import _ from "underscore";
import { EventAccountingV2 } from './EventAccountingEntities';
import $ from "jquery";


export interface EventAccountingV2State {
    running: EventAccountingV2;
}

const getBaseState = (): EventAccountingV2State => ({
    running: {
        model: {
            hotelsAccounting: []
        }
    } as EventAccountingV2
});


enum TypeKeys {
    RESET_STATE = "HOTELSACCOUNTING_RESET_STATE",
    SET_RUNNING = "HOTELSACCOUNTING_SET_RUNNING"
}

type EventAccountingV2Action =
    ResetState
    | SetRunning;


interface ResetState extends Action {
    type: TypeKeys.RESET_STATE;
}


interface SetRunning extends Action {
    type: TypeKeys.SET_RUNNING
    model: EventAccountingV2;
}

const actionCreator = {
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    setRunning: (model: EventAccountingV2): SetRunning => ({
        type: TypeKeys.SET_RUNNING,
        model: model
    })
}

export const actionsMiddleware = {
    loadInitialState: (eventId: string) => (dispatch, getState) => {
        abortAllRequest();
        dispatch(actionCreator.resetState());
        dispatch(actionsMiddleware.load(eventId));
    },
    load: (eventId: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<CoreResponse<EventAccountingV2>> => {
        let deferred = $.Deferred();

        let request: AbortableRequest = new AbortableRequest(`/accountings/hotels/${eventId}`, RequestMethods.GET);

        ajaxRequest<EventAccountingV2>(request)(dispatch, getState).then(response => {

            if (response.success == false) {
                deferred.resolve(response);
                return deferred.promise();
            }

            dispatch(actionCreator.setRunning(response.entity));
            deferred.resolve(response);

        });

        return deferred.promise();
    }
}


export const reducer: Reducer<EventAccountingV2State> = (state: EventAccountingV2State | undefined, incomingAction: EventAccountingV2Action): EventAccountingV2State => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.RESET_STATE:
            newState = { ...getBaseState() };
            break;
        case TypeKeys.SET_RUNNING:
            newState = dotProp.set(newState, "running", incomingAction.model);
            break;
    }

    return newState;
}