import * as React from "react";
import { MenuItemProps } from '../../menu/menuitem';
import { BodyHeader } from '../../base/bodyheader';
import { StickyBodyHeader } from "../../base/stickybodyheader";
import { MovementsListHeader } from "./base/listheader";
import { ConnectedMovementsList } from "./connected/connected.list";
import { ConnectedMovementsSearchForm } from "./connected/connected.searchform";

export interface MovementsPageListProps {
    totalCount?: number;
    eventFullName: string;
}

export interface MovementsPageListCallbacks {
    onLoad: (eventId: string) => void;
}

export class MovementsPageList extends React.Component<MovementsPageListProps & MovementsPageListCallbacks> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let eventId = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                eventId = (this.props as any).match.params.eventId;
            this.props.onLoad(eventId);
        }
    }



    render() {

        let eventId = "";
        if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
            eventId = (this.props as any).match.params.eventId;

        let menuItems = [
            {
                icon: "hand-holding-usd",
                label: "Conto",
                isButton: false,
                isSmall: true,
                path: `/events/${eventId}/v2/accounting`
            } as MenuItemProps
        ] as Array<MenuItemProps>;

        return (
            <React.Fragment>
                <StickyBodyHeader>
                    <BodyHeader subTitle={this.props.eventFullName} title={`Movimenti (${this.props.totalCount ?? "-"})`} menuItems={menuItems} />
                    <ConnectedMovementsSearchForm />
                    <MovementsListHeader />
                </StickyBodyHeader>
                <ConnectedMovementsList />
            </React.Fragment>
        )
    }
}