import * as React from "react";
import { DailyAvailability, RoomPricing } from "../../../store/pricings/PricingEntities";
import IntegerInput from "../../base/integerinput";
import { Row, Col, Form, FormGroup, Label } from "reactstrap";
import _ from "underscore";
import moment from "moment";

import './roomavailabilityform.css';

export interface RoomAvailabilityFormProps {
    roomPricing?: RoomPricing;
}

export interface RoomAvailabilityFormCallbacks {
    onChangeDay: (date: string, availability?: number) => void;
}


export class RoomAvailabilityForm extends React.Component<RoomAvailabilityFormProps & RoomAvailabilityFormCallbacks>{
    constructor(props) {
        super(props);
    }

    private getRowsForm(): JSX.Element[] {
        let elements = [];

        elements = this.props.roomPricing.dailyAvailabilities.map(daily => {

            let classTextTotal = 'text-eurorounders';
            classTextTotal = daily.totalAvailability >= 0 && daily.totalAvailability <= 5 ? "text-warning" : classTextTotal;
            classTextTotal = daily.totalAvailability < 0 ? "text-danger" : classTextTotal;


            return (
                <div key={daily.day} className="availability-row">
                    <span className="text-eurorounders margin-item">{moment(daily.day).format("L")}</span>
                    <FormGroup className="margin-item input-width">
                        <IntegerInput
                            onChane={(value?: number) => this.props.onChangeDay(daily.day, value)}
                            value={daily.availability}
                        />
                    </FormGroup>
                    <span className={`font-weight-bold ${classTextTotal}`}>{daily.totalAvailability}</span>
                </div>
            )
        })

        return elements;
    }


    render() {

        if (this.props.roomPricing === undefined || this.props.roomPricing == null) {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }

        let elements = this.getRowsForm();

        return (
            <React.Fragment>
                <Form className="text-eurorounders" >
                    <div style={{ marginBottom: "15px" }} className="availability-row">
                        <span style={{ width: "83px" }} className="text-eurorounders margin-item">Giorno</span>
                        <span className="text-eurorounders input-width margin-item">Disponibilità</span>
                        <span className="text-eurorounders">Libere</span>
                    </div>
                    {elements}
                </Form>
            </React.Fragment>
        );

    }
}