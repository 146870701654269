import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/customers/CustomerState';
import { actionCreator } from '../../../store/uiproperties/UIState';
import { CustomersList, CustomersListProps } from '../shared/customerslist';

export interface ConnectedCustomersListProps {
    onRowClick?: (id: string) => void;
    selectionEnabled: boolean;
}

const mapStateToProsp = (state: ApplicationState, ownProps: ConnectedCustomersListProps): CustomersListProps => {

    return {
        hasNextPage: state.customers.searchCriteria.hasNextPage,
        list: state.customers.list,
        startLoading: state.uiProperties.startFetchingPagedList,
        selectionEnabled: ownProps.selectionEnabled,
        selectedCustomers: state.customers.selectedCustomers
    }
}

const mapDispatchToProps = (dispatch, ownProps: ConnectedCustomersListProps) => dispatch => {
    return ({
        loadNewPage: () => {
            dispatch(actionsMiddleware.loadNextPage());
        },
        onRowClick: (agentId: string) => {
            dispatch(ownProps.onRowClick(agentId));
        },
        onUnload: () => dispatch(actionCreator.startFetchingPagedList(false))
    });
}

export const ConnectedCustomersList = connect<CustomersListProps, {}, ConnectedCustomersListProps>(mapStateToProsp, mapDispatchToProps)(CustomersList);