import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/customers/CustomerState';
import { CustomerSearchForm, CustomerSearchFormProps, CustomerSearchFormCallbacks } from '../shared/searchform';
import { actionCreator } from '../../../store/uiproperties/UIState';


const mapStateToProsp = (state: ApplicationState): CustomerSearchFormProps => {

    return {
        searchString: state.customers.searchCriteria.searchString
    }
}

const mapDispatchToProps = () => (dispatch): CustomerSearchFormCallbacks => {
    return ({
        onSearch: (searchString: string) => dispatch(actionsMiddleware.genericSearch(searchString)),
        toogleQRReader: () => dispatch(actionCreator.toogleQrReader()),
    });
}

export const ConnectedCustomerSearchForm = connect<CustomerSearchFormProps, CustomerSearchFormCallbacks>(mapStateToProsp, mapDispatchToProps)(CustomerSearchForm);