import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import { ConnectedHeader } from './components/header/connected.header';
import { ConnectedProtectedRoute } from './components/base/connected.protectedroute';
import { ConnectedErrorMessages } from './components/base/errormessages/connected.errormessages';
import { ConnectedOverlay } from './components/base/modaloverlay/connected.modaloverlay';
import { ConnectedLogin } from './components/login/connected.login';
import { ConnectedMenuRow } from "./components/menu/connected.menurow";
import { ConnectedProfilePageList } from './components/profile/connected/connnected.profilepagelist';
import { ConnectedProfileEditPage } from './components/profile/connected/connected.profileeditpage';
import { ConnectedRolePageList } from './components/profile/roles/connected/connected.rolepagelist';
import { ConnectedRoleEdit } from './components/profile/roles/connected/connected.roleedit';
import { ConnectedAgentsPageList } from './components/agents/connected/connected.agentslistpage';
import { ConnectedAgentEditPage } from './components/agents/connected/connected.agenteditpage';
import { ConnectedCustomersPageList } from './components/customers/connected/connected.customerslistpage';
import { ConnectedCustomerEdit } from './components/customers/connected/connected.edit';
import { ConnectedLocationsPageList } from './components/locations/connected/connected.listpage';
import { ConnectedLocationEditPage } from './components/locations/connected/connected.editpage';
import { ConnectedLocationGroupPageList } from './components/locations/groups/connected/connected.grouppagelist';
import { ConnectedLocationGroupEdit } from './components/locations/groups/connected/connected.groupedit';
import { ConnectedEventsPageList } from './components/events/connected/connected.listpage';
import { ConnectedEventEdit } from './components/events/connected/connected.editpage';
import { ConnectedEventMenu } from './components/events/connected/connected.eventmenu';
import { ConnectedEventAccountingMenu } from './components/events/connected/connected.eventaccountingmenu';
import { ConnectedHotelPriceListPage } from './components/periodpricings/connected/connected.hotelspricepage';
import { ConnectedHotelsPricingEdit } from './components/periodpricings/connected/connected.editpage';
import { ConnectedBookingCheckStatus } from './components/periodpricings/connected/connected.bookingcheckstatus';
import { ConnectedReservationListPage } from './components/reservations/connected/connected.listpage';
import { ConnectedReservationEditPage } from './components/reservations/connected/connected.editpage';
import { ConnectedQuotePage } from './components/reservations/connectedquote/connected.quotepage';
import { ConnectedCommunicationsListPage } from './components/comminucations/connected/connected.listpage';
import { MessageCreationPage } from './components/comminucations/connected/connected.messagecreation';
import { ConnectedEntriesPageList } from './components/entries/connected/connected.listpage';
import { ConnectedEventAccountPageList } from './components/eventaccountings/connected/connected.listpage';
import { ConnectedEventAccountingsDetails } from './components/eventaccountings/connected/connected.deatailspage';
import { ConnectedKpi } from './components/kpis/connected.kpi';
import { EventConfigurations } from './components/events/pages/configurations';
import { PaymentCategory } from './store/payments/paymentitems/PaymentItemEntities';
import { ConnectedPaymentMethodPageList } from './components/payments/paymentmethods/connected/connected.listpage';
import { ConnectedPaymentMethodEdit } from './components/payments/paymentmethods/connected/connected.paymentmethodeditpage';
import { ConnectedPaymentItemPageList } from './components/payments/paymentitems/connected/connected.listpage';
import { ConnectedPaymentItemEdit } from './components/payments/paymentitems/connected/connected.paymentitempage';
import { ConnectedCashRegisterPage } from './components/payments/cashregister/connected/connected.cashregister';
import { ConnectedMovementsPage } from './components/payments/movements/connected/connected.movements';
import { ConnectedAgentsPayments } from './components/eventaccountings/v2/agents/connected.agentspayments';
import { ConnectedHotelsPayments } from './components/eventaccountings/v2/hotels/connected.hotelspayments';
import { ConnectedAccountingSummary } from './components/eventaccountings/v2/summary/connected.accountingsummary';
import Guide from './components/guide/mainguide';

import './custom.css'

export default () => (
    <Layout>
        <ConnectedErrorMessages />
        <ConnectedOverlay />

        <Switch>
            <ConnectedProtectedRoute {...{ path: "/login", exact: true, isLoginPage: true }} >
                <ConnectedLogin />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/", exact: true, isLoginPage: false, isHome: true }} >
                <ConnectedHeader />
                <ConnectedMenuRow {...{ pathToShow: '/' }} />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/profiles", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedProfilePageList />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/profiles/edit/:id", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedProfileEditPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/profiles/create", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedProfileEditPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/profiles/roles", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedRolePageList />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/profiles/roles/edit/:id", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedRoleEdit />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/profiles/roles/create", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedRoleEdit />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/agents", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedAgentsPageList />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/agents/create", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedAgentEditPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/agents/edit/:id", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedAgentEditPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/customers", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedCustomersPageList />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/customers/edit/:id", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedCustomerEdit />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/customers/create", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedCustomerEdit />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/locations", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedLocationsPageList />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/locations/edit/:id", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedLocationEditPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/locations/create", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedLocationEditPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/locations/groups", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedLocationGroupPageList />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/locations/group/create", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedLocationGroupEdit />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/locations/group/edit/:id", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedLocationGroupEdit />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedEventsPageList />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/edit/:id", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedEventEdit />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/create", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedEventEdit />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:id/menu", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedEventMenu />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/hotels", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedHotelPriceListPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/hotels/create", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedHotelsPricingEdit />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/hotels/edit/:id", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedHotelsPricingEdit />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/reservations", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedReservationListPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/reservations/create", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedReservationEditPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/reservations/edit/:id", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedReservationEditPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/reservations/createfromquote/:fromquote", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedReservationEditPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/reservations/quote", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedQuotePage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/communications", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedCommunicationsListPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/communications/:actionToPerform/:id", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <MessageCreationPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/communications/:actionToPerform/:id", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <MessageCreationPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/entries", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedEntriesPageList />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/accounting", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedEventAccountPageList />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/accounting/:id", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedEventAccountingsDetails />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/kpi", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedKpi />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/hotels/bookingstatus", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedBookingCheckStatus />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/configurations", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <EventConfigurations />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/configurations/paymentmethods", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedPaymentMethodPageList />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/configurations/paymentmethods/create", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedPaymentMethodEdit />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/configurations/paymentmethods/edit/:id", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedPaymentMethodEdit />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/configurations/incomings", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedPaymentItemPageList side={PaymentCategory.Income} />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/configurations/income/create", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedPaymentItemEdit side={PaymentCategory.Income} />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/configurations/income/edit/:id", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedPaymentItemEdit side={PaymentCategory.Income} />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/configurations/costs", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedPaymentItemPageList side={PaymentCategory.Cost} />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/configurations/cost/create", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedPaymentItemEdit side={PaymentCategory.Cost} />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/configurations/cost/edit/:id", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedPaymentItemEdit side={PaymentCategory.Cost} />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:id/v2/accounting", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedEventAccountingMenu />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/v2/accounting/cash-register", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedCashRegisterPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/v2/accounting/movements", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedMovementsPage />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/v2/accounting/agents-account", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedAgentsPayments />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/v2/accounting/hotels-account", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedHotelsPayments />
            </ConnectedProtectedRoute>

            <ConnectedProtectedRoute {...{ path: "/events/:eventId/v2/accounting/summary", exact: true, isLoginPage: false, isHome: false }} >
                <ConnectedHeader />
                <ConnectedAccountingSummary />
            </ConnectedProtectedRoute>

        </Switch>
        <Route exact path="/offline">
            <ConnectedHeader />
            <h1 className="text-eurorounders">L'applicazione non riesce a contattare il server verificare connessione internet</h1>
        </Route>
        <Route exact path="/guide">
            <ConnectedHeader />
            <Guide />
        </Route>
    </Layout>
);
