import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router"
import { ApplicationState } from '../../../store/index';
import { actionMiddleware } from '../../../store/reservations/ReservationState';
import { ReservationsPageList, ReservationsPageListProps, ReservationsPageListCallbacks } from '../pages/listpage';

const mapStateToProsp = (state: ApplicationState): ReservationsPageListProps => {

    return {
        totalCount: state.reservations.totalCount,
        guestsCount: state.reservations.guestsCount,
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name.toUpperCase()}` : '',
        isProgressiveAssignmentEnabled: state.reservations.searchCriteria.checkInFrom != null && state.reservations.searchCriteria.checkInFrom !== undefined && state.reservations.searchCriteria.checkInFrom != ""
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): ReservationsPageListCallbacks => {
    return ({
        onLoad: (eventId: string) => dispatch(actionMiddleware.loadInitialState(eventId)),
        onPrint: () => dispatch(actionMiddleware.printCheckInList()),
        onGenerateProgressiveNumbers: () => dispatch(actionMiddleware.assignProgressiveNumbers()),
        onExcelExtraction: () => dispatch(actionMiddleware.downloadExcelList())
    });
}

export const ConnectedReservationListPage = withRouter(connect<ReservationsPageListProps, ReservationsPageListCallbacks>(mapStateToProsp, mapDispatchToProps)(ReservationsPageList));