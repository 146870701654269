import * as React from "react";
import { Input, Row, Col, Form, FormGroup, Label } from "reactstrap";
import { MenuItem } from '../../../menu/menuitem';
import { ConnectedPaymentMethodList } from '../connected/connected.list';
import { BodyHeader } from '../../../base/bodyheader';
import { MenuItemProps } from '../../../menu/menuitem';

export interface PaymentMethodListPageProps {
    loaded: boolean;
}

export interface PaymentMethodListPageCallbacks {
    onLoad?: () => void;
}

export class PaymentMethodsPageList extends React.Component<PaymentMethodListPageProps & PaymentMethodListPageCallbacks> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad)
            this.props.onLoad();
    }

    render() {
        let menuItems = [
            {
                icon: "plus",
                label: "crea",
                path: "/events/configurations/paymentmethods/create"
            },
            {
                icon: "cogs",
                label: "config.",
                path: "/events/configurations"
            }
        ] as Array<MenuItemProps>

        let body = (<React.Fragment />);

        if (this.props.loaded)
            body = (<ConnectedPaymentMethodList />)

        return (
            <React.Fragment>
                <BodyHeader title="Metodi pagamento" menuItems={menuItems} bottomSpace={true} />
                {body}
            </React.Fragment>
        )
    }
}