import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/index';
import { actionCreator } from '../../../../store/locations/groups/LocationGroupState';
import { LocationGroupPageListPageList, LocationGroupPageListProps } from '../pages/grouppagelist';

const mapStateToProsp = (state: ApplicationState): LocationGroupPageListProps => {
    return {}
}

const mapDispatchToProps = () => dispatch => {
    return ({
        onLoad: () => {
            dispatch(actionCreator.loadInitialState());
        }
    });
}

export const ConnectedLocationGroupPageList = connect<LocationGroupPageListProps>(mapStateToProsp, mapDispatchToProps)(LocationGroupPageListPageList);