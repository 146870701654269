import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { ModalRoomAvailability, ModalRoomAvailabilityCallbacks, ModalRoomAvailabilityProps } from '../modals/roomavailabilitymodal';
import { actionCreator, actionsMiddleware } from '../../../store/pricings/PricingState';


const mapStateToProsp = (state: ApplicationState): ModalRoomAvailabilityProps => {

    return {
        roomPricing: state.pricings.modalAvailabilitiesEdit,
        isOpen: state.pricings.isModalAvailabilitiesEditOpen
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): ModalRoomAvailabilityCallbacks => {
    return ({
        onSubmit: () => dispatch(actionsMiddleware.confirmAvailability()),
        toogleModal: () => dispatch(actionCreator.toggleAvailabilityModal())
    });
}

export const ConnectedRoomAvailabilityModal = connect<ModalRoomAvailabilityProps, ModalRoomAvailabilityCallbacks>(mapStateToProsp, mapDispatchToProps)(ModalRoomAvailability);