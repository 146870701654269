import * as React from "react";
import { connect } from "react-redux";
import { Prompt, RouteComponentProps, withRouter } from "react-router";
import { ApplicationState } from "../../store";
import * as H from "history";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { MenuItem } from "../menu/menuitem";

interface NavigationPromptCustomProps {
    changeInProgress: boolean;
}

interface NavigationPromptCustomCallbacks {
    confirmAction: () => void;
}

interface NavigationPromptCustomState {
    isConfirmationModalOpen: boolean;
    nextLocation: string;
}

class NavigationPromptCustom extends React.Component<NavigationPromptCustomProps & NavigationPromptCustomCallbacks, NavigationPromptCustomState>{

    private discardChanges: boolean;

    constructor(props) {
        super(props);
        this.state = {
            isConfirmationModalOpen: false,
            nextLocation: null
        };

        this.discardChanges = false;
    }

    private handleConfirmation(nextLocation: string): boolean {

        if (this.discardChanges)
            return true;

        this.setState({ isConfirmationModalOpen: true, nextLocation: nextLocation });

        return false;
    }

    private toggleModal() {
        this.setState({ isConfirmationModalOpen: !this.state.isConfirmationModalOpen })
    }

    private onCancel() {
        this.setState({ isConfirmationModalOpen: false, nextLocation: null });
    }

    private onConfirm() {
        this.onCancel();
        this.props.confirmAction();
    }

    private onDiscard() {
        const nextLocation = this.state.nextLocation;
        this.onCancel();
        this.discardChanges = true;
        (this.props as any).history.push(nextLocation);
    }

    render() {
        return (
            <React.Fragment>
                <Prompt when={this.props.changeInProgress} message={(nextLocation: H.Location) => this.handleConfirmation(nextLocation.pathname)} />
                <Modal className="bg-dark modal-lg" isOpen={this.state.isConfirmationModalOpen} toggle={() => this.toggleModal()} backdrop="static">
                    <ModalHeader className="text-eurorounders bg-dark" toggle={() => this.toggleModal()}>Attenzione</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders">
                        Uscire senza salvare?
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon="save" label="Salva" onClick={() => { this.onConfirm(); }} />
                        <MenuItem isSmall={true} isButton={true} icon="external-link-alt" label="Comtinua" onClick={() => this.onDiscard()} />
                        <MenuItem isSmall={true} isButton={true} icon="times" label="Annulla" onClick={() => this.onCancel()} />
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }

}

interface UnsavedChangesOwnProps extends RouteComponentProps {
    confirmAction: () => void;

}

const mapStateToProps = (state: ApplicationState): NavigationPromptCustomProps => {
    return {
        changeInProgress: state.uiProperties.changeInProgress
    }
}

const mapDispatchToProps = () => (dispatch, ownProps: UnsavedChangesOwnProps): NavigationPromptCustomCallbacks => {
    return {
        confirmAction: ownProps.confirmAction
    }
}

export const UnsavedChanges = withRouter<UnsavedChangesOwnProps, any>(connect<NavigationPromptCustomProps, NavigationPromptCustomCallbacks, UnsavedChangesOwnProps>(mapStateToProps, mapDispatchToProps)(NavigationPromptCustom) as any);
