import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware, actionCreator } from '../../../store/locations/LocationState';
import { LocationsSearchForm, LocationsSearchFormProps, LocationSearchFormCallbacks } from '../shared/searchform';

const mapStateToProsp = (state: ApplicationState): LocationsSearchFormProps => {

    return {
        searchString: state.locations.searchCriteria.searchString
    }
}

const mapDispatchToProps = () => (dispatch): LocationSearchFormCallbacks => {
    return ({
        onSearch: (searchString: string) => dispatch(actionsMiddleware.genericSearch(searchString))
    });
}

export const ConnectedLocationSearchForm = connect<LocationsSearchFormProps, LocationSearchFormCallbacks>(mapStateToProsp, mapDispatchToProps)(LocationsSearchForm);