import * as React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Form, Input, Row, Col, FormGroup, Label } from "reactstrap";
import { MenuItem } from "../menu/menuitem";

export interface EdiDocumentProps {
    isOpened: boolean;
    objectId: string;
    documentId: string;
    name: string;
}

export interface EditDocumentCallbacks {
    onChange: (value: string) => void;
    onSubmit: (documentId: string) => void;
    onCloseModal: () => void;
}

export class EditDocument extends React.Component<EdiDocumentProps & EditDocumentCallbacks>{
    constructor(props) {
        super(props);
    }

    render() {

        if (this.props.documentId === undefined || this.props.documentId == null) {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }

        return (
            <Modal className="bg-dark modal-lg" isOpen={this.props.isOpened} toggle={() => this.props.onCloseModal()} backdrop="static">
                <ModalHeader className="text-eurorounders bg-dark" toggle={() => this.props.onCloseModal()}>Modifica nome allegato</ModalHeader>
                <ModalBody className="bg-dark text-eurorounders modal-header-button-box">
                    <Form className="text-eurorounders"  style={{ width: "100%" }}>
                        <Row>
                            <Col lg="12" sm="12" xs="12">
                                <FormGroup>
                                    <Label>Nome</Label>
                                    <Input className="bg-dark text-eurorounders"  type="text" value={this.props.name || ''} onChange={(e) => { this.props.onChange(e.target.value) }} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <MenuItem isSmall={true} isButton={true} icon="save" label="Salva" onClick={() => this.props.onSubmit(this.props.documentId)} />
                    <MenuItem isSmall={true} isButton={true} icon="undo" label="Annulla" onClick={() => this.props.onCloseModal()} />
                </ModalFooter>
            </Modal>
        );
    }
}