import { ApplicationState } from "../../../store/index";
import { connect } from "react-redux";
import { Overlay, OverlayProps } from './modaloverlay';
import { actionCreator } from '../../../store/uiproperties/UIState';
import _ from "underscore";

const mapStateToProps = (state: ApplicationState): OverlayProps => {

    return {
        isOpened: state.uiProperties.saveRequestInProcess
    }
};


export const ConnectedOverlay = connect<OverlayProps>(mapStateToProps)(Overlay);