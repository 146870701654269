import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/communications/CommunicationState';
import { CommunicationsSentList, CommunicationsSentListProps, CommunicationsSentListCallbacks } from '../base/sentlist';


const mapStateToProsp = (state: ApplicationState): CommunicationsSentListProps => {

    return {
        list: state.communications.listSents
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): CommunicationsSentListCallbacks => {
    return ({
        onRowClick: (messageId: string) => dispatch(actionsMiddleware.navigateToEdit(messageId)) 
    });
}

export const ConnectedCommunicationsSentList = connect<CommunicationsSentListProps, CommunicationsSentListCallbacks>(mapStateToProsp, mapDispatchToProps)(CommunicationsSentList);