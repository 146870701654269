import { Action, Reducer } from 'redux';
import { ApplicationState } from '../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, checkRefreshToken, SaveRequest } from '../../utils/ajaxutilities';
import { actionsMiddleware as locationMiddleware, actionCreator as locationActionCreator } from '../locations/LocationState';
import { actionsMiddleware as eventsMiddleware } from '../events/EventState';
import dotProp from "dot-prop";
import $ from "jquery";
import { DailyAvailability, PeriodPricing, RoomPricing } from './PricingEntities';
import { RoomType } from '../locations/LocationEntities';
import { Location } from '../locations/LocationEntities';
import { Event } from '../events/EventEntities';
import { actionCreator as actionCreatorUi } from '../uiproperties/UIState';
import { push } from 'connected-react-router';
import _ from "underscore";
import moment from 'moment';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { CoreResponse } from '../serverresponse/serverresponse';

export interface PricingState {
    list?: Array<PeriodPricing>;
    running?: PeriodPricing;
    runningBackup?: PeriodPricing;
    modalAvailabilitiesEdit?: RoomPricing;
    isModalAvailabilitiesEditOpen: boolean;
    checkBookingState: NestedBookingsStatus;
}

export interface NestedBookingsStatus {
    selectedLocationId: string;
    selectedRoomId: string;
}

enum TypeKeys {
    RESET_STATE = "PRICING_RESET_STATE",
    SET_RUNNING = "PRICING_SET_RUNNING",
    SET_RUNNING_BACKUP = "PRICING_SET_RUNNING_BACKUP",
    UNLOAD_RUNNING = "PRICING_UNLOAD_RUNNING",
    SET_LIST = "PRICING_SET_LIST",
    ADD_ROOM_TYPE = "PRICING_ADD_ROOM_TYPE",
    SET_RUNNING_ROOM_PRICING = "PRICING_SET_RUNNING_ROOM_PRICING",
    TOGGLE_AVAILABILITY_MODAL = "PRICING_TOGGLE_AVAILABILITY_MODAL",
    SET_BOOKING_STATUS = "PRICING_SET_BOOKING_STATUS"
}

type PricingAction =
    ResetState
    | UnloadRunning
    | SetRunning
    | SetList
    | SetRunningBackup
    | SetRunningRoomPricing
    | TooggleAvailabilityModal
    | SetBookingStatusCheck;

interface TooggleAvailabilityModal extends Action {
    type: TypeKeys.TOGGLE_AVAILABILITY_MODAL
}

interface SetRunningRoomPricing extends Action {
    type: TypeKeys.SET_RUNNING_ROOM_PRICING;
    model: RoomPricing;
}

interface SetRunningBackup extends Action {
    type: TypeKeys.SET_RUNNING_BACKUP;
    model: PeriodPricing;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE;
}

interface SetRunning extends Action {
    type: TypeKeys.SET_RUNNING;
    model: PeriodPricing;
}

interface UnloadRunning extends Action {
    type: TypeKeys.UNLOAD_RUNNING;
}

interface SetList extends Action {
    type: TypeKeys.SET_LIST;
    list: Array<PeriodPricing>;
};

interface SetBookingStatusCheck extends Action {
    type: TypeKeys.SET_BOOKING_STATUS;
    checkState: NestedBookingsStatus;
}

export const actionCreator = {
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    setRunning: (model: PeriodPricing): SetRunning => ({
        type: TypeKeys.SET_RUNNING,
        model: model
    }),
    unloadRunning: (): UnloadRunning => ({
        type: TypeKeys.UNLOAD_RUNNING
    }),
    setList: (list: Array<PeriodPricing>): SetList => ({
        type: TypeKeys.SET_LIST,
        list: list
    }),
    setRunningBackup: (model: PeriodPricing): SetRunningBackup => ({
        type: TypeKeys.SET_RUNNING_BACKUP,
        model: model
    }),
    setRunningRoomPricing: (model: RoomPricing): SetRunningRoomPricing => ({
        type: TypeKeys.SET_RUNNING_ROOM_PRICING,
        model: model
    }),
    toggleAvailabilityModal: (): TooggleAvailabilityModal => ({
        type: TypeKeys.TOGGLE_AVAILABILITY_MODAL
    }),
    setBookingStatus: (nestedState: NestedBookingsStatus): SetBookingStatusCheck => ({
        checkState: nestedState,
        type: TypeKeys.SET_BOOKING_STATUS
    })
};

export const actionForExternalModule = {
    loadLocationModule: () => (dispatch, getState: () => ApplicationState) => {
        abortAllRequest();
        let locationSeected = null;
        if (getState().events.running !== undefined && getState().events.running != null)
            locationSeected = getState().pricings.running.location;
        dispatch(locationMiddleware.loadInitialStateExternal(locationSeected));
    },
    confirmSelection: () => (dispatch, getState: () => ApplicationState) => {
        let selectedLocation = getState().locations.selectedLocation;
        dispatch(actionsMiddleware.setRunningProp("location", selectedLocation));
    },
    onRowClick: (model?: Location) => (dispatch, getState: () => ApplicationState) => {
        dispatch(locationActionCreator.setSelected(model));
    },
    loadEvent: (eventId: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<any> => {

        let deferred = $.Deferred();

        if (getState().events.running === undefined || getState().events.running == null || getState().events.running.id != eventId) {

            dispatch(eventsMiddleware.load(eventId)).then(res => {
                deferred.resolve();
            });
            return deferred.promise();;
        }

        deferred.resolve();
        return deferred.promise();
    }
}

export const actionsMiddleware = {
    loadInitialState: (eventId: string) => (dispatch, getState: () => ApplicationState) => {
        dispatch(actionCreator.resetState());
        dispatch(actionsMiddleware.loadList(eventId));
        dispatch(locationMiddleware.getRoomTypes());
        dispatch(eventsMiddleware.setEventLocations(eventId));
    },
    loadList: (eventId: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<Array<PeriodPricing>> => {
        let request = new RequestModel(`/periodpricings/event/${eventId}`, RequestMethods.GET);

        let deferred = $.Deferred();

        let listRequest = ajaxRequest<Array<PeriodPricing>>(request)(dispatch, getState);


        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false) {
                deferred.resolve(null);
                return deferred.promise();
            }

            $.when(listRequest, dispatch(actionForExternalModule.loadEvent(eventId))).then(res => {
                if (res.success == false) {
                    deferred.resolve(null);
                    return deferred.promise();
                }
                dispatch(actionCreator.setList(res.entity));
                deferred.resolve(res.entity);
            });

        });

        return deferred.promise();
    },
    load: (id?: string) => (dispatch, getState: () => ApplicationState): JQueryPromise<PeriodPricing> => {
        let deferred = $.Deferred();

        if (id === undefined || id == null) {
            $.when(dispatch(actionsMiddleware.createNew())).then(pricing => {
                dispatch(actionCreator.setRunningBackup(pricing));
                deferred.resolve(pricing);
                return;
            });
            return deferred.promise();
        }


        let roomTypeDeferred = $.Deferred();

        let roomTypePromise = roomTypeDeferred.promise();

        let roomTypes = getState().locations.roomTypes;
        if (roomTypes === undefined || roomTypes == null || roomTypes.length == 0) {
            roomTypePromise = dispatch(locationMiddleware.getRoomTypes());
        } else {
            roomTypeDeferred.resolve();
        }

        let request = new RequestModel(`/periodpricings/${id}`, RequestMethods.GET);

        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false) {
                deferred.resolve(null);
                return deferred.promise();
            }


            $.when(ajaxRequest<PeriodPricing>(request)(dispatch, getState), roomTypePromise).then((pricing, rts) => {
                if (pricing.success == false) {
                    deferred.resolve(null);
                    return deferred.promise();
                }

                dispatch(actionCreator.setRunningBackup(pricing.entity));
                deferred.resolve(pricing.entity);
            });

        });



        return deferred.promise();
    },
    createNew: () => (dispatch, getState: () => ApplicationState): JQueryPromise<PeriodPricing> => {

        let deferred = $.Deferred();
        let roomTypes = getState().locations.roomTypes;
        if (roomTypes === undefined || roomTypes == null || roomTypes.length == 0) {
            dispatch(locationMiddleware.getRoomTypes()).then(rt => {
                let pricing = getNewPricing(rt, getState().events.running);
                deferred.resolve(pricing);
            });
        } else {
            let pricing = getNewPricing(roomTypes, getState().events.running);
            deferred.resolve(pricing);
        }
        return deferred.promise();
    },
    setRunningProp: (propertyName: string, value: any, roomTypeId?: string) => (dispatch, getState: () => ApplicationState) => {

        let runningPricing = { ...getState().pricings.running };

        switch (propertyName) {
            case "checkIn":
                runningPricing.checkIn = value;
                if (moment(value).isValid()) {
                    runningPricing.checkIn = moment(value).format("YYYY-MM-DDTHH:mm:ss");
                }
                break;
            case "checkOut":
                runningPricing.checkOut = value;
                if (moment(value).isValid()) {
                    runningPricing.checkOut = moment(value).format("YYYY-MM-DDTHH:mm:ss");
                }
                break;
            case "dailyTax":
                runningPricing.dailyTax = value;
                break;
            case "location":
                runningPricing.location = value;
                break;
        }

        if (roomTypeId !== undefined && roomTypeId != null) {
            runningPricing.roomPricings.forEach(rp => {
                if (rp.roomType.id == roomTypeId) {
                    switch (propertyName) {
                        case "availability":
                            rp.availability = value;
                            if (rp.dailyAvailabilities !== undefined && rp.dailyAvailabilities != null) {
                                rp.dailyAvailabilities.forEach(x => {
                                    x.availability = value;
                                });
                            }
                            break;
                        case "cost":
                            rp.cost = value;
                            break;
                        case "costNotSell":
                            rp.costNotSell = value;
                            break;
                        case "sellPrice":
                            rp.sellPrice = value;
                            break;
                    }
                }
            });
        }

        dispatch(actionCreator.setRunning(runningPricing));
    },
    save: () => (dispatch, getState: () => ApplicationState): JQueryPromise<PeriodPricing> => {

        let promise = $.Deferred();

        dispatch(actionCreatorUi.setErrorMessages([]));
        //validation
        let model: PeriodPricing = getState().pricings.running;

        let request = new SaveRequest(`/PeriodPricings`, RequestMethods.POST, model);

        ajaxRequest<PeriodPricing>(request)(dispatch, getState).then(response => {
            if (response.success != true) {
                promise.resolve(null);
                return;
            }
            promise.resolve(response.entity);
            dispatch(actionCreator.setRunningBackup(response.entity));
        });

        return promise.promise();
    },
    changeRoomPricing: (roomTypeId: string) => (dispatch, getState: () => ApplicationState) => {
        let roomTypes = getState().locations.roomTypes;
        let roomType = _.find(roomTypes, (rt) => rt.id == roomTypeId);

        if (roomType === undefined || roomType == null) {
            return;
        }

        let running = { ...getState().pricings.running };

        let roomTypeIndex: number = _.findIndex(running.roomPricings, (rp) => rp.roomType.id == roomType.id);

        if (roomTypeIndex < 0) {
            running.roomPricings.push({
                costNotSell: 0,
                sellPrice: 0,
                availability: 0,
                cost: 0,
                roomType: roomType,
                costStaff: 0
            });

            dispatch(actionCreator.setRunning(running));
            return;
        }

        running.roomPricings.splice(roomTypeIndex, 1);
        dispatch(actionCreator.setRunning(running));
    },
    delete: () => (dispatch, getState: () => ApplicationState) => {

        let requestModel = new SaveRequest(`/PeriodPricings/${getState().pricings.running.id}`, RequestMethods.DELETE, null);

        return ajaxRequest<any>(requestModel)(dispatch, getState).then(response => {
            if (response.success == true) {
                dispatch(push(`/events/${getState().pricings.running.event.id}/hotels`));
            }
        });
    },
    openAvailabilityModal: (roomPricingId: string) => (dispatch, getState: () => ApplicationState) => {
        let roomPricing = _.find(getState().pricings.running.roomPricings, (rp) => rp.id == roomPricingId)

        if (roomPricing === undefined || roomPricing == null) {
            return;
        }

        dispatch(actionCreator.setRunningRoomPricing({ ...JSON.parse(JSON.stringify(roomPricing)) }));
        dispatch(actionCreator.toggleAvailabilityModal());
    },
    setRoomPricingAvailability: (date: string, availability?: number) => (dispatch, getState: () => ApplicationState) => {

        let roomPricing = { ...getState().pricings.modalAvailabilitiesEdit };

        roomPricing.dailyAvailabilities.forEach(x => {
            if (x.day == date) {
                x.availability = availability;
            }
        });

        dispatch(actionCreator.setRunningRoomPricing(roomPricing));
    },
    confirmAvailability: () => (dispatch, getState: () => ApplicationState) => {

        let running = { ...getState().pricings.running };

        let runningRoomPricingIndex = _.findIndex(getState().pricings.running.roomPricings, (rp) => rp.id == getState().pricings.modalAvailabilitiesEdit.id);

        if (runningRoomPricingIndex > -1) {
            running.roomPricings[runningRoomPricingIndex] = getState().pricings.modalAvailabilitiesEdit;
        }

        let isAvailabilityEditable = true;

        running.roomPricings[runningRoomPricingIndex].dailyAvailabilities.forEach(x => {

            if (x.availability != running.roomPricings[runningRoomPricingIndex].availability) {
                isAvailabilityEditable = false;
            }

        });

        running.roomPricings[runningRoomPricingIndex].isAvailabilityEditable = isAvailabilityEditable;

        dispatch(actionCreator.setRunning(running));
        dispatch(actionCreator.toggleAvailabilityModal());
    },
    setRunningBookingCheckProp: (propertyName: string, value: any, roomTypeId?: string) => (dispatch, getState: () => ApplicationState) => {

        let runningCheckState = { ...getState().pricings.checkBookingState };

        switch (propertyName) {
            case "selectedLocationId":
                runningCheckState.selectedLocationId = value;

                break;
            case "selectedRoomId":
                runningCheckState.selectedRoomId = value;
                break;
        }

        dispatch(actionCreator.setBookingStatus(runningCheckState));
    },
    downloadExcel: () => (dispatch, getState: () => ApplicationState) => {
        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false)
                return;

            if (!getState().pricings.checkBookingState.selectedLocationId) {
                dispatch(actionCreatorUi.setErrorMessages(["Select location"]));
                return;
            }

            if (!getState().pricings.checkBookingState.selectedRoomId) {
                dispatch(actionCreatorUi.setErrorMessages(["Select room type"]));
                return;
            }

            dispatch(showLoading());

            let token = getState().currentProfile.authData.token;

            fetch(`api/periodpricings/nested/${getState().events.running.id}/${getState().pricings.checkBookingState.selectedLocationId}/${getState().pricings.checkBookingState.selectedRoomId}`, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": token
                })
            }).then(x => {
                if (!x.ok) {
                    throw Error();
                }

                if (x.ok) {
                    try {
                        let responseCloned = x.clone();
                        let reclone = responseCloned.clone();
                        responseCloned.blob().then(blob => {
                            if (blob.type.indexOf("json") >= 0) {
                                reclone.json().then((response: CoreResponse<any>) => {
                                    if (!response.success) {
                                        dispatch(actionCreatorUi.setErrorMessages(response.messages));
                                        dispatch(hideLoading());
                                    }
                                });
                            }
                        });

                    } catch { }
                }

                return x;
            })
                .then(resp => resp.blob())
                .then(blob => {
                    if (blob.type.indexOf("json") >= 0)
                        return;
                    dispatch(hideLoading());
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `bookings_${getState().events.running.name.replace(/[/\\?%*:|"<>]/g, '-')}.xlsx`;
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(() => {
                    dispatch(actionCreatorUi.setErrorMessages(["Si è verificato un errore"]));
                    dispatch(hideLoading());
                });

        });
    }
};

const getBaseState = (): PricingState => ({
    running: null,
    list: [],
    isModalAvailabilitiesEditOpen: false,
    modalAvailabilitiesEdit: null,
    runningBackup: null,
    checkBookingState: {
        selectedLocationId: null,
        selectedRoomId: null
    }
});

const getNewPricing = (roomTypes: Array<RoomType>, event: Event): PeriodPricing => {

    let newPricing: PeriodPricing = {
        roomPricings: [],
        event: event
    } as PeriodPricing;

    //roomTypes.forEach(rt => {
    //    newPricing.roomPricings.push({
    //        costNotSell: 0,
    //        sellPrice: 0,
    //        availability: 0,
    //        cost: 0,
    //        roomType: rt
    //    });
    //});

    return newPricing;
};

export const reducer: Reducer<PricingState> = (state: PricingState | undefined, incomingAction: PricingAction): PricingState => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {
        case TypeKeys.RESET_STATE:
            newState = { ...getBaseState() };
            break;
        case TypeKeys.UNLOAD_RUNNING:
            newState = dotProp.set(newState, "running", null);
            break
        case TypeKeys.SET_LIST:
            newState = dotProp.set(newState, "list", [...incomingAction.list]);
            break;
        case TypeKeys.SET_RUNNING:
            newState = dotProp.set(newState, "running", { ...incomingAction.model });

            if (incomingAction.model === undefined || incomingAction.model == null)
                newState = dotProp.set(newState, "running", null);

            break;
        case TypeKeys.SET_RUNNING_BACKUP:
            newState = dotProp.set(newState, "running", { ...incomingAction.model });
            newState = dotProp.set(newState, "runningBackup", { ...incomingAction.model });

            if (incomingAction.model === undefined || incomingAction.model == null) {
                newState = dotProp.set(newState, "running", null);
                newState = dotProp.set(newState, "runningBackup", null);
            }

            break;
        case TypeKeys.SET_RUNNING_ROOM_PRICING:
            newState = dotProp.set(newState, "modalAvailabilitiesEdit", { ...incomingAction.model });
            break;
        case TypeKeys.TOGGLE_AVAILABILITY_MODAL:
            newState = dotProp.set(newState, "isModalAvailabilitiesEditOpen", !newState.isModalAvailabilitiesEditOpen);
            break;
        case TypeKeys.SET_BOOKING_STATUS:
            newState = dotProp.set(newState, "checkBookingState", incomingAction.checkState);
            break;
    }

    return newState;
}