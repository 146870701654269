import { connect } from 'react-redux';
import { withRouter } from "react-router"
import { ApplicationState } from '../../../../store/index';
import { actionsMiddleware } from '../../../../store/payments/paymentmethods/PaymentMethodState';
import { PaymentMethodListPageCallbacks, PaymentMethodListPageProps, PaymentMethodsPageList } from '../pages/listpage';

const mapStateToProsp = (state: ApplicationState): PaymentMethodListPageProps => {
    return {
        loaded: state.paymentMethods.loadCompleted
    }
}

const mapDispatchToProps = () => (dispatch): PaymentMethodListPageCallbacks => {
    return ({
        onLoad: () => dispatch(actionsMiddleware.loadInitialState())
    });
}

export const ConnectedPaymentMethodPageList = withRouter(connect<PaymentMethodListPageProps, PaymentMethodListPageCallbacks>(mapStateToProsp, mapDispatchToProps)(PaymentMethodsPageList));