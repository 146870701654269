import * as React from "react"
import { guid } from './../../../utils/commons';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { MenuItem } from '../../menu/menuitem';

export interface ErrorMessagesProps {
    messages: Array<string>;
    close?: () => void;
}

export class ErrorMessages extends React.Component<ErrorMessagesProps>{
    constructor(props) {
        super(props);
    }

    render() {

        if (this.props.messages === undefined || this.props.messages == null || this.props.messages.length == 0) {
            return (<React.Fragment></React.Fragment>);
        }

        let elementToPrint = [];

        elementToPrint = this.props.messages.map(x => {
            return (
                <li key={guid()}>
                    {x}
                </li>
            )
        });

        let isOpen: boolean = elementToPrint.length > 0;

        return (
            <React.Fragment>
                <Modal className="bg-dark" isOpen={isOpen} toggle={() => this.props.close()} backdrop="static">
                    <ModalHeader className="text-eurorounders bg-dark text-uppercase" toggle={() => this.props.close()}>Attenzione</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders modal-header-button-box">
                        <ul style={{ listStyleType: "none" }}>
                            {elementToPrint}
                        </ul>
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon="times" label="ok" onClick={() => this.props.close()} />
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}