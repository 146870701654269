import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/communications/CommunicationState';
import { CommunicationsList, CommunicationsListCallbacks, CommunicationsListProps } from '../base/list';


const mapStateToProsp = (state: ApplicationState): CommunicationsListProps => {

    return {
        list: state.communications.list,
        locations: state.events.runningEventLocations
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): CommunicationsListCallbacks => {
    return ({
        onRowClick: (locationId: string) => {
            dispatch(actionsMiddleware.navigateToCreate(locationId));
        }
    });
}

export const ConnectedCommunicationsList = connect<CommunicationsListProps, CommunicationsListCallbacks>(mapStateToProsp, mapDispatchToProps)(CommunicationsList);