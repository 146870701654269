import * as React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { MenuItem } from '../menu/menuitem';

export interface ConfirmationModalProps {
    text: string;
    confirmIcon?: string;
    onConfirm: () => void;
    onClosed?: () => void;
    onOpened?: () => void;
}

interface ConfirmationModalState {
    isModalOpened;
}

export class ConfirmationModal extends React.Component<ConfirmationModalProps, ConfirmationModalState>{
    constructor(props) {
        super(props);
        this.state = {
            isModalOpened: false
        }
    }

    public toogleModal(): void {

        this.setState({ isModalOpened: !this.state.isModalOpened });
    }

    private onOpenedModal() {
        if (this.props.onOpened)
            this.props.onOpened();
    }

    private onClosedModal() {
        if (this.props.onClosed)
            this.props.onClosed();
    }

    private onConfirm() {
        this.props.onConfirm();
        this.toogleModal();
    }

    render() {

        let confirmIcon = "save"

        if (this.props.confirmIcon !== undefined && this.props.confirmIcon != null)
            confirmIcon = this.props.confirmIcon;

        return (
            <React.Fragment>
                <Modal className="bg-dark" isOpen={this.state.isModalOpened} toggle={() => this.toogleModal()} backdrop="static" onClosed={() => this.onClosedModal()} onOpened={() => this.onOpenedModal()}>
                    <ModalHeader className="text-eurorounders bg-dark text-uppercase" toggle={() => this.toogleModal()}>Conferma</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders modal-header-button-box">
                        <div>
                            <p>
                                {this.props.text}
                            </p>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <MenuItem isSmall={true} isButton={true} icon={confirmIcon} label="Si" onClick={() => this.onConfirm()} />
                        <MenuItem isSmall={true} isButton={true} icon="undo" label="No" onClick={() => this.toogleModal()} />
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }

}