import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from '../../../store/index';
import { getEventMenu } from '../../../store/uiproperties/UIState';
import { actionsMiddleware } from '../../../store/events/EventState';
import { EventMenu, EventMenuProps, EventMenuCallbacks } from '../pages/eventmenu';
import _ from "underscore";

const mapStateToProsp = (state: ApplicationState): EventMenuProps => {

    let menuVoices = [];

    if (state.events.running !== undefined && state.events.running != null)
        menuVoices = getEventMenu(state.events.running.id, state.events.running.cashModuleEnabled);

    let menuVoicesToSet = [];

    menuVoices.forEach(m => {
        for (let p of state.currentProfile.permissions) {
            let toBreak = false;
            for (let x of m.associatedToPermissions) {
                if (x == p.code) {
                    menuVoicesToSet.push(m);
                    toBreak = true;
                    break;
                }
            }
            if (toBreak)
                break;
        }
    });

    return {
        event: state.events.running,
        menuVoices: menuVoicesToSet,
        canEditEvent: _.some(state.currentProfile.permissions, p => p.code == "Events")
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): EventMenuCallbacks => {
    return ({
        onLoad: (id: string) => dispatch(actionsMiddleware.load(id))
    });
}

export const ConnectedEventMenu = withRouter(connect<EventMenuProps, EventMenuCallbacks>(mapStateToProsp, mapDispatchToProps)(EventMenu));