import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionMiddleware, ReservationPaymentStatus, actionCreator } from '../../../store/reservations/ReservationState';
import { ReservationSearchFormAdvancedCallbacks, ReservationSearchFormAdvancedProps, SearchFormAdvanced } from '../base/searchformadvanced';


const mapStateToProsp = (state: ApplicationState): ReservationSearchFormAdvancedProps => {

    return {
        locations: state.events.runningEventLocations,
        reservationStates: state.reservations.reservationStatusDesc,
        roomTypes: state.locations.roomTypes,
        searchCriteria: state.reservations.searchCriteria,
        reservationType: state.reservations.reservationTypeDesc,
        orderFields: [
            {
                key: "CheckIn",
                desc: "Check In"
            },
            {
                key: "CheckOut",
                desc: "Check Out"
            },
            {
                key: "ProgressiveNumber",
                desc: "Id"
            }
        ],
        orderDirections: [
            {
                key: "Ascending",
                desc: "Crescente"
            },
            {
                key: "Descending",
                desc: "Decrescente"
            }
        ],
        paymentStatusOptions: [
            {
                desc: "Da pagare",
                key: ReservationPaymentStatus.ToPay
            },
            {
                desc: "Dare resto",
                key: ReservationPaymentStatus.GiveChange
            }
        ],
        paymentMethods: state.paymentMethods.paymentMethods,
        isOpen: state.reservations.advancedFiltersOpened
    }
}

const mapDispatchToProps = (dispatch) => (dispatch): ReservationSearchFormAdvancedCallbacks => {
    return ({
        onSearch: (propertyName: string, value: any) => dispatch(actionMiddleware.doSearch(propertyName, value)),
        close: () => dispatch(actionCreator.toggleAdvancedFiltersModal(false))
    });
}

export const ConnectedReservationSearchFormAdvanced = connect<ReservationSearchFormAdvancedProps, ReservationSearchFormAdvancedCallbacks>(mapStateToProsp, mapDispatchToProps)(SearchFormAdvanced);