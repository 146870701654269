import * as React from 'react';
import { Container } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import './layout.css';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <LoadingBar style={{ backgroundColor: '#007bff', zIndex: 9999, position: "fixed", top: "0" }} />
        <Container>
            {props.children}
        </Container>
    </React.Fragment>
);
