import { Action, Reducer } from 'redux';
import { Role, Permission } from './Role';
import { AppThunkAction, ApplicationState } from '../';
import { ajaxRequest, RequestMethods, RequestModel, checkRefreshToken, SaveRequest } from '../../utils/ajaxutilities';
import { OrderDirection } from '../../utils/commons';
import dotprop from "dot-prop";
import { actionCreator as actionCreatorUi } from '../uiproperties/UIState';
import { CoreResponse } from '../serverresponse/serverresponse';
import $ from "jquery";
import _ from 'underscore';
import { push } from 'connected-react-router';


export interface RolesState {
    rolesList: Array<Role>;
    permissions: Array<Permission>;
    runningRole: Role;
    rolesToDelete: Array<string>;
}

export const rolesFunction = {
    getRoles: () => (dispatch, getState): JQueryPromise<CoreResponse<Array<Role>>> => {

        let requestModel = new RequestModel("/roles/list", RequestMethods.GET, null, null);

        return ajaxRequest<Array<Role>>(requestModel)(dispatch, getState);


    },
    getPemrmissions: () => (dispatch, getState): JQueryPromise<CoreResponse<Array<Permission>>> => {

        let requestModel = new RequestModel("/roles/permissions", RequestMethods.GET);

        return ajaxRequest<Array<Permission>>(requestModel)(dispatch, getState);

    },
    getRole: (roleId: string) => (dispatch, getState): JQueryPromise<CoreResponse<Role>> => {

        let requestModel = new RequestModel(`/roles/${roleId}`, RequestMethods.GET);

        return ajaxRequest<Role>(requestModel)(dispatch, getState);

    },
    saveRole: (role: Role) => (dispatch, getState): JQueryPromise<CoreResponse<Role>> => {

        let requestModel = new SaveRequest(`/roles`, RequestMethods.POST, role);

        return ajaxRequest<Role>(requestModel)(dispatch, getState);

    },
    deleteRole: (id: string) => (dispatch, getState): JQueryPromise<CoreResponse<any>> => {

        let requestModel = new SaveRequest(`/roles/${id}`, RequestMethods.DELETE, null);

        return ajaxRequest<any>(requestModel)(dispatch, getState);

    }
};

export const actionCreator = {
    loadInitialState: (): AppThunkAction<any> => (dispatch, getState) => {


        checkRefreshToken()(dispatch, getState).then(x => {

            if (x == false) {
                return;
            }

            $.when(rolesFunction.getPemrmissions()(dispatch, getState), rolesFunction.getRoles()(dispatch, getState)).then((permissions, roles) => {

                if (permissions.success == false || roles.success == false || roles.entity === undefined || permissions.entity === undefined)
                    return;
                dispatch(actionCreator.setPermission(permissions.entity));
                dispatch(actionCreator.setRoles(roles.entity));
                dispatch(actionCreatorUi.setPageLoaded(true));
            });

        });

    },
    loadAllRoles: (): AppThunkAction<any> => (dispatch, getState) => {
        rolesFunction.getRoles()(dispatch, getState).then(roles => {

            if (roles.success == false || roles.entity === undefined)
                return;

            dispatch(actionCreator.setRoles(roles.entity));
        });
    },
    setProperty: (propetyName: string, value: any) => (dispatch, getState: () => ApplicationState) => {
        let runningRole = getState().roles.runningRole;
        let newRole = { ...runningRole };
        newRole.name = value;
        dispatch(actionCreator.setRunningRole(newRole));
    },
    updateRunningPermissions: (permission: Permission, checked: boolean) => (dispatch, getState: () => ApplicationState) => {
        
        let runningRole = getState().roles.runningRole;
        let newRole = { ...runningRole };
        let index = _.findIndex(newRole.permissions, (perm) => perm.id == permission.id);

        if (checked == true && index == -1) {
            newRole.permissions.push(permission);
        } else if (checked == false && index > -1) {
            newRole.permissions.splice(index, 1);
        } else {
            return;
        }
        dispatch(actionCreator.setRunningRole(newRole));
    },
    findRunningRole: (roleId: string): AppThunkAction<any> => (dispatch, getState) => {
        
        if (roleId === undefined || roleId == null || roleId == "") {
            dispatch(actionCreator.setRunningRole(getRunningRole()));
            dispatch(actionCreatorUi.setPageLoaded(true));
            return;
        }

        rolesFunction.getRole(roleId)(dispatch, getState).then(roleResponse => {
            if (roleResponse.success == false)
                return;

            if (roleResponse.entity == null || roleResponse.entity === undefined) {
                dispatch(actionCreatorUi.setErrorMessages(["role not found"], "edit-role"));
                return;
            }

            dispatch(actionCreator.setRunningRole(roleResponse.entity));
            dispatch(actionCreatorUi.setPageLoaded(true));
        });
    },
    unloadRunning: (): UnloadRunning => ({
        type: TypeKeys.UNLOAD_RUNNING
    }),
    navigateToRunningRole: (roleId: string): AppThunkAction<any> => (dispatch, getState) => {
        dispatch(actionCreator.unloadRunning());
        dispatch(push(`/profiles/roles/edit/${roleId}`));
    },
    saveRole: (): AppThunkAction<any> => (dispatch, getState: () => ApplicationState) => {

        let roleToSave = getState().roles.runningRole;

        rolesFunction.saveRole(roleToSave)(dispatch, getState).then(roleResponse => {
            if (roleResponse.success == false)
                return;

            dispatch(actionCreator.setRunningRole(roleResponse.entity));
        });
    },
    deleteRole: (roleId: string): AppThunkAction<any> => (dispatch, getState) => {
        rolesFunction.deleteRole(roleId)(dispatch, getState).then(response => {
            if (response.success)
                dispatch(push('/profiles/roles'));
        });
    },
    setPermission: (permissions: Array<Permission>): SetPermissions => ({
        type: TypeKeys.SET_PERMISSIONS,
        data: permissions
    }),
    setRoles: (roles: Array<Role>): SetRoles => ({
        type: TypeKeys.SET_ROLES,
        data: roles
    }),
    resetState: (): ResetState => ({
        type: TypeKeys.RESET_STATE
    }),
    setRunningRole: (role: Role): SetRunnigRole => ({
        type: TypeKeys.SET_RUNNING,
        data: role
    })
};

enum TypeKeys {
    SET_ROLES = "ROLES_STATE_SET_ROLES",
    SET_PERMISSIONS = "ROLES_STATE_SET_PERMISSIONS",
    RESET_STATE = "ROLES_STATE_RESET_STATE",
    SET_RUNNING = "ROLES_STATE_SET_RUNNING",
    UNLOAD_RUNNING = "ROLES_UNLOAD_RUNNING"
}

type RoleAction = SetRoles | SetPermissions | ResetState | SetRunnigRole | UnloadRunning;

interface UnloadRunning extends Action {
    type: TypeKeys.UNLOAD_RUNNING
}

interface SetRoles extends Action {
    type: TypeKeys.SET_ROLES;
    data: Array<Role>;
}

interface SetPermissions extends Action {
    type: TypeKeys.SET_PERMISSIONS;
    data: Array<Permission>;
}

interface SetRunnigRole extends Action {
    type: TypeKeys.SET_RUNNING,
    data: Role;
}

interface ResetState extends Action {
    type: TypeKeys.RESET_STATE
}

const getRunningRole = (): Role => {
    return {
        id: null,
        permissions: []
    } as Role;
}


let baseState: RolesState = {
    permissions: [],
    rolesList: [],
    runningRole: getRunningRole(),
    rolesToDelete: []
}

export const reducer: Reducer<RolesState> = (state: RolesState | undefined, incomingAction: RoleAction): RolesState => {

    if (state === undefined) {
        return baseState;
    }

    let newState: RolesState = { ...state };

    switch (incomingAction.type) {

        case TypeKeys.SET_PERMISSIONS:
            newState = dotprop.set(newState, "permissions", incomingAction.data);
            break;
        case TypeKeys.SET_ROLES:
            newState = dotprop.set(newState, "rolesList", incomingAction.data);
            break;
        case TypeKeys.RESET_STATE:
            newState = dotprop.set(newState, "permissions", []);
            newState = dotprop.set(newState, "rolesList", []);
            break;
        case TypeKeys.SET_RUNNING:
            newState = dotprop.set(newState, "runningRole", incomingAction.data);
            break;
        case TypeKeys.UNLOAD_RUNNING:
            newState = dotprop.set(newState, "runningRole", null);
            break;
    }


    return newState;
};