import * as React from "react";
import { LocationGroup } from "../../../../store/locations/groups/LocationGroupEntities";
import { BaseList } from '../../../base/baseList';
import GoToTop from "../../../base/gototop";


export interface LocationGroupListProps {
    list: Array<LocationGroup>;
    onRowClick?: (roleId: string) => void;
}

export class LocationGroupList extends React.Component<LocationGroupListProps>{

    constructor(props) {
        super(props);
    }

    private onRowClick(group: LocationGroup) {
        this.props.onRowClick(group.id);
    }

    private getRows(list: Array<LocationGroup>): JSX.Element[] {

        let elements = list.map(element => {
            
            let baseStyle = { cursor: "pointer" };
            return (
                <tr key={element.id} style={baseStyle} onClick={() => this.onRowClick(element)}>
                    <td>
                        {element.name}
                    </td>
                </tr>
            )
        });

        return elements;

    }

    private getHead(): JSX.Element {

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>Nome</th>
                </tr>
            </thead>
        );

    }

    render() {

        return (
            <React.Fragment>
                <BaseList hasNextPage={false} list={this.props.list} active={false} onEndOfList={() => { }} getElements={(profileList: Array<LocationGroup>) => { return this.getRows(profileList) }} getHead={this.getHead} />
                <GoToTop />
            </React.Fragment>
        )
    }
}