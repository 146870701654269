import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/index';
import { actionsMiddleware, actionsCreator } from '../../../../store/payments/movements/MovementsState';
import { MovementsList, MovementsListCallbacks, MovementsListProps } from '../base/list';


const mapStateToProsp = (state: ApplicationState): MovementsListProps => {
    return {
        startLoading: state.uiProperties.startFetchingPagedList,
        hasNextPage: state.movements.searchCriteria?.hasNextPage ? true : false,
        list: state.movements.list,
        paymentItems: state.paymentItems.paymentItems
    };
}

const mapDispatchToProps = () => (dispatch): MovementsListCallbacks => {
    return ({
        loadNewPage: () => dispatch(actionsMiddleware.loadNextPage()),
        registerDelete: (id: string) => dispatch(actionsCreator.setItemToDelete(id)),
        onDelete: () => dispatch(actionsMiddleware.delete())
    });
}

export const ConnectedMovementsList = connect<MovementsListProps, MovementsListCallbacks>(mapStateToProsp, mapDispatchToProps)(MovementsList);