import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionCreator } from '../../../store/agents/AgentsState';
import { actionCreator as uiActions } from '../../../store/uiproperties/UIState';
import { AgentsListRapidProvisioning, AgentsListRapidProvisioningProps } from '../shared/agentslistrapidprovisioning';

const mapStateToProsp = (state: ApplicationState): AgentsListRapidProvisioningProps => {

    return {
        hasNextPage: state.agents.searchState.hasNextPage,
        list: state.agents.agentsList,
        startLoading: state.uiProperties.startFetchingPagedList,
        allCommisionTemplate: state.agents.commisionTemplateList
    }
}

const mapDispatchToProps = () => dispatch => {
    return ({
        loadNewPage: () => {
            dispatch(actionCreator.loadNextPage());
        },
        onProvisioningProfileCheck: (agentId: string, commissionProfileId: string, isDefaultCommission: boolean) => {
            dispatch(actionCreator.setRunningAgentForProvisioning(agentId));
            dispatch(actionCreator.setRunningAgentProp("commissionTemplate", commissionProfileId));
            if (isDefaultCommission) {
                dispatch(actionCreator.saveAgent()).then(response => {
                    if (response !== undefined && response != null)
                        dispatch(actionCreator.setRefreshAgentInList());
                });
            }
        },
        onAgentNameClick: (agentId: string) => {
            dispatch(actionCreator.navigateToRunningAgent(agentId));
        },
        setRunningDefaultCommission: (id: string) => {
            dispatch(actionCreator.findRunningCommissionTemplate(id));
            dispatch(actionCreator.toogleJustCommissionTemplateModal(true));
        },
        setRunningManualCommissionForAgent: (agentId: string) => dispatch(actionCreator.setRunningManualCommissionForAgent(agentId)),
        onUnload: () => dispatch(uiActions.startFetchingPagedList(false)),
        restartLoading: () => dispatch(uiActions.startFetchingPagedList(true))

    });
}

export const ConnectedAgentsListRapidProvisioning = connect<AgentsListRapidProvisioningProps>(mapStateToProsp, mapDispatchToProps)(AgentsListRapidProvisioning);