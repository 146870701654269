import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/index';
import { actionCreator } from '../../../../store/profile/RolesState';
import { actionCreator as actionCreatorsUI } from '../../../../store/uiproperties/UIState';
import { RolePageList, RolePageListProps } from '../pages/rolepagelist';

const mapStateToProsp = (state: ApplicationState): RolePageListProps => {

    return {
        elementsSelected: state.roles.rolesToDelete.length > 0
    }
}

const mapDispatchToProps = () => dispatch => {
    return ({
        onLoad: () => {
            dispatch(actionCreator.loadInitialState());
        }
    });
}

export const ConnectedRolePageList = connect<RolePageListProps>(mapStateToProsp, mapDispatchToProps)(RolePageList);