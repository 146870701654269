import * as React from "react";
import { BaseList } from '../../../base/baseList';
import { AgentAccounting } from '../../../../store/eventaccountings/EventAccountingEntities';
import _ from "underscore";

import './agents-accounting.css';
import { CommissionPaymentMode } from "../../../../store/profile/Profile";
import { BodyHeader } from "../../../base/bodyheader";
import { MenuItemProps } from "../../../menu/menuitem";
import { ConnectedOutcomeModal } from "../../../payments/outcome/connected.outcomemodal";

export interface AgentsAccountingProps {
    list: Array<AgentAccounting>;
    eventFullName: string;
}

export interface AgentsAccountingCallbacks {
    openOutcomeModal: (eventId: string) => void;
    onLoad: (eventId: string) => void;
}

export class AgentsAccounting extends React.Component<AgentsAccountingProps & AgentsAccountingCallbacks>{

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onLoad) {
            let eventId = null;
            if ((this.props as any).match !== undefined && (this.props as any).match != null && (this.props as any).match.params !== undefined && (this.props as any).match.params != null)
                eventId = (this.props as any).match.params.eventId;
            this.props.onLoad(eventId);
        }
    }

    private getStandaloneRow(model: AgentAccounting): JSX.Element {
        return (
            <tr key={model.id}>
                <td>
                    <div className="agents-accounting-table-cell">
                        <div>{model.lastName} {model.firstName}</div>
                        <div>-</div>
                    </div>
                </td>
                <td>
                    <div className="agents-accounting-table-cell">
                        <div>-</div>
                        <div>-</div>
                    </div>
                </td>
                <td>
                    <div className="agents-accounting-table-cell">
                        <div>-</div>
                        <div>-</div>
                    </div>
                </td>
                <td>
                    <div className="agents-accounting-table-cell">
                        <div>-</div>
                        <div>-</div>
                    </div>
                </td>
                <td>
                    <div className="agents-accounting-table-cell">
                        <div>-</div>
                    </div>
                </td>
                <td>
                    <div className="agents-accounting-table-cell">
                        <div>{model.paid}€</div>
                        <div>-</div>
                    </div>
                </td>
            </tr >
        )
    }

    private getStandardRow(model: AgentAccounting): JSX.Element {

        let commissionTypeRoom = model.commissionForRoomMode == CommissionPaymentMode.Fixed ? "€" : "%";
        let commissionTypePlayer = model.commissionForPlayerMode == CommissionPaymentMode.Fixed ? "€" : "%";

        let sourceEntryPayment = ""

        if (model.commissionForPlayerMode == CommissionPaymentMode.Percentage) {
            sourceEntryPayment = "(" + model.playerCommissionSource + ")";
        }

        let paymentMode = `${model.type} ${model.mode}`;

        if (model.totalRow)
            paymentMode = "-";

        let totalEarningAndPaidText = "";
        let toPayText = "";

        if (model.totalRow) {
            totalEarningAndPaidText = "font-weight-bold text-warning";
            toPayText = "font-weight-bold";
            if (model.toPay >= 0)
                toPayText = `${toPayText} text-warning`;
            else
                toPayText = `${toPayText} text-danger`;
        }

        return (
            <tr key={model.id}>
                <td>
                    <div className="agents-accounting-table-cell">
                        <div>{model.lastName} {model.firstName}</div>
                        <div>{paymentMode}</div>
                    </div>
                </td>
                <td>
                    <div className="agents-accounting-table-cell">
                        <div>{model.commissionForRoom}{commissionTypeRoom}</div>
                        <div>{model.commissionForPlayer}{commissionTypePlayer} {sourceEntryPayment}</div>
                    </div>
                </td>
                <td>
                    <div className="agents-accounting-table-cell">
                        <div>{model.reservationsNumber} ({model.guestReservations}) - {model.nightsAccounted} ({model.guestNights})</div>
                        <div>{model.entriesNumber}</div>
                    </div>
                </td>
                <td>
                    <div className="agents-accounting-table-cell">
                        <div>{model.totalReservationsEarning}€</div>
                        <div>{model.totalEntriesEarning}€</div>
                    </div>
                </td>
                <td>
                    <div className={`agents-accounting-table-cell ${totalEarningAndPaidText}`}>
                        <div>{model.totalEarning}€</div>
                    </div>
                </td>
                <td>
                    <div className="agents-accounting-table-cell">
                        <div className={totalEarningAndPaidText}>{model.paid}€</div>
                        <div className={toPayText}>{model.toPay}€</div>
                    </div>
                </td>
            </tr >
        )
    }

    private getRows(list: Array<AgentAccounting>): JSX.Element[] {

        let renderList = list.map(model => {
            if (model.standalonePayments)
                return this.getStandaloneRow(model);
            else
                return this.getStandardRow(model);

        });

        return renderList;

    }

    private getHead(): JSX.Element {

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>
                        <div className="agents-accounting-table-cell">
                            <div>Nome</div>
                            <div>Tipo Provvigione</div>
                        </div>
                    </th>
                    <th>
                        <div className="agents-accounting-table-cell">
                            <div>Prov. x prenotaz.</div>
                            <div>Prov. x entry</div>
                        </div>
                    </th>
                    <th>
                        <div className="agents-accounting-table-cell">
                            <div>#Prenotazioni - #Notti</div>
                            <div>#Entries</div>
                        </div>
                    </th>
                    <th>
                        <div className="agents-accounting-table-cell">
                            <div>Tot. Pren.</div>
                            <div>Tot. Entries</div>
                        </div>
                    </th>
                    <th>
                        <div className="agents-accounting-table-cell">
                            <div>Totale</div>
                        </div>
                    </th>
                    <th>
                        <div className="agents-accounting-table-cell">
                            <div>Pagato</div>
                            <div>Da pagare</div>
                        </div>
                    </th>
                </tr>
            </thead>
        );

    }

    render() {

        let menuItems = [
            {
                icon: "arrow-up",
                label: "uscita",
                isButton: true,
                isSmall: true,
                onClick: () => this.props.openOutcomeModal((this.props as any).match.params.eventId)
            } as MenuItemProps,
            {
                icon: "hand-holding-usd",
                label: "conto",
                isButton: false,
                isSmall: true,
                path: `/events/${(this.props as any).match.params.eventId}/v2/accounting`
            } as MenuItemProps
        ] as Array<MenuItemProps>;

        return (
            <React.Fragment>
                <BodyHeader bottomSpace subTitle={this.props.eventFullName} title={`Dettaglio Rendicontazione`} menuItems={menuItems} />
                <BaseList hasNextPage={false} list={this.props.list} active={false} onEndOfList={() => { }} getElements={(list: Array<AgentAccounting>) => { return this.getRows(list) }} getHead={() => { return this.getHead() }} />
                <ConnectedOutcomeModal afterSave={(eventId: string) => this.props.onLoad(eventId)} />
            </React.Fragment>
        )
    }
}