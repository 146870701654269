import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware } from '../../../store/customers/CustomerState';
import { CustomerEditPage, CustomerEditPageProps } from '../pages/customeredit';
import { withRouter } from 'react-router';
import { actionCreator } from '../../../store/uiproperties/UIState';
import { GpModel } from "../../../utils/commons";


const mapStateToProps = (state: ApplicationState): CustomerEditPageProps => {

    return {
        customer: state.customers.runningCustomer
    }
}

const mapDispatchToProps = () => dispatch => {
    return {
        onDelete: () => dispatch(actionsMiddleware.delete()),
        onLoad: (id: string) => dispatch(actionsMiddleware.loadCustomer(id)),
        onSubmit: () => dispatch(actionsMiddleware.save()),
        toogleQRReader: () => dispatch(actionCreator.toogleQrReader()),
        onScan: (data: GpModel) => dispatch(actionsMiddleware.setGPData(data))
    }
}

export const ConnectedCustomerEdit = withRouter(connect<CustomerEditPageProps>(mapStateToProps, mapDispatchToProps)(CustomerEditPage));