import * as React from "react";
import { guid } from '../../utils/commons';

interface RankingStarsProps {
    ranking: number;
    className?: string;
}

export default class RankingStars extends React.Component<RankingStarsProps>{
    constructor(props) {
        super(props);
    }

    private getStars(): JSX.Element[] {

        let stars = [];

        for (let i = 0; i < this.props.ranking; i++) {
            stars.push(<i style={{ marginRight: "2px" }} key={guid()} className="fas fa-star fa-xs"></i>)
        }

        for (let i = this.props.ranking; i < 5; i++) {
            stars.push(<i style={{ marginRight: "2px" }} key={guid()} className="far fa-star fa-xs"></i>)
        }

        return stars;
    }


    render() {

        if (this.props.ranking === undefined || this.props.ranking == null || this.props.ranking < 0 || this.props.ranking > 5) {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }
        let stars = this.getStars();
        return (
            <React.Fragment>
                <span className={this.props.className !== undefined && this.props.className != null ? this.props.className : ''}>
                    {stars}
                </span>
            </React.Fragment>
        );
    }
}