import { Action, Reducer } from 'redux';
import { ApplicationState } from '../../';
import { ajaxRequest, RequestMethods, RequestModel, abortAllRequest, AbortableRequest, checkRefreshToken, SaveRequest } from '../../../utils/ajaxutilities';
import dotProp from "dot-prop";
import _ from "underscore";
import { Payment, PaymentTypology, TrasferCommand } from './PaymentEntities'
import { PaymentItem, PaymentCategory, SystemPaymentItem } from '../paymentitems/PaymentItemEntities';
import $ from "jquery";
import { actionCreator as agentActions, agentFunctions } from '../../agents/AgentsState';
import { Profile } from '../../profile/Profile';
import moment from "moment";


export interface PaymentState {
    transferModal: TrasferModalState;
    incomeModal: IncomeModalState;
    outcomeModal: OutcomeModalState;
    cashFundModal: CashFundModalState;
}

export interface TrasferModalState {
    data: TrasferCommand;
    modalOpen: boolean;
}

export interface IncomeModalState {
    data: Payment;
    modalOpen: boolean;
}

export interface OutcomeModalState {
    data: Payment;
    modalOpen: boolean;
    isAgentModalOpen: boolean;
    agent?: Profile;
}

export interface CashFundModalState {
    data: Payment;
    modalOpen: boolean;
}

const getBaseState = (): PaymentState => ({
    transferModal: getBaseTrasferModalState(),
    incomeModal: getBaseIncomeModalState(),
    outcomeModal: getBaseOutocomeModalState(),
    cashFundModal: getBaseCashFundModalState()
});

const getBaseTrasferModalState = (eventId: string = null, isOpen: boolean = false): TrasferModalState => ({
    data: {
        eventId: eventId,
    },
    modalOpen: isOpen
});

const getBaseIncomeModalState = (eventId: string = null, isOpen: boolean = false): IncomeModalState => ({
    data: {
        eventId: eventId,
        typology: PaymentTypology.Inbound,
        paymentDate: moment().toISOString()
    } as Payment,
    modalOpen: isOpen
});

const getBaseCashFundModalState = (eventId: string = null, isOpen: boolean = false): CashFundModalState => ({
    data: {
        eventId: eventId,
        typology: PaymentTypology.CashFund,
        paymentDate: moment().toISOString()
    } as Payment,
    modalOpen: isOpen
});

const getBaseOutocomeModalState = (eventId: string = null, isOpen: boolean = false): OutcomeModalState => ({
    data: {
        eventId: eventId,
        typology: PaymentTypology.Outbound,
        paymentDate: moment().toISOString()
    } as Payment,
    modalOpen: isOpen,
    isAgentModalOpen: false
});


enum TypeKeys {
    SET_TRAFER_STATE = "PAYMENT_SET_TRAFER_STATE",
    SET_INCOME_STATE = "PAYMENT_SET_INCOME_STATE",
    SET_OUTCOME_STATE = "PAYMENT_SET_OUTCOME_STATE",
    SET_CASHFUND_STATE = "PAYMENT_SET_CASHFUND_STATE",
}

interface SetTrasfeState extends Action {
    type: TypeKeys.SET_TRAFER_STATE;
    state: TrasferModalState;
}

interface SetIncomeState extends Action {
    type: TypeKeys.SET_INCOME_STATE;
    state: IncomeModalState;
}

interface SetOutcomeState extends Action {
    type: TypeKeys.SET_OUTCOME_STATE;
    state: OutcomeModalState;
}

interface SetCashFundState extends Action {
    type: TypeKeys.SET_CASHFUND_STATE;
    state: CashFundModalState;
}

type PaymentActions = SetTrasfeState
    | SetIncomeState
    | SetOutcomeState
    | SetCashFundState;

const actionsCreator = {
    setTrasferState: (state: TrasferModalState): SetTrasfeState => ({
        state: state,
        type: TypeKeys.SET_TRAFER_STATE
    }),
    setIncomeState: (state: IncomeModalState): SetIncomeState => ({
        state: state,
        type: TypeKeys.SET_INCOME_STATE
    }),
    setOutcomeState: (state: OutcomeModalState): SetOutcomeState => ({
        state: state,
        type: TypeKeys.SET_OUTCOME_STATE
    }),
    setCashFundState: (state: CashFundModalState): SetCashFundState => ({
        state: state,
        type: TypeKeys.SET_CASHFUND_STATE
    })
}

export const actionsMiddleware = {
    openTrasfeModal: (eventId: string) => (dispatch, getState) => dispatch(actionsCreator.setTrasferState(getBaseTrasferModalState(eventId, true))),
    closeTrasferModal: () => (dispatch, getState) => dispatch(actionsCreator.setTrasferState(getBaseTrasferModalState(null))),
    editTrasferProperty: (propertyName: string, value: any) => (dispatch, getState: () => ApplicationState) => {
        let newState = { ...getState().payment.transferModal }

        switch (propertyName) {
            case "amount":
                newState.data.amount = value;
                break;
            case "sourceMethod":
                newState.data.sourceMethod = value;
                break;
            case "destinationMethod":
                newState.data.destinationMethod = value;
                break;
            case "notes":
                newState.data.notes = value;
                break;
        }

        dispatch(actionsCreator.setTrasferState(newState));
    },
    saveTrasfer: () => (dispatch, getState: () => ApplicationState): JQueryPromise<boolean> => {
        const deffred = $.Deferred();
        const saveRequest: SaveRequest = new SaveRequest("/payments/trasfer", RequestMethods.POST, getState().payment.transferModal.data);
        ajaxRequest<any>(saveRequest)(dispatch, getState).then(response => {
            if (response.success) {
                dispatch(actionsMiddleware.closeTrasferModal());
                deffred.resolve(true);
            } else {
                deffred.resolve(false);
            }
        });

        return deffred.promise();
    },
    openIncomeModal: (eventId: string) => (dispatch, getState) => dispatch(actionsCreator.setIncomeState(getBaseIncomeModalState(eventId, true))),
    closeIncomeModal: () => (dispatch, getState) => dispatch(actionsCreator.setIncomeState(getBaseIncomeModalState(null))),
    saveIncome: () => (dispatch, getState: () => ApplicationState): JQueryPromise<boolean> => {
        const deffred = $.Deferred();
        const saveRequest: SaveRequest = new SaveRequest("/payments/income", RequestMethods.POST, getState().payment.incomeModal.data);
        ajaxRequest<any>(saveRequest)(dispatch, getState).then(response => {
            if (response.success) {
                dispatch(actionsMiddleware.closeIncomeModal());
                deffred.resolve(true);
            } else {
                deffred.resolve(false);
            }
        });

        return deffred.promise();
    },
    editIncomeProperty: (propertyName: string, value: any) => (dispatch, getState: () => ApplicationState) => {
        let newState = { ...getState().payment.incomeModal } as IncomeModalState;

        switch (propertyName) {
            case "amount":
                newState.data.amount = value;
                break;
            case "paymentItemId":
                newState.data.paymentItemId = value;
                break;
            case "methodId":
                newState.data.methodId = value;
                break;
            case "notes":
                newState.data.notes = value;
                break;
            case "paymentDate":
                newState.data.paymentDate = value;
                break;
        }

        dispatch(actionsCreator.setIncomeState(newState));
    },
    openOutcomeModal: (eventId: string) => (dispatch, getState) => dispatch(actionsCreator.setOutcomeState(getBaseOutocomeModalState(eventId, true))),
    closeOutcomeModal: () => (dispatch, getState) => dispatch(actionsCreator.setOutcomeState(getBaseOutocomeModalState(null))),
    saveOutcome: () => (dispatch, getState: () => ApplicationState): JQueryPromise<boolean> => {
        const deffred = $.Deferred();
        let dataToSave = { ...getState().payment.outcomeModal.data };
        dataToSave.agentId = getState().payment.outcomeModal.agent?.id;
        const saveRequest: SaveRequest = new SaveRequest("/payments/outcome", RequestMethods.POST, dataToSave);
        ajaxRequest<any>(saveRequest)(dispatch, getState).then(response => {
            if (response.success) {
                dispatch(actionsMiddleware.closeOutcomeModal());
                deffred.resolve(true);
            } else {
                deffred.resolve(false);
            }
        });

        return deffred.promise();
    },
    editOutcomeProperty: (propertyName: string, value: any) => (dispatch, getState: () => ApplicationState) => {
        let newState = { ...getState().payment.outcomeModal } as OutcomeModalState;

        switch (propertyName) {
            case "amount":
                newState.data.amount = value;
                break;
            case "paymentItemId":
                newState.data.paymentItemId = value;
                break;
            case "methodId":
                newState.data.methodId = value;
                break;
            case "notes":
                newState.data.notes = value;
                break;
            case "paymentDate":
                newState.data.paymentDate = value;
                break;
        }

        const agentPaymentItem = _.find(getState().paymentItems.paymentItems, (item) => item.systemItem == SystemPaymentItem.Agent);

        if (agentPaymentItem?.id != newState.data.paymentItemId) {
            newState.agent = null;
        }

        dispatch(actionsCreator.setOutcomeState(newState));
    },
    outcomeToggleAgentModal: () => (dispatch, getState: () => ApplicationState) => {
        let newState = { ...getState().payment.outcomeModal } as OutcomeModalState;
        newState.isAgentModalOpen = !newState.isAgentModalOpen;

        if (!newState.isAgentModalOpen) {
            dispatch(agentActions.setSelectedAgent(null));
        } else {
            dispatch(agentActions.loadInitialState(newState.agent));
        }

        dispatch(actionsCreator.setOutcomeState(newState));
    },
    onConfirmAgent: () => (dispatch, getState: () => ApplicationState) => {
        let newState = { ...getState().payment.outcomeModal } as OutcomeModalState;
        newState.agent = { ...getState().agents.selectedAgent }
        newState.isAgentModalOpen = false;
        dispatch(actionsCreator.setOutcomeState(newState));
    },
    openCashFundModal: (eventId: string) => (dispatch, getState) => dispatch(actionsCreator.setCashFundState(getBaseCashFundModalState(eventId, true))),
    closeCashFundModal: () => (dispatch, getState) => dispatch(actionsCreator.setCashFundState(getBaseCashFundModalState(null))),
    saveCashFund: () => (dispatch, getState: () => ApplicationState): JQueryPromise<boolean> => {
        const deffred = $.Deferred();
        const saveRequest: SaveRequest = new SaveRequest("/payments/cash-fund", RequestMethods.POST, getState().payment.cashFundModal.data);
        ajaxRequest<any>(saveRequest)(dispatch, getState).then(response => {
            if (response.success) {
                dispatch(actionsMiddleware.closeCashFundModal());
                deffred.resolve(true);
            } else {
                deffred.resolve(false);
            }
        });

        return deffred.promise();
    },
    editCashFundProperty: (propertyName: string, value: any) => (dispatch, getState: () => ApplicationState) => {
        let newState = { ...getState().payment.cashFundModal } as CashFundModalState;

        switch (propertyName) {
            case "amount":
                newState.data.amount = value;
                break;
            case "methodId":
                newState.data.methodId = value;
                break;
            case "notes":
                newState.data.notes = value;
                break;
            case "paymentDate":
                newState.data.paymentDate = value;
                break;
        }

        dispatch(actionsCreator.setCashFundState(newState));
    },
}


export const reducer: Reducer<PaymentState> = (state: PaymentState | undefined, incomingAction: PaymentActions): PaymentState => {

    if (state === undefined) {
        return getBaseState();
    }

    let newState = { ...state };

    switch (incomingAction.type) {

        case TypeKeys.SET_TRAFER_STATE:
            newState = dotProp.set(newState, "transferModal", { ...incomingAction.state });
            break;
        case TypeKeys.SET_INCOME_STATE:
            newState = dotProp.set(newState, "incomeModal", { ...incomingAction.state });
            break;
        case TypeKeys.SET_OUTCOME_STATE:
            newState = dotProp.set(newState, "outcomeModal", { ...incomingAction.state });
            break;
        case TypeKeys.SET_CASHFUND_STATE:
            newState = dotProp.set(newState, "cashFundModal", { ...incomingAction.state });
            break;

    }

    return newState;
}
