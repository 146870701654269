import * as React from "react";
import { CommunicationListModel } from "../../../store/communications/CommunicationEntities";
import { Location } from '../../../store/locations/LocationEntities'
import { BaseList } from '../../base/baseList';
import _ from "underscore";
import moment from "moment";
import GoToTop from "../../base/gototop";

export interface CommunicationsListProps {
    list: Array<CommunicationListModel>;
    locations: Array<Location>;
}

export interface CommunicationsListCallbacks {
    onRowClick: (locationId: string) => void;
}

export class CommunicationsList extends React.Component<CommunicationsListProps & CommunicationsListCallbacks>{

    constructor(props) {
        super(props);
    }

    private getRows(list: Array<CommunicationListModel>): JSX.Element[] {

        let renderList = list.map(model => {

            let locationName = "-";

            let location = _.find(this.props.locations, (l) => l.id == model.locationId);

            if (location !== undefined && location != null) {
                locationName = location.name;
            }

            return (
                <tr key={model.locationId} style={{ cursor: "pointer" }} onClick={() => this.props.onRowClick(model.locationId)}>
                    <td className="text-capitalize">
                        {locationName}
                    </td>
                    <td>
                        {model.communicationsCount}
                    </td>
                </tr >
            )
        });

        return renderList;

    }

    private getHead(): JSX.Element {

        return (
            <thead>
                <tr className="text-eurorounders">
                    <th>
                        Hotel
                    </th>
                    <th>
                        #Prenotazioni
                    </th>
                </tr>
            </thead>
        );

    }

    render() {

        return (
            <React.Fragment>
                <BaseList hasNextPage={false} list={this.props.list} active={false} onEndOfList={() => { }} getElements={(list: Array<CommunicationListModel>) => { return this.getRows(list) }} getHead={() => { return this.getHead() }} />
                <GoToTop />
            </React.Fragment>
        )
    }
}