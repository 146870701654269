import * as React from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap"
import { ConnectedCurrentProfile } from '../profile/connected/connected.currentprofile';
import { ENVIRONMENT, EnvironmentType } from '../../utils/commons';
import './appheader.css';

export interface HeaderProps {
    name: string;
    surname: string;
    isProfileActive: boolean;
    logout?: () => void;
    onModalOpen?: () => void;
}

interface HeaderState {
    isModalOpened: boolean;
}

export class AppHeader extends React.Component<HeaderProps, HeaderState>{
    constructor(props) {
        super(props);

        this.state = {
            isModalOpened: false
        }
        this.toogleModal = this.toogleModal.bind(this);
    }

    private toogleModal() {

        if (!this.state.isModalOpened) {
            this.props.onModalOpen();
        }

        this.setState({ isModalOpened: !this.state.isModalOpened });
    }

    render() {

        let environment = null;

        if (ENVIRONMENT == EnvironmentType.Stage) {
            environment = (<span style={{ marginLeft: "15px" }} className="text-danger text-uppercase">{ENVIRONMENT}</span>);
        }

        let editingCurrentProfileAccess = (<a className="text-eurorounders text-uppercase" onClick={this.toogleModal} style={{ cursor: "pointer", marginRight: "15px" }}>{this.props.name} {this.props.surname} <i style={{ position: "relative", top: "-2px", left: "2px" }} className="fas fa-pen fa-xs"></i></a>);
        let logoutAction = (<a onClick={this.props.logout} className="text-eurorounders text-uppercase" style={{ cursor: "pointer" }}>LOGOUT</a>);
        if (this.props.isProfileActive != true) {
            editingCurrentProfileAccess = (<React.Fragment></React.Fragment>);
            logoutAction = (<React.Fragment></React.Fragment>);
        }

        return (
            <React.Fragment>
                <div className="er-header">
                    <div className="logo-container">
                        <Link to="/">
                            <img className="logo" src="Logo_eurorounders-03.png" />
                        </Link>
                    </div>
                    {editingCurrentProfileAccess}
                    {logoutAction}
                    {environment}
                </div>
                <Modal className="bg-dark modal-lg" isOpen={this.state.isModalOpened} toggle={this.toogleModal}>
                    <ModalHeader className="text-eurorounders bg-dark text-uppercase" toggle={this.toogleModal}>{this.props.name} {this.props.surname}</ModalHeader>
                    <ModalBody className="bg-dark text-eurorounders modal-header-button-box">
                        <ConnectedCurrentProfile />
                    </ModalBody>
                </Modal>
            </React.Fragment>

        )
    }
}