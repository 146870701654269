import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from '../../../store/index';
import { actionsMiddleware, actionCreator } from '../../../store/locations/LocationState';
import { LocationEditPage, LocationEditPageCallbacks, LocationEditPageProps } from '../pages/locationedit';


const mapStateToProsp = (state: ApplicationState): LocationEditPageProps => {

    return {
        location: state.locations.running,
    }
}

const mapDispatchToProps = () => (dispatch): LocationEditPageCallbacks => {
    return {
        onDelete: () => dispatch(actionsMiddleware.delete()),
        onLoad: (id: string) => dispatch(actionsMiddleware.load(id))
    }
}

export const ConnectedLocationEditPage = withRouter(connect<LocationEditPageProps, LocationEditPageCallbacks>(mapStateToProsp, mapDispatchToProps)(LocationEditPage));