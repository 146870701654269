import { connect } from 'react-redux';
import { withRouter } from "react-router"
import { ApplicationState } from '../../../../store/index';
import { actionsMiddleware } from '../../../../store/payments/paymentmethods/PaymentMethodState';
import { actionCreator as actionCreatorsUI } from '../../../../store/uiproperties/UIState';
import { EditPaymentMethodCallbacks, EditPaymentMethodProps, PaymentMethodEditPage } from '../pages/paymentmethodeditpage';
import $ from "jquery";

const mapStateToProsp = (state: ApplicationState): EditPaymentMethodProps => {
    return {
        paymentMethod: state.paymentMethods.runningPaymentMethod
    }
}

const mapDispatchToProps = () => (dispatch): EditPaymentMethodCallbacks => {
    return ({
        onChange: (desc: string) => {
            dispatch(actionsMiddleware.changeDescription(desc));
            dispatch(actionCreatorsUI.setChangeInProgress(true));
        },
        onDelete: (id: string) => dispatch(actionsMiddleware.delete(id)),
        onLoad: (id: string) => dispatch(actionsMiddleware.setRunning(id)),
        onSubmit: () => {
            $.when(dispatch(actionsMiddleware.save())).then(r => dispatch(actionCreatorsUI.setChangeInProgress(false)));
        }
    });
}

export const ConnectedPaymentMethodEdit = withRouter(connect<EditPaymentMethodProps, EditPaymentMethodCallbacks>(mapStateToProsp, mapDispatchToProps)(PaymentMethodEditPage));