import * as React from "react";
import { Form, FormGroup, Row, Col, Input, Label, InputGroup, InputGroupText, InputGroupAddon, Button } from "reactstrap";
import { SearchCriteria } from '../../../store/reservations/ReservationState';
import { ERDatePicker as DatePicker } from '../../base/datepicker';
import _ from "underscore";

import './searchform.css';
import '../../base/collapsible.css';

import { GpModel } from "../../../utils/commons";
import { GPReader } from "../../base/qrreader";

export interface ReservationSearchFormBaseProps {
    searchCriteria: SearchCriteria;
    moduleLoaded: boolean;
    isSearchCriteriaChanged: boolean;
}

export interface ReservationSearchFormBaseCallbacks {
    onSearch: (propertyName: string, value: any) => void;
    toogleQRReader: () => void;
    removeFilters: () => void;
    toggleAdvancedFilters: () => void;
}


export class ReservationSearchFormBase extends React.Component<ReservationSearchFormBaseProps & ReservationSearchFormBaseCallbacks>{

    constructor(props) {
        super(props)
    }



    private onScan(data: GpModel) {
        if (!data)
            return;
        this.props.onSearch("customerContacts", `${data.firstName} ${data.lastName}`);
    }

    render() {

        if (this.props.moduleLoaded == false) {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }

        let disabledCheckInTo = true;

        if (this.props.searchCriteria?.checkInFrom)
            disabledCheckInTo = false;

        let removeFiltersClass = "";

        if (!this.props.isSearchCriteriaChanged)
            removeFiltersClass = `disable-events`;

        return (
            <React.Fragment>
                <Form className="text-eurorounders" >
                    <GPReader onScan={(data: GpModel) => this.onScan(data)} />
                    <Row>
                        <Col lg="3" sm="6" xs="6">
                            <FormGroup>
                                <Label>Cliente</Label>
                                <InputGroup>
                                    <Input
                                        className="bg-dark text-eurorounders"

                                        type="search"
                                        value={this.props.searchCriteria.customerContacts || ''}
                                        onChange={(e) => { this.props.onSearch("customerContacts", e.target.value) }}
                                        placeholder="Cerca per nome cognome telefono e email"
                                    />
                                    <InputGroupAddon addonType="append">
                                        <Button onClick={() => this.props.toogleQRReader()}>
                                            <i className={`icon fas fa-qrcode fa-xs`} />
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="6" xs="6">
                            <FormGroup>
                                <Label>#Progressivo</Label>
                                <InputGroup>
                                    <Input
                                        className="bg-dark text-eurorounders"
                                        type="search"
                                        value={this.props.searchCriteria.progressiveNumber || ''}
                                        onChange={(e) => { this.props.onSearch("progressiveNumber", e.target.value) }}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="text-eurorounders bg-dark">#</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="6" xs="6">
                            <FormGroup>
                                <Label>Check In da</Label>
                                <DatePicker selectedDate={this.props.searchCriteria.checkInFrom} onChange={date => this.props.onSearch("checkInFrom", date)} />
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="6" xs="6">
                            <FormGroup>
                                <Label>Check In a</Label>
                                <DatePicker disabled={disabledCheckInTo} selectedDate={this.props.searchCriteria.checkInTo} onChange={date => this.props.onSearch("checkInTo", date)} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <div className="collapsible-content text-eurorounders">
                    <div className="title-container">
                        <span className="title" onClick={() => this.props.toggleAdvancedFilters()}>Ricerca avanzata</span>
                    </div>
                    <span className={`button ${removeFiltersClass}`} onClick={() => this.props.removeFilters()}>PULISCI</span>
                </div>
            </React.Fragment>
        );
    }

}