import * as React from "react";
import { Row, Col } from "reactstrap";
import { EventAccounting } from '../../../../store/eventaccountings/EventAccountingEntities';

export interface TotalAccountingProps {
    accounting: EventAccounting;
}


export class TotalAccounting extends React.Component<TotalAccountingProps> {

    constructor(props) {
        super(props);
    }

    render() {

        if (this.props.accounting === undefined || this.props.accounting == null)
            return (<></>);

        let totalText = "text-danger";

        if (this.props.accounting.totalNetGain >= 0) {
            totalText = "text-success";
        }

        return (
            <Row className="text-eurorounders" style={{ marginTop: "15px" }}>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            totale prenotazioni "cash"
                        </span>
                        <span className="float-right text-success">
                            {this.props.accounting.totalStandardBookingGross} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            TOTAL PRENOTAZIONI WEB
                        </span>
                        <span className="float-right text-success">
                            {this.props.accounting.totalWebBookingGross} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            TOTALE DA ENTRIES
                        </span>
                        <span className="float-right text-success">
                            {this.props.accounting.totalEarningByEntries} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            COSTO AGENTI per PRENOTAZIONI
                        </span>
                        <span className="float-right text-warning">
                            {this.props.accounting.totalAgentsCostByReservations} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            COSTO AGENTI per Entries
                        </span>
                        <span className="float-right text-warning">
                            {this.props.accounting.totalAgentsCostByEntries} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            COSTO NOTTI VENDUTE
                        </span>
                        <span className="float-right text-warning">
                            {this.props.accounting.totalSoldNightsCost} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            COSTO NOTTI INVENDUTE
                        </span>
                        <span className="float-right text-warning">
                            {this.props.accounting.totalUnsoldNightsCost} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            COSTO NOTTI STAFF
                        </span>
                        <span className="float-right text-warning">
                            {this.props.accounting.totalStaffNightsCost} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            COSTO NOTTI STAFF HOTEL
                        </span>
                        <span className="float-right text-warning">
                            {this.props.accounting.totalStaffHotelNightsCost} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            COSTO NOTTI GUEST
                        </span>
                        <span className="float-right text-warning">
                            {this.props.accounting.totalGuestNightsCost} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            TASSE NOTTI "CASH"
                        </span>
                        <span className="float-right text-warning">
                            {this.props.accounting.totalStandardBookingTax} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            TASSE NOTTI WEB
                        </span>
                        <span className="float-right text-warning">
                            {this.props.accounting.totalWebBookingTax} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            TASSE NOTTI STAFF
                        </span>
                        <span className="float-right text-warning">
                            {this.props.accounting.totalStaffTax} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            TASSE NOTTI STAFF HOTEL
                        </span>
                        <span className="float-right text-warning">
                            {this.props.accounting.totalStaffHotelTax} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            TASSE NOTTI GUEST
                        </span>
                        <span className="float-right text-warning">
                            {this.props.accounting.totalGuestTax} €
                        </span>
                    </div>
                </Col>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <div className="clearfix font-weight-bold text-uppercase" style={{ width: "100%" }}>
                        <span className="float-left">
                            Totale
                        </span>
                        <span className={`float-right ${totalText}`}>
                            {this.props.accounting.totalNetGain} €
                        </span>
                    </div>
                </Col>
            </Row>
        )
    }
}