import * as React from "react";
import { Form, FormGroup, Row, Col, Input, Label, InputGroup, InputGroupText, InputGroupAddon, Button } from "reactstrap";
import { PaymentTypology, PaymentsSearchCriteria } from '../../../../store/payments/payments/PaymentEntities';
import { PaymentItem } from '../../../../store/payments/paymentitems/PaymentItemEntities';
import { PaymentMethod } from '../../../../store/payments/paymentmethods/PaymentMethodEntities';
import { KeyDesc } from '../../../../store/shared/KeyDesc';
import { ERDatePicker as DatePicker } from '../../../base/datepicker';
import BaseSelect from '../../../base/baseselect';
import Collapsible, { CollapsibleContentButton } from '../../../base/collapsiblecontent';


export interface MovementsSearchFormProps {
    searchCriteria: PaymentsSearchCriteria;
    methods: Array<PaymentMethod>;
    paymentItems: Array<PaymentItem>;
    typologies: Array<KeyDesc<PaymentTypology>>;
    orderDirections: Array<KeyDesc<string>>;
}

export interface MovementsSearchFormCallbacks {
    onSearch: (propertyName: string, value: any) => void;
    removeFilters: () => void;
}


export class MovementsSearchForm extends React.Component<MovementsSearchFormProps & MovementsSearchFormCallbacks>{

    constructor(props) {
        super(props)
    }

    render() {

        let disabledItems = "";

        if (!this.props.searchCriteria.typology || this.props.searchCriteria.typology == PaymentTypology.CashFund)
            disabledItems = "disable-events";


        //let clearFilterButton: CollapsibleContentButton = {
        //    isActive: this.props.isSearchCriteriaChanged,
        //    label: "pulisci",
        //    onClick: this.props.removeFilters
        //}

        return (
            <React.Fragment>
                <Form className="text-eurorounders" >
                    <Row>
                        <Col lg="3" sm="6" xs="6">
                            <FormGroup>
                                <Label>Data</Label>
                                <DatePicker selectedDate={this.props.searchCriteria.date} onChange={date => this.props.onSearch("date", date)} />
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="6" xs="6">
                            <FormGroup>
                                <Label>Metodo</Label>
                                <BaseSelect
                                    descField="method"
                                    idField="id"
                                    options={this.props.methods}
                                    value={this.props.searchCriteria.paymentMethod}
                                    onChange={(value: any) => { this.props.onSearch("paymentMethod", value) }} />
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="6" xs="6">
                            <FormGroup>
                                <Label>Tipologia</Label>
                                <BaseSelect
                                    descField="desc"
                                    idField="key"
                                    options={this.props.typologies}
                                    value={this.props.searchCriteria.typology}
                                    onChange={(value: any) => { this.props.onSearch("typology", value) }} />
                            </FormGroup>
                        </Col>
                        <Col lg="3" sm="6" xs="6">
                            <FormGroup>
                                <Label>Oggetto</Label>
                                <BaseSelect
                                    descField="description"
                                    idField="id"
                                    options={this.props.paymentItems}
                                    value={this.props.searchCriteria.paymentItem}
                                    onChange={(value: any) => { this.props.onSearch("paymentItem", value) }}
                                    className={disabledItems}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Collapsible title="Ricerca avanzata">
                        <Row>
                            <Col lg="6" sm="6" xs="6">
                                <FormGroup>
                                    <Label>Direzione ordinamento</Label>
                                    <BaseSelect
                                        descField="desc"
                                        idField="key"
                                        options={this.props.orderDirections}
                                        value={this.props.searchCriteria.orderDirection}
                                        onChange={(value: any) => { this.props.onSearch("orderDirection", value) }} />
                                </FormGroup>
                            </Col>
                            <Col lg="6" sm="6" xs="6">
                                <FormGroup>
                                    <Label>Note</Label>
                                    <Input
                                        className="bg-dark text-eurorounders"
                                        type="search"
                                        value={this.props.searchCriteria.notes || ''}
                                        onChange={(e) => { this.props.onSearch("notes", e.target.value) }}
                                        placeholder="Cerca nelle note"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Collapsible>
                </Form>
            </React.Fragment>
        );
    }

}