import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from '../../store/index';
import { actionsMiddleware } from '../../store/kpis/KpiState';
import { actionsMiddleware as eventMiddleware } from '../../store/events/EventState';
import { KpiPage, KpiPageCallbacks, KpiPageProps } from './kpipage';
import _ from "underscore";

const mapStateToProsp = (state: ApplicationState): KpiPageProps => {

    return {
        dataLoaded: state.kpis.moduleLoaded,
        model: state.kpis.kpi,
        eventFullName: state.events.running !== undefined && state.events.running != null ? `${state.events.running.type !== undefined && state.events.running.type != null ? state.events.running.type : ''} ${state.events.running.name.toUpperCase()}` : ''
    }
}

const mapDispatchToProps = () => (dispatch): KpiPageCallbacks => {
    return ({
        onLoad: (eventId: string) => {
            dispatch(actionsMiddleware.loadInitialState(eventId));
        },
        loadExternalModule: () => {
            dispatch(eventMiddleware.loadInitialStateExternal([], true, 10))
        },
        onSelectEvent: (id: string) => eventMiddleware.onSelect(id),
        onEventsConfirmed: () => dispatch(actionsMiddleware.onEventsConfirmed()),
        onDelete: (eventId: string) => dispatch(actionsMiddleware.onDelete(eventId))
    });
}

export const ConnectedKpi = withRouter(connect<KpiPageProps, KpiPageCallbacks>(mapStateToProsp, mapDispatchToProps)(KpiPage));

//import * as React from "react";
//import { connect } from 'react-redux';
//import { withRouter } from "react-router";
//import { ApplicationState } from '../../store/index';
//import { actionsMiddleware } from '../../store/kpis/KpiState';
//import { Kpi, KpiCallbacks, KpiProps } from './kpi';
//import _ from "underscore";

//const mapStateToProsp = (state: ApplicationState): KpiProps => {

//    return {
//        dataLoaded: state.kpis.moduleLoaded,
//        actualEvent: state.events.running,
//        kpi: state.kpis.kpi
//    }
//}

//const mapDispatchToProps = () => (dispatch): KpiCallbacks => {
//    return ({
//        onLoad: (eventId: string) => {
//            dispatch(actionsMiddleware.loadInitialState(eventId));
//        }
//    });
//}

//export const ConnectedKpi = withRouter(connect<KpiProps, KpiCallbacks>(mapStateToProsp, mapDispatchToProps)(Kpi));