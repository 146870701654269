import * as React from "react";
import { Customer } from "../../../store/customers/CustomerEntities";
import { BaseList } from '../../base/baseList';
import { CheckBox } from '../../base/checkbox';
import RankingStars from '../../base/rankingstars';
import _ from "underscore";
import GoToTop from "../../base/gototop";

export interface CustomersListProps {
    selectionEnabled: boolean;
    selectedCustomers?: Array<Customer>;
    list: Array<Customer>;
    hasNextPage: boolean;
    loadNewPage?: () => void;
    startLoading?: boolean;
    onRowClick?: (id: string) => void;
    onUnload?: () => void;
}

export class CustomersList extends React.Component<CustomersListProps>{

    constructor(props) {
        super(props);
    }

    private getRows(list: Array<Customer>): JSX.Element[] {

        let customerArray = list.map(customer => {

            let checkbox = (<React.Fragment></React.Fragment>);

            let isSelected = false;

            if (this.props.selectedCustomers !== undefined && this.props.selectedCustomers != null && this.props.selectedCustomers.length > 0) {
                isSelected = _.some(this.props.selectedCustomers, (c) => c.id == customer.id)
            }

            if (this.props.selectionEnabled == true) {
                checkbox = (
                    <td>
                        <CheckBox isChecked={isSelected} onCheck={(checked: boolean) => { }} />
                    </td>
                )
            }

            
            const ranking = (<RankingStars ranking={customer.rating} />);

            return (
                <tr key={customer.id} style={{ cursor: "pointer" }} onClick={() => this.props.onRowClick(customer.id)}>
                    {checkbox}
                    <td>
                        {customer.lastName} {customer.firstName}
                    </td>
                    <td className="d-none d-md-table-cell">
                        {customer.email}
                    </td>
                    <td>
                        {customer.phone}
                    </td>
                    <td className="d-none d-md-table-cell">
                        {ranking}
                    </td>
                </tr >
            )
        });

        return customerArray;

    }

    private getHead(): JSX.Element {

        let thSelection = (<React.Fragment></React.Fragment>);

        if (this.props.selectionEnabled == true) {
            thSelection = (<th>Sel.</th>);
        }

        return (
            <thead>
                <tr className="text-eurorounders">
                    {thSelection}
                    <th>Nome</th>
                    <th className="d-none d-md-table-cell">Email</th>
                    <th>Telefono</th>
                    <th className="d-none d-md-table-cell">Rating</th>
                </tr>
            </thead>
        );

    }


    render() {

        return (
            <React.Fragment>
                <BaseList onUnload={this.props.onUnload} hasNextPage={this.props.hasNextPage} list={this.props.list} active={this.props.startLoading} onEndOfList={this.props.loadNewPage} getElements={(list: Array<Customer>) => { return this.getRows(list) }} getHead={() => { return this.getHead() }} />
                <GoToTop />
            </React.Fragment>
        )
    }
}